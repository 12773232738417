import React, { Component, Fragment } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import { putMileage, rejectMileage } from 'ducks/validateMileage';
import { showModal, hideModal } from 'ducks/modal';

import { Subtitle, Info, FlexWrapper, PlaceholderImage } from 'common';
import ValidateMileageForm from '../ValidateMileageForm';
import RejectMileageForm from '../RejectMileageForm';

import { ImagePreview, ModalContent, UserName, ImageContainer, ModalContainer } from './styled';

class ValidateMileageModal extends Component {
  state = {
    rejected: false,
  }

  onSubmitMileage = (mileage) => {
    const {
      car, onPutMileage, hideMileageModal, showMileageModal,
    } = this.props;

    const payload = {
      id: car.id,
      body: mileage,
      mileage_id: car.current_mileage ? car.current_mileage.id : null,
    };

    const nextCar = this.findNextCar();
    onPutMileage(payload).then(() => (nextCar ? showMileageModal(nextCar) : hideMileageModal()));
  }

  findNextCar = () => {
    const { car, allMileage, hideNextCar } = this.props;

    if (hideNextCar) return false;

    const carIndex = allMileage.findIndex(singleCar => singleCar.id === car.id);

    if (carIndex < allMileage.length - 1) {
      return allMileage[carIndex + 1];
    }
  }

  render() {
    const { car, onRejectMileage } = this.props;

    return (
      <ModalContainer>
        <FlexWrapper>
          <ImageContainer>
            {car.current_mileage.verification_image ? (
              <ImagePreview image={car.current_mileage.verification_image} />
            ) : (
              <PlaceholderImage size="80%" />
            )}
          </ImageContainer>
          <ModalContent>
            {this.state.rejected ? (
              <RejectMileageForm
                onCancel={() => this.setState({ rejected: false })}
                onSubmit={values => onRejectMileage(car.id, car.current_mileage.id, values)}
              />
            ) : (
              <Fragment>
                <Subtitle>{car.model}</Subtitle>
                <UserName>{car.user.full_name}</UserName>
                <Info label="Jaar">
                  {moment(car.model_year).format('YYYY')}
                </Info>
                <Info label="Verdiende punten">
                  {`+ ${car.current_mileage.points} pt`}
                </Info>
                <Info label="Laatst gevalideerde km&apos;s">
                  {car.validated_mileage}
                </Info>
                <ValidateMileageForm
                  onSubmit={this.onSubmitMileage}
                  mileage={car.current_mileage.mileage}
                  rejectClaim={() => this.setState({ rejected: true })}
                  nextCar={this.findNextCar()}
                />
              </Fragment>
            )}
          </ModalContent>
        </FlexWrapper>
      </ModalContainer>
    );
  }
}

ValidateMileageModal.propTypes = {
  allMileage: PT.oneOfType([PT.array, PT.object]),
  car: PT.object,
  onRejectMileage: PT.func,
  onPutMileage: PT.func,
  hideMileageModal: PT.func,
  showMileageModal: PT.func,
  hideNextCar: PT.bool,
};

const mapStateToProps = state => ({
  allMileage: state.validateMileage.data,
});

const mapDispatchToProps = dispatch => ({
  showMileageModal: (car) => {
    dispatch(showModal({
      type: 'VALIDATE_MILEAGE',
      modalProps: { car },
    }));
  },
  hideMileageModal: () => {
    dispatch(hideModal());
  },
  onPutMileage: payload => (
    dispatch(putMileage(payload))
  ),
  onRejectMileage: (carId, mileageId, values) => (
    dispatch(rejectMileage(carId, mileageId, values)).then(dispatch(hideModal()))
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ValidateMileageModal);
