import React, { Fragment } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';

import formatFiltersToQuery from 'services/formatFiltersToQuery';
import isTicketInvite from 'services/isTicketInvite';
import { inviteDriversToEvent } from 'ducks/inviteDrivers';

import { Container, Title, Button, Subtitle, Card, Loading } from 'common';
import { StepsContainer, StepsItem } from 'common/steps';
import DriversList from 'modules/DriversOverview/components/DriversList';

import { Subject, DriversListContainer, ActionContainer } from './styled';

const InviteDriversConfirm = ({
  match, history, invitedData, onInviteDrivers, selectedDrivers, selectedDriversCount, dealers, levels, loading,
}) => {
  const sendInvites = () => {
    const id = isTicketInvite(match.params) ? match.params.ticketId : match.params.eventId;
    onInviteDrivers(id, invitedData, isTicketInvite(match.params)).then(() => history.push(`/event/${match.params.eventId}/overview`));
  };

  const invitedDrivers = Object.keys(invitedData.invite).filter(checked => invitedData.invite[checked]);

  const getReadableList = selectedFilters => (
    Object.keys(selectedFilters).map((filter) => {
      // classic is not supported in backend
      if (filter === 'classic') return null;

      if (filter === 'page') return null;

      let readableListNames = '';
      let text = '';

      if (filter !== 'search') {
        selectedFilters[filter].map((filterItem, index) => {
          if (filter === 'dealer') {
            filterItem = dealers.find(dealer => dealer.id === filterItem).name;
          }

          if (filter === 'level') {
            filterItem = levels.find(level => level.id === filterItem).description;
          }

          if (index < selectedFilters[filter].length - 2) {
            readableListNames += `${filterItem}, `;
          } else if (index === selectedFilters[filter].length - 2) {
            readableListNames += `${filterItem} en `;
          } else {
            readableListNames += `${filterItem}`;
          }
        });
      }

      switch (filter) {
      case 'level': text = readableListNames; break;
      case 'tag': text = `Geïnteresseerd in ${readableListNames}`; break;
      case 'model': text = `Eigenaar van een ${readableListNames}`; break;
      case 'status': text = `Account status ${readableListNames}`; break;
      case 'dealer': text = `Dealer ${readableListNames}`; break;
      case 'search': text = `Zoekresultaten van '${selectedFilters.search}'`; break;
      case 'model_groups': text = `Modelgroepen ${readableListNames}`; break;
      default: text = readableListNames;
      }

      return <li key={filter}>{text}</li>;
    })
  );

  return (
    <Container>
      <StepsContainer>
        <StepsItem
          onClick={() => (
            history.push(`/event/${match.params.eventId}/invite${isTicketInvite(match.params) ? `/${match.params.ticketId}` : ''}/select${formatFiltersToQuery(invitedData)}`)
          )}
        >
          1. Porsche rijders
        </StepsItem>
        <StepsItem
          onClick={() => (
            history.push(`/event/${match.params.eventId}/invite${isTicketInvite(match.params) ? `/${match.params.ticketId}` : ''}/message`)
          )}
        >
          2. Bericht
        </StepsItem>
        <StepsItem active>3. Bevestig</StepsItem>
      </StepsContainer>
      <Title underline>Bevestig en verzend uw uitnodigingen</Title>
      <Subtitle>Bericht</Subtitle>
      <Card>
        <Subject>{invitedData.subject}</Subject>
        <p>
          {invitedData.message.split('\n').map((line, index) => (
            <span key={index}>
              {line}
              <br />
            </span>
          ))}
        </p>
      </Card>
      <DriversListContainer>
        {!invitedData.user_filters ? (
          <Fragment>
            <Subtitle>{invitedDrivers.length} Porsche rijder{invitedDrivers.length === 1 ? '' : 's'}</Subtitle>
            <DriversList drivers={selectedDrivers} />
          </Fragment>
        ) : (
          <Fragment>
            <Subtitle>{selectedDriversCount} Porsche rijder{selectedDriversCount === 1 ? '' : 's'} op basis van:</Subtitle>
            <ul>
              {getReadableList(invitedData.user_filters)}
            </ul>
          </Fragment>
        )}
      </DriversListContainer>
      <ActionContainer>
        <Button onClick={sendInvites} disabled={loading}>Bevestig en verzend &gt;</Button>
        {loading && <Loading small />}
      </ActionContainer>
    </Container>
  );
};

InviteDriversConfirm.propTypes = {
  invitedData: PT.object,
  selectedDrivers: PT.array,
  history: PT.object,
  match: PT.object,
  onInviteDrivers: PT.func,
  selectedDriversCount: PT.object,
  dealers: PT.array,
  levels: PT.array,
  loading: PT.bool,
};

export default connect(state => ({
  dealers: state.dealers.data,
  selectedDrivers: state.inviteDrivers.data,
  selectedDriversCount: state.inviteDrivers.selectedDriversCount,
  levels: state.levels.data,
  driversStats: state.drivers.stats,
  loading: state.inviteDrivers.loading.inviteDrivers,
  invitedData: getFormValues('invite-drivers')(state),
}), { onInviteDrivers: inviteDriversToEvent })(InviteDriversConfirm);
