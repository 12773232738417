import React from 'react';
import PT from 'prop-types';
import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  hasRole,
  isOwnDealerEvent,
  isOwnEvent,
  COMMUNITY_MANAGER,
} from 'services/authHelper';
import { isPublished } from 'services/eventHelper';
import { Small, PlaceholderImage } from 'common';

import ActionButtons from './components/ActionButtons';
import Statistics from './components/Statistics';
import StatusLabel from './components/StatusLabel';

import {
  ExtendedCard, ContentContainer, ContentRow, Image, Title, EventDate, Day,
  Month, BottomContainer, ActionsContainer, ImageContainer,
} from './styled';

const EventCard = ({
  event, noActionButtons, past, history, showBottomBar, readonly,
  dealer, roles, user,
}) => {
  const cardClickHandler = () => {
    if (readonly) return;

    history.push(isPublished(event) || past ? `/event/${event.id}` : `/event/edit/${event.id}`);
  };

  const hasFullAccess = () => {
    if (hasRole(roles, [COMMUNITY_MANAGER])) return true;
    if (isOwnDealerEvent(event, dealer)) return true;
    if (isOwnEvent(event, user)) return true;
    return false;
  };

  return (
    <ExtendedCard
      onClick={cardClickHandler}
      compact
      readonly
      data-cy={`event-${event.id}`}
    >
      <ImageContainer>
        {event.image
          ? <Image backgroundImage={event.image} />
          : <PlaceholderImage />
        }
      </ImageContainer>
      <ContentContainer>
        <ContentRow>
          <div>
            <Title>
              {event.name || 'Nog geen titel'} <StatusLabel event={event} />
            </Title>
            {event.category && <Small>{event.category.name}</Small>}
            {event.organizer && <Small>{event.organizer}</Small>}
          </div>
          {event.start_date && (
            <EventDate>
              <Day>
                {moment(event.start_date).format('DD')}
                {!moment(event.start_date).isSame(moment(event.end_date), 'day') &&
                  ` - ${event.end_date ? moment(event.end_date).format('DD') : 'Geen datum'}`}
              </Day>
              <Month>
                {moment(event.start_date).format('MMM YYYY')}

                {!moment(event.start_date).isSame(moment(event.end_date), 'month') &&
                  ` - ${event.end_date ? moment(event.end_date).format('MMM YYYY') : 'Geen datum'}`}
              </Month>
            </EventDate>
          )}
        </ContentRow>

        {showBottomBar &&
          <BottomContainer>
            {isPublished(event) && (
              <Statistics event={event} past={past} hasFullAccess={hasFullAccess()} />
            )}
            {!noActionButtons && (
              <ActionsContainer>
                <ActionButtons event={event} />
              </ActionsContainer>
            )}
          </BottomContainer>
        }
      </ContentContainer>
    </ExtendedCard>
  );
};

EventCard.propTypes = {
  event: PT.object,
  noActionButtons: PT.bool,
  past: PT.bool,
  history: PT.object,
  showBottomBar: PT.bool,
  readonly: PT.bool,
  dealer: PT.object,
  roles: PT.array,
  user: PT.object,
};

export default compose(
  withRouter,
  connect(state => ({
    roles: state.authentication.roles,
    dealer: state.authentication.data.role.dealer,
    user: state.authentication.data,
  })),
)(EventCard);
