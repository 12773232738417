import styled from 'styled-components';

import { Title, FlexWrapper } from 'common';

export const ExtendedTitle = styled(Title)`
  margin: 0;
  font-size: 36px;
`;

export const Name = styled.p`
  letter-spacing: .05em;
  margin: 0;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div`
  margin-right: 9px;
`;

export const CardActions = styled.div`
  display: flex;
`;

export const CardContent = styled(FlexWrapper)`
  margin-top: 40px;
`;
