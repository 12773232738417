import React, { Fragment } from 'react';
import PT from 'prop-types';

import { Small } from 'common';
import { ContentRowStats, ContentBlock, Stats } from './styled';

const Statistics = ({ event, past, hasFullAccess }) => (
  <ContentRowStats>
    {!past ? (
      <Fragment>
        <ContentBlock>
          <Stats>{event.attendee || 0}</Stats>
          <Small>Aanwezig</Small>
        </ContentBlock>
        <ContentBlock>
          <Stats>{event.interested || 0}</Stats>
          <Small>Interesse</Small>
        </ContentBlock>
        <ContentBlock>
          <Stats>{event.invited || 0}</Stats>
          <Small>Uitgenodigd</Small>
        </ContentBlock>
      </Fragment>
    ) : (
      <Fragment>
        <ContentBlock>
          <Stats>{event.attended || 0}</Stats>
          <Small>Aanwezig</Small>
        </ContentBlock>
        <ContentBlock>
          <Stats>{event.attendee_percentage || 0}%</Stats>
          <Small>Vol</Small>
        </ContentBlock>
        <ContentBlock>
          <Stats>{event.sold || 0}</Stats>
          <Small>Verkocht</Small>
        </ContentBlock>
        {hasFullAccess && (
          <ContentBlock>
            <Stats>€{event.total_revenue || 0}</Stats>
            <Small>Opbrengst</Small>
          </ContentBlock>
        )}
      </Fragment>
    )}
  </ContentRowStats>
);

Statistics.propTypes = {
  event: PT.object,
  past: PT.bool,
  hasFullAccess: PT.bool,
};

export default Statistics;
