import createAction from 'services/createAction';

const CREATE_CUSTOM_REWARD = 'customReward/CREATE';
const CREATE_CUSTOM_REWARD_SUCCESS = 'customReward/CREATE_SUCCESS';
const CREATE_CUSTOM_REWARD_FAILED = 'customReward/CREATE_FAILED';

const GET_CUSTOM_REWARD = 'customReward/GET';
const GET_CUSTOM_REWARD_SUCCESS = 'customReward/GET_SUCCESS';
const GET_CUSTOM_REWARD_FAILED = 'customReward/GET_FAILED';

const EDIT_CUSTOM_REWARD = 'customReward/EDIT';
const EDIT_CUSTOM_REWARD_SUCCESS = 'customReward/EDIT_SUCCESS';
const EDIT_CUSTOM_REWARD_FAILED = 'customReward/EDIT_FAILED';

const ARCHIVE_CUSTOM_REWARD = 'customReward/ARCHIVE';
const ARCHIVE_CUSTOM_REWARD_SUCCESS = 'customReward/ARCHIVE_SUCCESS';
const ARCHIVE_CUSTOM_REWARD_FAILED = 'customReward/ARCHIVE_FAILED';

const RESET_CUSTOM_REWARD = 'customReward/RESET';

const initialState = {
  data: {},
  error: {
    message: '',
  },
  loading: {
    create: false,
    get: false,
    edit: false,
    archive: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case CREATE_CUSTOM_REWARD_SUCCESS:
    return {
      ...state,
      loading: { ...state.loading, create: false },
    };
  case CREATE_CUSTOM_REWARD_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, create: false },
    };
  case CREATE_CUSTOM_REWARD:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, create: true },
    };
  case GET_CUSTOM_REWARD_SUCCESS:
    return {
      ...state,
      data: payload,
      loading: { ...state.loading, get: false },
    };
  case GET_CUSTOM_REWARD_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, get: false },
    };
  case GET_CUSTOM_REWARD:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, get: true },
    };
  case EDIT_CUSTOM_REWARD_SUCCESS:
    return {
      ...state,
      loading: { ...state.loading, edit: false },
    };
  case EDIT_CUSTOM_REWARD_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, edit: false },
    };
  case EDIT_CUSTOM_REWARD:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, edit: true },
    };
  case ARCHIVE_CUSTOM_REWARD_SUCCESS:
    return {
      ...state,
      loading: { ...state.loading, archive: false },
    };
  case ARCHIVE_CUSTOM_REWARD_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, archive: false },
    };
  case ARCHIVE_CUSTOM_REWARD:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, archive: true },
    };
  case RESET_CUSTOM_REWARD:
    return {
      ...initialState,
    };
  default:
    return state;
  }
};

export const resetCustomReward = createAction(RESET_CUSTOM_REWARD);

const createCustomRewardSuccess = createAction(CREATE_CUSTOM_REWARD_SUCCESS);
const createCustomRewardFailed = createAction(CREATE_CUSTOM_REWARD_FAILED);

export const createCustomReward = formData => (dispatch, createState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: CREATE_CUSTOM_REWARD });

    api.post({ path: 'rewards', body: formData, v2: true })
      .then(() => {
        dispatch(createCustomRewardSuccess());
        resolve();
      })
      .catch((err) => {
        dispatch(createCustomRewardFailed({ message: err.data }));
        reject(err);
      });
  })
);

const getCustomRewardSuccess = createAction(GET_CUSTOM_REWARD_SUCCESS);
const getCustomRewardFailed = createAction(GET_CUSTOM_REWARD_FAILED);

export const getCustomReward = rewardId => (dispatch, createState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: GET_CUSTOM_REWARD });

    api.get({ path: `rewards/${rewardId}`, v2: true })
      .then((res) => {
        dispatch(getCustomRewardSuccess(res));
        resolve(res);
      })
      .catch((err) => {
        dispatch(getCustomRewardFailed({ message: err.data }));
        reject(err);
      });
  })
);

const editCustomRewardSuccess = createAction(EDIT_CUSTOM_REWARD_SUCCESS);
const editCustomRewardFailed = createAction(EDIT_CUSTOM_REWARD_FAILED);

export const editCustomReward = ({ rewardId, formData }) => (dispatch, createState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: EDIT_CUSTOM_REWARD });

    api.put({ path: `rewards/${rewardId}`, body: formData, v2: true })
      .then((res) => {
        dispatch(editCustomRewardSuccess(res));
        resolve(res);
      })
      .catch((err) => {
        dispatch(editCustomRewardFailed({ message: err.data }));
        reject(err);
      });
  })
);

const archiveCustomRewardSuccess = createAction(ARCHIVE_CUSTOM_REWARD_SUCCESS);
const archiveCustomRewardFailed = createAction(ARCHIVE_CUSTOM_REWARD_FAILED);

export const archiveCustomReward = rewardId => (dispatch, createState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: ARCHIVE_CUSTOM_REWARD });

    api.del({ path: `rewards/${rewardId}`, v2: true })
      .then(() => {
        dispatch(archiveCustomRewardSuccess());
        resolve();
      })
      .catch((err) => {
        dispatch(archiveCustomRewardFailed({ message: err.data }));
        reject(err);
      });
  })
);
