import styled from 'styled-components';
import { media } from 'styles/utils';
import { ContentRow } from '../../styled';

export const ContentRowStats = styled(ContentRow)`
  justify-content: center;
  margin: 18px 0;

  ${media.tablet`
    margin: 0;
  `}
`;

export const ContentBlock = styled.div`
  border-right: 1px solid ${props => props.theme.border};
  padding: 0 18px;
  flex: 1;
  text-align: center;

  &:last-of-type {
    border: 0;
  }
`;

export const Stats = styled.p`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin: 0;
`;
