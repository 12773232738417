import React, { Component } from 'react';
import PT from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getPages, getPage } from 'ducks/brandPortal';

import { BrandPortal } from 'modules';

class BrandPortalSubPage extends Component {
  componentDidMount() {
    if (this.props.pages.length === 0) this.props.getPages();
    this.getSubPage(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.subPage !== nextProps.match.params.subPage
      || this.props.pages.length !== nextProps.pages.length) {
      this.getSubPage(nextProps);
    }
  }

  getSubPage = (props) => {
    const { match: { params }, pages } = props;

    if (pages.length > 0) {
      const currentPage = pages.find(page => page.slug === params.topic).top_pages
        .find(topPage => topPage.slug === params.page).sub_pages
        .find(subPage => subPage.slug === params.subPage);

      this.props.getPage(currentPage.id);
    }
  }

  render() {
    return (
      <BrandPortal pages={this.props.pages} page={this.props.page} />
    );
  }
}

BrandPortalSubPage.propTypes = {
  getPages: PT.func,
  pages: PT.array,
  match: PT.object,
  page: PT.object,
  getPage: PT.func,
};

export default compose(
  withRouter,
  connect(state => ({
    pages: state.brandPortal.pages,
    page: state.brandPortal.page,
  }), { getPages, getPage }),
)(BrandPortalSubPage);
