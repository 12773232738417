/* global google */
import React, { Component, Fragment } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import { change } from 'redux-form';

import { InputError } from 'common/form';

import AsyncSelectStyled from './styled';

class LocationFinder extends Component {
  state = {
    selectedOption: null,
  }

  getDetailType = (data, types) => {
    const detailType = data.filter(info => types.some(type => info.types.includes(type)));
    return detailType.length === 0 ? '' : detailType[0].long_name;
  }

  parseAddressDetail = ({ address_components: addressComponents, geometry }) => ({
    city: this.getDetailType(addressComponents, ['locality', 'administrative_area_level_2']),
    housenumber: this.getDetailType(addressComponents, ['street_number']),
    latitude: geometry.location.lat(),
    longitude: geometry.location.lng(),
    country: this.getDetailType(addressComponents, ['country']),
    zipcode: this.getDetailType(addressComponents, ['postal_code']),
    street: this.getDetailType(addressComponents, ['route', 'establishment', 'natural_feature']),
  });

  handleChange = (selectedOption) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ placeId: selectedOption.value }, (result) => {
      Object.entries(this.parseAddressDetail(result[0])).forEach((detail) => {
        this.props.change(this.props.meta.form, detail[0], detail[1]);
      });
    });
    this.setState({ selectedOption });
  }

  loadOptions = (inputValue) => {
    const autocomplete = new google.maps.places.AutocompleteService();

    return new Promise((resolve) => {
      autocomplete.getPlacePredictions({ input: inputValue }, results => resolve(this.parseSelectData(results || [])));
    });
  }

  parseSelectData = data => data.map(result => ({ value: result.place_id, label: result.description }));

  parseDefaultValue = data => ({
    label: data.filter(part => part).join(', '),
    value: data.filter(part => part).join(', '),
  });

  render() {
    const { defaultValue, meta } = this.props;

    return (
      <Fragment>
        <AsyncSelectStyled
          cacheOptions
          classNamePrefix="react-select"
          className="react-select"
          value={this.state.selectedOption || this.parseDefaultValue(defaultValue)}
          onChange={this.handleChange}
          loadOptions={this.loadOptions}
          error={meta.error}
          submitFailed={meta.submitFailed}
        />
        <InputError meta={meta} />
      </Fragment>
    );
  }
}

LocationFinder.propTypes = {
  change: PT.func,
  meta: PT.object,
  defaultValue: PT.array,
};

export default connect(null, { change })(LocationFinder);
