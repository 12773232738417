import React, { Component } from 'react';
import { connect } from 'react-redux';
import PT from 'prop-types';

import { getPointsHistoryFromDriver } from 'ducks/driver/pointsHistory';

import { Container, EmptyState, Loading } from 'common';
import DriverTabsPointsHistoryCard from '../DriverTabsPointsHistoryCard';

class DriverTabsPointsHistory extends Component {
  componentWillMount() {
    if (this.props.driverFromPoints !== this.props.driver.id) {
      this.props.getPointsHistoryFromDriver(this.props.driver.id);
    }
  }

  render() {
    const { pointsHistory, loading } = this.props;

    if (loading) return <Container><Loading /></Container>;

    return (
      <Container>
        {pointsHistory.length === 0 ? (
          <EmptyState
            title="Nog geen points history"
            subtitle="Gebruiker heeft tot op heden nog geen punten gekregen."
          />
        ) : pointsHistory.map(pointHistory => (
          <DriverTabsPointsHistoryCard
            key={pointHistory.id}
            pointHistory={pointHistory}
          />
        ))}
      </Container>
    );
  }
}

DriverTabsPointsHistory.propTypes = {
  driver: PT.object,
  driverFromPoints: PT.string,
  getPointsHistoryFromDriver: PT.func,
  pointsHistory: PT.array,
  loading: PT.bool,
};

export default connect(state => ({
  driver: state.driver.data,
  pointsHistory: state.pointsHistory.data,
  loading: state.pointsHistory.loading.pointsHistory,
  driverFromPoints: state.pointsHistory.driverId,
}), { getPointsHistoryFromDriver })(DriverTabsPointsHistory);
