import styled from 'styled-components';

export const Icon = styled.span`
  color: ${props => props.theme.red};
  font-weight: 700;
  font-size: 18px;
  vertical-align: middle;
`;

export const ActionEmailContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;

  button:first-of-type {
    margin-right: 0;
  }

  button:last-of-type {
    margin-left: 36px;
  }
`;
