import React from 'react';
import PT from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { withRouter } from 'react-router-dom';

import { saveEvent } from 'ducks/editEvent';
import { showNotification } from 'ducks/notification';

import { HeaderBar } from 'common';

import Logo from 'images/logo.png';
import Cross from 'vectors/Cross.svg';

import { ExtendedContainer, Image, ExtendedAnchor, ExtendedTitle } from './styled';

const EventHeader = ({
  formData, onSaveEvent, onShowNotification, history, eventData,
}) => (
  <HeaderBar fixed>
    <ExtendedContainer>
      <Image src={Logo} alt="logo" onClick={() => history.push('/')} />
      <ExtendedTitle data-cy="title-event-header" variant="gray" small>{formData.name ? formData.name : eventData.name}</ExtendedTitle>
      <ExtendedAnchor
        bold
        small
        onClick={() => {
          if (Object.keys(formData).length > 0) {
            onSaveEvent(formData).then(() => {
              onShowNotification('Het event is succesvol opgeslagen.', 'green');
              history.push('/');
            });
          } else {
            onShowNotification('Het event is succesvol opgeslagen.', 'green');
            history.push('/');
          }
        }}
        data-cy="button-event-save"
      >
          Opslaan &amp; sluiten <Cross />
      </ExtendedAnchor>
    </ExtendedContainer>
  </HeaderBar>
);

EventHeader.propTypes = {
  formData: PT.object,
  onSaveEvent: PT.func,
  onShowNotification: PT.func,
  history: PT.object,
  eventData: PT.object,
};

export default compose(
  withRouter,
  connect(state => ({
    eventData: state.editEvent.data,
    formData: getFormValues('create-event-details')(state) || {},
  }), {
    onSaveEvent: saveEvent,
    onShowNotification: showNotification,
  }),
)(EventHeader);
