import React, { Component } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import { change, getFormValues } from 'redux-form';

import { Anchor, LimitFilter } from 'common';
import InviteNewDriversModal from 'modules/DriversOverview/components/InviteNewDriversModal';
import FiltersHeaderSearch from '../FiltersHeaderSearch';

import { Container, Stats, LeftContainer, Icon } from './styled';

class FiltersHeader extends Component {
  state = {
    inviteNewDriversOpen: false,
  }

  setLimit = (e) => {
    this.props.onChange('drivers-filters', 'limit', e.value);
    this.props.onChange('drivers-filters', 'page', '1');
  }

  toggleNewDriversModal = () => {
    this.setState({
      inviteNewDriversOpen: !this.state.inviteNewDriversOpen,
    });
  }

  render() {
    const { stats, filterValues, invite } = this.props;

    return (
      <Container>
        <LeftContainer>
          <FiltersHeaderSearch />
          <Stats>{stats.current || 0}/{stats.total || 0}</Stats>
        </LeftContainer>
        <LimitFilter
          selectedValue={filterValues.limit}
          onChange={this.setLimit}
        />
        {!invite && (
          <Anchor onClick={this.toggleNewDriversModal} small bold>
            Nodig nieuwe Porsche rijder uit <Icon>+</Icon>
          </Anchor>
        )}

        {this.state.inviteNewDriversOpen && (
          <InviteNewDriversModal
            toggleModal={this.toggleNewDriversModal}
          />
        )}
      </Container>
    );
  }
}

FiltersHeader.propTypes = {
  stats: PT.shape({
    current: PT.number,
    total: PT.number,
  }),
  filterValues: PT.object,
  onChange: PT.func,
  invite: PT.bool,
};

export default connect(state => ({
  filterValues: getFormValues('drivers-filters')(state),
}), { onChange: change })(FiltersHeader);
