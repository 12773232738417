import React from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';

import Cross from 'vectors/Cross.svg';
import { hideNotification } from 'ducks/notification';

import NotificationContainer from './styled';

const Notification = ({ notification, noAnimation, handleHide }) => {
  if (!notification.isActive) return null;

  return (
    <NotificationContainer variant={notification.variant} noAnimation={noAnimation} data-cy="container-notification">
      {notification.message}
      <Cross onClick={handleHide} />
    </NotificationContainer>
  );
};

Notification.propTypes = {
  notification: PT.object,
  noAnimation: PT.bool,
  handleHide: PT.func,
};

export default connect(state => ({
  notification: state.notification,
}), { handleHide: hideNotification })(Notification);
