import styled from 'styled-components';
import Dropzone from 'react-dropzone';

import { Button } from 'common';

export const DropzoneStyled = styled(Dropzone)`
  display: flex;
  border: 1px dashed ${props => props.theme.border};
  border-radius: 0;
  padding: 20px;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  label {
    margin: 0;
  }
`;

export const Thumbnail = styled.img`
  max-width: 100%;
  height: auto;
`;

export const ExtendedButton = styled(Button)`
  padding: 10px 16px;
  letter-spacing: 0;
  font-size: 15px;
  text-transform: none;
  position: absolute;
  right: 0;
  top: 0;
  background-color: ${props => props.theme.black};
  opacity: 0.5;

  &:hover {
    opacity: 0.8;
  }
`;

export const ThumbnailsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 18px 0;
`;

export const ThumbnailContainer = styled.div`
  position: relative;
  margin-right: 18px;
  width: 225px;
  height: 225px;
  background-image: url('${props => props.backgroundImage}');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border: 1px dashed ${props => props.theme.border};
`;
