import createAction from 'services/createAction';

const CREATE = 'users/CREATE';
const CREATE_SUCCESS = 'users/CREATE_SUCCESS';
const CREATE_FAILED = 'users/CREATE_FAILED';

const initialState = {
  error: {
    message: '',
  },
  loading: {
    create: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case CREATE_SUCCESS:
    return {
      ...state,
      loading: { ...state.loading, create: false },
    };
  case CREATE_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, create: false },
    };
  case CREATE:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, create: true },
    };
  default:
    return state;
  }
};

const createUserSuccess = createAction(CREATE_SUCCESS);
const createUserFailed = createAction(CREATE_FAILED);

export const createUser = values => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: CREATE });

    api.post({
      path: 'management/user',
      v2: true,
      body: values,
    })
      .then((res) => {
        dispatch(createUserSuccess());
        resolve(res);
      })
      .catch((err) => {
        dispatch(createUserFailed({ message: err.data }));
        reject(err);
      });
  })
);
