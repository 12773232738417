import React from 'react';

import { Title, Container } from 'common';
import { DriversOverview } from 'modules';

const Drivers = () => (
  <Container>
    <Title underline>Rijder Overzicht</Title>
    <DriversOverview />
  </Container>
);

export default Drivers;
