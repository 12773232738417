import styled from 'styled-components';
import PT from 'prop-types';

import Porsche24Logo from 'images/porsche-24-logo.png';

const PlaceholderImage = styled.div`
  background-image: url(${Porsche24Logo});
  background-color: ${props => props.theme.background.dark};
  opacity: 0.1;
  background-size: ${props => props.size || '60%'};
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
`;

PlaceholderImage.propTypes = {
  size: PT.string,
};

export default PlaceholderImage;
