import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PT from 'prop-types';

import { getBadgesFromDriver, addBadgeToDriver } from 'ducks/driver/getBadges';
import { showNotification } from 'ducks/notification';
import ArrowDown from 'vectors/ArrowDown.svg';

import { DropdownActions, ActionsList, ActionsListItem, DropdownButton, DropdownListContainer,
  ActionText, ArrowIcon } from './styled';

class AddBadgeDropdown extends Component {
  state = {
    isOpen: false,
    badges: [],
  };

  addBadge = async (badgeId, progress, achieved) => {
    if (achieved) return;

    this.setState({ isOpen: false });

    const driverBadge = this.props.driverBadges.find(b => b.id === badgeId);    
    const currentProgress = driverBadge?.progress_value || 0;
    let diff = 0;

    // Some badges don't have "tiers" but are still grouped together. These badges can only progress to 1
    if (progress === 1) {
      diff = progress;
    } else {
      diff = progress - currentProgress;
    }

    // Redundancy to prevent removing badge progress
    if (diff <= 0) {
      this.props.showNotification('Deze rijder heeft al deze badge.', 'red');
      return;
    }

    this.props.addBadgeToDriver(this.props.driver.id, badgeId, diff).then(() => {
      this.props.getBadgesFromDriver(this.props.driver.id);
    })
  }

  badgeWithTiersDTO = (badge) => (tier, i, list) => {
    const driverBadge = this.props.driverBadges.find(b => b.id === badge.badge_id);
    const tierIdx = list.findIndex(t => t.name === driverBadge?.name);
    const achieved = tierIdx >= i;

    return {
      badgeId: badge.badge_id,
      name: tier.name,
      progress: tier.target,
      achieved,
    };
  }

  // Some badges don't have "tiers" but are still grouped together.
  badgeWithoutTiersDTO = (badge) => {
    const driverBadge = this.props.driverBadges.find(b => b.id === badge.badge_id);
  
    return {
      badgeId: badge.badge_id,
      name: badge.tiers[0].name,
      progress: badge.tiers[0].target,
      achieved: driverBadge?.achieved_tier,
    };
  }

  render() {
    const classicBadge = this.props.badges?.data.find((badge) => badge.name === 'Classic');
    const collectorBadge = this.props.badges?.data.find((badge) => badge.name === 'Collector');
    const rallyBadge = this.props.badges?.data.find((badge) => badge.name === 'Rally');
    const trackBadge = this.props.badges?.data.find((badge) => badge.name === 'Track');
    const kilometerBadge = this.props.badges?.data.find((badge) => badge.name === 'Kilometer');
    const PADTBadges = this.props.badges?.data.filter((badge) => badge.name === 'PADT');
    const PTEBadges = this.props.badges?.data.filter((badge) => ['Warm-up', 'Precision', 'Performance'].includes(badge.name));
    const iceBadges = this.props.badges?.data.filter((badge) => ['Ice Experience', 'Ice Force', 'Ice Force Pro'].includes(badge.name));

    const badges = [
      { name: 'Classic', tiers: classicBadge?.tiers.map(this.badgeWithTiersDTO(classicBadge)) },
      { name: 'Collector', tiers: collectorBadge?.tiers.map(this.badgeWithTiersDTO(collectorBadge)) },
      { name: 'Ice', tiers: iceBadges.map(this.badgeWithoutTiersDTO) },
      { name: 'Kilometer', tiers: kilometerBadge?.tiers.map(this.badgeWithTiersDTO(kilometerBadge)) },
      { name: 'PADT', tiers: PADTBadges.map(this.badgeWithoutTiersDTO) },
      { name: 'PTE', tiers: PTEBadges.map(this.badgeWithoutTiersDTO) },
      { name: 'Rally', tiers: rallyBadge?.tiers.map(this.badgeWithTiersDTO(rallyBadge)) },
      { name: 'Track', tiers: trackBadge?.tiers.map(this.badgeWithTiersDTO(trackBadge)) },
    ]

    return (
      <div>
        <DropdownButton
          sub
          small
          square
          onClick={(e) => {
            e.stopPropagation();
            this.setState(prevState => ({ isOpen: !prevState.isOpen }));
          }}
        >
          Voeg badge toe
          <ArrowDown />
        </DropdownButton>

        {this.state.isOpen && (
          <DropdownListContainer>
            <DropdownActions>
              <ActionsList>
                {badges.map((badge) => (
                  <ActionsListItem key={badge.name}>
                    <ActionText>
                      {badge.name}
                      <ArrowIcon />
                    </ActionText>
                    <DropdownActions>
                      <ActionsList>
                        {badge.tiers.map((tier) => (
                          <ActionsListItem
                            key={tier.name}
                            onClick={() => this.addBadge(tier.badgeId, tier.progress, tier.achieved)}
                            disabled={tier.achieved}
                          >
                            <ActionText>{tier.name}</ActionText>
                          </ActionsListItem>
                        ))}
                      </ActionsList>
                    </DropdownActions>
                  </ActionsListItem>
                ))}
              </ActionsList>
            </DropdownActions>
          </DropdownListContainer>
        )}
      </div>
    );
  }
}

AddBadgeDropdown.propTypes = {
  driver: PT.object,
  addBadgeToDriver: PT.func,
  getBadgesFromDriver: PT.func,
  showNotification: PT.func,
  badges: PT.object,
  driverBadges: PT.arrayOf(PT.object),
};

const mapStateToProps = state => ({
  driver: state.driver.data,
  badges: state.badges,
  driverBadges: state.getBadges.data,
});

const mapDispatchToProps = {
  addBadgeToDriver,
  getBadgesFromDriver,
  showNotification,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AddBadgeDropdown);
