import createAction from 'services/createAction';

const GET_EVENT_EMAILS = 'eventEmails/GET';
const GET_EVENT_EMAILS_SUCCESS = 'eventEmails/GET_SUCCESS';
const GET_EVENT_EMAILS_FAILED = 'eventEmails/GET_FAILED';

const initialState = {
  data: [],
  eventId: '',
  error: {
    message: '',
  },
  loading: {
    eventEmails: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case GET_EVENT_EMAILS_SUCCESS:
    return {
      ...state,
      data: payload.data,
      eventId: payload.eventId,
      loading: { ...state.loading, eventEmails: false },
    };
  case GET_EVENT_EMAILS_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, eventEmails: false },
    };
  case GET_EVENT_EMAILS:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, eventEmails: true },
    };
  default:
    return state;
  }
};

const getEventEmailsSuccess = createAction(GET_EVENT_EMAILS_SUCCESS);
const getEventEmailsFailed = createAction(GET_EVENT_EMAILS_FAILED);

export const getEventEmails = eventId => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: GET_EVENT_EMAILS });

    api.get({ path: 'emails', query: { event_id: eventId }, v2: true })
      .then((res) => {
        dispatch(getEventEmailsSuccess({ data: res, eventId }));
        resolve(res);
      })
      .catch((err) => {
        dispatch(getEventEmailsFailed({ message: err.data }));
        reject(err);
      });
  })
);
