import styled from 'styled-components';
import { media } from 'styles/utils';

export const StatsContainer = styled.div`
  border-top: 1px solid ${props => props.theme.border};
  border-bottom: 1px solid ${props => props.theme.border};
  display: flex;
  justify-content: center;
  padding: 36px;
  margin-top: 70px;
`;

export const ContentBlock = styled.div`
  border-right: 1px solid ${props => props.theme.border};
  padding: 0 18px;
  flex: 1;
  text-align: center;

  &:last-of-type {
    border: 0;
  }
`;

export const Amount = styled.p`
  font-size: 24px;
  margin: 0;
  font-weight: 700;
`;

export const StatsTitle = styled.p`
  color: ${props => props.theme.gray};
  text-transform: uppercase;
  font-weight: 700;
  font-size: 10px;
  margin: 0;

  ${media.tablet`
    font-size: 18px;
  `}
`;
