import React from 'react';
import PT from 'prop-types';

import { Subtitle } from 'common';

import MailIcon from 'vectors/mail.svg';
import PhoneIcon from 'vectors/phone.svg';

import { ContactBlock, Contact, ContactName, ContactRole, AnchorLink } from './styled';

const EventPreviewContactBlock = ({ data, noMargin, contactRole }) => (
  <ContactBlock noMargin={noMargin}>
    <Subtitle bold>Contact</Subtitle>
    <Contact>
      <ContactName>{data.contact_person}</ContactName>
      <ContactRole>{contactRole || 'Organisator'} </ContactRole>
    </Contact>
    {data.dealer && (
      <Contact>
        <ContactName>{data.dealer.name}</ContactName>
        <ContactRole>Dealership</ContactRole>
      </Contact>
    )}
    <Contact>
      <AnchorLink as="a" href={`mailto:${data.email}`}><MailIcon /> {data.email}</AnchorLink>
      <AnchorLink as="a" href={`tel:${data.phone}`}><PhoneIcon /> {data.phone}</AnchorLink>
    </Contact>
  </ContactBlock>
);

EventPreviewContactBlock.propTypes = {
  data: PT.object,
  noMargin: PT.bool,
  contactRole: PT.string,
};

export default EventPreviewContactBlock;
