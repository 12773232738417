import React, { Fragment } from 'react';
import PT from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  hasRole,
  isOwnDealerEvent,
  isOwnEvent,
  COMMUNITY_MANAGER,
  EVENT_MANAGER,
  PARTNER,
} from 'services/authHelper';

import { Card, Button } from 'common';
import { Label, SubLabel } from 'common/form';

import OkSvg from 'vectors/ok.svg';

import TicketForm from '../TicketForm';
import TicketAmountForm from '../TicketAmountForm';

import { Header, ExtendedTitle, TicketInfo, ImageContainer, ExtendedSubLabel, TicketEventDetailInfo, HeaderContainer, TicketIdContainer, TicketId } from './styled';

const copyText = (e) => {
  const el = e.target;
  el.select();
  document.execCommand('Copy');
  setTimeout(() => el.blur(), 1000);
};

const Ticket = ({
  data, summary, edit, onToggleEdit, onDeleteTicket, newTicket, history, eventId, eventDetail, roles, user, event, onInviteDrivers,
}) => (
  <Card data-cy={`container-event-ticket-${edit ? 'edit' : 'view'}`} compact>
    <Header>
      <HeaderContainer>
        <ExtendedTitle small>{data.name} {eventDetail && `- ${data.ticket_type.name}`}</ExtendedTitle>
        {eventDetail && (
          <TicketIdContainer>
            <TicketId
              value={data.id}
              onClick={copyText}
              readOnly
            />
          </TicketIdContainer>
        )}
      </HeaderContainer>

      {!hasRole(roles, [EVENT_MANAGER, PARTNER]) && (
        !edit && eventDetail && (
          <Button
            variant="black"
            small
            onClick={onInviteDrivers}
          >
            Uitnodigen
          </Button>
        )
      )}

      {((hasRole(roles, [COMMUNITY_MANAGER, PARTNER]) || isOwnDealerEvent(event, user.role.dealer) || isOwnEvent(event, user)) && !edit) && (
        !summary ? (
          <Fragment>
            <Button
              onClick={() => onToggleEdit(data.id)}
              variant="black"
              data-cy="button-event-ticket-edit"
              small
            >
              {data.sold ? 'Wijzig aantal' : 'Wijzigen'}
            </Button>
            {!hasRole(roles, [PARTNER]) && (
              <Button
                onClick={() => onDeleteTicket(data.id)}
                data-cy="button-event-ticket-delete"
                small
                sub
              >
                  Verwijderen
              </Button>
            )}
          </Fragment>
        ) : (
          <Button
            variant={!eventDetail ? 'black' : undefined}
            sub={eventDetail}
            small
            onClick={() => history.push(`/event/edit/${eventId}/tickets/${data.id}`)}
          >
            Wijzigen
          </Button>
        )
      )}
    </Header>

    {!edit ? (
      <TicketInfo>
        {!eventDetail ? (
          <Fragment>
            <div>
              <Label>{data.ticket_type.name}</Label>
              {data.points && <SubLabel groupWithLabel>{data.points.name}</SubLabel>}
              {data.custom_fields && data.custom_fields.filter(field => field.item).length > 0 && (
                <ExtendedSubLabel>
                  <ImageContainer><OkSvg /></ImageContainer> Bevat aanvullende gegevens
                </ExtendedSubLabel>
              )}
              {data.checklist && data.checklist.filter(check => check.special).length > 0 && (
                <ExtendedSubLabel>
                  <ImageContainer><OkSvg /></ImageContainer> Bevat deelnemersvoorwaarden
                </ExtendedSubLabel>
              )}
              {data.checklist && data.checklist.filter(check => !check.special).length > 0 && (
                <ExtendedSubLabel>
                  <ImageContainer><OkSvg /></ImageContainer> Bevat checklists
                </ExtendedSubLabel>
              )}
            </div>
            <div>
              <Label>EUR {data.price_incl}</Label>
              <SubLabel groupWithLabel>Beschikbaar: {data.quantity - data.sold}</SubLabel>
              <SubLabel groupWithLabel>Max. aantal p.p.: {data.limit_per_person}</SubLabel>
            </div>
          </Fragment>
        ) : (
          <TicketEventDetailInfo>
            <div>
              <Label>Tickets verkocht</Label>
              <SubLabel groupWithLabel>{data.sold} van {data.quantity}</SubLabel>
            </div>
            <div>
              <Label>Uitnodigingen verstuurd</Label>
              <SubLabel groupWithLabel>{data.invited}</SubLabel>
            </div>
          </TicketEventDetailInfo>
        )}
      </TicketInfo>
    ) : (
      !data.sold ? (
        <TicketForm data={data} onToggleEdit={onToggleEdit} newTicket={newTicket} />
      ) : (
        <TicketAmountForm data={data} onToggleEdit={onToggleEdit} />
      )
    )}
  </Card>
);

Ticket.defaultProps = {
  summary: false,
};

Ticket.propTypes = {
  data: PT.object,
  edit: PT.bool,
  onToggleEdit: PT.func,
  onDeleteTicket: PT.func,
  summary: PT.bool,
  newTicket: PT.bool,
  history: PT.object,
  eventId: PT.string,
  eventDetail: PT.bool,
  roles: PT.array,
  user: PT.object,
  event: PT.object,
  onInviteDrivers: PT.func,
};

export default compose(
  withRouter,
  connect(state => ({
    roles: state.authentication.roles,
    user: state.authentication.data,
    event: state.event.data,
  })),
)(Ticket);
