import styled from 'styled-components';

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
`;

export const ModalContainer = styled.div`
  padding: 18px 54px 30px 54px;
`;

export const SearchContainer = styled.div`
  width: 210px;
`;

export const AttendingContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 13px;

  & > button {
    margin: 0 18px;
  }
`;

export const DropdownList = styled.div`
  position: absolute;
  margin-top: 10px;
  background-color: ${props => props.theme.white};
  box-shadow: ${props => props.theme.boxShadow};
  border: 1px solid ${props => props.theme.border.withBoxShadow};
`;

export const DropdownItem = styled.div`
  list-style: none;
  text-align: center;
  border-bottom: 1px solid ${props => props.theme.border};
  background-color: ${props => props.theme.white};
  font-weight: 700;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
  padding: 5px;
  cursor: pointer;
  padding: 5px 15px;

  &:last-of-type {
    border: 0;
  }

  &:hover {
    background-color: ${props => props.theme.border};
    color: ${props => props.theme.white};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
`;

export const ModalFooter = styled.div`
  margin-top: 20px;
`;
