import React, { Component, Fragment } from 'react';
import PT from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm, Field, change, isDirty, initialize, getFormValues } from 'redux-form';

import * as validation from 'services/validations';
import { createEventEmail, getEventEmail, updateEventEmail, resetEventEmail } from 'ducks/eventEmail';
import { showNotification } from 'ducks/notification';

import { Title, Subtitle, Container, Button, LabelTag, Column, Row } from 'common';
import { Wysiwyg, Label, SubLabel, InputText, FormField, FormSection } from 'common/form';

import { ButtonContainer, StyledCheckbox, ExtendedLabelTag } from './styled';

class EventDetailEmailEditor extends Component {
  state = {
    checked: [false, false, false],
    editMode: !!this.props.match.params.emailId,
  }

  componentDidMount() {
    if (this.state.editMode) {
      this.props.getEventEmail(this.props.match.params.emailId)
        .then(res => this.initializeForm(res));
    }
  }

  componentWillUnmount() {
    this.props.resetEventEmail();
  }

  onSubmit = (values) => {
    const { history, match, create, onShowNotification, update } = this.props;

    if (this.state.editMode) {
      const valuesEdit = { ...values, event_id: match.params.eventId };
      return update({ formData: valuesEdit, emailId: match.params.emailId })
        .then((res) => {
          onShowNotification('Je wijzigingen zijn opgeslagen', 'green');
          window.scrollTo(0, 0);
          this.initializeForm(res);
        })
        .catch(() => onShowNotification('Er is iets misgegaan bij het opslaan. Probeer nog eens.', 'red'));
    }

    return create({ formData: values, eventId: match.params.eventId })
      .then((res) => {
        onShowNotification('Je email is opgeslagen als draft', 'green');
        history.push(`/event/${match.params.eventId}/emails/edit/${res.id}`);
        window.scrollTo(0, 0);
        this.setState({ editMode: true });
        this.initializeForm(res);
      })
      .catch(() => onShowNotification('Er is iets misgegaan bij het opslaan. Probeer nog eens.', 'red'));
  }

  initializeForm = (res) => {
    this.props.dispatch(initialize('edit-event-email', {
      subject: res.subject,
      content: {
        ...res.content,
      },
    }));

    this.setState({
      checked: [!!res.content.section_1_button_href, !!res.content.section_2_button_href, !!res.content.section_3_button_href],
    });
  }

  handleCheckbox = (e, index) => {
    const checkedArrayCopy = this.state.checked;
    checkedArrayCopy[index] = e.target.checked;
    this.setState({ checked: checkedArrayCopy });

    if (!e.target.checked) {
      this.props.change(`content.section_${index + 1}_button_text`, '');
      this.props.change(`content.section_${index + 1}_button_href`, '');
    }
  }

  handleBackButton = () => {
    if (this.props.dirty) {
      // eslint-disable-next-line
      const warning = confirm('De wijzigingen zijn niet opgeslagen. Weet je het zeker?');

      if (!warning) return false;
    }

    return this.props.history.push(`/event/${this.props.match.params.eventId}/emails`);
  }

  render() {
    const { history, match, handleSubmit, dirty, formData } = this.props;

    return (
      <Container>
        <form
          onSubmit={handleSubmit(this.onSubmit)}
        >
          <ButtonContainer>
            <Button
              variant="black"
              small
              onClick={this.handleBackButton}
            >
              Terug naar overzicht
            </Button>
            <div>
              {this.state.editMode && dirty && <ExtendedLabelTag variant="warning">Unsaved changes</ExtendedLabelTag>}
              <Button small sub type="submit">Save</Button>
              <Button
                small
                onClick={() => history.push(`/event/${match.params.eventId}/emails/preview/${match.params.emailId}`)}
                disabled={!this.state.editMode || dirty}
              >
                Preview & send
              </Button>
            </div>
          </ButtonContainer>

          <div>
            <Title underline>
              {this.state.editMode ? 'Edit email' : 'New email'}
              {this.state.editMode && <LabelTag>Draft</LabelTag>}
            </Title>
            <FormField>
              <Label>Subject</Label>
              <Field
                component={InputText}
                name="subject"
                validate={validation.required}
              />
            </FormField>

            <Subtitle>Intro</Subtitle>
            <FormSection>
              <FormField>
                <Label>Aanhef</Label>
                <SubLabel groupWithLabel>De naam wordt automatisch ingevuld</SubLabel>
                <Row verticalAlign>
                  <Column>
                    <Field
                      component={InputText}
                      name="content.intro_heading"
                      validate={validation.required}
                    />
                  </Column>
                  <Column flex={3}>
                    <Subtitle small bold noWhitespace>[VOORNAAM],</Subtitle>
                  </Column>
                </Row>
              </FormField>
              <FormField>
                <Label>Intro tekst</Label>
                <Field
                  component={Wysiwyg}
                  name="content.intro_body"
                  validate={validation.required}
                  defaultValue={formData.content ? formData.content.intro_body : ''}
                />
              </FormField>
            </FormSection>

            <Subtitle>Block 1</Subtitle>
            <FormSection flex>
              <Column>
                <FormField>
                  <Label>Titel</Label>
                  <Field
                    component={InputText}
                    name="content.section_1_heading"
                    validate={validation.required}
                  />
                </FormField>
                <FormField>
                  <Label>Tekst</Label>
                  <Field
                    component={Wysiwyg}
                    name="content.section_1_body"
                    validate={validation.required}
                    defaultValue={formData.content ? formData.content.section_1_body : ''}
                  />
                </FormField>
                <FormField>
                  <Label noWhitespace>
                    <StyledCheckbox
                      checked={this.state.checked[0]}
                      onChange={e => this.handleCheckbox(e, 0)}
                    />
                    Link toevoegen
                  </Label>
                </FormField>
                {this.state.checked[0] && (
                  <Fragment>
                    <FormField>
                      <Label>Button tekst</Label>
                      <Field
                        component={InputText}
                        name="content.section_1_button_text"
                      />
                    </FormField>
                    <FormField>
                      <Label>Button url</Label>
                      <SubLabel groupWithLabel>Let op! Zorg dat de url begint met http:&#47;&#47; of https:&#47;&#47; </SubLabel>
                      <Field
                        component={InputText}
                        name="content.section_1_button_href"
                      />
                    </FormField>
                  </Fragment>
                )}
              </Column>
            </FormSection>

            <Subtitle>Block 2</Subtitle>
            <FormSection flex>
              <Column>
                <FormField>
                  <Label>Titel</Label>
                  <Field
                    component={InputText}
                    name="content.section_2_heading"
                    validate={validation.required}
                  />
                </FormField>
                <FormField>
                  <Label>Tekst</Label>
                  <Field
                    component={Wysiwyg}
                    name="content.section_2_body"
                    validate={validation.required}
                    defaultValue={formData.content ? formData.content.section_2_body : ''}
                  />
                </FormField>
                <FormField>
                  <Label noWhitespace>
                    <StyledCheckbox
                      checked={this.state.checked[1]}
                      onChange={e => this.handleCheckbox(e, 1)}
                    />
                    Link toevoegen
                  </Label>
                </FormField>
                {this.state.checked[1] && (
                  <Fragment>
                    <FormField>
                      <Label>Button tekst</Label>
                      <Field
                        component={InputText}
                        name="content.section_2_button_text"
                      />
                    </FormField>
                    <FormField>
                      <Label>Button url</Label>
                      <SubLabel groupWithLabel>Let op! Zorg dat de url begint met http:&#47;&#47; of https:&#47;&#47; </SubLabel>
                      <Field
                        component={InputText}
                        name="content.section_2_button_href"
                      />
                    </FormField>
                  </Fragment>
                )}
              </Column>
            </FormSection>

            <FormField>
              <Label>Afsluitende tekst en afzender</Label>
              <SubLabel groupWithLabel>
                Deze footer moet het volgende omvatten: contactgegevens, afsluitende regel en afzender. Voorbeeld:<br /><br />
                We kijken uit naar je komst.<br />
                Bij vragen kunt u contact opnemen met Porsche Nederland via 06-12345678<br /><br />

                Met vriendelijke groet,<br />
                Porsche Nederland
              </SubLabel>
              <Field
                component={Wysiwyg}
                name="content.outro_body"
                validate={validation.required}
                defaultValue={formData.content ? formData.content.outro_body : ''}
              />
            </FormField>

            <Button stretch sub type="submit">Save</Button>
          </div>
        </form>
      </Container>
    );
  }
}

EventDetailEmailEditor.propTypes = {
  history: PT.object,
  match: PT.object,
  change: PT.func,
  create: PT.func,
  onShowNotification: PT.func,
  handleSubmit: PT.func,
  dirty: PT.bool,
  dispatch: PT.func,
  getEventEmail: PT.func,
  formData: PT.object,
  update: PT.func,
  resetEventEmail: PT.func,
};

export default compose(
  withRouter,
  connect(state => ({
    dirty: isDirty('edit-event-email'),
    formData: getFormValues('edit-event-email')(state) || {},
  }), {
    change,
    create: createEventEmail,
    update: updateEventEmail,
    onShowNotification: showNotification,
    getEventEmail,
    resetEventEmail,
  }),
  reduxForm({ form: 'edit-event-email' }),
)(EventDetailEmailEditor);
