import styled, { css } from 'styled-components';
import PT from 'prop-types';
import { media } from 'styles/utils';
import { Card, Small } from 'common';

export const ExtendedCard = styled(Card)`
  background-color: ${props => props.theme.white};
  padding: 0;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  ${props => props.readonly && css`
    cursor: default;
  `}

  ${media.tablet`
    margin: 18px;
    flex-direction: row;
  `}
`;

ExtendedCard.propTypes = {
  readonly: PT.bool,
};

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 18px;
  color: ${props => props.theme.text};
  letter-spacing: 2px;

  ${media.tablet`
    justify-content: space-between;
    width: 70%;
  `}
`;

export const ContentRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Image = styled.div`
  background-image: url('${props => props.backgroundImage}');
  background-size: cover;
  background-position: center;
  height: 100%;
`;

Image.propTypes = {
  backgroundImage: PT.string,
};

export const ImageContainer = styled.div`
  width: 100%;
  height: 200px;

  ${media.tablet`
    max-width: 30%;
  `}
`;

export const Title = styled.h3`
  font-size: 18px;
  font-weight: normal;
  margin: 0;
  letter-spacing: normal;

  ${media.tablet`
    font-size: 24px;
  `}
`;

export const EventDate = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: bold;
  font-size: 24px;
  text-align: right;

  ${media.tablet`
    font-size: 37px;
  `}
`;

export const Day = styled.p`
  margin: 0;
`;

export const Month = styled(Small)`
  font-size: 12px;
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${media.tablet`
    flex-direction: row;
    justify-content: flex-end;
  `}
`;

export const ActionsContainer = styled.div`
  display: flex;
  margin-top: 10px;

  & button:first-of-type {
    margin-right: 10px;
  }
`;
