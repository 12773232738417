import createAction from 'services/createAction';

const GET_CEM_PACKAGES = 'CEMPackages/GET';
const GET_CEM_PACKAGES_SUCCESS = 'CEMPackages/GET_SUCCESS';
const GET_CEM_PACKAGES_FAILED = 'CEMPackages/GET_FAILED';

const initialState = {
  data: [],
  error: {
    message: '',
  },
  loading: {
    get: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case GET_CEM_PACKAGES_SUCCESS:
    return {
      ...state,
      data: payload,
      loading: { get: false },
    };
  case GET_CEM_PACKAGES_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { get: false },
    };
  case GET_CEM_PACKAGES:
    return {
      ...state,
      error: { message: '' },
      loading: { get: true },
    };
  default:
    return state;
  }
};

const getCEMPackagesSuccess = createAction(GET_CEM_PACKAGES_SUCCESS);
const getCEMPackagesFailed = createAction(GET_CEM_PACKAGES_FAILED);

export const getCEMPackages = () => (dispatch, getState, api) => {
  dispatch({ type: GET_CEM_PACKAGES });

  api.get({ path: 'packages', v2: true })
    .then((res) => {
      dispatch(getCEMPackagesSuccess(res));
    })
    .catch((err) => {
      dispatch(getCEMPackagesFailed({ message: err.data }));
    });
};
