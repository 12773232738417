import React from 'react';

import Block from '../Block';
import Text from '../Text';

const FallbackBlock = () => (
  <Block>
    <Text>Dit element bestaat niet.</Text>
  </Block>
);

export default FallbackBlock;
