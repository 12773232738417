import moment from 'moment';

// if event start_date has past but event is still ongoing, take end_date to determine category

const isThisWeek = (startDate, endDate) => (
  moment(startDate).isSame(moment(), 'week') || moment(endDate).isSame(moment(), 'week')
);

const isNextWeek = (startDate, endDate) => (
  moment(startDate).isSame(moment().add(1, 'week'), 'week') ||
  moment(endDate).isSame(moment().add(1, 'week'), 'week')
);

const isThisMonth = (startDate, endDate) => (
  moment(startDate).isSame(moment(), 'month') || moment(endDate).isSame(moment(), 'month')
);

const isNextMonth = (startDate, endDate) => (
  moment(startDate).isSame(moment().add(1, 'month'), 'month') ||
moment(endDate).isSame(moment().add(1, 'month'), 'month')
);

const isThisYear = (startDate, endDate) => (
  moment(startDate).isSame(moment(), 'year') || moment(endDate).isSame(moment(), 'year')
);

export default (events) => {
  const noDate = [];
  const thisWeek = [];
  const nextWeek = [];
  const thisMonth = [];
  const nextMonth = [];
  const thisYear = [];
  const later = [];

  events.forEach((event) => {
    // noDate
    if (!event.start_date) {
      return noDate.push(event);
    }

    // this week
    if (isThisWeek(event.start_date, event.end_date)) {
      return thisWeek.push(event);
    }

    // next week
    if (isNextWeek(event.start_date, event.end_date)) {
      return nextWeek.push(event);
    }

    // this month
    if (isThisMonth(event.start_date, event.end_date)) {
      return thisMonth.push(event);
    }

    // next month
    if (isNextMonth(event.start_date, event.end_date)) {
      return nextMonth.push(event);
    }

    // this year
    if (isThisYear(event.start_date, event.end_date)) {
      return thisYear.push(event);
    }

    return later.push(event);
  });

  return {
    noDate,
    thisWeek,
    nextWeek,
    thisMonth,
    nextMonth,
    thisYear,
    later,
  };
};
