import React from 'react';
import PT from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { change, getFormValues } from 'redux-form';

import {
  hasRole,
  DEALER,
} from 'services/authHelper';

import { selectDriver, deselectDriver } from 'ducks/inviteDrivers';

import { Row, Column, Name, Status, Dealer } from './styled';

const getUniqueDealers = (carLinks) => {
  if (carLinks.length === 0) return [];

  const dealerNames = carLinks.map(carLink => carLink.dealer_set && carLink.dealer.name);
  const uniqueDealers = [...new Set(dealerNames)];

  return uniqueDealers;
};

const DriverRow = ({
  driver, roles, history, invite, onChange, invitedData, onSelectDriver, onDeselectDriver,
}) => {
  const onSelect = (e) => {
    onChange('invite-drivers', `invite.${driver.id}`, e.target.checked);
    if (e.target.checked) {
      onSelectDriver(driver);
    } else {
      onDeselectDriver(driver.id);
    }
  };

  return (
    <Row onClick={() => !invite && history.push(`/driver/${driver.id}`)} invite={invite}>
      {invite && (
        <Column>
          <input
            type="checkbox"
            onClick={e => e.stopPropagation()}
            onChange={onSelect}
            checked={invitedData.invite[driver.id] || false}
          />
        </Column>
      )}
      <Column padding><Name>{driver.full_name}</Name> <Status>{driver.status}</Status></Column>
      <Column>{driver.address.length > 0 ? driver.address[0].city : '-'}</Column>
      {!hasRole(roles, [DEALER]) && (
        <Column>
          {getUniqueDealers(driver.car_link).map(dealer => <Dealer key={dealer}>{dealer}</Dealer>)}
        </Column>
      )}
      <Column ellipsis="180px">{driver.email}</Column>
    </Row>
  );
};

DriverRow.propTypes = {
  driver: PT.object,
  roles: PT.array,
  history: PT.object,
  invite: PT.bool,
  onChange: PT.func,
  invitedData: PT.object,
  onSelectDriver: PT.func,
  onDeselectDriver: PT.func,
};

export default compose(
  withRouter,
  connect(state => ({
    invitedData: getFormValues('invite-drivers')(state),
  }), { onChange: change, onSelectDriver: selectDriver, onDeselectDriver: deselectDriver }),
)(DriverRow);
