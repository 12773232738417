import createAction from 'services/createAction';

const DOWNLOAD_FILE = 'download/FILE';
const DOWNLOAD_FILE_SUCCESS = 'download/FILE_SUCCESS';
const DOWNLOAD_FILE_FAILED = 'download/FILE_FAILED';

const initialState = {
  loading: false,
  error: false,
  data: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case DOWNLOAD_FILE:
    return {
      ...state,
      loading: true,
    };

  case DOWNLOAD_FILE_SUCCESS:
    return {
      ...state,
      loading: false,
      data: payload,
    };

  case DOWNLOAD_FILE_FAILED:
    return {
      ...state,
      loading: false,
      data: '',
    };

  default:
    return state;
  }
};

const downloadFileSuccess = createAction(DOWNLOAD_FILE_SUCCESS);
const downloadFileFailed = createAction(DOWNLOAD_FILE_FAILED);

export const downloadFile = file => (dispatch, getState, api) => {
  dispatch({ type: DOWNLOAD_FILE });

  api.get({ path: `documents/${file}/`, brandPortal: true })
    .then((res) => {
      dispatch(downloadFileSuccess(res));
      window.location.href = res.meta.download_url;
    })
    .catch((err) => {
      dispatch(downloadFileFailed({ message: err.data }));
    });
};
