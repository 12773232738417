import styled from 'styled-components';

export const DriversContainer = styled.table`
  border: 1px solid ${props => props.theme.border.withBoxShadow};
  box-shadow: ${props => props.theme.boxShadow};
  border-spacing: 0px;
  border-collapse: collapse;
  width: 100%;
  flex-basis: 70%;
  flex-shrink: 0;
`;

export const HeaderCell = styled.th`
  text-transform: uppercase;
  line-height: 36px;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 2px;
  padding: 0;
  text-align: left;
  border-bottom: 1px solid ${props => props.theme.border.withBoxShadow};

  &:first-of-type {
    padding: 0px 18px;
  }
`;

export const RowEmpty = styled.tr`
  & td {
    padding: 18px;
  }
`;
