import createAction from 'services/createAction';
import { showNotification } from 'ducks/notification';

const GET_MY_UPCOMING_EVENTS = 'events/GET_MY_UPCOMING';
const GET_MY_UPCOMING_EVENTS_SUCCESS = 'events/GET_MY_UPCOMING_SUCCESS';
const GET_MY_UPCOMING_EVENTS_FAILED = 'events/GET_MY_UPCOMING_FAILED';

const GET_ALL_UPCOMING_EVENTS = 'events/GET_ALL_UPCOMING';
const GET_ALL_UPCOMING_EVENTS_SUCCESS = 'events/GET_ALL_UPCOMING_SUCCESS';
const GET_ALL_UPCOMING_EVENTS_FAILED = 'events/GET_ALL_UPCOMING_FAILED';

const GET_MY_PAST_EVENTS = 'events/GET_MY_PAST';
const GET_MY_PAST_EVENTS_SUCCESS = 'events/GET_MY_PAST_SUCCESS';
const GET_MY_PAST_EVENTS_FAILED = 'events/GET_MY_PAST_FAILED';

const GET_ALL_PAST_EVENTS = 'events/GET_ALL_PAST';
const GET_ALL_PAST_EVENTS_SUCCESS = 'events/GET_ALL_PAST_SUCCESS';
const GET_ALL_PAST_EVENTS_FAILED = 'events/GET_ALL_PAST_FAILED';

const RESET_EVENTS = 'events/RESET';

const DELETE_EVENT = 'events/DELETE';
const DELETE_EVENT_SUCCESS = 'events/DELETE_SUCCESS';
const DELETE_EVENT_FAILED = 'events/DELETE_FAILED';
const UNPUBLISH_EVENT = 'events/UNPUBLISH';

const initialState = {
  data: {
    myEvents: {
      upcoming: [],
      past: {
        activities: [],
      },
    },
    allEvents: {
      upcoming: [],
      past: {
        activities: [],
      },
    },
  },
  error: {
    message: '',
  },
  loading: {
    upcoming: false,
    past: false,
    delete: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case GET_MY_UPCOMING_EVENTS_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        myEvents: {
          ...state.data.myEvents,
          upcoming: payload,
        },
      },
      loading: {
        ...state.loading,
        upcoming: false,
      },
    };
  case GET_MY_UPCOMING_EVENTS_FAILED:
  case GET_ALL_UPCOMING_EVENTS_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: {
        ...state.loading,
        upcoming: false,
      },
    };
  case GET_MY_UPCOMING_EVENTS:
  case GET_ALL_UPCOMING_EVENTS:
    return {
      ...state,
      loading: {
        ...state.loading,
        upcoming: true,
      },
    };
  case GET_ALL_UPCOMING_EVENTS_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        allEvents: {
          ...state.data.allEvents,
          upcoming: payload,
        },
      },
      loading: {
        ...state.loading,
        upcoming: false,
      },
    };
  case GET_MY_PAST_EVENTS:
  case GET_ALL_PAST_EVENTS:
    return {
      ...state,
      loading: {
        ...state.loading,
        past: true,
      },
    };
  case GET_MY_PAST_EVENTS_FAILED:
  case GET_ALL_PAST_EVENTS_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: {
        ...state.loading,
        past: false,
      },
    };
  case GET_MY_PAST_EVENTS_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        myEvents: {
          ...state.data.myEvents,
          past: {
            ...payload,
            activities: [
              ...state.data.myEvents.past.activities,
              ...payload.activities,
            ],
          },
        },
      },
      loading: {
        ...state.loading,
        past: false,
      },
    };
  case GET_ALL_PAST_EVENTS_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        allEvents: {
          ...state.data.allEvents,
          past: {
            ...payload,
            activities: [
              ...state.data.allEvents.past.activities,
              ...payload.activities,
            ],
          },
        },
      },
      loading: {
        ...state.loading,
        past: false,
      },
    };
  case DELETE_EVENT:
    return {
      ...state,
      loading: {
        ...state.loading,
        delete: true,
      },
    };
  case DELETE_EVENT_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        allEvents: {
          ...state.data.allEvents,
          upcoming: state.data.allEvents.upcoming.filter(event => event.id !== payload),
        },
        myEvents: {
          ...state.data.allEvents,
          upcoming: state.data.myEvents.upcoming.filter(event => event.id !== payload),
        },
      },
      loading: {
        ...state.loading,
        delete: false,
      },
    };
  case UNPUBLISH_EVENT:
    return {
      ...state,
      data: {
        ...state.data,
        allEvents: {
          ...state.data.allEvents,
          upcoming: [
            ...state.data.allEvents.upcoming.map((event) => {
              if (event.id !== payload) return event;

              return ({
                ...event,
                published: false,
              });
            }),
          ],
        },
        myEvents: {
          ...state.data.allEvents,
          upcoming: [
            ...state.data.myEvents.upcoming.map((event) => {
              if (event.id !== payload) return event;

              return ({
                ...event,
                published: false,
              });
            }),
          ],
        },
      },
      loading: {
        ...state.loading,
        delete: false,
      },
    };
  case DELETE_EVENT_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: {
        ...state.loading,
        delete: false,
      },
    };
  case RESET_EVENTS:
    return initialState;
  default:
    return state;
  }
};

const getMyUpcomingEventsSuccess = createAction(GET_MY_UPCOMING_EVENTS_SUCCESS);
const getMyUpcomingEventsFailed = createAction(GET_MY_UPCOMING_EVENTS_FAILED);

export const getMyUpcomingEvents = () => (dispatch, getState, api) => {
  dispatch({ type: GET_MY_UPCOMING_EVENTS });

  const query = { my: true };

  api.get({ path: 'management/activity/upcoming', query })
    .then((res) => {
      dispatch(getMyUpcomingEventsSuccess(res));
    })
    .catch((err) => {
      dispatch(getMyUpcomingEventsFailed({ message: err.data }));
    });
};

const getAllUpcomingEventsSuccess = createAction(GET_ALL_UPCOMING_EVENTS_SUCCESS);
const getAllUpcomingEventsFailed = createAction(GET_ALL_UPCOMING_EVENTS_FAILED);

export const getAllUpcomingEvents = () => (dispatch, getState, api) => {
  dispatch({ type: GET_ALL_UPCOMING_EVENTS });

  api.get({ path: 'management/activity/upcoming' })
    .then((res) => {
      dispatch(getAllUpcomingEventsSuccess(res));
    })
    .catch((err) => {
      dispatch(getAllUpcomingEventsFailed({ message: err.data }));
    });
};

const getMyPastEventsSuccess = createAction(GET_MY_PAST_EVENTS_SUCCESS);
const getMyPastEventsFailed = createAction(GET_MY_PAST_EVENTS_FAILED);

export const getMyPastEvents = params => (dispatch, getState, api) => {
  dispatch({ type: GET_MY_PAST_EVENTS });

  const query = {
    my: true,
    ...params,
  };

  api.get({ path: 'management/activity/past', query })
    .then((res) => {
      dispatch(getMyPastEventsSuccess(res));
    })
    .catch((err) => {
      dispatch(getMyPastEventsFailed({ message: err.data }));
    });
};

const getAllPastEventsSuccess = createAction(GET_ALL_PAST_EVENTS_SUCCESS);
const getAllPastEventsFailed = createAction(GET_ALL_PAST_EVENTS_FAILED);

export const getAllPastEvents = params => (dispatch, getState, api) => {
  dispatch({ type: GET_ALL_PAST_EVENTS });

  api.get({ path: 'management/activity/past', query: params })
    .then((res) => {
      dispatch(getAllPastEventsSuccess(res));
    })
    .catch((err) => {
      dispatch(getAllPastEventsFailed({ message: err.data }));
    });
};

const deleteEventSuccess = createAction(DELETE_EVENT_SUCCESS);
const unpublishEvent = createAction(UNPUBLISH_EVENT);
const deleteEventFailed = createAction(DELETE_EVENT_FAILED);

export const deleteEvent = (id, unpublish) => (dispatch, getState, api) => {
  dispatch({ type: DELETE_EVENT });

  api.del({ path: `management/activity/${id}` })
    .then(() => {
      if (unpublish) {
        dispatch(unpublishEvent(id));
        dispatch(showNotification('Het event is succesvol gedepubliceerd.', 'green'));
      } else {
        dispatch(deleteEventSuccess(id));
        dispatch(showNotification('Het event is succesvol verwijderd.', 'green'));
      }
    })
    .catch((err) => {
      dispatch(deleteEventFailed({ message: err.data }));
      dispatch(showNotification('Er is iets mis gegaan, probeer het later nogmaals.'));
    });
};

export const resetEvents = createAction(RESET_EVENTS);
