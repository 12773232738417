import styled from 'styled-components';

import { Title } from 'common';
import { SubLabel } from 'common/form';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;

  button {
    flex: none;
  }
`;

export const ExtendedTitle = styled(Title)`
  flex: 1;
  margin: 0;
`;

export const TicketInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ImageContainer = styled.span`
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  width: 12px;

  svg {
    width: 12px;
    fill: ${props => props.theme.red};
  }
`;

export const ExtendedSubLabel = styled(SubLabel)`
  margin-top: 0;
  margin-bottom: 0;
`;

export const TicketEventDetailInfo = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

export const HeaderContainer = styled.div`
  width: 100%;
`;

export const TicketIdContainer = styled.div`
  width: 220px;
  position: relative;
  border: 1px solid transparent;
  cursor: pointer;

  &:hover {
    border-radius: 2px 0 0 2px;
    border: 1px solid ${props => props.theme.border};
  }

  &:focus-within {
    border-radius: 2px 0 0 2px;
    transition-delay: 1s;
    border: 1px solid ${props => props.theme.border};
  }

  &:after {
    content: "Kopiëren";
    display: block;
    position: absolute;
    right: 0;
    transform: translateX(100%);
    top: -1px;
    bottom: -1px;
    padding: 4px 7px 0;
    font-size: 12px;
    opacity: 0;
    border: 1px solid ${props => props.theme.border};
    background: ${props => props.theme.background};
    border-radius: 0 2px 2px 0;
    transition: 0.2s;
    pointer-events: none;
    color: ${props => props.theme.gray};
  }

  &:hover::after {
    content: "Kopiëren";
    opacity: 1;
  }

  &:active::after {
    content: "Gekopieerd";
    opacity: 1;
    color: c-black;
  }

  &:focus-within::after {
    content: "Gekopieerd";
    transition-delay: 1s;
    color: ${props => props.theme.black};
    opacity: 0;
  }
`;

export const TicketId = styled.input`
  color: ${props => props.theme.gray};
  font-size: 12px;
  width: 100%;
  margin: 0;
  border: 0;
  padding-left: 10px;
  cursor: pointer;
  outline: none;

  &::selection {
    background: transparent;
    color: ${props => props.theme.gray};
  }
`;
