import React from 'react';
import PT from 'prop-types';

import { DRAFT, REVIEW, PUBLISHED } from 'services/eventHelper';
import { LabelTag } from 'common';

const StatusLabel = ({ event }) => {
  switch (event.status) {
  case REVIEW: return <LabelTag variant="warning">Wachten op goedkeuring</LabelTag>;
  case DRAFT: return <LabelTag>Niet gepubliceerd</LabelTag>;
  default: return null;
  }
};

StatusLabel.propTypes = {
  event: PT.shape({
    status: PT.oneOf([
      DRAFT, REVIEW, PUBLISHED,
    ]),
  }),
};

export default StatusLabel;
