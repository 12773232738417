import React from 'react';

import Porsche24LogoWhite from 'images/porsche-24-logo-white.png';

import { IntroductionBlock, Image, Introduction } from './styled';

const EventPreviewIntroductionBlock = () => (
  <IntroductionBlock>
    <Image src={Porsche24LogoWhite} alt="Porsche24 logo" />
    <Introduction>Porsche24 beloont Porsche rijders voor rijden en deelname aan events. Uw toegang tot Porsche24 is de Porsche24 app - vol events, rijtrainingen, de mooiste routes, het laatste Porsche nieuws, typische Porsche privileges en bijzondere verrassingen.</Introduction>
  </IntroductionBlock>
);

export default EventPreviewIntroductionBlock;
