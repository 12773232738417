import React from 'react';
import styled, { css } from 'styled-components';
import Dropzone from 'react-dropzone';

export const DropzoneStyled = styled(({ fullHeight, ...rest }) => <Dropzone {...rest} />)`
  display: flex;
  border: 1px dashed ${props => props.theme.border};
  border-radius: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 18px;

  label {
    margin: 0;
  }

  ${props => props.fullHeight && css`
    height: 100%;
  `}
`;
