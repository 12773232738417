import React, { Fragment } from 'react';
import { Field } from 'redux-form';

import { Anchor } from 'common';
import { Label, SubLabel, FormField, InputText } from 'common/form';

import { Icon, ActionContainer } from './styled';

const TicketFormCustomFields = ({ fields }) => fields.map((field, index) => (
  <Fragment key={`${field.term}_${index}`}>
    <FormField indent>
      <Label>Naam invulveld</Label>
      <SubLabel groupWithLabel>Bijvoorbeeld: kledingmaat, dieetwensen, gegevens tweede bestuurder.</SubLabel>
      <Field
        name={`${field}.item`}
        component={InputText}
        type="text"
      />

      <ActionContainer>
        {fields.length > 1 ? (
          <Anchor small onClick={() => fields.remove(index)}>
            Verwijder
          </Anchor>
        ) : (
          <Anchor
            small
            onClick={() => {
              fields.remove(index);
              fields.push({});
            }}
          >
            Leegmaken
          </Anchor>
        )}

        <Anchor small bold onClick={() => fields.push({})}>
          Voeg aanvullende vraag toe <Icon>+</Icon>
        </Anchor>
      </ActionContainer>
    </FormField>
  </Fragment>
));

export default TicketFormCustomFields;
