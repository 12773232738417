import React, { Component } from 'react';
import { connect } from 'react-redux';
import PT from 'prop-types';

import {
  hasRole,
  DEALER,
} from 'services/authHelper';

import { getDealers } from 'ducks/dealers';
import { getGiftableRewards } from 'ducks/rewards';

import { Title, Subtitle, Button, Loading } from 'common';
import { Label, InputSelect } from 'common/form';

import { ModalContainer, ActionsContainer, RewardsContainer } from './styled';

// ID is hardcoded -- discussed with backend
const padtID = '331bcd8113514aada652db7615ccf245';

class DriverTabsRewardsAssign extends Component {
  state = {
    selectedDealer: hasRole(this.props.authentication.roles, [DEALER]) ? {
      label: this.props.authentication.data.role.dealer.name,
      value: this.props.authentication.data.role.dealer.id,
    } : '',
    selectedReward: '',
  }

  componentDidMount() {
    if (this.props.dealers.length === 0) {
      this.props.getDealers();
    }

    this.props.getGiftableRewards()
      .then(() => {
        if (hasRole(this.props.authentication.roles, [DEALER])) {
          const allowedReward = this.props.rewards.find(reward => reward.id === padtID);

          this.setState({
            selectedReward: {
              label: allowedReward.name,
              value: allowedReward.id,
            },
          });
        }
      });
  }

  assignReward = () => {
    this.props.assignReward(
      this.state.selectedReward.value,
      this.state.selectedReward.value === padtID ? this.state.selectedDealer.value : null,
    );
  }

  render() {
    const { dealers, rewards } = this.props;

    if (dealers.length === 0) {
      return (
        <ModalContainer>
          <Loading small />
        </ModalContainer>
      );
    }

    return (
      <ModalContainer>
        <Title small>Reward geven</Title>
        <Subtitle small>U kunt deze Porsche rijder de onderstaande rewards geven.</Subtitle>

        <RewardsContainer>
          <Label>Rewards</Label>
          <InputSelect
            classNamePrefix="react-select"
            name="assign_reward"
            options={rewards.map(reward => ({
              label: reward.name,
              value: reward.id,
            }))}
            className="react-select"
            placeholder="Selecteer reward"
            onChange={e => this.setState({ selectedReward: e })}
            value={this.state.selectedReward}
            isDisabled={hasRole(this.props.authentication.roles, [DEALER])}
          />
        </RewardsContainer>

        {this.state.selectedReward.value === padtID && (
          <RewardsContainer>
            <Label>Porsche Advanced Driver Training</Label>
            <InputSelect
              classNamePrefix="react-select"
              name="assign_padt"
              options={dealers.map(dealer => ({
                label: dealer.name,
                value: dealer.id,
              }))}
              className="react-select"
              placeholder="Selecteer locatie"
              onChange={e => this.setState({ selectedDealer: e })}
              value={this.state.selectedDealer}
              isDisabled={hasRole(this.props.authentication.roles, [DEALER])}
            />
          </RewardsContainer>
        )}

        <ActionsContainer>
          <Button small onClick={this.assignReward}>Verstuur</Button>
        </ActionsContainer>
      </ModalContainer>
    );
  }
}

DriverTabsRewardsAssign.propTypes = {
  dealers: PT.array,
  getDealers: PT.func,
  assignReward: PT.func,
  authentication: PT.object,
  getGiftableRewards: PT.func,
  rewards: PT.array,
};

export default connect(state => ({
  dealers: state.dealers.data,
  authentication: state.authentication,
  rewards: state.rewards.data.giftable,
}), {
  getDealers, getGiftableRewards,
})(DriverTabsRewardsAssign);
