import React, { Component, Fragment } from 'react';
import PT from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, FieldArray, getFormValues, initialize, change } from 'redux-form';

import {
  saveTicket, postSpecialRequirements, putSpecialRequirements, deleteSpecialRequirements,
  postChecklist, putChecklist, deleteChecklist,
} from 'ducks/editTicket';
import { loadEvent } from 'ducks/editEvent';
import { showNotification } from 'ducks/notification';

import { Button, Subtitle } from 'common';
import {
  Form, Label, SubLabel, FormField, InputText, InputTextarea, InputError,
  InputCheckbox, InputSelect, RadioTag, MultipleTags, MultiColumns,
} from 'common/form';

import * as validation from 'services/validations';

import TicketFormAdditionalTerms from '../TicketFormAdditionalTerms';
import TicketFormCustomFields from '../TicketFormCustomFields';
import TicketFormChecklist from '../TicketFormChecklist';
import WarningModal from '../WarningModal';

import { FormActions } from './styled';

class TicketForm extends Component {
  state = {
    showAdditionalTerms: false,
    showCustomFields: false,
    showChecklist: false,
    showModal: false,
  }

  componentWillMount() {
    const { data } = this.props;

    if (this.checkInitialOptional(data.checklist.filter(item => item.special))) {
      this.setState({ showAdditionalTerms: true });
    }
    if (this.checkInitialOptional(data.custom_fields)) {
      this.setState({ showCustomFields: true });
    }
    if (this.checkInitialOptional(data.checklist.filter(item => !item.special))) {
      this.setState({ showChecklist: true });
    }
  }

  componentDidMount() {
    const { data, levels, eventData } = this.props;

    const initialData = {
      ticket_type_id: data.ticket_type_id ? data.ticket_type_id : null,
      name: data.name ? data.name : '',
      price: data.price_incl ? data.price_incl.toString() : 0,
      start_date: Object.keys(eventData).length > 0 ? eventData.start_date : new Date(),
      vat: (data.price_vat || data.price_vat === 0) ? data.price_vat : 21,
      quantity: data.quantity ? data.quantity : 0,
      limit_per_person: data.limit_per_person ? data.limit_per_person : 2,
      description: data.description ? data.description : '',
      level_id: data.level ? data.level.map(level => level.id) : levels.map(level => level.id),
      points_id: data.points ? data.points.id : null,
      signature: data.signature ? data.signature : false,
      custom_fields: data.custom_fields && data.custom_fields.length > 0
        ? data.custom_fields
        : [{}],
      checklist: data.checklist && data.checklist.filter(item => !item.special).length > 0
        ? data.checklist.filter(item => !item.special)[0]
        : {
          special: false,
          fields: [{}],
        },
      special_requirements: data.checklist && data.checklist.filter(item => item.special).length > 0
        ? data.checklist.filter(item => item.special)[0]
        : {
          special: true,
          fields: [{}],
        },
      public: data.public ? data.public : false,
      external_url: data.external_url ? data.external_url : '',
    };

    this.props.dispatch(initialize('edit-ticket-form', initialData));
  }

  checkInitialOptional = (data) => {
    if (data.length > 0) return true;
    return false;
  }

  toggleCollapsibleFields = (field, state) => {
    this.setState({ [field]: state });
  }

  processSubmit = (excludedValues, res) => {
    const { special_requirements: specialRequirements } = excludedValues[0];
    const { checklist } = excludedValues[1];

    if (specialRequirements.id && !this.state.showCustomFields) {
      // Delete customField if ID is passed and checkbox is unchecked
      this.props.onDeleteSpecialRequirements(specialRequirements.id);
    } else if (Object.keys(specialRequirements.fields[0]).length > 0) {
      // If there are special requirements available
      if (!specialRequirements.id) {
        // Post new entry when there is no ID available
        this.props.onPostSpecialRequirements(specialRequirements, res.ticket.id);
      } else {
        // Put existing entry when there is an ID available
        this.props.onPutSpecialRequirements(specialRequirements, specialRequirements.id);
      }
    }

    if (checklist.id && !this.state.showChecklist) {
      // Delete checklist if ID is passed and checkbox is unchecked
      this.props.onDeleteChecklist(checklist.id);
    } else if (Object.keys(checklist.fields[0]).length > 0) {
      // If there are checklists available
      if (!checklist.id) {
        // Post new entry when there is no ID available
        this.props.onPostChecklist(checklist, res.ticket.id);
      } else {
        // Put existing entry when there is an ID available
        this.props.onPutChecklist(checklist, checklist.id);
      }
    }

    this.props.onLoadEvent(this.props.eventId);
    this.props.onToggleEdit();
  }

  render() {
    const {
      data, formData, formErrors, ticketTypes, ticketCategories, levels,
      onSaveTicket, onToggleEdit, handleSubmit, dispatch, newTicket, submitFailed,
    } = this.props;

    let selectedTicketType = '';
    if (formData.ticket_type_id) {
      selectedTicketType = ticketTypes.filter(type => type.id === formData.ticket_type_id)[0].name;
    }

    return (
      <Fragment>
        {this.state.showModal && (
          <WarningModal onClose={() => this.setState({ showModal: false })} />
        )}
        <Form onSubmit={
          handleSubmit((values) => {
            if (selectedTicketType === 'Prive ticket') {
              delete values.level_id;
            }

            if (newTicket) {
              onSaveTicket(values, null, newTicket).then(({ excludedValues, res }) => this.processSubmit(excludedValues, res));
            } else {
              onSaveTicket(values, this.props.data.id).then(({ excludedValues, res }) => this.processSubmit(excludedValues, res));
            }
          })}
        >
          {ticketTypes.length > 0 && ticketTypes.map(ticketType => (
            <Field
              component={RadioTag}
              name="ticket_type_id"
              key={ticketType.id}
              id={ticketType.id}
              label={ticketType.name}
              checked={formData.ticket_type_id === ticketType.id}
              validate={validation.required}
              error={submitFailed && (formErrors && formErrors.ticket_type_id)}
              data-cy="input-radio-ticket-type"
            />
          ))}
          {submitFailed && (formErrors && formErrors.ticket_type_id) && (
            <InputError meta={{ submitFailed, error: formErrors.ticket_type_id }} />
          )}

          <FormField>
            <Label>Ticket naam</Label>
            <SubLabel groupWithLabel>(bijvoorbeeld Warm-up deelnemer, Hospitality vrijdag, VIP preview, etc.)</SubLabel>
            <Field
              name="name"
              component={InputText}
              type="text"
              validate={validation.required}
              data-cy="input-event-ticket-name"
            />
          </FormField>

          {selectedTicketType === 'Extern ticket' ? (
            <FormField>
              <Label>Externe url</Label>
              <Field
                name="external_url"
                component={InputText}
                type="text"
                validate={validation.required}
                data-cy="input-event-ticket-url-external"
              />
            </FormField>
          ) : (
            <MultiColumns>
              <FormField>
                <Label>Prijs (EUR)</Label>
                <Field
                  name="price"
                  component={InputText}
                  type="number"
                  validate={validation.requiredNumber}
                  min="0"
                  noErrorText
                  onBlur={(event) => {
                    if (!newTicket && parseInt(event.target.value, 10) !== data.price_incl) {
                      this.setState({ showModal: true });
                    }
                  }}
                />
              </FormField>

              <FormField>
                <Label>BTW</Label>
                <Field
                  name="vat"
                  component={InputText}
                  type="number"
                  validate={validation.requiredNumber}
                  noErrorText
                  min="0"
                  onBlur={(event) => {
                    if (!newTicket && parseInt(event.target.value, 10) !== data.price_vat) {
                      this.setState({ showModal: true });
                    }
                  }}
                />
              </FormField>

              <FormField>
                <Label>Aantal</Label>
                <Field
                  name="quantity"
                  component={InputText}
                  type="number"
                  validate={validation.requiredNumber}
                  noErrorText
                  min="0"
                />
              </FormField>

              <FormField>
                <Label>Max. aantal p.p.</Label>
                <Field
                  name="limit_per_person"
                  component={InputText}
                  type="number"
                  validate={validation.requiredNumber}
                  noErrorText
                  min="0"
                />
              </FormField>
            </MultiColumns>
          )}

          <FormField>
            <Label>Ticket omschrijving</Label>
            <SubLabel groupWithLabel>Omschrijf wat men krijgt met het ticket. Bijvoorbeeld: Twee keer 30 minuten track time, inclusief Porsche hospitality arrangement voor twee personen.</SubLabel>
            <Field
              name="description"
              component={InputTextarea}
            />
          </FormField>

          {(levels.length > 0 && selectedTicketType !== 'Prive ticket') && (
            <FormField>
              <Label>Porsche24 level</Label>
              <Field
                component={MultipleTags}
                name="level_id"
                data={levels}
                value={formData.level_id}
                defaultValue={levels.filter(level =>
                  level.name === 'porsche24'
                  || level.name === 'silver'
                  || level.name === 'red'
                  || level.name === 'yellow'
                  || level.name === 'carbon')}
                validate={validation.requiredArray}
                error={submitFailed && (formErrors && formErrors.level_id)}
              />

              {submitFailed && (formErrors && formErrors.level_id) && (
                <InputError meta={{ submitFailed, error: formErrors.level_id }} />
              )}
            </FormField>
          )}

          {ticketCategories.length > 0 && (
            <FormField>
              <Label>Ticket categorie</Label>
              <SubLabel groupWithLabel>De categorie zal bepalen hoeveel punten de deelnemers toegewezen krijgen.</SubLabel>
              <InputSelect
                classNamePrefix="react-select"
                isSearchable
                name="points_id"
                options={ticketCategories.map(category => ({
                  label: `${category.name}, ${category.points}`,
                  value: category.id,
                }))}
                value={ticketCategories.filter(category => category.id === formData.points_id).reduce((curr, acc) => ({ label: `${acc.name}, ${acc.points}`, value: formData.points_id }), '')}
                onChange={e => dispatch(change('edit-ticket-form', 'points_id', e.value))}
                className="react-select"
              />
            </FormField>
          )}

          {selectedTicketType !== 'Extern ticket' && (
            <Fragment>
              <Subtitle>Optioneel</Subtitle>

              <FormField>
                <InputCheckbox
                  id="additional_terms"
                  label="Deelnemersvoorwaarden opstellen"
                  subLabel="Met dient akkoord te gaan met deze voorwaarden als ze dit ticket bestellen."
                  onChange={e => this.toggleCollapsibleFields('showAdditionalTerms', e.currentTarget.checked)}
                  checked={this.state.showAdditionalTerms}
                />
              </FormField>

              {this.state.showAdditionalTerms && (
                <FieldArray name="special_requirements.fields" component={TicketFormAdditionalTerms} />
              )}

              <FormField>
                <InputCheckbox
                  id="checklist"
                  label="Checklist"
                  subLabel="Deze checklist dient ter voorbereiding op het event en wordt opgenomen in het ticket."
                  onChange={e => this.toggleCollapsibleFields('showChecklist', e.currentTarget.checked)}
                  checked={this.state.showChecklist}
                />
              </FormField>

              {this.state.showChecklist && (
                <FieldArray name="checklist.fields" component={TicketFormChecklist} />
              )}

              <FormField>
                <InputCheckbox
                  id="custom_fields"
                  label="Aanvullende gegevens vragen"
                  subLabel="Met dient deze velden met aanvullende gegevens in te vullen als ze dit ticket bestellen."
                  onChange={e => this.toggleCollapsibleFields('showCustomFields', e.currentTarget.checked)}
                  checked={this.state.showCustomFields}
                />
              </FormField>

              {this.state.showCustomFields && (
                <FieldArray name="custom_fields" component={TicketFormCustomFields} />
              )}

              <FormField>
                <Field
                  name="signature"
                  component={InputCheckbox}
                  label="Digitale handtekening nodig?"
                  subLabel="Bij aankoop van dit ticket is een digitale handtekening van de afnemer vereist."
                  defaultChecked={formData.signature}
                />
              </FormField>

              <FormField>
                <Field
                  name="public"
                  component={InputCheckbox}
                  label="Openbaar maken"
                  subLabel="Als deze tickets openbaar zijn kan iedereen zonder account ook tickets aanschaffen."
                  defaultChecked={formData.public}
                />
              </FormField>
            </Fragment>
          )}

          <FormActions>
            <Button
              variant="black"
              type="submit"
              data-cy="button-ticket-save"
              small
            >
              Opslaan
            </Button>
            <Button
              onClick={onToggleEdit}
              data-cy="button-ticket-cancel"
              small
              sub
            >
              Annuleren
            </Button>
          </FormActions>
        </Form>
      </Fragment>
    );
  }
}

TicketForm.propTypes = {
  data: PT.object,
  formData: PT.object,
  formErrors: PT.object,
  newTicket: PT.bool,
  ticketTypes: PT.array,
  ticketCategories: PT.array,
  levels: PT.array,
  eventId: PT.string,
  submitFailed: PT.bool,
  dispatch: PT.func,
  onToggleEdit: PT.func,
  handleSubmit: PT.func,
  onSaveTicket: PT.func,
  onPostSpecialRequirements: PT.func,
  onPutSpecialRequirements: PT.func,
  onDeleteSpecialRequirements: PT.func,
  onPostChecklist: PT.func,
  onPutChecklist: PT.func,
  onDeleteChecklist: PT.func,
  onLoadEvent: PT.func,
  eventData: PT.object,
};

export default compose(
  connect(state => ({
    eventId: state.editEvent.data.id,
    eventData: state.editEvent.data,
    ticketTypes: state.ticket.types,
    ticketCategories: state.ticket.categories,
    levels: state.levels.data,
    formErrors: (state.form['edit-ticket-form'] && state.form['edit-ticket-form'].syncErrors) || {},
    formData: getFormValues('edit-ticket-form')(state) || {},
  }), {
    onSaveTicket: saveTicket,
    onPostSpecialRequirements: postSpecialRequirements,
    onPutSpecialRequirements: putSpecialRequirements,
    onDeleteSpecialRequirements: deleteSpecialRequirements,
    onPostChecklist: postChecklist,
    onPutChecklist: putChecklist,
    onDeleteChecklist: deleteChecklist,
    onLoadEvent: loadEvent,
  }),
  reduxForm({
    form: 'edit-ticket-form',
    onSubmitFail: (error, dispatch) => {
      dispatch(showNotification('Er is iets misgegaan. Controleer de velden.'));
    },
  }),
)(TicketForm);
