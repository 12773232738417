import React from 'react';
import PT from 'prop-types';

import { Info, Card, Container, Button, Subtitle } from 'common';

import { Header, ExtendedTitle, ButtonContainer, Content, OrdersInfoRow } from './styled';

const OrderCard = ({ booking, showOrdersModal }) => (
  <Container>
    <Card>
      <Header>
        <div>
          <ExtendedTitle>
            {booking.products[0].product_name}
          </ExtendedTitle>
          <Subtitle>{booking.reason}</Subtitle>
        </div>
        <ButtonContainer>
          <Button small onClick={() => showOrdersModal(booking)}>Edit order</Button>
        </ButtonContainer>
      </Header>
      <Content>
        <OrdersInfoRow>
          <Info label="Name">
            {booking.user.firstname} {booking.user.infix} {booking.user.surname}
          </Info>
          <Info label="Email">
            {booking.user.email}
          </Info>
        </OrdersInfoRow>
        <OrdersInfoRow>
          <Info label="Psp reference">
            {booking.psp_reference}
          </Info>
        </OrdersInfoRow>
        <OrdersInfoRow>
          <Info label="Country">
            {booking.user.address.country}
          </Info>
          <Info label="Street">
            {booking.user.address.street}
          </Info>
          <Info label="House number">
            {booking.user.address.house_number}
          </Info>
          <Info label="House number addition">
            {booking.user.address.infix}
          </Info>
        </OrdersInfoRow>
      </Content>
    </Card>
  </Container>
);

OrderCard.propTypes = {
  booking: PT.object,
  showOrdersModal: PT.func,
};

export default OrderCard;
