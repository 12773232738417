import React, { Component } from 'react';
import PT from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { showNotification } from 'ducks/notification';

import ArrowDown from 'vectors/ArrowDown.svg';
import { Card, Title, LabelTag, Button } from 'common';

import { DropdownActions, ActionsList, ActionsListItem, CardHeader, SentDate } from './styled';

class EventDetailEmailCard extends Component {
  state = {
    actionsOpen: false,
  }

  handleClickOutside() {
    this.setState({ actionsOpen: false });
  }

  handleClickOnCard = () => {
    const { history, match, email } = this.props;

    if (email.status === 'published') {
      return history.push(`/event/${match.params.eventId}/emails/preview/${email.id}`);
    }

    return history.push(`/event/${match.params.eventId}/emails/edit/${email.id}`);
  }

  handleDelete = () => {
    // eslint-disable-next-line
    const warning = confirm('Weet je zeker dat je deze email wilt verwijderen?');

    if (!warning) return false;

    return this.props.onDelete(this.props.email.id)
      .then(() => {
        this.props.getHistory(this.props.match.params.eventId);
        this.props.showNotification('Het verwijderen van de email is gelukt.', 'green');
      })
      .catch(() => this.props.showNotification('Er is iets misgegaan. Probeer het nogmaals.', 'red'));
  }

  render() {
    const { history, match, email } = this.props;

    return (
      <Card compact onClick={this.handleClickOnCard}>
        <CardHeader>
          <div>
            <Title
              small
              noWhitespace
              variant={!email.subject && 'gray'}
            >
              {email.subject || 'Nog geen titel'}
              {email.status === 'draft' && <LabelTag>Draft</LabelTag>}
              {email.status === 'published' && <LabelTag variant="danger">Verzonden</LabelTag>}
              {email.status === 'error' && <LabelTag variant="warning">Error</LabelTag>}
            </Title>
            {email.status === 'published'
              ? <SentDate>Verzonden op: {moment(email.sent_on).format('LLL')}</SentDate>
              : email.updated_on
                ? <SentDate>Laatst gewijzigd op: {moment(email.updated_on).format('LLL')}</SentDate>
                : <SentDate>Aangemaakt op: {moment(email.created_on).format('LLL')}</SentDate>
            }
          </div>

          {email.status !== 'published' && (
            <div>
              <Button
                sub
                small
                square
                onClick={(e) => {
                  e.stopPropagation();
                  this.setState({ actionsOpen: !this.state.actionsOpen });
                }}
                className="ignore-react-onclickoutside"
              >
                <ArrowDown />
              </Button>

              {this.state.actionsOpen && (
                <DropdownActions className="ignore-react-onclickoutside">
                  <ActionsList>
                    <ActionsListItem
                      onClick={(e) => {
                        e.stopPropagation();
                        this.setState({ actionsOpen: false });
                        history.push(`/event/${match.params.eventId}/emails/edit/${email.id}`);
                      }}
                    >
                      Wijzigen
                    </ActionsListItem>
                    <ActionsListItem
                      onClick={(e) => {
                        e.stopPropagation();
                        this.setState({ actionsOpen: false });
                        history.push(`/event/${match.params.eventId}/emails/preview/${email.id}`);
                      }}
                    >
                      Preview & versturen
                    </ActionsListItem>
                    <ActionsListItem
                      onClick={(e) => {
                        e.stopPropagation();
                        this.setState({ actionsOpen: false });
                        this.handleDelete();
                      }}
                    >
                      Verwijderen
                    </ActionsListItem>
                  </ActionsList>
                </DropdownActions>
              )}
            </div>
          )}
        </CardHeader>
      </Card>
    );
  }
}

EventDetailEmailCard.propTypes = {
  history: PT.object,
  match: PT.object,
  email: PT.shape({
    id: PT.string,
    status: PT.oneOf(['draft', 'published', 'error']),
  }),
  onDelete: PT.func,
  getHistory: PT.func,
  showNotification: PT.func,
};

export default compose(
  withRouter,
  connect(null, { showNotification }),
  onClickOutside,
)(EventDetailEmailCard);
