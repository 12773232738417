import React from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { Field, getFormValues } from 'redux-form';

import { EmptyState, Loading } from 'common';
import { InputCheckbox } from 'common/form';

import { Table, TableHeader, TableCell, TableRow, TopItem, BottomItem, TableHead } from './styled';

const attendeeStatus = {
  null: '-',
  true: 'Aanwezig',
  false: 'Niet aanwezig',
};

const EventDetailOverviewAttendeesList = ({ attendees, loading, markedValues }) => {
  if (loading) return <Loading />;

  return (
    <Table>
      <TableHead>
        <tr>
          <TableHeader />
          <TableHeader size="large">Naam</TableHeader>
          <TableHeader size="medium">Datum</TableHeader>
          <TableHeader size="medium">Ticket</TableHeader>
          <TableHeader size="small">Status</TableHeader>
        </tr>
      </TableHead>
      <tbody>
        {attendees.tickets.length > 0 ? (attendees.tickets.map(attendee => (
          <TableRow key={attendee.id}>
            <TableCell>
              <Field
                component={InputCheckbox}
                name={`marked.${attendee.id}`}
                noWhitespace
                center
                checked={markedValues.marked[attendee.id] || false}
              />
            </TableCell>
            <TableCell>
              <TopItem>{attendee.user.name}</TopItem>
              <BottomItem>{attendee.unique_ticket_id}</BottomItem>
            </TableCell>
            <TableCell>{moment(attendee.created).format('LL')}</TableCell>
            <TableCell>
              <TopItem>{attendee.name}</TopItem>
              <BottomItem>{attendee.type.name}</BottomItem>
            </TableCell>
            <TableCell>{attendeeStatus[attendee.attended]}</TableCell>
          </TableRow>
        ))) : (
          <tr>
            <td colSpan="5">
              <EmptyState title="Voor dit evenement zijn nog geen deelnemers" small />
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

EventDetailOverviewAttendeesList.propTypes = {
  attendees: PT.object,
  loading: PT.bool,
  markedValues: PT.object,
};

export default connect(state => ({
  attendees: state.event.attendees,
  loading: state.event.loading.getAttendees,
  markedValues: getFormValues('mark-attendees')(state),
}))(EventDetailOverviewAttendeesList);
