import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PT from 'prop-types';

import Cancel from 'vectors/cancel.svg';
import Confirm from 'vectors/ok.svg';
import Camera from 'vectors/Camera.svg';

import { editMileageFromDriver } from 'ducks/driver/editMileage';
import { getDriverDetails } from 'ducks/driver/driver';
import { showModal, hideModal } from 'ducks/modal';

import { Button } from 'common';

import { ExtendedInputText, Icon, CameraIconContainer } from './styled';

class MileageInput extends Component {
  state = {
    showInput: false,
    value: '',
  }

  componentWillUnmount() {
    this.props.hideMileageModal();
  }

  toggleInput = () => {
    this.setState({
      showInput: !this.state.showInput,
    });
  }

  confirmEditMileage = () => {
    this.props.editMileageFromDriver(this.props.car.id, parseInt(this.state.value, 10))
      .then(() => this.props.getDriverDetails(this.props.driver.id));
  }

  resetValue = () => {
    this.setState({
      value: '',
      showInput: false,
    });
  }

  render() {
    const {
      car, showMileageModal, driver, hideNextCar,
    } = this.props;

    // the car data in driver overview doesn't container the user prop so we add that manually
    const data = { ...car, user: { full_name: driver.full_name } };

    return (
      <Fragment>
        {!this.state.showInput ? (
          <div>
            {car.current_mileage && car.current_mileage.mileage >= 0 ? (
              car.current_mileage.confirmed ? (
                <Button small variant="black" onClick={this.toggleInput}>Update km stand</Button>
              ) : (
                <Button small onClick={() => showMileageModal(data, hideNextCar)}>
                  <CameraIconContainer><Camera /></CameraIconContainer>Valideer KM stand
                </Button>
              )
            ) : (
              <Button small onClick={this.toggleInput}>Vul km stand in</Button>
            )}
          </div>
        ) : (
          <div>
            <ExtendedInputText
              value={this.state.value}
              placeholder={(car.current_mileage && car.current_mileage.mileage)}
              onChange={e => this.setState({ value: e.target.value })}
            />
            <Button
              square
              small
              onClick={this.confirmEditMileage}
            >
              <Icon><Confirm /></Icon>
            </Button>
            <Button
              square
              small
              variant="black"
              onClick={this.resetValue}
            >
              <Icon><Cancel /></Icon>
            </Button>
          </div>
        )}
      </Fragment>
    );
  }
}

MileageInput.propTypes = {
  car: PT.object,
  editMileageFromDriver: PT.func,
  getDriverDetails: PT.func,
  driver: PT.object,
  showMileageModal: PT.func,
  hideMileageModal: PT.func,
  hideNextCar: PT.bool,
};

const mapDispatchToProps = dispatch => ({
  showMileageModal: (car, hideNextCar) => {
    dispatch(showModal({
      type: 'VALIDATE_MILEAGE',
      modalProps: { car, hideNextCar },
    }));
  },
  hideMileageModal: () => {
    dispatch(hideModal());
  },
  editMileageFromDriver: (carId, value) => (
    dispatch(editMileageFromDriver(carId, value))
  ),
  getDriverDetails: (id) => {
    dispatch(getDriverDetails(id));
  },
});

export default connect(null, mapDispatchToProps)(MileageInput);
