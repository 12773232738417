import React from 'react';
import PT from 'prop-types';

import { InputError, Label, SubLabel } from 'common/form';

import { CheckboxContainer, StyledInput, Labels } from './styled';

const InputRadio = ({
  input, meta, children, ...otherProps
}) => (
  <CheckboxContainer>
    <StyledInput
      {...input}
      {...otherProps}
      type="radio"
      submitFailed={meta.submitFailed}
      error={meta.error}
      id={otherProps.id || input.name}
    />
    <Labels>
      {otherProps.label && <Label htmlFor={input.name}>{otherProps.label}</Label>}
      {otherProps.subLabel && <SubLabel>{otherProps.subLabel}</SubLabel>}
      {children}
    </Labels>
    <InputError meta={meta} />
  </CheckboxContainer>
);

InputRadio.defaultProps = {
  meta: {
    error: undefined,
    submitFailed: false,
  },
};

InputRadio.propTypes = {
  input: PT.object,
  meta: PT.object,
  children: PT.node,
};

export default InputRadio;
