const COMMUNITY_MANAGER = 'community_manager';
const DEALER = 'dealer' || 'dealer_admin';
const EVENT_MANAGER = 'event_manager';
const PARTNER = 'partner';
const PROJAXION = 'projaxion';

export {
  COMMUNITY_MANAGER,
  DEALER,
  EVENT_MANAGER,
  PARTNER,
  PROJAXION,
};

const BROADCAST_READ = 'broadcast:read';
const BROADCAST_CREATE = 'broadcast:create';
const BROADCAST_UPDATE = 'broadcast:update';
const BROADCAST_DELETE = 'broadcast:delete';
const BROADCAST_PUBLISH = 'broadcast:publish';

const CAR_READ = 'car:read';
const CAR_DELETE = 'car:delete';
const CAR_CONFIRM = 'car:confirm';

const DRIVER_READ = 'driver:read';
const DRIVER_CREATE = 'driver:create';
const DRIVER_UPDATE = 'driver:update';
const DRIVER_DELETE = 'driver:delete';
const DRIVER_EXPORT = 'driver:export';
const DRIVER_INVITE = 'driver:invite';

const EVENT_READ = 'event:read';
const EVENT_CREATE = 'event:create';
const EVENT_UPDATE = 'event:update';
const EVENT_DRAFT = 'event:draft';
const EVENT_PUBLISH = 'event:publish';
const EVENT_DELETE = 'event:delete';
const EVENT_ATTEND = 'event:attend';
const EVENT_ATTENDEES = 'event:attendees';

const MILEAGE_READ = 'mileage:read';
const MILEAGE_CREATE = 'mileage:create';
const MILEAGE_UPDATE = 'mileage:update';
const MILEAGE_DELETE = 'mileage:delete';
const MILEAGE_CONFIRM = 'mileage:confirm';
const MILEAGE_REJECT = 'mileage:reject';

const TICKET_READ = 'ticket:read';
const TICKET_CREATE = 'ticket:create';
const TICKET_UPDATE = 'ticket:update';
const TICKET_DELETE = 'ticket:delete';
const TICKET_CANCEL = 'ticket:cancel';

export {
  BROADCAST_READ,
  BROADCAST_CREATE,
  BROADCAST_UPDATE,
  BROADCAST_DELETE,
  BROADCAST_PUBLISH,
  CAR_READ,
  CAR_DELETE,
  CAR_CONFIRM,
  DRIVER_READ,
  DRIVER_CREATE,
  DRIVER_UPDATE,
  DRIVER_DELETE,
  DRIVER_EXPORT,
  DRIVER_INVITE,
  EVENT_READ,
  EVENT_CREATE,
  EVENT_UPDATE,
  EVENT_DRAFT,
  EVENT_PUBLISH,
  EVENT_DELETE,
  EVENT_ATTEND,
  EVENT_ATTENDEES,
  MILEAGE_READ,
  MILEAGE_CREATE,
  MILEAGE_UPDATE,
  MILEAGE_DELETE,
  MILEAGE_CONFIRM,
  MILEAGE_REJECT,
  TICKET_READ,
  TICKET_CREATE,
  TICKET_UPDATE,
  TICKET_DELETE,
  TICKET_CANCEL,
};

const hasPermissions = (userPermissions, permissions) =>
  permissions.some(permission => userPermissions.includes(permission));

const hasRole = (userRoles, roles) =>
  userRoles.some(role => roles.includes(role));

export {
  hasPermissions,
  hasRole,
};

/**
 * Usage
 * For permissions:
 * {hasPermissions(permissions, [EVENTS_CREATE]) && ()}
 *
 * For roles:
 * {hasRole(roles, [EVENT_MANAGER]) && ()}
 */

const isOwnDealerEvent = (event, dealer) => {
  if (!event.dealer || !dealer || event.dealer.id !== dealer.id) return false;
  if (event.dealer.id === dealer.id) return true;
  return false;
};

const isOwnEvent = (event, user) => event.user_created_id === user.id;

export { isOwnDealerEvent, isOwnEvent };
