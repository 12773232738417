import styled from 'styled-components';
import PT from 'prop-types';

import { media } from 'styles/utils';

import downloadIcon from 'vectors/download.svg';

export const Column = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-start;
  align-content: flex-start;

  &:first-of-type {
    justify-content: flex-start;
  }

  ${media.tablet`
    flex-basis: 50%;
  `}
`;

export const Title = styled.h2`
  font-size: 28px;
  font-style: italic;
  font-weight: normal;
  margin: 0 0 14px;
  line-height: 100%;
  letter-spacing: 0.1px;
`;

export const Text = styled.div.attrs(props => ({
  dangerouslySetInnerHTML: !props.children ? { __html: props.html } : null,
}))`
  font-size: 14px;
  font-style: italic;
  line-height: 26px;
  letter-spacing: 0.1px;
  color: ${props => props.theme.gray};
`;

Text.propTypes = {
  children: PT.node,
  html: PT.string,
};

export const Button = styled.button`
  position: relative;
  width: 300px;
  padding: 18px 35px 18px 30px;
  margin-bottom: 20px;
  flex: 0 0 100%;
  border: 1px solid ${props => props.theme.border};
  background-color: transparent;
  appearance: none;
  cursor: pointer;
  font-size: 13px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 1.9px;
  text-transform: uppercase;

  ${media.tablet`
    max-width: 300px;
  `}
`;

export const Icon = styled(downloadIcon)`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 17px;
  height: 20px;
  margin-left: 30px;
`;
