import React, { Fragment } from 'react';
import PT from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Circle, Line, TimelineContainer, StageContainer, StageTitle, StageDescription } from './styled';

const CEMPackageStage = ({ active, last, data, nextActive }) => (
  <StageContainer>
    <TimelineContainer>
      <Circle active={active} />
      {!last && <Line active={nextActive} />}
    </TimelineContainer>
    <div>
      <StageTitle bold noWhitespace>{data.name}</StageTitle>
      <StageDescription>{data.description || ''}</StageDescription>
    </div>
  </StageContainer>
);

CEMPackageStage.propTypes = {
  active: PT.bool,
  last: PT.bool,
  data: PT.object,
  nextActive: PT.bool,
};

const CEMPackageStages = ({ stages }) => (
  <Fragment>
    {stages.map((stage, index) => (
      <CEMPackageStage
        key={stage.id}
        active={stage.active}
        nextActive={stages[index + 1] && stages[index + 1].active}
        last={index === stages.length - 1}
        data={stage}
      />
    ))}
  </Fragment>
);

CEMPackageStages.propTypes = {
  stages: PT.arrayOf(PT.shape({
    id: PT.string.isRequired,
    name: PT.string.isRequired,
    ordinal: PT.number,
    description: PT.string,
    active: PT.bool,
  })).isRequired,
};

export default withRouter(CEMPackageStages);
