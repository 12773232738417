import createAction from 'services/createAction';

const POST_INVITE_NEW_DRIVER = 'inviteNewDriver/POST';
const POST_INVITE_NEW_DRIVER_SUCCESS = 'inviteNewDriver/POST_SUCCESS';
const POST_INVITE_NEW_DRIVER_FAILED = 'inviteNewDriver/POST_FAILED';

const initialState = {
  data: [],
  error: {
    message: '',
  },
  loading: {
    post: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case POST_INVITE_NEW_DRIVER_SUCCESS:
    return {
      ...state,
      loading: { ...state.loading, post: false },
    };
  case POST_INVITE_NEW_DRIVER_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, post: false },
    };
  case POST_INVITE_NEW_DRIVER:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, post: true },
    };
  default:
    return state;
  }
};

const postInviteNewDriverSuccess = createAction(POST_INVITE_NEW_DRIVER_SUCCESS);
const postInviteNewDriverFailed = createAction(POST_INVITE_NEW_DRIVER_FAILED);

export const postInviteNewDriver = email => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: POST_INVITE_NEW_DRIVER });

    api.post({ path: 'management/user/invite', body: { email } })
      .then(() => {
        dispatch(postInviteNewDriverSuccess());
        resolve();
      })
      .catch((err) => {
        dispatch(postInviteNewDriverFailed({ message: err.data }));
        reject(err);
      });
  })
);
