import React, { Component } from 'react';
import PT from 'prop-types';
import { compose } from 'redux';
import { Switch, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import configureSentry from 'services/configureSentry';
import { getCookie } from 'services/cookies';
import { getUser, logout } from 'ducks/authentication';
import { showNotification } from 'ducks/notification';

import {
  AppRoot, Authentication, EventShare, PrivacyPolicy, TermsAndConditions, RedirectDeeplink,
  Redirect,
} from 'modules';
import { PrivateRoute, Notification, SentryBoundary } from 'common';

configureSentry();

class App extends Component {
  componentWillMount() {
    // Skip authentication on the share page as share is public
    if (this.props.location.pathname.indexOf('/share') >= 0) return;

    if (!this.props.authentication.email && getCookie('X-Access-Token')) {
      this.props.getUser()
        .then((res) => {
          if (res.force_password_change) {
            this.props.showNotification(
              "Uw wachtwoord is verouderd. Pas deze aan via 'Wachtwoord vergeten'.",
              'orange',
              true,
            );
            this.props.logout();
          }
        });
    }
  }

  render() {
    const { authentication } = this.props;

    // Don't check cookies and authentication when on share page
    if (this.props.location.pathname.indexOf('/share') < 0) {
      if (!authentication.email && getCookie('X-Access-Token')) {
        return null;
      }
    }

    return (
      <SentryBoundary>
        <main>
          <Notification />
          <Switch>
            <Route
              path="/redirect/:url"
              component={RedirectDeeplink}
            />
            <Route
              path="/p24/redirect"
              component={Redirect}
            />
            <Route
              path="/share/:id"
              component={EventShare}
            />
            <Route
              path="/privacy-policy"
              component={PrivacyPolicy}
            />
            <Route
              path="/gebruiksvoorwaarden"
              component={TermsAndConditions}
            />
            <Route
              path="/:path(login|forgot-password|activate_account|oauth|password_reset)"
              component={Authentication}
            />
            <PrivateRoute
              path="/"
              component={AppRoot}
              access={authentication}
              fallbackUrl="/login"
            />
          </Switch>
        </main>
      </SentryBoundary>
    );
  }
}

App.propTypes = {
  authentication: PT.object,
  getUser: PT.func,
  location: PT.object,
  logout: PT.func,
  showNotification: PT.func,
};

export default compose(
  withRouter,
  connect(state => ({
    authentication: state.authentication.data,
  }), { getUser, logout, showNotification }),
)(App);
