import qs from 'qs';

export default (filters) => {
  if (filters.user_filters) {
    const formattedFilters = filters.user_filters;
    formattedFilters.limit = '10';
    formattedFilters.classic = formattedFilters.classic ? formattedFilters.classic : '';
    return `?${qs.stringify(formattedFilters)}`;
  }

  return '';
};
