import React from 'react';
import PT from 'prop-types';
import moment from 'moment';

import { Container, Subtitle } from 'common';

import { Description, Highlights, Highlight, Block, Small, BlockTitle } from './styled';

const EventPreviewBasicInfo = ({ event }) => (
  <Container>
    <Description large dangerouslySetInnerHTML={{ __html: event.short_description }} />
    <Highlights>
      {moment(event.start_date).format('dddd_DD_MMMM') === moment(event.end_date).format('dddd_DD_MMMM') ? (
        <Highlight>
          <Block>
            <BlockTitle>
              {moment(event.start_date).format('dddd, DD MMMM [van] H:mm ')}
              {moment(event.end_date).format('[tot] H:mm')}
            </BlockTitle>
          </Block>
        </Highlight>
      ) : (
        <Highlight>
          <Block>
            <Small>Begint:</Small>
            <BlockTitle>{moment(event.start_date).format('dddd, DD MMM [om] H:mm')}</BlockTitle>
          </Block>
          <Block>
            <Small>Eindigt:</Small>
            <BlockTitle>{moment(event.end_date).format('dddd, DD MMM [om] H:mm')}</BlockTitle>
          </Block>
        </Highlight>
      )}
      <Highlight>
        <Block>
          <BlockTitle>Locatie:</BlockTitle>
          <Small>{event.street}{event.housenumber ? ` ${event.housenumber},` : ','} {event.zipcode} {event.city}, {event.country}</Small>
        </Block>
      </Highlight>
    </Highlights>
    <Subtitle bold>Informatie</Subtitle>
    <Description dangerouslySetInnerHTML={{ __html: event.long_description }} />
  </Container>
);

EventPreviewBasicInfo.propTypes = {
  event: PT.object,
};

export default EventPreviewBasicInfo;
