import React, { Component } from 'react';
import PT from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import getBrandPortalUrl from 'services/getBrandPortalUrl';
import { getChangelog } from 'ducks/changelog';

import Block from '../Block';

import { Title, Item, ItemInfo, ItemType, ItemDate, ItemTitle, ItemArrow } from './styled';

class ChangelogBlock extends Component {
  componentDidMount() {
    if (this.props.data.value.enable_changelog) this.props.getChangelog();
  }

  render() {
    const { data, changelog, pages } = this.props;

    return (
      data.value.enable_changelog && (
        <Block>
          <Title>Updates</Title>
          {changelog && changelog.map(log => (
            <Item key={log.id} to={getBrandPortalUrl(log.id, pages)}>
              <ItemInfo>
                <ItemType>{log.type}</ItemType>
                <ItemDate>{log.date}</ItemDate>
              </ItemInfo>
              <ItemTitle>{log.title}</ItemTitle>
              <ItemArrow />
            </Item>
          ))}
        </Block>
      )
    );
  }
}

ChangelogBlock.propTypes = {
  data: PT.object,
  getChangelog: PT.func,
  changelog: PT.array,
  pages: PT.array,
};

export default compose(connect(state => ({
  changelog: state.changelog.data,
}), { getChangelog }))(ChangelogBlock);
