import styled from 'styled-components';

export default styled.div`
  position: relative;
  width: 100%;
  margin: 40px 0;
  padding-bottom: 30px;

  &:before {
    content: '';
    position: absolute;
    display: block;
    top: -12px;
    left: 20px;
    height: 12px;
    width: 40%;
    background-color: ${props => props.theme.red};
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    bottom: 0;
    left: 20px;
    height: 30px;
    width: 40%;
    background-color: ${props => props.theme.red};
  }
`;
