import React, { Fragment, Component } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import qs from 'qs';

import {
  getMyUpcomingEvents, getAllUpcomingEvents, getMyPastEvents,
  getAllPastEvents, resetEvents,
} from 'ducks/events';

import { Wrapper, Container, Loading, Button, EmptyState } from 'common';
import EventsHeader from './components/EventsHeader';
import UpcomingList from './components/UpcomingList';
import PastList from './components/PastList';

import ButtonContainer from './styled';

class Events extends Component {
  constructor(props) {
    super(props);

    const query = qs.parse(this.props.history.location.search, { ignoreQueryPrefix: true });

    this.state = {
      showAllEvents: query.all ? JSON.parse(query.all) : false,
      showPastEvents: false,
    };
  }

  componentWillMount() {
    if (this.state.showAllEvents) return this.props.getAllUpcomingEvents();

    this.props.getMyUpcomingEvents();
  }

  componentWillUnmount() {
    this.props.resetEvents();
  }

  getMorePastEvents = (offset) => {
    if (this.state.showAllEvents) {
      this.props.getAllPastEvents({ offset, limit: 15 });
    } else {
      this.props.getMyPastEvents({ offset, limit: 15 });
    }
  }

  toggleShowAllEvents = (bool) => {
    this.setState({
      showAllEvents: bool,
      showPastEvents: false,
    }, () => {
      if (bool) {
        this.props.history.push('/events?all=true');

        if (this.props.allEvents.upcoming.length === 0) {
          this.props.getAllUpcomingEvents();
        }
      } else {
        this.props.history.push('/events?all=false');

        if (this.props.myEvents.upcoming.length === 0) {
          this.props.getMyUpcomingEvents();
        }
      }
    });
  }

  togglePastEvents = () => {
    this.setState({
      showPastEvents: !this.state.showPastEvents,
    }, () => {
      if ((!this.state.showAllEvents && this.state.showPastEvents) &&
          this.props.myEvents.past.activities.length === 0) {
        this.props.getMyPastEvents({ offset: 0, limit: 15 });
      }

      if ((this.state.showAllEvents && this.state.showPastEvents) &&
          this.props.allEvents.past.activities.length === 0) {
        this.props.getAllPastEvents({ offset: 0, limit: 15 });
      }
    });
  }

  render() {
    const {
      myEvents, allEvents, loadingUpcoming, loadingPast, roles,
    } = this.props;

    const events = this.state.showAllEvents ? allEvents : myEvents;

    return (
      <Fragment>
        <Container>
          <EventsHeader
            showAllEvents={this.state.showAllEvents}
            toggleShowAllEvents={this.toggleShowAllEvents}
            roles={roles}
          />
        </Container>

        {!loadingUpcoming && (
          <Container data-cy="container-events-upcomming">
            {events.upcoming.length !== 0 ? (
              <UpcomingList
                events={events.upcoming}
              />
            ) : (
              <EmptyState title="Er zijn geen events" />
            )}
          </Container>
        )}

        {(loadingPast || loadingUpcoming) && (
          <Container>
            <Loading />
          </Container>
        )}

        {this.state.showPastEvents && (
          <Wrapper>
            {events.past.activities.length !== 0 ? (
              <Container data-cy="container-events-past">
                <PastList
                  events={events.past.activities}
                  getMorePastEvents={this.getMorePastEvents}
                  pagination={{
                    count: events.past.count,
                    limit: events.past.limit,
                    offset: events.past.offset,
                  }}
                  loading={loadingPast}
                />
              </Container>
            ) : (
              <EmptyState title="Er zijn geen afgelopen events" />
            )}
          </Wrapper>
        )}

        {!loadingUpcoming && (
          <Container>
            <ButtonContainer>
              <Button onClick={this.togglePastEvents}>{this.state.showPastEvents ? 'Verberg' : 'Toon'} afgelopen events</Button>
            </ButtonContainer>
          </Container>
        )}
      </Fragment>
    );
  }
}

Events.propTypes = {
  getMyUpcomingEvents: PT.func,
  myEvents: PT.object,
  getAllUpcomingEvents: PT.func,
  allEvents: PT.object,
  loadingUpcoming: PT.bool,
  getMyPastEvents: PT.func,
  loadingPast: PT.bool,
  getAllPastEvents: PT.func,
  resetEvents: PT.func,
  roles: PT.array,
  history: PT.object,
};

export default compose(
  withRouter,
  connect(
    state => ({
      roles: state.authentication.roles,
      myEvents: state.events.data.myEvents,
      allEvents: state.events.data.allEvents,
      loadingUpcoming: state.events.loading.upcoming,
      loadingPast: state.events.loading.past,
    }),
    {
      getMyUpcomingEvents,
      getAllUpcomingEvents,
      getMyPastEvents,
      getAllPastEvents,
      resetEvents,
    },
  ),
)(Events);
