import styled, { css } from 'styled-components';
import PT from 'prop-types';

const Label = styled.label`
  display: block;
  font-size: 16px;
  font-weight: 700;
  color: ${props => props.theme.black};
  padding-left: 18px;
  margin-bottom: 12px;

  ${props => props.uppercase && css`
    text-transform: uppercase;
  `}

  ${props => props.noWhitespace && css`
    margin: 0;
    padding: 0;
  `}
`;

Label.propTypes = {
  uppercase: PT.bool,
  noWhitespace: PT.bool,
};

export default Label;
