import React, { Component } from 'react';
import PT from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, getFormValues, initialize, isDirty } from 'redux-form';

import * as validation from 'services/validations';
import { editDriverDetails, getDriverDetails } from 'ducks/driver/driver';
import { showNotification } from 'ducks/notification';

import { Title, Button, LabelTag, Modal, Subtitle } from 'common';
import { Form, FormField, Label, InputText, MultiColumns } from 'common/form';

import { ModalContainer } from './styled';

class DriverEdit extends Component {
  componentDidMount() {
    this.props.dispatch(initialize('edit-driver', {
      email: this.props.driver.email,
      address: {
        country: this.props.driver.address[0].country,
        country_code: this.props.driver.address[0].country_code,
        zipcode: this.props.driver.address[0].zipcode,
        housenumber: this.props.driver.address[0].housenumber,
        infix: this.props.driver.address[0].infix,
        street: this.props.driver.address[0].street,
        city: this.props.driver.address[0].city,
        box_number: null,
      },
    }));
  }

  handleSave = (values) => {
    const { edit, driver, onShowNotification } = this.props;

    edit(driver.id, values)
      .then(() => {
        onShowNotification('Het wijzigen is gelukt', 'green');
        this.props.onClose();
        this.props.getDriverDetails(driver.id);
      })
      .catch(() => onShowNotification('Het wijzigen is mislukt. Probeer opnieuw.'));
  };

  hideModal = () => {
    if (this.props.dirty) {
      // eslint-disable-next-line
      const warning = confirm('De wijzigingen zijn niet opgeslagen. Weet je het zeker?');

      if (!warning) return false;
    }

    return this.props.onClose();
  }

  render() {
    const { handleSubmit, dirty } = this.props;

    return (
      <Modal onHideModal={this.hideModal}>
        <ModalContainer>
          <Title underline>Driver bewerken</Title>
          <Form onSubmit={handleSubmit(this.handleSave)}>
            <Subtitle>Details</Subtitle>
            <FormField>
              <Label>E-mailadres</Label>
              <Field
                component={InputText}
                name="email"
                validate={[validation.email, validation.required]}
              />
            </FormField>
            <Subtitle>Adres</Subtitle>
            <MultiColumns>
              <FormField>
                <Label>Land</Label>
                <Field
                  component={InputText}
                  name="address.country"
                />
              </FormField>
              <FormField>
                <Label>Landcode</Label>
                <Field
                  component={InputText}
                  name="address.country_code"
                />
              </FormField>
            </MultiColumns>
            <FormField>
              <Label>Postcode</Label>
              <Field
                component={InputText}
                name="address.zipcode"
                validate={[validation.required]}
              />
            </FormField>
            <MultiColumns>
              <FormField>
                <Label>Huisnummer</Label>
                <Field
                  component={InputText}
                  name="address.housenumber"
                  validate={[validation.required]}
                />
              </FormField>
              <FormField>
                <Label>Toevoegsel</Label>
                <Field
                  component={InputText}
                  name="address.infix"
                />
              </FormField>
            </MultiColumns>
            <FormField>
              <Label>Straat</Label>
              <Field
                component={InputText}
                name="address.street"
                validate={[validation.required]}
              />
            </FormField>
            <FormField>
              <Label>Stad</Label>
              <Field
                component={InputText}
                name="address.city"
                validate={[validation.required]}
              />
            </FormField>
            <p>Let er op om de gebruiker te vragen of je de wijziging mag delen met de desbetreffende dealer, zodat de gegevens bij de dealer in C@P ook actueel zijn.</p>
            <Button small type="submit">Opslaan</Button> {dirty && <LabelTag>unsaved changes</LabelTag>}
          </Form>
        </ModalContainer>
      </Modal>
    );
  }
}

DriverEdit.propTypes = {
  handleSubmit: PT.func,
  edit: PT.func,
  driver: PT.object,
  onClose: PT.func,
  onShowNotification: PT.func,
  dispatch: PT.func,
  dirty: PT.bool,
  getDriverDetails: PT.func,
};

export default compose(
  connect(state => ({
    dirty: isDirty('edit-driver'),
    formData: getFormValues('edit-driver')(state) || {},
    driver: state.driver.data,
  }), {
    edit: editDriverDetails,
    onShowNotification: showNotification,
    getDriverDetails,
  }),
  reduxForm({ form: 'edit-driver' }),
)(DriverEdit);
