import createAction from 'services/createAction';

const GET = 'roles/GET';
const GET_SUCCESS = 'roles/GET_SUCCESS';
const GET_FAILED = 'roles/GET_FAILED';

const initialState = {
  data: [],
  error: {
    message: '',
  },
  loading: {
    get: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case GET_SUCCESS:
    return {
      ...state,
      data: payload.data,
      loading: { ...state.loading, get: false },
    };
  case GET_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, get: false },
    };
  case GET:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, get: true },
    };
  default:
    return state;
  }
};

const getRolesSuccess = createAction(GET_SUCCESS);
const getRolesFailed = createAction(GET_FAILED);

export const getRoles = () => (dispatch, getState, api) => {
  dispatch({ type: GET });

  api.get({ path: 'management/roles', v2: true })
    .then((res) => {
      dispatch(getRolesSuccess({ data: res }));
    })
    .catch((err) => {
      dispatch(getRolesFailed({ message: err.data }));
    });
};
