import React, { Component } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import { getFormValues, change } from 'redux-form';
import qs from 'qs';

import isTicketInvite from 'services/isTicketInvite';
import { showNotification } from 'ducks/notification';
import { setSelectedDriversCount } from 'ducks/inviteDrivers';

import { Container, Title, Button } from 'common';
import { StepsContainer, StepsItem } from 'common/steps';
import { DriversOverview } from 'modules';

import { ActionsContainer } from './styled';

class InviteDrivers extends Component {
  inviteDrivers = () => {
    if (Object.keys(this.props.invitedData.invite).length === 0) {
      this.props.showNotification('Selecteer ten minste 1 Porsche rijder');
    } else {
      if (this.props.invitedData.user_filters) {
        this.props.onChange('invite-drivers', 'user_filters', '');
      }

      const { params } = this.props.match;

      this.props.history.push(`/event/${params.eventId}/invite${isTicketInvite(params) ? `/${params.ticketId}` : ''}/message`);
    }
  }

  inviteFilteredDrivers = () => {
    this.props.setSelectedDriversCount(this.props.driversStats.current);
    const filteredFilters = this.props.filterValues;

    if (filteredFilters.classic === '') delete filteredFilters.classic;
    delete filteredFilters.limit;

    const { params } = this.props.match;

    this.props.onChange('invite-drivers', 'invite', {});
    this.props.onChange('invite-drivers', 'user_filters', this.props.filterValues);
    this.props.history.push(`/event/${params.eventId}/invite${isTicketInvite(params) ? `/${params.ticketId}` : ''}/message`);
  }

  shouldDisableFilterButton = () => {
    if (this.props.location.search) {
      const query = qs.parse(this.props.history.location.search, { ignoreQueryPrefix: true });

      const notActive = !query.status || (query.status && query.status[0] !== 'active');
      const noStatus = query.status && !query.status.length > 0;
      const noDrivers = this.props.driversStats.current === 0;

      if (notActive || noStatus || noDrivers) return true;

      // enable button when a filter is selected
      if (query.level ||
        query.tag ||
        query.model ||
        query.dealer ||
        query.search ||
        query.model_groups
      ) {
        return false;
      }
    }

    return true;
  }

  render() {
    const { driversStats, invitedData } = this.props;
    const isDisabled = this.shouldDisableFilterButton();

    return (
      <Container>
        <StepsContainer>
          <StepsItem active>1. Porsche rijders</StepsItem>
          <StepsItem disabled>2. Bericht</StepsItem>
          <StepsItem disabled>3. Bevestig</StepsItem>
        </StepsContainer>
        <Title underline>Selecteer Porsche rijders die u uit wil nodigen</Title>
        <DriversOverview invite />
        <ActionsContainer>
          <Button
            onClick={this.inviteFilteredDrivers}
            light
            disabled={isDisabled}
          >
            Nodig alle {!isDisabled ? driversStats.current : '0'} porsche rijders uit op basis van filters
          </Button>
          <Button
            onClick={this.inviteDrivers}
          >
            Nodig {Object
              .keys(invitedData.invite)
              .filter(checked => invitedData.invite[checked]).length
            } porsche rijders uit &gt;
          </Button>
        </ActionsContainer>
      </Container>
    );
  }
}

InviteDrivers.defaultProps = {
  invitedData: {
    invite: {},
  },
};

InviteDrivers.propTypes = {
  showNotification: PT.func,
  invitedData: PT.object,
  location: PT.object,
  driversStats: PT.object,
  history: PT.object,
  match: PT.object,
  filterValues: PT.object,
  onChange: PT.func,
  setSelectedDriversCount: PT.func,
};

export default connect(state => ({
  drivers: state.drivers.data,
  driversStats: state.drivers.stats,
  invitedData: getFormValues('invite-drivers')(state),
  filterValues: getFormValues('drivers-filters')(state),
}), {
  showNotification,
  onChange: change,
  setSelectedDriversCount,
})(InviteDrivers);
