import createAction from 'services/createAction';

const GET_PAGES = 'brandPortal/GET_PAGES';
const GET_PAGES_SUCCESS = 'brandPortal/GET_PAGES_SUCCESS';
const GET_PAGES_FAILED = 'brandPortal/GET_PAGES_FAILED';

const GET_PAGE = 'brandPortal/GET_PAGE';
const GET_PAGE_SUCCESS = 'brandPortal/GET_PAGE_SUCCESS';
const GET_PAGE_FAILED = 'brandPortal/GET_PAGE_FAILED';

const initialState = {
  loading: false,
  error: false,
  pages: [],
  page: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case GET_PAGES:
  case GET_PAGE:
    return {
      ...state,
      loading: true,
    };

  case GET_PAGES_SUCCESS:
    return {
      ...state,
      loading: false,
      pages: payload.topics.map(topic => ({
        ...topic,
        top_pages: payload.top_pages[topic.id].map(topPage => ({
          ...topPage,
          sub_pages: payload.sub_pages[topPage.id].map(subPage => subPage),
        })),
      })),
    };

  case GET_PAGE_SUCCESS:
    return {
      ...state,
      loading: false,
      page: payload,
    };

  case GET_PAGES_FAILED:
  case GET_PAGE_FAILED:
    return {
      ...state,
      loading: false,
      error: true,
    };

  default:
    return state;
  }
};

const getPagesSuccess = createAction(GET_PAGES_SUCCESS);
const getPagesFailed = createAction(GET_PAGES_FAILED);

export const getPages = () => (dispatch, getState, api) => {
  dispatch({ type: GET_PAGES });

  api.get({ path: 'nav/', brandPortal: true })
    .then((res) => {
      dispatch(getPagesSuccess(res));
    })
    .catch((err) => {
      dispatch(getPagesFailed({ message: err.data }));
    });
};

const getPageSuccess = createAction(GET_PAGE_SUCCESS);
const getPageFailed = createAction(GET_PAGE_FAILED);

export const getPage = id => (dispatch, getState, api) => {
  dispatch({ type: GET_PAGE });

  api.get({ path: `pages/${id}/`, brandPortal: true })
    .then((res) => {
      dispatch(getPageSuccess(res));
    })
    .catch((err) => {
      dispatch(getPageFailed({ message: err.data }));
    });
};
