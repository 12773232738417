import React, { Fragment } from 'react';
import PT from 'prop-types';

import ArrowRight from 'vectors/arrowRight.svg';

import ExtendedButton from './styled';

const EventPreviewActions = ({ eventId, hasTickets }) => (
  <Fragment>
    <ExtendedButton
      onClick={() => { window.location.href = '/p24app'; }}
      stretch
    >
      <ArrowRight /> Aanmelden via Porsche24 app
    </ExtendedButton>
    {hasTickets && (
      <ExtendedButton
        onClick={() => { window.location.href = `/tickets/event/${eventId}`; }}
        variant="black"
        stretch
      >
        <ArrowRight /> Aanmelden zonder Porsche24 account
      </ExtendedButton>
    )}
  </Fragment>
);

EventPreviewActions.propTypes = {
  eventId: PT.string,
  hasTickets: PT.bool,
};

export default EventPreviewActions;
