import React, { Component, Fragment } from 'react';
import PT from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';

import { resetEvent, loadCategories } from 'ducks/editEvent';

import EventHeader from './components/EventHeader';
import Details from './components/Details';
import Tickets from './components/Tickets';
import Publish from './components/Publish';

class EventEditor extends Component {
  componentDidMount() {
    if (this.props.categories.length === 0) {
      this.props.loadCategories();
    }
  }

  componentWillUnmount() {
    this.props.resetEvent();
  }

  render() {
    return (
      <Fragment>
        <EventHeader />
        <Switch>
          <Route path="/event/edit/:id/details" component={Details} />
          <Route path="/event/edit/:id/tickets/:ticketId?" component={Tickets} />
          <Route path="/event/edit/:id/publish" component={Publish} />
          <Redirect from="/event/edit/:id" to="/event/edit/:id/details" />
        </Switch>
      </Fragment>
    );
  }
}

EventEditor.propTypes = {
  resetEvent: PT.func,
  loadCategories: PT.func,
  categories: PT.array,
};

export default compose(
  withRouter,
  connect(state => ({
    categories: state.editEvent.categories,
  }), {
    resetEvent,
    loadCategories,
  }),
)(EventEditor);
