import createAction from 'services/createAction';

const GET_CUSTOM_REWARDS = 'rewards/GET_CUSTOM';
const GET_CUSTOM_REWARDS_SUCCESS = 'rewards/GET_CUSTOM_SUCCESS';
const GET_CUSTOM_REWARDS_FAILED = 'rewards/GET_CUSTOM_FAILED';

const GET_GIFTABLE_REWARDS = 'rewards/GET_GIFTABLE';
const GET_GIFTABLE_REWARDS_SUCCESS = 'rewards/GET_GIFTABLE_SUCCESS';
const GET_GIFTABLE_REWARDS_FAILED = 'rewards/GET_GIFTABLE_FAILED';

const initialState = {
  data: {
    custom: [],
    giftable: [],
  },
  error: {
    message: '',
  },
  loading: {
    getCustomRewards: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case GET_CUSTOM_REWARDS_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        custom: payload,
      },
      loading: { ...state.loading, getCustomRewards: false },
    };
  case GET_CUSTOM_REWARDS_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, getCustomRewards: false },
    };
  case GET_CUSTOM_REWARDS:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, getCustomRewards: true },
    };
  case GET_GIFTABLE_REWARDS_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        giftable: payload,
      },
      loading: { ...state.loading, getGiftableRewards: false },
    };
  case GET_GIFTABLE_REWARDS_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, getGiftableRewards: false },
    };
  case GET_GIFTABLE_REWARDS:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, getGiftableRewards: true },
    };
  default:
    return state;
  }
};

const getCustomRewardsSuccess = createAction(GET_CUSTOM_REWARDS_SUCCESS);
const getCustomRewardsFailed = createAction(GET_CUSTOM_REWARDS_FAILED);

export const getCustomRewards = () => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: GET_CUSTOM_REWARDS });

    api.get({ path: 'rewards?is_editable=true', v2: true })
      .then((res) => {
        dispatch(getCustomRewardsSuccess(res));
        resolve(res);
      })
      .catch((err) => {
        dispatch(getCustomRewardsFailed({ message: err.data }));
        reject(err);
      });
  })
);

const getGiftableRewardsSuccess = createAction(GET_GIFTABLE_REWARDS_SUCCESS);
const getGiftableRewardsFailed = createAction(GET_GIFTABLE_REWARDS_FAILED);

export const getGiftableRewards = () => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: GET_GIFTABLE_REWARDS });

    api.get({ path: 'rewards?is_giftable=true', v2: true })
      .then((res) => {
        dispatch(getGiftableRewardsSuccess(res));
        resolve(res);
      })
      .catch((err) => {
        dispatch(getGiftableRewardsFailed({ message: err.data }));
        reject(err);
      });
  })
);
