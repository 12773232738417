import styled from 'styled-components';

export const ModalContainer = styled.div`
  margin: 48px 36px 0 36px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 22px 0;
`;

export const RewardsContainer = styled.div`
  margin: 22px 0;
`;
