import React, { Fragment } from 'react';
import PT from 'prop-types';

import { EventCard } from 'modules';

import { Title } from './styled';

const EventsCategory = ({
  events, title, titleInvert, past,
}) => (
  <Fragment>
    {events.length > 0 && <Title titleInvert={titleInvert}>{title}</Title>}
    {events.map(event => (
      <EventCard
        event={event}
        key={event.id}
        noActionButtons={past}
        past={past}
        showBottomBar
      />
    ))}
  </Fragment>
);

EventsCategory.propTypes = {
  events: PT.array,
  title: PT.string,
  titleInvert: PT.bool,
  past: PT.bool,
};


export default EventsCategory;
