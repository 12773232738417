import React, { Component } from 'react';
import PT from 'prop-types';
import { withRouter } from 'react-router-dom';

import getBrandPortalUrl from 'services/getBrandPortalUrl';
import { Container } from 'common';

import BrandPortalTopics from './components/BrandPortalTopics';
import BrandPortalSidebar from './components/BrandPortalSidebar';
import BrandPortalContent from './components/BrandPortalContent';

import ContentContainer from './styled';

class BrandPortal extends Component {
  componentDidMount() {
    document.addEventListener('click', this.handleBrandPortalRouting);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleBrandPortalRouting);
  }

  handleBrandPortalRouting = (event) => {
    if (event.target.matches('a[linktype="page"]')) {
      event.preventDefault();
      const pageId = event.target.id;

      if (pageId) {
        const url = getBrandPortalUrl(event.target.id, this.props.pages);
        this.props.history.push(url);
      }
    }
  }

  render() {
    const { pages, page, match: { params } } = this.props;
    if (!page || !pages || pages.length <= 0) return null;

    const topic = pages.find(topicPage => topicPage.slug === params.topic) || pages[0];
    return (
      <Container>
        <BrandPortalTopics topics={pages} />
        <ContentContainer>
          <BrandPortalSidebar selectedTopic={topic} />
          <BrandPortalContent page={page} />
        </ContentContainer>
      </Container>
    );
  }
}

BrandPortal.propTypes = {
  pages: PT.array,
  match: PT.object,
  page: PT.object,
  history: PT.object,
};

export default withRouter(BrandPortal);
