import styled from 'styled-components';

import { Button } from 'common';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TicketContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;


export const TicketWrapper = styled.div`
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  margin: 18px 0 0 18px;
  display: flex;
  flex-direction: column;

  & button:nth-of-type(2) {
    margin-top: 9px;
  }
`;

export const ButtonUp = styled(Button)`
  margin-right: 0;

  svg {
    transform: rotate(180deg);
  }
`;
