import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const EditorContainer = styled.div`
  padding-bottom: 36px;
`;
