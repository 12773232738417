import styled from 'styled-components';

import { media } from 'styles/utils';
import EditIcon from 'vectors/edit.svg';
import FlexWrapper from 'common/FlexWrapper';

export const StyledEditIcon = styled(EditIcon)`
  width: 20px;
  height: 20px;
  margin-left: 8px;
`;

export const TitleContainer = styled(FlexWrapper)`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 31px;

  ${media.desktop`
    margin-bottom: 0;
  `}
`;
