import styled from 'styled-components';
import { media } from 'styles/utils';

import { Container } from 'common';

export const Main = styled(Container)`
  ${media.desktop`
    display: flex;

    ${Container} {
      flex: 1;
      margin-right: 36px;
    }
  `}
`;

export const Aside = styled.aside`
  flex-basis: 420px;
  padding-top: 36px;
`;
