import React, { Component, Fragment } from 'react';
import PT from 'prop-types';

import { Button, Container } from 'common';
import { InputText, SubLabel } from 'common/form';

class EditEventManager extends Component {
  state = {};

  setValue = (val, field, id) => {
    this.setState({
      [id]: {
        ...this.state[id],
        password: {
          ...this.state[id].password,
          [field]: val,
        },
      },
    });
  }

  toggleShowPasswordFields = (id, bool) => {
    this.setState({
      [id]: {
        showFields: bool,
        password: {
          newPassword: '',
          comparePassword: '',
        },
      },
    });
  }

  savePassword(id, passwords) {
    this.props.edit(id, passwords).then(() => {
      this.setState({
        [id]: {
          showFields: false,
        },
      });
    });
  }

  render() {
    const { eventManager } = this.props;

    return (
      <div>
        <p>{eventManager.email}</p>
        <Container small>
          {this.state[eventManager.id] && this.state[eventManager.id].showFields ? (
            <Fragment>
              <InputText
                type="password"
                placeholder="Nieuw wachtwoord"
                value={
                  this.state[eventManager.id].password ?
                    this.state[eventManager.id].password.newPassword :
                    ''
                }
                onChange={e => this.setValue(e.target.value, 'newPassword', eventManager.id)}
              />
              <InputText
                type="password"
                placeholder="Herhaal wachtwoord"
                value={
                  this.state[eventManager.id].password ?
                    this.state[eventManager.id].password.comparePassword :
                    ''
                }
                onChange={e => this.setValue(e.target.value, 'comparePassword', eventManager.id)}
              />
              <SubLabel>
                Uw wachtwoord moet minstens 8 tekens, 1 hoofdletter, 1 kleine letter, 1 nummer en 1 speciaal teken bevatten.
              </SubLabel>
              <Button
                onClick={() => this.savePassword(eventManager.id, this.state[eventManager.id].password)}
                small
                stretch
              >
                Opslaan
              </Button>
              <Button
                onClick={() => this.toggleShowPasswordFields(eventManager.id, false)}
                sub
                small
                stretch
              >
              Annuleren
              </Button>
            </Fragment>
          ) : (
            <Button
              onClick={() => this.toggleShowPasswordFields(eventManager.id, true)}
              small
              stretch
            >
              Wijzig wachtwoord
            </Button>
          )}
        </Container>
      </div>
    );
  }
}

EditEventManager.propTypes = {
  eventManager: PT.object,
  edit: PT.func,
};

export default EditEventManager;
