import styled from 'styled-components';

const FormActions = styled.div`
  button {
    &:not(:last-of-type) {
      margin-right: 18px;
    }
  }
`;

export default FormActions;
