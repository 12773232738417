import styled, { css } from 'styled-components';
import PT from 'prop-types';

const Container = styled.div`
  width: 100%;
  max-width: 1128px;
  margin: 0 auto;
  padding: 18px;

  ${props => props.small && css`
    max-width: 440px;
  `}

  ${props => props.textCenter && css`
    text-align: center;
  `}

  ${props => props.alignCenter && css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `}
`;

Container.propTypes = {
  small: PT.bool,
  textCenter: PT.bool,
  alignCenter: PT.bool,
};

export default Container;
