import React from 'react';
import moment from 'moment';

import { Wrapper } from 'common';

import FooterContainer from './styled';

const EventPreviewFooter = () => (
  <Wrapper>
    <FooterContainer>
      <p>
        © {moment().format('YYYY')} Porsche Nederland.&nbsp;
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.porsche.com/netherlands/nl/legal-notice/"
        >
            Copyright & privacy
        </a>.&nbsp;
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="/privacy-policy"
        >
            Privacy Porsche24
        </a>.&nbsp;
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.porsche.com/netherlands/nl/cookies/"
        >
            Cookies
        </a>.&nbsp;
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.porsche.com/netherlands/nl/verkoopinformatie/"
        >
            Verkoopinformatie
        </a>.
      </p>
      <p>
          * De vermelde waarden zijn volgens de voorgeschreven meetmethode berekend. Sinds 1 september 2017 worden bepaalde nieuwe auto's al typegoedgekeurd volgens de wereldwijd geharmoniseerde testmethode voor personenwagens en lichte bedrijfswagens (Worldwide Harmonized Light Vehicles Test Procedure, WLTP), een meer realistische testmethode voor het meten van het brandstofverbruik en de CO₂-emissie. Vanaf 1 september 2018 wordt de NEDC (Nieuwe Europese Testcyclus) vervangen door de WLTP. Door de meer realistische testomstandigheden zullen het brandstofverbruik en de CO₂-emissies gemeten volgens de WLTP in veel gevallen hoger zijn dan die gemeten volgens de NEDC. Daardoor kunnen vanaf 1 september 2018 bij de autobelastingen of andere voertuigspecifieke heffingen, die o.a. gericht zijn op de CO₂-emissie, veranderingen optreden. Op <a href="http://www.porsche.com/wltp" target="_blank" rel="noopener noreferrer">www.porsche.com/wltp</a> vindt u meer informatie over de verschillen tussen WLTP en NEDC.
      </p>
      <p>
          Momenteel is het nog steeds wettelijk verplicht om de NEDC-cijfers te vermelden. In het geval van nieuwe auto's waarvoor typegoedkeuring is verleend volgens WLTP, zijn de NEDC-cijfers afgeleid van de WLTP-gegevens. Zolang het nog niet verplicht is om de WLTP-waarden te vermelden, mogen deze wel vrijwillig worden genoemd. Voor zover de NEDC-waarden als bandbreedtes worden vermeld, hebben deze geen betrekking op een specifiek automodel en maken geen deel uit van het aanbod. Ze mogen slechts worden gezien als referentie bij de vergelijking van verschillende autotypes. Extra uitrustingen en accessoires (aanbouwdelen, bandenmaat enz.) kunnen relevante voertuigparameters zoals gewicht, rolweerstand en aerodynamica veranderen en naast weers- en verkeersomstandigheden alsmede het individuele rijgedrag het brandstofverbruik, het stroomverbruik, de CO₂-emissie en de prestaties van een auto beïnvloeden.
      </p>
    </FooterContainer>
  </Wrapper>
);

export default EventPreviewFooter;
