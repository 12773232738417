import styled from 'styled-components';

export const Video = styled.div`
  position: relative;
  display: block;
  padding-bottom: 56.25%;
  margin-top: 2px;
`;

export const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
`;
