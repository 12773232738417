import React, { Component } from 'react';
import PT from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm, Field, getFormValues } from 'redux-form';

import {
  hasRole,
  DEALER,
  COMMUNITY_MANAGER,
} from 'services/authHelper';
import { DRAFT } from 'services/eventHelper';

import * as validation from 'services/validations';

import { loadEvent, saveEvent, uploadImage, deleteImage } from 'ducks/editEvent';

import { Icon } from 'modules/EventEditor/styled';
import { Title, Subtitle, Button, Container } from 'common';
import { StepsContainer, StepsItem } from 'common/steps';
import { Form, MultiColumns, Label, SubLabel, InputText, InputTextarea, InputDate, InputCheckbox, FormField, Wysiwyg, LocationFinder, RadioTag, InputDropZoneImage, InputError } from 'common/form';

import WarningModal from '../WarningModal';

class Details extends Component {
  state = {
    showWarningModal: false,
  };

  componentDidMount() {
    this.props.onLoad(this.props.match.params.id);
  }

  componentDidUpdate(prevProps) {
    const { formData, data } = this.props;
    const { formData: prevFormData } = prevProps;

    // Check wether start date, end date or location is changed by comparing it with previous form data
    // onBlur does not work on those fields
    if (
      data.status === 'published' &&
      prevFormData.start_date &&
      formData.start_date &&
      (
        prevFormData.start_date !== formData.start_date ||
        prevFormData.end_date !== formData.end_date ||
        prevFormData.city !== formData.city
      )
    ) {
      this.openConfirmationModal();
    }
  }

  openConfirmationModal = () => {
    this.setState({
      showWarningModal: true,
    });
  }

  render() {
    const {
      handleSubmit, onSubmit, submitFailed, data, categories,
      formData, formErrors, history, match,
      onUploadImage, onDeleteImage, roles,
    } = this.props;

    return (
      <Container>
        {this.state.showWarningModal && (
          <WarningModal onClose={() => this.setState({ showWarningModal: false })} />
        )}
        <StepsContainer>
          <StepsItem data-cy="step-event-1" active>1. Details</StepsItem>
          <StepsItem data-cy="step-event-2" disabled>2. Tickets</StepsItem>
          <StepsItem data-cy="step-event-3" disabled>
            {`3. ${hasRole(roles, [COMMUNITY_MANAGER])
              ? 'Publiceren'
              : hasRole(roles, [DEALER]) && formData.status === DRAFT
                ? 'Indienen voor review'
                : 'Opslaan'}`}
          </StepsItem>
        </StepsContainer>

        <Title underline>Event aanmaken</Title>

        <Form onSubmit={
          handleSubmit(values => (
            onSubmit(values).then(() => history.push(`/event/edit/${match.params.id}/tickets`))
          ))}
        >
          <Subtitle>Event details</Subtitle>

          <FormField>
            <Label>Event naam</Label>
            <Field
              name="name"
              component={InputText}
              type="text"
              validate={validation.required}
              data-cy="input-event-name"
            />
          </FormField>

          <FormField>
            <Label>Korte omschrijving</Label>
            <SubLabel groupWithLabel>Korte omschrijving</SubLabel>
            <Field
              name="short_description"
              component={Wysiwyg}
              height="190"
              validate={validation.draftjsRequired}
              defaultValue={formData.short_description}
            />
          </FormField>

          <FormField>
            <Label>Lange omschrijving</Label>
            <Field
              name="long_description"
              component={Wysiwyg}
              height="500"
              validate={validation.draftjsRequired}
              defaultValue={formData.long_description}
            />
          </FormField>

          <FormField>
            <Label>Locatie omschrijving</Label>
            <SubLabel groupWithLabel>Voer een plaats of adres in</SubLabel>
            <Field
              name="country"
              component={LocationFinder}
              validate={validation.required}
              defaultValue={[formData.street, formData.housenumber, formData.zipcode, formData.city, formData.country]}
            />
          </FormField>

          <Label>Datum en Tijd</Label>
          <MultiColumns>
            <FormField data-cy="container-event-start-date">
              <SubLabel groupWithLabel>Event begint op</SubLabel>
              <Field
                component={InputDate}
                name="start_date"
                validate={validation.required}
                defaultDate={formData.start_date}
                noErrorText
              />
            </FormField>

            <FormField data-cy="container-event-end-date">
              <SubLabel groupWithLabel>Event eindigt op</SubLabel>
              <Field
                component={InputDate}
                name="end_date"
                validate={validation.required}
                defaultDate={data.end_date || formData.end_date}
                noErrorText
                minDate={formData.start_date}
              />
            </FormField>
          </MultiColumns>

          <FormField>
            <Field
              name="surprise"
              component={InputCheckbox}
              defaultChecked={data.surprise}
              label="Maak dit een surprise event"
              subLabel="Surprise events zijn pas 5 dagen vóór het event zichtbaar voor de 'porsche rijders' en zullen er speciaal uitzien in de app."
            />
          </FormField>

          <FormField>
            <Field
              name="private"
              component={InputCheckbox}
              defaultChecked={data.private}
              label="Maak dit een privé event"
              subLabel="Privé events zijn alleen zichtbaar voor gebruikers die je via dit systeem uitnodigt en zullen er speciaal uitzien in de app. Bij een privé event kan je bij ticket een ‘normaal ticket’ kiezen, doordat het event is afgeschermd."
            />
          </FormField>

          <Subtitle>Foto & Video</Subtitle>

          <FormField>
            <Label>Image</Label>
            <InputDropZoneImage
              assets={data.image}
              onUpload={(acceptedFiles) => {
                onUploadImage(match.params.id, acceptedFiles[0]);
              }}
              onDelete={() => onDeleteImage(match.params.id)}
            />
          </FormField>

          <FormField>
            <Label>Youtube</Label>
            <Field
              name="youtube_link"
              component={InputText}
              type="text"
            />
          </FormField>

          <FormField>
            <Subtitle>Categorie</Subtitle>

            {categories.length > 0 && categories.map(category => (
              <Field
                component={RadioTag}
                name="category_id"
                key={category.id}
                id={category.id}
                label={category.name}
                checked={formData.category_id === category.id}
                validate={validation.required}
                error={submitFailed && (formErrors && formErrors.category_id)}
                data-cy={(formData.category_id !== category.id) ? 'input-select-category-unchecked' : 'input-select-category-checked'}
              />
            ))}
            {submitFailed && (formErrors && formErrors.category_id) && (
              <InputError meta={{ submitFailed, error: formErrors.category_id }} />
            )}
          </FormField>

          <Subtitle>Contactgegevens</Subtitle>

          <FormField>
            <Label>Contactpersoon</Label>
            <Field
              name="contact_person"
              component={InputText}
              type="text"
              validate={validation.required}
            />
          </FormField>

          <FormField>
            <Label>Phone</Label>
            <Field
              name="phone"
              component={InputText}
              type="text"
              validate={validation.telephone}
            />
          </FormField>

          <FormField>
            <Label>Whatsapp nummer</Label>
            <Field
              name="whatsapp_number"
              component={InputText}
              type="text"
              placeholder="+316..."
            />
          </FormField>

          <FormField>
            <Label>E-mailadres</Label>
            <Field
              name="email"
              component={InputText}
              type="email"
              validate={[validation.required, validation.email]}
            />
          </FormField>

          <Subtitle>Algemene voorwaarden</Subtitle>

          <FormField>
            <SubLabel>Het is mogelijk de algemene voorwaarden te bewerken.</SubLabel>
            <Field
              name="terms_and_conditions"
              component={InputTextarea}
              validate={validation.required}
            />
          </FormField>

          <FormField>
            <Button type="submit" data-cy="button-event-next-step">Volgende <Icon>&gt;</Icon></Button>
          </FormField>
        </Form>
      </Container>
    );
  }
}

Details.propTypes = {
  handleSubmit: PT.func,
  onSubmit: PT.func,
  submitFailed: PT.bool,
  onLoad: PT.func,
  match: PT.object,
  history: PT.object,
  data: PT.object,
  categories: PT.array,
  formData: PT.object,
  formErrors: PT.object,
  onUploadImage: PT.func,
  onDeleteImage: PT.func,
  roles: PT.array,
};

export default compose(
  withRouter,
  connect(state => ({
    roles: state.authentication.roles,
    data: state.editEvent.data,
    categories: state.editEvent.categories,
    formErrors: (state.form['create-event-details'] && state.form['create-event-details'].syncErrors) || {},
    formData: getFormValues('create-event-details')(state) || {},
  }), {
    onSubmit: saveEvent,
    onLoad: loadEvent,
    onUploadImage: uploadImage,
    onDeleteImage: deleteImage,
  }),
  reduxForm({ form: 'create-event-details' }),
)(Details);
