import React, { Fragment, Component } from 'react';
import PT from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  hasRole,
  DEALER,
  COMMUNITY_MANAGER,
  PARTNER,
} from 'services/authHelper';
import { DRAFT } from 'services/eventHelper';

import { loadEvent, submitEventToReview, publishEvent } from 'ducks/editEvent';
import { showNotification } from 'ducks/notification';

import { TicketList, Icon } from 'modules/EventEditor/styled';
import EventCard from 'modules/EventCard';
import { Wrapper, Container, Title, Subtitle, Button, Modal } from 'common';
import { StepsContainer, StepsItem } from 'common/steps';

import { ModalContainer, ModalTitle, ModalButtonsContainer } from './styled';

import Ticket from '../Ticket';

class Publish extends Component {
  state = {
    confirm: false,
  };

  componentWillMount() {
    if (Object.keys(this.props.event).length === 0) {
      this.props.onLoad(this.props.match.params.id);
    }
  }

  onSaveEvent = () => {
    const { history, onShowNotification } = this.props;

    onShowNotification('Het event is succesvol opgeslagen.', 'green');
    history.push('/');
  }

  onSubmitEvent = () => {
    const {
      match, history, onSubmit,
    } = this.props;

    onSubmit(match.params.id).then(() => {
      history.push('/events');
    });
  }

  onPublishEvent = () => {
    const {
      match, history, onPublish,
    } = this.props;

    onPublish(match.params.id).then(() => {
      history.push('/events');
    });
  }

  openConfirmationModal = () => {
    this.setState({
      confirm: true,
    });
  }

  closeConfirmationModal = () => {
    this.setState({
      confirm: false,
    });
  }

  render() {
    const {
      event, tickets, match, history, roles,
    } = this.props;

    return (
      <Fragment>
        <Container>
          <StepsContainer>
            <StepsItem onClick={() => {
              history.push(`/event/edit/${match.params.id}/details`);
            }}
            >
              1. Details
            </StepsItem>
            <StepsItem onClick={() => {
              history.push(`/event/edit/${match.params.id}/tickets`);
            }}
            >
              2. Tickets
            </StepsItem>
            <StepsItem active>
              {`3. ${hasRole(roles, [COMMUNITY_MANAGER])
                ? 'Publiceren'
                : hasRole(roles, [DEALER]) && event.status === DRAFT
                  ? 'Indienen voor review'
                  : 'Opslaan'}`}
            </StepsItem>
          </StepsContainer>
          <Title underline>Bevestigen en {
            hasRole(roles, [COMMUNITY_MANAGER])
              ? 'publiceren'
              : hasRole(roles, [DEALER, PARTNER]) && event.status === DRAFT
                ? 'indienen voor review'
                : 'opslaan'}
          </Title>
        </Container>

        <Container>
          <Subtitle>Event details</Subtitle>

          <EventCard event={event} readonly />
        </Container>

        <Wrapper variant="background">
          <Container>
            <Subtitle>Tickets</Subtitle>

            {tickets && (
              <Wrapper variant="background">
                <Container>
                  <TicketList>
                    {tickets.map(ticket => (
                      <Ticket
                        data={ticket}
                        key={ticket.id}
                        eventId={event.id}
                        edit={false}
                        summary
                      />
                    ))}
                  </TicketList>
                </Container>
              </Wrapper>
            )}
          </Container>
        </Wrapper>

        <Container textCenter>
          <Button
            onClick={this.onSaveEvent}
            data-cy="button-event-save"
          >
            Opslaan <Icon>&gt;</Icon>
          </Button>

          {hasRole(roles, [DEALER, PARTNER]) && event.status === DRAFT && (
            <Button
              onClick={this.onSubmitEvent}
              data-cy="button-event-submit-to-review"
            >
              Indienen voor review <Icon>&gt;</Icon>
            </Button>
          )}

          {hasRole(roles, [COMMUNITY_MANAGER]) && (
            <Button
              onClick={this.openConfirmationModal}
              data-cy="button-event-publish"
            >
              Publiceren <Icon>&gt;</Icon>
            </Button>
          )}
        </Container>
        {this.state.confirm && (
          <Modal onHideModal={this.closeConfirmationModal}>
            <ModalContainer>
              <ModalTitle small>Weet je zeker dat je deze event wilt publiceren?</ModalTitle>
              <ModalButtonsContainer>
                <Button small onClick={this.onPublishEvent}>Ok</Button>
                <Button small onClick={this.closeConfirmationModal}>Cancel</Button>
              </ModalButtonsContainer>
            </ModalContainer>
          </Modal>
        )}
      </Fragment>
    );
  }
}

Publish.propTypes = {
  event: PT.object,
  tickets: PT.array,
  match: PT.object,
  history: PT.object,
  onLoad: PT.func,
  onSubmit: PT.func,
  onPublish: PT.func,
  onShowNotification: PT.func,
  roles: PT.array,
};

export default compose(
  withRouter,
  connect(state => ({
    roles: state.authentication.roles,
    event: state.editEvent.data,
    tickets: state.editEvent.data.ticket,
  }), {
    onLoad: loadEvent,
    onSubmit: submitEventToReview,
    onPublish: publishEvent,
    onShowNotification: showNotification,
  }),
)(Publish);
