import React, { Component } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import { change, getFormValues, reset } from 'redux-form';

import {
  hasRole,
  DEALER,
} from 'services/authHelper';

import { getLevels } from 'ducks/levels';
import { getTags } from 'ducks/tags';
import { getCarModels } from 'ducks/carModels';
import { getAccountStatus } from 'ducks/accountStatus';
import { getDealers } from 'ducks/dealers';
import { showNotification } from 'ducks/notification';

import { InputCheckbox } from 'common/form';
import FilterCategory from '../SidebarFiltersCategory';
import ModelGroupFilter from '../SidebarFiltersModelGroup';

import { SidebarContainer, Label, ResetContainer, ExtendedAnchor, MinusIcon } from './styled';

class SidebarFilters extends Component {
  state = {
    levels: false,
    tags: false,
    status: false,
    dealers: false,
    modelGroups: false,
    modelGroupsFilter: {},
  }

  componentWillMount() {
    this.props.getLevels();
    this.props.getTags();
    this.props.getCarModels();
    this.props.getAccountStatus();
    this.props.getDealers();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.filterValues !== this.props.filterValues) {
      this.setState({
        levels: !!nextProps.filterValues.level,
        tags: !!nextProps.filterValues.tag,
        status: !!nextProps.filterValues.status,
        modelGroups: !!nextProps.filterValues.model_groups || !!nextProps.filterValues.model || nextProps.filterValues.classic !== '',
        dealers: !!nextProps.filterValues.dealer,
      });
    }

    // when carmodels are loaded
    if (this.props.carModels !== nextProps.carModels) {
      const modelFilters = this.checkCarModelFilters(nextProps.carModels, this.props.filterValues);
      this.setState({
        modelGroupsFilter: modelFilters,
      });
    }
  }

  checkCarModelFilters = (carModels, filterValues) => {
    if (!filterValues.model) return {};

    // checked should include modelGroups that have a model that is checked
    const checked = {};
    Object.entries(carModels).map(([modelGroup, models]) => (
      models.map((model) => {
        if (filterValues.model.includes(model)) {
          checked[modelGroup] = true;
        }
        return null;
      })
    ));
    return checked;
  }

  toggleFilterDropdown = (filter) => {
    this.setState({
      [filter]: !this.state[filter],
    });
  }

  toggleModelGroupDropdown = (modelGroup) => {
    this.setState({
      modelGroupsFilter: {
        ...this.state.modelGroupsFilter,
        [modelGroup]: !this.state.modelGroupsFilter[modelGroup],
      },
    });
  }

  handleCheckboxOnClick = (e, field, value) => {
    // This function is used to change array values in redux form
    if (e.target.checked) {
      // When checked is true, create a new array with either current values + new value
      // or empty array when there are no current values + new value
      this.props.change('drivers-filters', field, [...(this.props.filterValues[field] || []), value]);
    } else {
      // when checked is false, filter away the selected value
      this.props.change('drivers-filters', field, this.props.filterValues[field].filter(item => item !== value));
    }
  }

  handleRadioOnClick = (e, field) => {
    this.props.change('drivers-filters', field, e.target.value);
  }

  render() {
    const {
      levels, tags, carModels, accountStatus, dealers, roles, filterValues,
      onReset, onShowNotification,
    } = this.props;

    return (
      <SidebarContainer>
        <ResetContainer>
          <ExtendedAnchor
            small
            onClick={() => {
              onReset('drivers-filters');
              onShowNotification('Alle filters zijn gereset.', 'green');
            }}
          >
            Verwijder alles <MinusIcon>—</MinusIcon>
          </ExtendedAnchor>
        </ResetContainer>

        <FilterCategory
          title="Rijdersniveau"
          onClick={() => this.toggleFilterDropdown('levels')}
          active={this.state.levels}
        >
          {levels.map(level => (
            <InputCheckbox
              key={level.id}
              id={level.id}
              onChange={e => this.handleCheckboxOnClick(e, 'level', level.id)}
              checked={!!filterValues.level && filterValues.level.includes(level.id)}
            >
              <Label htmlFor={level.id}>{level.name}</Label>
            </InputCheckbox>
          ))}
        </FilterCategory>

        <FilterCategory
          title="Interesses"
          onClick={() => this.toggleFilterDropdown('tags')}
          active={this.state.tags}
        >
          {tags.map(tag => (
            <InputCheckbox
              key={tag}
              id={tag}
              onChange={e => this.handleCheckboxOnClick(e, 'tag', tag)}
              checked={!!filterValues.tag && filterValues.tag.includes(tag)}
            >
              <Label htmlFor={tag}>{tag}</Label>
            </InputCheckbox>
          ))}
        </FilterCategory>

        <FilterCategory
          onClick={() => this.toggleFilterDropdown('modelGroups')}
          active={this.state.modelGroups}
          title="Porsche model"
        >
          <ModelGroupFilter
            handleRadioOnClick={this.handleRadioOnClick}
            carModels={carModels}
            filterValues={filterValues}
            toggleModelGroupDropdown={this.toggleModelGroupDropdown}
            handleCheckboxOnClick={this.handleCheckboxOnClick}
            modelGroupsFilter={this.state.modelGroupsFilter}
          />
        </FilterCategory>

        <FilterCategory
          onClick={() => this.toggleFilterDropdown('status')}
          active={this.state.status}
          title="Account status"
        >
          {accountStatus.map(status => (
            <InputCheckbox
              key={status.value}
              id={status.value}
              onChange={e => this.handleCheckboxOnClick(e, 'status', status.value)}
              checked={!!filterValues.status && filterValues.status.includes(status.value)}
            >
              <Label htmlFor={status.value}>{status.name}</Label>
            </InputCheckbox>
          ))}
        </FilterCategory>

        {!hasRole(roles, [DEALER]) && (
          <FilterCategory
            onClick={() => this.toggleFilterDropdown('dealers')}
            active={this.state.dealers}
            title="Dealers"
          >
            {dealers.map(dealer => (
              <InputCheckbox
                key={dealer.id}
                id={dealer.id}
                onChange={e => this.handleCheckboxOnClick(e, 'dealer', dealer.id)}
                checked={!!filterValues.dealer && filterValues.dealer.includes(dealer.id)}
              >
                <Label htmlFor={dealer.id}>{dealer.name}</Label>
              </InputCheckbox>
            ))}
          </FilterCategory>
        )}
      </SidebarContainer>
    );
  }
}

SidebarFilters.propTypes = {
  levels: PT.array,
  getLevels: PT.func,
  tags: PT.array,
  getTags: PT.func,
  carModels: PT.object,
  getCarModels: PT.func,
  accountStatus: PT.array,
  getAccountStatus: PT.func,
  dealers: PT.array,
  getDealers: PT.func,
  change: PT.func,
  filterValues: PT.object,
  roles: PT.array,
  onReset: PT.func,
  onShowNotification: PT.func,
};

export default connect(state => ({
  levels: state.levels.data,
  tags: state.tags.data,
  carModels: state.carModels.data,
  accountStatus: state.accountStatus.data,
  dealers: state.dealers.data,
  filterValues: getFormValues('drivers-filters')(state),
  roles: state.authentication.roles,
}), {
  getLevels, getTags, getCarModels, getAccountStatus, getDealers, change, onReset: reset, onShowNotification: showNotification,
})(SidebarFilters);
