import styled, { css } from 'styled-components';
import PT from 'prop-types';

const HeaderBar = styled.header`
  width: 100%;
  margin-bottom: 18px;
  border-bottom: 1px solid ${props => props.theme.border};
  background-color: ${props => props.theme.white};

  ${props => props.fixed && css`
    position: sticky;
    top: 0;
    z-index: 6;
  `}
`;

HeaderBar.propTypes = {
  fixed: PT.bool,
};

export default HeaderBar;
