import styled from 'styled-components';

import { Button } from 'common';

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Colored = styled.span`
  color: ${props => props.theme.red};
  margin-left: 5px;

  svg {
    width: 8px;
    fill: ${props => props.theme.red};
    padding-top: 5px;
  }
`;

export const ExtendedButton = styled(Button)`
  margin-right: 18px;

  &:last-of-type {
    margin-right: 0;
  }
`;
