import styled from 'styled-components';

import { media } from 'styles/utils';
import { Title } from 'common';

export const Header = styled.div`
  margin: 0 0 10px 0;

  ${media.desktop`
    display: flex;
    justify-content: space-between;
  `}
`;

export const ExtendedTitle = styled(Title)`
  font-weight: 700;
  line-height: 1;
  margin: 0;
`;

export const ButtonContainer = styled.div`
  margin: 31px 0 0 0;

  ${media.desktop`
    margin: 0;
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const OrdersInfoRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;
