import React from 'react';
import PT from 'prop-types';

import ArrowDownRed from 'vectors/arrowDown-red.svg';

import { ListContainer } from '../../styled';
import { Container, Title } from './styled';

const FilterContainer = ({
  title, active, onClick, children,
}) => (
  <Container>
    <Title onClick={onClick} active={active}>
      {title} <ArrowDownRed />
    </Title>

    <ListContainer active={active}>
      {children}
    </ListContainer>
  </Container>
);

FilterContainer.propTypes = {
  title: PT.string,
  active: PT.bool,
  onClick: PT.func,
  children: PT.any,
};

export default FilterContainer;
