import styled from 'styled-components';

import { Button, FlexWrapper } from 'common';
import { Label } from 'common/form';

export const UserDetailsBlock = styled.div`
  position: absolute;
  right: 0;
  top: 50px;
  width: 380px;
  padding: 20px;
  border: 1px solid ${props => props.theme.border};
  background-color: ${props => props.theme.white};
  z-index: 3;
`;

export const Email = styled.p`
  font-weight: 400;
  font-size: 14px;
  text-transform: lowercase;
  color: ${props => props.theme.gray};
  letter-spacing: 2px;
`;

export const ExtendedButton = styled(Button)`
  margin-bottom: 9px;
`;

export const ExtendedFlexWrapper = styled(FlexWrapper)`
  align-items: center;
  margin-bottom: 20px;
  padding: 10px 0;
  border-top: 1px solid ${props => props.theme.border};
  border-bottom: 1px solid ${props => props.theme.border};

  ${Label} {
    flex: 1;
    margin-left: 10px;
    font-size: 12px;
    letter-spacing: 2px;
  }
`;
