import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 27px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LeftContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Stats = styled.span`
  margin: 0 10px;
`;

export const Icon = styled.span`
  color: ${props => props.theme.red};
  font-weight: 700;
  font-size: 18px;
  vertical-align: middle;
`;
