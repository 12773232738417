import styled from 'styled-components';

export const TicketList = styled.div`
  padding: 0 64px;
`;

export const Icon = styled.span`
  color: ${props => props.theme.white};
  font-weight: 700;
  font-size: 18px;
  vertical-align: middle;
`;
