import styled from 'styled-components';

import { Card, Small } from 'common';

export const CEMPackageCardContainer = styled(Card)`
  width: 290px;
  min-height: 360px;
`;

export const CEMPackageCardImage = styled.div`
  width: 100%;
  background-image: url('${props => props.backgroundImage}');
  background-size: cover;
  background-position: center;
  height: 100%;
`;

export const CEMPackageCardImageContainer = styled.div`
  width: 100%;
  height: 220px;
`;

export const CEMPackageCardContent = styled.div`
  padding: 24px 18px;
`;

export const CEMPackageCardShortDescription = styled(Small)`
  margin-top: 18px;
`;
