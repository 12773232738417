import createAction from 'services/createAction';
import { showNotification } from 'ducks/notification';

const GET_USER_CHANNELS = 'channel/GET_USER_CHANNELS';
const GET_USER_CHANNELS_SUCCESS = 'channel/GET_USER_CHANNELS_SUCCESS';
const GET_USER_CHANNELS_FAILED = 'channel/GET_USER_CHANNELS_FAILED';

const UPDATE_CHANNEL = 'channel/UPDATE';
const UPDATE_CHANNEL_SUCCESS = 'channel/UPDATE_SUCCESS';
const UPDATE_CHANNEL_FAILED = 'channel/UPDATE_FAILED';

const initialState = {
  error: {
    message: '',
  },
  loading: {
    update: false,
    getUserChannels: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case GET_USER_CHANNELS_SUCCESS:
    return {
      ...state,
      data: {
        email: payload.some(channel => channel.type === 'email' && channel.user_registered),
        push: payload.some(channel => channel.type === 'push' && channel.user_registered),
        post: payload.some(channel => channel.type === 'post' && channel.user_registered),
      },
      loading: { ...state.loading, getUserChannels: false },
    };
  case GET_USER_CHANNELS_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, getUserChannels: false },
    };
  case GET_USER_CHANNELS:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, getUserChannels: true },
    };
  case UPDATE_CHANNEL_SUCCESS:
    return {
      ...state,
      loading: { ...state.loading, update: false },
    };
  case UPDATE_CHANNEL_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, update: false },
    };
  case UPDATE_CHANNEL:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, update: true },
    };
  default:
    return state;
  }
};

const getUserChannelsSuccess = createAction(GET_USER_CHANNELS_SUCCESS);
const getUserChannelsFailed = createAction(GET_USER_CHANNELS_FAILED);

export const getUserChannels = (userId) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: GET_USER_CHANNELS });

    return api.get({ path: `channel/${userId}`, v2: true })
      .then((res) => {
        dispatch(getUserChannelsSuccess(res));
        resolve(res);
      })
      .catch((err) => {
        reject(err);
        dispatch(getUserChannelsFailed('Er is iets fout gegaan. Probeer opnieuw.'));
      });
  })
);

const updateChannelSuccess = createAction(UPDATE_CHANNEL_SUCCESS);
const updateChannelFailed = createAction(UPDATE_CHANNEL_FAILED);

export const updateChannel = (userId, type, activate) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: UPDATE_CHANNEL });

    const payload = {
      "user_id": userId,
      "channel_type": type,
      "activate": activate,
    };

    return api.put({ path: 'channel/user_update', v2: true, body: payload })
      .then(() => {
        dispatch(updateChannelSuccess());
        dispatch(showNotification('Update succesvol gedaan', 'green'));
        resolve();
      })
      .catch((err) => {
        dispatch(updateChannelFailed('Er is iets fout gegaan. Probeer opnieuw.'));
        dispatch(showNotification('Er is iets fout gegaan. Probeer opnieuw.'));
        reject();
      });
  })
);
