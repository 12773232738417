import React, { Fragment } from 'react';
import PT from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import getBlock from 'services/blockHelper';
import { Title, Loading } from 'common';

import ContentArea from './styled';

const BrandPortalContent = ({ page, pages, loading }) => (
  <ContentArea>
    {loading ?
      <Loading />
      : (
        <Fragment>
          {page && <Title underline noWhitespace>{page.page_title || page.title}</Title>}
          {page.blocks && page.blocks.map((block) => {
            const Block = getBlock(block.type);
            return <Block key={block.id} data={block} pages={pages} />;
          })}
        </Fragment>
      )
    }
  </ContentArea>
);

BrandPortalContent.propTypes = {
  page: PT.object,
  pages: PT.array,
  loading: PT.bool,
};

export default compose(
  withRouter,
  connect(state => ({
    pages: state.brandPortal.pages,
    loading: state.brandPortal.loading,
  })),
)(BrandPortalContent);
