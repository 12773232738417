import createAction from 'services/createAction';

const GET_MILEAGE_HISTORY = 'getMileageHistory/GET';
const GET_MILEAGE_HISTORY_SUCCESS = 'getMileageHistory/GET_SUCCESS';
const GET_MILEAGE_HISTORY_FAILED = 'getMileageHistory/GET_FAILED';

const initialState = {
  data: [],
  driverId: '',
  error: {
    message: '',
  },
  loading: {
    mileageHistory: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case GET_MILEAGE_HISTORY_SUCCESS:
    return {
      ...state,
      ...payload,
      loading: { ...state.loading, mileageHistory: false },
    };
  case GET_MILEAGE_HISTORY_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, mileageHistory: false },
    };
  case GET_MILEAGE_HISTORY:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, mileageHistory: true },
    };
  default:
    return state;
  }
};

const getMileageHistorySuccess = createAction(GET_MILEAGE_HISTORY_SUCCESS);
const getMileageHistoryFailed = createAction(GET_MILEAGE_HISTORY_FAILED);

export const getMileageHistoryFromDriver = driverId => (dispatch, getState, api) => {
  dispatch({ type: GET_MILEAGE_HISTORY });

  api.get({ path: `management/user/${driverId}/mileage` })
    .then((res) => {
      dispatch(getMileageHistorySuccess({ data: res, driverId }));
    })
    .catch((err) => {
      dispatch(getMileageHistoryFailed({ message: err.data }));
    });
};
