import React, { Component } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';

import { getActivitiesFromDriver } from 'ducks/driver/getActivities';

import { Container, EmptyState, Loading } from 'common';
import { EventCard } from 'modules';

class DriverTabsActivities extends Component {
  componentWillMount() {
    if (this.props.driverFromActivities !== this.props.driver.id) {
      this.props.getActivitiesFromDriver(this.props.driver.id);
    }
  }

  render() {
    const { activities, loading } = this.props;

    if (loading) return <Container><Loading /></Container>;

    // copy activities because reverse is destructive and can change the actual array
    const reversedActivites = [...activities].reverse();

    return (
      <Container>
        {activities.length === 0 ? (
          <EmptyState
            title="Nog geen activiteiten"
            subtitle="Gebruiker heeft tot op heden nog niet deel genomen aan activiteiten"
          />
        ) : reversedActivites.map(activity => (
          <EventCard key={activity.id} event={activity} />
        ))}
      </Container>
    );
  }
}

DriverTabsActivities.propTypes = {
  activities: PT.array,
  driverFromActivities: PT.string,
  driver: PT.object,
  loading: PT.bool,
  getActivitiesFromDriver: PT.func,
};

export default connect(state => ({
  activities: state.getActivities.data,
  driverFromActivities: state.getActivities.driverId,
  loading: state.getActivities.loading.activities,
  driver: state.driver.data,
}), { getActivitiesFromDriver })(DriverTabsActivities);
