import React, { Component } from 'react';
import PT from 'prop-types';

import { baseUrlBrandPortal } from 'config/environment';
import SliderBlock from '../SliderBlock';
import Block from '../Block';
import StyledContainer from '../StyledContainer';
import FootNote from '../FootNote';

import Image from './styled';

class ImageBlock extends Component {
  state = {
    currentSlide: 0,
  }

  getImageUrl = data => `${baseUrlBrandPortal()}${data.image.large.src}`;

  getImageAlt = data => data.image.large.alt;

  render() {
    const { data } = this.props;
    const imageData = data.value.length > 1 ? data.value : data.value[0].value;

    return (
      <Block>
        <StyledContainer>
          {imageData.length ? (
            <SliderBlock onChange={slide => this.setState({ currentSlide: slide })}>
              {imageData.map(image => (
                <Image key={`${image.id}_${image.value.image.id}`}>
                  <img src={this.getImageUrl(image.value)} alt={this.getImageUrl(image.value)} />
                </Image>
              ))}
            </SliderBlock>
          ) : (
            <Image>
              <img src={this.getImageUrl(imageData)} alt={this.getImageUrl(imageData)} />
            </Image>
          )}
          <FootNote text={data.value[this.state.currentSlide].value.foot_note} />
        </StyledContainer>
      </Block>
    );
  }
}

ImageBlock.propTypes = {
  data: PT.object,
};

export default ImageBlock;
