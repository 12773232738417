import styled, { css } from 'styled-components';
import PT from 'prop-types';

export const InnerContainer = styled.div`
  display: flex;
`;

export const ContentContainer = styled.div`
  flex-shrink: 0;
  flex: 4;
`;

export const ListContainer = styled.div`
  display: none;

  ${props => props.active && css`
    display: block;
    margin-bottom: 18px;
  `}
`;

ListContainer.propTypes = {
  active: PT.bool,
};
