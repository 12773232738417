import styled from 'styled-components';

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledInput = styled.input`
  flex-basis: 30px;
`;

export const Labels = styled.div`
  flex: 1;

  label {
    margin: 0 0 5px;
  }

  p {
    margin: 0;
  }
`;
