import styled from 'styled-components';

import { Title } from 'common';

export const ModalContainer = styled.div`
  padding: 30px;
`;

export const ModalTitle = styled(Title)`
  margin: 0;
`;

export const ModalButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 70px;
`;
