import createAction from 'services/createAction';

const CREATE_EVENT_EMAIL = 'eventEmail/CREATE';
const CREATE_EVENT_EMAIL_SUCCESS = 'eventEmail/CREATE_SUCCESS';
const CREATE_EVENT_EMAIL_FAILED = 'eventEmail/CREATE_FAILED';

const GET_EVENT_EMAIL = 'eventEmail/GET';
const GET_EVENT_EMAIL_SUCCESS = 'eventEmail/GET_SUCCESS';
const GET_EVENT_EMAIL_FAILED = 'eventEmail/GET_FAILED';

const UPDATE_EVENT_EMAIL = 'eventEmail/UPDATE';
const UPDATE_EVENT_EMAIL_SUCCESS = 'eventEmail/UPDATE_SUCCESS';
const UPDATE_EVENT_EMAIL_FAILED = 'eventEmail/UPDATE_FAILED';

const DELETE_EVENT_EMAIL = 'eventEmail/DELETE';
const DELETE_EVENT_EMAIL_SUCCESS = 'eventEmail/DELETE_SUCCESS';
const DELETE_EVENT_EMAIL_FAILED = 'eventEmail/DELETE_FAILED';

const SEND_EVENT_EMAIL = 'eventEmail/SEND';
const SEND_EVENT_EMAIL_SUCCESS = 'eventEmail/SEND_SUCCESS';
const SEND_EVENT_EMAIL_FAILED = 'eventEmail/SEND_FAILED';

const GET_TEMPLATE_EVENT_EMAIL = 'eventEmail/GET_TEMPLATE';
const GET_TEMPLATE_EVENT_EMAIL_SUCCESS = 'eventEmail/GET_TEMPLATE_SUCCESS';
const GET_TEMPLATE_EVENT_EMAIL_FAILED = 'eventEmail/GET_TEMPLATE_FAILED';

const RESET_EVENT_EMAIL = 'eventEmail/RESET';

const initialState = {
  data: {},
  preview: {},
  error: {
    message: '',
  },
  loading: {
    create: false,
    get: false,
    update: false,
    delete: false,
    send: false,
    getPreview: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case RESET_EVENT_EMAIL:
    return {
      ...initialState,
    };
  case CREATE_EVENT_EMAIL_SUCCESS:
    return {
      ...state,
      data: payload,
      loading: { ...state.loading, create: false },
    };
  case CREATE_EVENT_EMAIL_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, create: false },
    };
  case CREATE_EVENT_EMAIL:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, create: true },
    };
  case GET_EVENT_EMAIL_SUCCESS:
    return {
      ...state,
      data: payload,
      loading: { ...state.loading, get: false },
    };
  case GET_EVENT_EMAIL_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, get: false },
    };
  case GET_EVENT_EMAIL:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, get: true },
    };
  case UPDATE_EVENT_EMAIL_SUCCESS:
    return {
      ...state,
      data: payload,
      loading: { ...state.loading, update: false },
    };
  case UPDATE_EVENT_EMAIL_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, update: false },
    };
  case UPDATE_EVENT_EMAIL:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, update: true },
    };
  case DELETE_EVENT_EMAIL_SUCCESS:
    return {
      ...state,
      loading: { ...state.loading, delete: false },
    };
  case DELETE_EVENT_EMAIL_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, delete: false },
    };
  case DELETE_EVENT_EMAIL:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, delete: true },
    };
  case SEND_EVENT_EMAIL_SUCCESS:
    return {
      ...state,
      loading: { ...state.loading, send: false },
    };
  case SEND_EVENT_EMAIL_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, send: false },
    };
  case SEND_EVENT_EMAIL:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, send: true },
    };
  case GET_TEMPLATE_EVENT_EMAIL_SUCCESS:
    return {
      ...state,
      preview: payload,
      loading: { ...state.loading, getPreview: false },
    };
  case GET_TEMPLATE_EVENT_EMAIL_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, getPreview: false },
    };
  case GET_TEMPLATE_EVENT_EMAIL:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, getPreview: true },
    };
  default:
    return state;
  }
};

export const resetEventEmail = createAction(RESET_EVENT_EMAIL);

const createEventEmailSuccess = createAction(CREATE_EVENT_EMAIL_SUCCESS);
const createEventEmailFailed = createAction(CREATE_EVENT_EMAIL_FAILED);

export const createEventEmail = ({ formData, eventId }) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: CREATE_EVENT_EMAIL });

    api.post({ path: 'emails', body: { ...formData, event_id: eventId }, v2: true })
      .then((res) => {
        dispatch(createEventEmailSuccess(res));
        resolve(res);
      })
      .catch((err) => {
        dispatch(createEventEmailFailed({ message: err.data }));
        reject(err);
      });
  })
);

const getEventEmailSuccess = createAction(GET_EVENT_EMAIL_SUCCESS);
const getEventEmailFailed = createAction(GET_EVENT_EMAIL_FAILED);

export const getEventEmail = emailId => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: GET_EVENT_EMAIL });

    api.get({ path: `emails/${emailId}`, v2: true })
      .then((res) => {
        dispatch(getEventEmailSuccess(res));
        resolve(res);
      })
      .catch((err) => {
        dispatch(getEventEmailFailed({ message: err.data }));
        reject(err);
      });
  })
);

const updateEventEmailSuccess = createAction(UPDATE_EVENT_EMAIL_SUCCESS);
const updateEventEmailFailed = createAction(UPDATE_EVENT_EMAIL_FAILED);

export const updateEventEmail = ({ formData, emailId }) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: UPDATE_EVENT_EMAIL });

    api.put({ path: `emails/${emailId}`, body: formData, v2: true })
      .then((res) => {
        dispatch(updateEventEmailSuccess(res));
        resolve(res);
      })
      .catch((err) => {
        dispatch(updateEventEmailFailed({ message: err.data }));
        reject(err);
      });
  })
);

const deleteEventEmailSuccess = createAction(DELETE_EVENT_EMAIL_SUCCESS);
const deleteEventEmailFailed = createAction(DELETE_EVENT_EMAIL_FAILED);

export const deleteEventEmail = emailId => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: DELETE_EVENT_EMAIL });

    api.del({ path: `emails/${emailId}`, v2: true })
      .then(() => {
        dispatch(deleteEventEmailSuccess());
        resolve();
      })
      .catch((err) => {
        dispatch(deleteEventEmailFailed({ message: err.data }));
        reject(err);
      });
  })
);

const sendEventEmailSuccess = createAction(SEND_EVENT_EMAIL_SUCCESS);
const sendEventEmailFailed = createAction(SEND_EVENT_EMAIL_FAILED);

export const sendEventEmail = emailId => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: SEND_EVENT_EMAIL });

    api.post({ path: `emails/${emailId}/send`, v2: true })
      .then(() => {
        dispatch(sendEventEmailSuccess());
        resolve();
      })
      .catch((err) => {
        dispatch(sendEventEmailFailed({ message: err.data }));
        reject(err);
      });
  })
);

const getTemplateEventEmailSuccess = createAction(GET_TEMPLATE_EVENT_EMAIL_SUCCESS);
const getTemplateEventEmailFailed = createAction(GET_TEMPLATE_EVENT_EMAIL_FAILED);

export const getTemplateEventEmail = emailId => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: GET_TEMPLATE_EVENT_EMAIL });

    api.post({ path: `emails/${emailId}/preview`, v2: true })
      .then((res) => {
        dispatch(getTemplateEventEmailSuccess(res));
        resolve();
      })
      .catch((err) => {
        dispatch(getTemplateEventEmailFailed({ message: err.data }));
        reject(err);
      });
  })
);
