import styled, { css } from 'styled-components';
import PT from 'prop-types';

const Row = styled.div`
  display: flex;

  ${props => props.verticalAlign && css`
    align-items: center;
  `}
`;

Row.propTypes = {
  verticalAlign: PT.bool,
};

export default Row;
