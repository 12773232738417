import styled, { css } from 'styled-components';
import PT from 'prop-types';

import { Anchor } from 'common';

import { ListContainer } from '../../styled';

export const SidebarContainer = styled.div`
  padding: 18px;
  background-color: ${props => props.theme.black};
  color: ${props => props.theme.white};
  flex: 1;
  flex-shrink: 0;
`;

export const Title = styled.h2`
  font-size: 24px;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${props => props.active && css`
    svg {
      transform: rotate(180deg);
    }
  `}
`;

Title.propTypes = {
  active: PT.bool,
};

ListContainer.propTypes = {
  active: PT.bool,
};

export const Label = styled.label`
  font-size: 12px;
  cursor: pointer;
  position: relative;
  width: 100%;
  display: inline-block;

  & svg {
    position: absolute;
    right: 0;
    top: 20%;
  }

  ${props => props.active && css`
    & svg {
      transform: rotate(180deg);
    }
  `}
`;

Label.propTypes = {
  active: PT.bool,
};

export const ResetContainer = styled.div`
  margin-right: auto;
  display: flex;
  justify-content: flex-end;
`;

export const ExtendedAnchor = styled(Anchor)`
  color: ${props => props.theme.white};
  display: flex;
  align-items: center;
  font-weight: 700;
`;

export const MinusIcon = styled.span`
  color: ${props => props.theme.red};
  margin-left: 9px;
`;
