import createAction from 'services/createAction';

const GET_UPDATES = 'updates/GET';
const GET_UPDATES_SUCCESS = 'updates/GET_SUCCESS';
const GET_UPDATES_FAILED = 'updates/GET_FAILED';

const RESET_UPDATES = 'updates/RESET';

const initialState = {
  data: [],
  eventId: '',
  error: {
    message: '',
  },
  loading: {
    updates: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case GET_UPDATES_SUCCESS:
    return {
      ...state,
      data: payload.data,
      eventId: payload.eventId,
      loading: { ...state.loading, updates: false },
    };
  case GET_UPDATES_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, updates: false },
    };
  case GET_UPDATES:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, updates: true },
    };
  case RESET_UPDATES:
    return {
      ...initialState,
    };
  default:
    return state;
  }
};

export const resetUpdates = createAction(RESET_UPDATES);

const getUpdatesSuccess = createAction(GET_UPDATES_SUCCESS);
const getUpdatesFailed = createAction(GET_UPDATES_FAILED);

export const getUpdates = eventId => (dispatch, getState, api) => {
  dispatch({ type: GET_UPDATES });

  api.get({ path: `management/activity/${eventId}/activity-update` })
    .then((res) => {
      dispatch(getUpdatesSuccess({ data: res, eventId }));
    })
    .catch((err) => {
      dispatch(getUpdatesFailed({ message: err.data }));
    });
};
