import styled from 'styled-components';

import FormField from '../FormField';

export default styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  & > ${FormField} {
    flex: 1;

    &:not(:first-of-type) {
      margin-left: 18px;
    }
  }
`;
