module.exports = `
  // BCcookie permission Tool
  // runs only if not in a lightbox

  if (window == window.parent) {
    (function () { // Do not change

      var protocol = (("https:" == document.location.protocol) ? "https://" : "http://");
      var bcScript = document.createElement('script');
      bcScript.async = true;
      bcScript.src = protocol + 'cdn.blueconic.net/pon.js';
      (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(bcScript);
    })(); // all links with the js-u-show-cookie-settings class fire the event to change cookie permissions with blueconic dialogue

    var ready = function (fn) {
      if (typeof fn !== 'function') return;
      if (document.readyState === 'complete') {
        return fn();
      }
      document.addEventListener('DOMContentLoaded', fn, false);
    };

    ready(function () {
      function triggerBCPM(event) {
        event.preventDefault();
        document.getElementById('bcpm-footer-link').click();
      }
      Array.prototype.slice.call(document.getElementsByClassName('js-u-show-cookie-settings')).forEach(function (element) {
        element.onclick = triggerBCPM;
      });
    }); // Removes all existing cookies when changing preferences in blueconic plugin (http://www.gxsoftware.com/nl/producten/permission-management.htm)

    var bcSubscriptions = bcSubscriptions || [];
    bcSubscriptions.push(['onPermissionLevelChange', function (event, context) {
      try {
        var cookies = document.cookie.split(";");
        var safeCookies = ['BCPermissionLevel'];
        cookies.forEach(function (cookie, index) {
          var cookiename = cookie.split('=')[0]; // set expiring date to yesterday

          var date = new Date();
          date.setTime(date.getTime() - 86400000);
          var expires = "; expires=" + date.toGMTString();
          if (safeCookies.indexOf(cookiename) === -1) {
            document.cookie = cookiename + "=" + expires;
          }
        }); // ensure that permissionlevel is set after reload so any personalised changes are visible

        window.blueConicClient.util.cookie.setCookie('BCPermissionLevel', context.permissionLevel);
        location.reload(true);
      } catch (err) {}
    }]);
  };
`;
