import React, { Component } from 'react';
import PT from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getPages } from 'ducks/brandPortal';

import { BrandPortal } from 'modules';

class BrandPortalTopic extends Component {
  componentDidMount() {
    if (this.props.pages.length === 0) this.props.getPages();
    this.handleRedirect(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.handleRedirect(nextProps.pages);
  }

  componentDidUpdate(prevProps) {
    this.handleRedirect(prevProps.pages);
  }

  getTopic = pages => pages.find(topic => topic.slug === this.props.match.params.topic) || pages[0];

  handleRedirect = (pages) => {
    if (pages.length > 0) {
      const topic = this.getTopic(pages);
      if (topic.top_pages.length) {
        this.props.history.push(`/brand-portal/${topic.slug}/${topic.top_pages[0].slug}`);
      }
    }
  }

  render() {
    return (
      <BrandPortal pages={this.props.pages} page={this.getTopic(this.props.pages)} />
    );
  }
}

BrandPortalTopic.propTypes = {
  getPages: PT.func,
  pages: PT.array,
  match: PT.object,
  history: PT.object,
};

export default compose(
  withRouter,
  connect(state => ({
    pages: state.brandPortal.pages,
  }), { getPages }),
)(BrandPortalTopic);
