import React, { Component } from 'react';
import PT from 'prop-types';

import { Accordion, ToggleButton, Title, Checklist, ToggleArrow, Content } from './styled';

class AccordionBlock extends Component {
  state = {
    open: false,
  };

  render() {
    const {
      children, title, index, checklist,
    } = this.props;
    const { open } = this.state;

    return (
      <Accordion>
        <ToggleButton onClick={() => this.setState({ open: !open })}>
          <Title>
            {checklist && <Checklist>{index}</Checklist>}
            {title}
          </Title>
          <ToggleArrow direction={open ? 'up' : 'down'} />
        </ToggleButton>
        {open && (
          <Content>
            {children}
          </Content>
        )}
      </Accordion>
    );
  }
}

AccordionBlock.propTypes = {
  children: PT.oneOfType([
    PT.array,
    PT.object,
  ]),
  title: PT.string,
  index: PT.number,
  checklist: PT.bool,
};

export default AccordionBlock;
