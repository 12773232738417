import React, { Fragment } from 'react';
import PT from 'prop-types';
import { Field } from 'redux-form';

import { Anchor } from 'common';
import { InputText, Label } from 'common/form';

import { ActionEmailContainer, Icon } from './styled';

const inviteNewDriversModalField = ({ fields }) => fields.map((email, index) => (
  <Fragment key={`${email.email}_${index}`}>
    <Label>E-mailadres</Label>
    <Field
      component={InputText}
      type="email"
      name={`${email}.email`}
    />
    <ActionEmailContainer>
      {fields.length > 1 && (
        <Anchor small onClick={() => fields.remove(index)}>
          Verwijder
        </Anchor>
      )}

      <Anchor small bold onClick={() => fields.push({})}>
        Voeg Porsche rijder toe <Icon>+</Icon>
      </Anchor>
    </ActionEmailContainer>
  </Fragment>
));

inviteNewDriversModalField.propTypes = {
  fields: PT.object,
};

export default inviteNewDriversModalField;
