import styled, { css } from 'styled-components';
import PT from 'prop-types';

import ArchiveIcon from 'vectors/archive.svg';

import Subtitle from '../Subtitle';

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ExtendedSubtitle = styled(Subtitle)`
  margin: 0;

  ${props => props.previous && css`
    color: ${props.theme.gray};
  `}
`;

ExtendedSubtitle.propTypes = {
  previous: PT.bool,
};

export const ExpirationDate = styled.p`
  margin: 0;
  color: ${props => props.theme.gray};
  letter-spacing: 0.6px;
`;

export const Price = styled.span`
  color: ${props => props.theme.red};
  font-size: 24px;

  ${props => props.previous && css`
    color: ${props.theme.gray};
  `}
`;

Price.propTypes = {
  previous: PT.bool,
};

export const ButtonContainer = styled.div`
  margin-top: 20px;
`;

export const StyledIcon = styled(ArchiveIcon)`
  margin-right: 10px;
  margin-bottom: 2px;
  width: 20px;
  vertical-align: sub;
  fill: ${props => props.theme.gray};
`;
