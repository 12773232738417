import styled from 'styled-components';
import { Button } from 'common';

export default styled(Button)`
  display: flex;
  align-items: center;
  font-size: 16px;
  text-align: left;
  text-transform: none;
  letter-spacing: normal;
  border: 2px solid ${props => props.theme[props.variant]};
  transition: 0.3s all;
  margin-bottom: 18px;

  &:hover {
    color: ${props => props.theme[props.variant]};
    background-color: ${props => props.theme.white};

    svg {
      fill: ${props => props.theme[props.variant]};
    }
  }

  svg {
    width: 8px;
    margin-right: 20px;
    fill: ${props => props.theme.white};
  }
`;
