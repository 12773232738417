import { getApiEndpoint, getAuthToken, getBrandPortalApiEndpoint } from './environment';

export const port = process.env.PORT || 3000;
export const env = process.env.NODE_ENV || 'development';

export const API_ENDPOINT = getApiEndpoint();
export const API_V2_ENDPOINT = getApiEndpoint('v2');
export const AUTH_TOKEN = getAuthToken();
export const API_BRANDPORTAL_ENDPOINT = getBrandPortalApiEndpoint();

// Toggle Serverside rendering
export const SSR = false;
