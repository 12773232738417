import React from 'react';
import PT from 'prop-types';

import Block from '../Block';

import { Quote, Author } from './styled';

const QuoteBlock = ({ data }) => (
  <Block withDivider>
    <Quote text={data.value.quote} />
    <Author>- {data.value.quoter}</Author>
  </Block>
);

QuoteBlock.propTypes = {
  data: PT.object,
};

export default QuoteBlock;
