import { Component } from 'react';
import PT from 'prop-types';
import { withRouter } from 'react-router-dom';

class RedirectDeeplink extends Component {
  componentDidMount() {
    if (this.isMobileDevice()) {
      window.location.href = `porsche24://${this.props.match.params.url}${this.props.location.search || ''}`;
    } else {
      window.location.href = this.getDesktopUrl(this.props.match.params.url);
    }
  }

  getDesktopUrl = () => {
    if (this.props.match.params.url === 'faq') {
      return 'https://www.porsche.com/netherlands/nl/motorsportandevents/porsche24/faq/';
    }
    return 'https://porsche24.porsche.nl/p24app/';
  }

  isMobileDevice = () => !!(navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/iPhone|iPad|iPod/i) || navigator.userAgent.match(/Opera Mini/i) || navigator.userAgent.match(/IEMobile/i));

  render() {
    return null;
  }
}

RedirectDeeplink.propTypes = {
  match: PT.object,
  location: PT.object,
};

export default withRouter(RedirectDeeplink);
