import styled from 'styled-components';

import { Container } from 'common';

import { media } from 'styles/utils';

export const ExtendedContainer = styled(Container)`
  padding: 10px 20px;

  ${media.tablet`
    display: flex;
    align-items: center;
  `}

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const Image = styled.img`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  height: 8px;
  width: auto;
  margin-bottom: 20px;
  cursor: pointer;

  ${media.tablet`
    left: 0;
    transform: none;
    margin: 0;
  `}
`;

export const Navigation = styled.nav`
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  ${media.tablet`
    justify-content: flex-end;
  `}

  a {
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 2px;
    color: ${props => props.theme.black};
    text-transform: uppercase;
    text-decoration: none;
    padding: 0 8px;
    margin: 0 7px 20px;

    ${media.tablet`
      line-height: 50px;
      margin-bottom: 0;
    `}

    &.active {
      color: ${props => props.theme.red};
    }
  }
`;

const PlainNavButton = styled.button`
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 2px;
  color: ${props => props.theme.black};
  text-transform: uppercase;
  text-decoration: none;
  padding: 0 8px;
  margin: 0 7px 20px;

  ${media.tablet`
    margin-bottom: 0;
  `}

  svg {
    margin-left: 5px;
  }
`;

export const NavButton = styled(PlainNavButton)`
  border: 0;
  background-color: transparent;
  outline: none;
  cursor: pointer;
`;
