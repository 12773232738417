import React from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';

import { hideModal } from 'ducks/modal';

import ValidateMileageModal from 'modules/ValidateMileage/components/ValidateMileageModal';
import ValidateCarsModal from 'modules/ValidateCars/components/ValidateCarsModal';
import OrdersModal from 'modules/Orders/components/OrdersModal';
import AddPointsModal from 'modules/DriverDetail/components/AddPointsModal';
import Modal from 'common/Modal';

const components = {
  VALIDATE_MILEAGE: ValidateMileageModal,
  VALIDATE_CARS: ValidateCarsModal,
  ORDERS: OrdersModal,
  ADD_POINTS: AddPointsModal,
};

const GeneralModal = ({ modalProps, type, onHideModal }) => {
  const ModalComponent = components[type];

  if (!type) return null;
  return (
    <Modal onHideModal={onHideModal} large>
      <ModalComponent {...modalProps} onHideModal={onHideModal} />
    </Modal>
  );
};

GeneralModal.propTypes = {
  modalProps: PT.object,
  type: PT.string,
  onHideModal: PT.func,
};

export default connect(state => ({ ...state.modal }), { onHideModal: hideModal })(GeneralModal);
