import styled, { css } from 'styled-components';
import PT from 'prop-types';

export const Container = styled.div`
  border-bottom: 1px solid ${props => props.theme.border.withBoxShadow};

  &:last-of-type{
    border-bottom: 0;
  }
`;

export const Title = styled.h2`
  font-size: 24px;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${props => props.active && css`
    svg {
      transform: rotate(180deg);
    }
  `}
`;

Title.propTypes = {
  active: PT.bool,
};
