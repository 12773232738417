import styled, { css } from 'styled-components';
import PT from 'prop-types';

export const Table = styled.table`
  border: 1px solid ${props => props.theme.border.withBoxShadow};
  box-shadow: ${props => props.theme.boxShadow};
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
`;

export const TableHead = styled.thead`
  border-bottom: 1px solid ${props => props.theme.border.withBoxShadow};
  box-shadow: ${props => props.theme.boxShadow};
`;

export const TableHeader = styled.th`
  height: 36px;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 2px;
  color: ${props => props.theme.black};
  text-align: left;
  padding-right: 18px;

  ${props => props.size === 'small' && css`
    width: 12%;
  `}

  ${props => props.size === 'medium' && css`
    width: 25%;
  `}

  ${props => props.size === 'large' && css`
    width: 33%;
  `}
`;

TableHeader.propTypes = {
  size: PT.oneOf(['small', 'medium', 'large']),
};

export const TableRow = styled.tr`
  height: 45px;
`;

export const TableCell = styled.td`
  font-size: 12px;
  letter-spacing: 2px;
  border-bottom: 1px solid ${props => props.theme.border.withBoxShadow};
  padding-right: 18px;
`;

export const TopItem = styled.p`
  margin: 0;
`;

export const BottomItem = styled.p`
  margin: 0;
  color: ${props => props.theme.gray};
  font-size: 10px;
  letter-spacing: 1px;
  font-weight: 400;
`;
