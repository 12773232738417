import React from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';

import { setAppStatus } from 'ducks/p24app';
import { showNotification } from 'ducks/notification';

import { Modal, Title, Button } from 'common';

import { ActionContainer, Container } from './styled';

const processSubmit = (toggleModal, onChangeStatus, onSuccess, status) => {
  const newStatus = status === 'down' ? 'up' : 'down';

  onChangeStatus(newStatus).then((res) => {
    if (res.status === 'up') {
      onSuccess('De p24 app is weer bereikbaar.', 'green');
    } else {
      onSuccess('Het blokkeren van de p24 app is gelukt.', 'green');
    }
    toggleModal();
  });
};

const BlockMobileAppModal = ({
  toggleModal, onChangeStatus, onShowNotification, status,
}) => (
  <Modal onHideModal={toggleModal}>
    <Container>
      <Title small>Let op!</Title>
      {status === 'up' ? (
        <p>Hiermee wordt de p24 app tijdelijk onbruikbaar voor ALLE gebruikers. Weet je zeker dat je verder wilt gaan?</p>
      ) : (
        <p>Hiermee wordt de p24 app weer bruikbaar voor ALLE gebruikers. Weet je zeker dat je verder wilt gaan?</p>
      )}

      <ActionContainer>
        <Button
          small
          variant="black"
          onClick={toggleModal}
        >
          Annuleer
        </Button>
        <Button
          small
          onClick={() => processSubmit(toggleModal, onChangeStatus, onShowNotification, status)}
          className="ignore-react-onclickoutside"
        >
          {status === 'up' ? 'Ja, blokkeer de P24 app' : 'Ja, maak de P24 app bereikbaar'}
        </Button>
      </ActionContainer>
    </Container>
  </Modal>
);

BlockMobileAppModal.propTypes = {
  toggleModal: PT.func,
  status: PT.string,
  onChangeStatus: PT.func,
  onShowNotification: PT.func,
};

export default connect(state => ({
  status: state.p24app.status,
}), {
  onChangeStatus: setAppStatus,
  onShowNotification: showNotification,
})(BlockMobileAppModal);
