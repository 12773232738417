import React, { Component, Fragment } from 'react';
import PT from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Switch, Route, Redirect } from 'react-router-dom';

import isTicketInvite from 'services/isTicketInvite';
import { getEvent } from 'ducks/event';
import { deselectAllDrivers } from 'ducks/inviteDrivers';

import InviteHeader from './components/InviteHeader';
import InviteDriversSelect from './components/InviteDriversSelect';
import InviteDriversMessage from './components/InviteDriversMessage';
import InviteDriversConfirm from './components/InviteDriversConfirm';

class InviteDrivers extends Component {
  componentDidMount() {
    this.props.getEvent(this.props.match.params.eventId);
  }

  componentWillUnmount = () => {
    this.props.deselectAllDrivers();
  }

  getHeaderData = () => {
    if (Object.keys(this.props.eventData).length === 0) return '';

    const data = (
      isTicketInvite(this.props.match.params) ?
        this.props.eventData.ticket.find(ticket => ticket.id === this.props.match.params.ticketId) :
        this.props.eventData
    );

    return data.name;
  }

  render() {
    const { match } = this.props;
    const headerData = this.getHeaderData();

    return (
      <Fragment>
        <InviteHeader name={headerData} eventId={match.params.eventId} />
        <Switch>
          <Route path="/event/:eventId/invite/:ticketId?/select" component={InviteDriversSelect} />
          <Route path="/event/:eventId/invite/:ticketId?/message" component={InviteDriversMessage} />
          <Route path="/event/:eventId/invite/:ticketId?/confirm" component={InviteDriversConfirm} />
          <Redirect from="/event/:eventId/invite/:ticketId?" to="/event/:eventId/invite/:ticketId?/select" />
        </Switch>
      </Fragment>
    );
  }
}

InviteDrivers.propTypes = {
  eventData: PT.object,
  getEvent: PT.func,
  match: PT.object,
  deselectAllDrivers: PT.func,
};

export default compose(
  reduxForm({
    form: 'invite-drivers',
    initialValues: {
      invite: {},
    },
  }),
  connect(state => ({
    eventData: state.event.data,
  }), { getEvent, deselectAllDrivers }),
)(InviteDrivers);
