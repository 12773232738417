import React from 'react';
import PT from 'prop-types';

import getBrandPortalUrl from 'services/getBrandPortalUrl';

import ArrowRight from 'vectors/arrowRight.svg';
import Block from '../Block';

import { Title, QuickLinkContainer, QuickLink, QuickLinkLabel, QuickLinkIcon } from './styled';

const QuickLinksBlock = ({ data, pages }) => (
  <Block>
    <Title>{data.value.title}</Title>
    <QuickLinkContainer>
      {data.value.quick_links.map(quickLink => (
        <QuickLink
          key={quickLink.id}
          to={getBrandPortalUrl(quickLink.value.url, pages)}
        >
          <QuickLinkLabel>{quickLink.value.button_label}</QuickLinkLabel>
          <QuickLinkIcon>
            <ArrowRight />
          </QuickLinkIcon>
        </QuickLink>
      ))}
    </QuickLinkContainer>
  </Block>
);

QuickLinksBlock.propTypes = {
  data: PT.object,
  pages: PT.array,
};

export default QuickLinksBlock;
