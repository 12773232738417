import React, { Fragment, Component } from 'react';
import PT from 'prop-types';

import { Title, Loading, Modal } from 'common';
import { InputDropZoneFile } from 'common/form';

import { CEMPackageCSVUploadContainer, ModalContainer, LoadingContainer } from './styled';

class CEMPackageCSVUpload extends Component {
  state = {
    showModal: false,
    status: null,
  }

  onUpload = (file) => {
    this.setState({ showModal: !this.state.showModal });

    this.props.onUpload(this.props.packageId, file)
      .then(() => this.setState({ status: 'success' }))
      .catch(() => this.setState({ status: 'failed' }));
  }

  render() {
    const { loading } = this.props;

    return (
      <Fragment>
        <CEMPackageCSVUploadContainer>
          <InputDropZoneFile
            onUpload={this.onUpload}
            label="Upload csv"
          />
        </CEMPackageCSVUploadContainer>

        {this.state.showModal && (
          <Modal
            onHideModal={() => this.setState({ showModal: !this.state.showModal })}
            large
          >
            <ModalContainer>
              {loading ? (
                <LoadingContainer><Loading small /></LoadingContainer>
              ) : this.state.status === 'success' ? (
                <Fragment>
                  <Title small underline>Gelukt</Title>
                  <p>Het uploaden van de CSV is gelukt.</p>
                </Fragment>
              ) : (
                <Fragment>
                  <Title small underline>Mislukt</Title>
                  <p>Het uploaden van de CSV is niet gelukt. Probeer opnieuw.</p>
                </Fragment>
              )}
            </ModalContainer>
          </Modal>
        )}
      </Fragment>
    );
  }
}

CEMPackageCSVUpload.propTypes = {
  onUpload: PT.func.isRequired,
  loading: PT.bool,
  packageId: PT.string,
};

export default CEMPackageCSVUpload;
