import moment from 'moment';
import { ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

export const required = (value, message) => {
  if (value) return undefined;
  return typeof message === 'string' ? message : 'Dit veld is verplicht';
};

export const requiredNumber = (value, message) => {
  if (value || value === 0) return undefined;
  return typeof parseInt(value, 10) === 'number' ? message : 'Dit veld is verplicht';
};

export const draftjsRequired = (value, message) => {
  if (value) {
    const contents = htmlToDraft(value);
    const contentState = ContentState.createFromBlockArray(contents.contentBlocks);
    if (contentState.getPlainText().replace(' ', '').length > 0) return undefined;
  }
  return typeof message === 'string' ? message : 'Dit veld is verplicht';
};

export const requiredArray = (value, message) => {
  if (value && value.length > 0) return undefined;
  return typeof message === 'string' ? message : 'Dit veld is verplicht';
};

export const email = (value) => {
  if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) return undefined;
  return 'Geen geldig e-mailadres';
};

export const password = (value) => {
  if (/(?=[A-Z])/.test(value) && /(?=[\W])/.test(value) && value.length >= 8) return undefined;
  return 'Wachtwoord is niet correct';
};

export const telephone = (value) => {
  // Check if number has either 9 chars, or 10 with leading 0
  if (/^([0-9]{10})$/i.test(value)) return undefined;
  return 'Geen geldig telefoonnummer';
};

export const numeric = (value) => {
  if (/^[0-9]*$/i.test(value)) return undefined;
  return 'Alleen nummers zijn toegestaan';
};

export const maxLength = (value, maximumLength) => {
  if (value.length <= maximumLength) return undefined;
  return `De maximale lengte is ${maximumLength}`;
};

export const numericWithMax = (value, max) => {
  if (/^[0-9]*$/i.test(value) && value <= max && value > 0) return undefined;
  return `De waarde moet tussen 1 en ${max} zijn`;
};

export const isAfterDate = (value, values) => {
  if (!value || moment(value).isAfter(values.start_date)) return undefined;
  return 'Moet na de startdatum zijn';
};
