import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import PT from 'prop-types';

import { InputError } from 'common/form';

export const StyledTextarea = styled.textarea`
    display: block;
    width: 100%;
    height: ${props => props.height};
    font-size: 16px;
    color: ${props => props.theme.black};
    border: solid 1px ${props => props.theme.border};
    padding: 7px 20px;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    resize: none;

    &::placeholder {
      color: ${props => props.theme.grey};
    }

    ::-ms-clear {
      display: none;
    }

    ${props => (props.meta.error && props.meta.submitFailed) && css`
      border: 1px solid ${props.theme.red};
    `}
`;

const Textarea = ({
  input, meta, ...otherProps
}) => (
  <Fragment>
    <StyledTextarea {...input} {...otherProps} meta={meta} />
    {!otherProps.noErrorText && <InputError meta={meta} />}
  </Fragment>
);

Textarea.defaultProps = {
  meta: {
    error: undefined,
    submitFailed: false,
  },
  height: '100px',
};

Textarea.propTypes = {
  input: PT.object,
  meta: PT.object,
  height: PT.string,
};

export default Textarea;
