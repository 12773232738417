import React, { Component } from 'react';
import PT from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import onClickOutside from 'react-onclickoutside';

import Logo from 'images/logo.png';
import Person from 'vectors/person.svg';

import {
  hasRole,
  EVENT_MANAGER,
  PARTNER,
  COMMUNITY_MANAGER,
  PROJAXION,
} from 'services/authHelper';

import { getAppStatus } from 'ducks/p24app';
import { getValidateMileage } from 'ducks/validateMileage';

import { HeaderBar } from 'common';
import UserDetails from './components/UserDetails';
import { ExtendedContainer, Image, Navigation, NavButton } from './styled';

class Header extends Component {
  state = {
    userDataOpen: false,
  };

  componentWillMount() {
    if (!hasRole(this.props.roles, [EVENT_MANAGER, PARTNER, PROJAXION])) {
      this.props.getValidateMileage();
      this.props.getAppStatus();
    }
  }

  toggleUserData = () => this.setState({ userDataOpen: !this.state.userDataOpen });

  isDriver = location => location.pathname.includes('/driver');

  isEvent = location => location.pathname.includes('/event');

  handleClickOutside = () => { this.setState({ userDataOpen: false }); };

  render() {
    const {
      authentication, validateMileageCount, history,
    } = this.props;

    return (
      <HeaderBar>
        <ExtendedContainer>
          <Image src={Logo} alt="logo" onClick={() => history.push('/')} />
          <Navigation>
            <NavLink to="/events" isActive={(match, location) => this.isEvent(location)}>Events</NavLink>
            <NavLink to="/drivers" isActive={(match, location) => this.isDriver(location)}>Porsche rijders</NavLink>
            <NavLink to="/validate-mileage">Valideer km {validateMileageCount ? `(${validateMileageCount})` : ''}</NavLink>
            <NavLink to="/brand-portal">Brand Portal</NavLink>
            {hasRole(this.props.roles, [COMMUNITY_MANAGER, PROJAXION]) && <NavLink to="/orders">Orders</NavLink>}
            {hasRole(this.props.roles, [COMMUNITY_MANAGER]) && <NavLink to="/rewards">Rewards</NavLink>}
            <NavButton onClick={() => this.toggleUserData()} data-cy="header-user-name"><Person /></NavButton>
            {this.state.userDataOpen && <UserDetails data={authentication} />}
          </Navigation>
        </ExtendedContainer>
      </HeaderBar>
    );
  }
}

Header.propTypes = {
  roles: PT.array,
  authentication: PT.object,
  getValidateMileage: PT.func,
  validateMileageCount: PT.number,
  history: PT.object,
  getAppStatus: PT.func,
};

export default compose(
  withRouter,
  connect(state => ({
    roles: state.authentication.roles,
    authentication: state.authentication.data,
    validateMileageCount: state.validateMileage.count,
  }), {
    getValidateMileage,
    getAppStatus,
  }),
  onClickOutside,
)(Header);
