import createAction from 'services/createAction';
import { showNotification } from 'ducks/notification';

const GET_EVENT_MANAGERS = 'eventManagers/GET';
const GET_EVENT_MANAGERS_SUCCESS = 'eventManagers/GET_SUCCESS';
const GET_EVENT_MANAGERS_FAILED = 'eventManagers/GET_FAILED';

const PUT_EVENT_MANAGER = 'eventManagers/PUT';
const PUT_EVENT_MANAGER_SUCCESS = 'eventManagers/PUT_SUCCESS';
const PUT_EVENT_MANAGER_FAILED = 'eventManagers/PUT_FAILED';

const initialState = {
  data: [],
  error: {
    message: '',
  },
  loading: {
    get: false,
    put: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case GET_EVENT_MANAGERS_SUCCESS:
    return {
      ...state,
      data: payload,
      loading: { ...state.loading, get: false },
    };
  case GET_EVENT_MANAGERS_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, get: false },
    };
  case GET_EVENT_MANAGERS:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, get: true },
    };
  case PUT_EVENT_MANAGER_SUCCESS:
    return {
      ...state,
      loading: { ...state.loading, put: false },
    };
  case PUT_EVENT_MANAGER_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, put: false },
    };
  case PUT_EVENT_MANAGER:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, put: true },
    };
  default:
    return state;
  }
};

const getEventManagersSuccess = createAction(GET_EVENT_MANAGERS_SUCCESS);
const getEventManagersFailed = createAction(GET_EVENT_MANAGERS_FAILED);

export const getEventManagers = () => (dispatch, getState, api) => {
  dispatch({ type: GET_EVENT_MANAGERS });

  api.get({ path: 'event_managers' })
    .then((res) => {
      dispatch(getEventManagersSuccess(res));
    })
    .catch((err) => {
      dispatch(getEventManagersFailed({ message: err.data }));
    });
};

const putEventManagerSuccess = createAction(PUT_EVENT_MANAGER_SUCCESS);
const putEventManagerFailed = createAction(PUT_EVENT_MANAGER_FAILED);

export const editEventManager = (id, passwords) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: PUT_EVENT_MANAGER });

    api.put({
      path: `event_managers/${id}`,
      body: {
        password: passwords.newPassword,
        password_compare: passwords.comparePassword,
      },
    }).then((res) => {
      resolve();
      dispatch(putEventManagerSuccess(res));
      dispatch(showNotification('Het wachtwoord is succesvol aangepast.', 'green'));
    }).catch((err) => {
      reject(err);
      dispatch(putEventManagerFailed({ message: err.data }));
      dispatch(showNotification('Er is iets fout gegaan. Probeer opnieuw.'));
    });
  })
);
