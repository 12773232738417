import styled from 'styled-components';

import { media } from 'styles/utils';

export default styled.div`
  padding: 30px 0px;
  width: 100%;

  & h3 {
    font-size: 24px;
  }

  & p {
    margin: 40px 0;
  }

  & ul {
    list-style-type: disc;
  }

  & ol {
    list-style-type: decimal;
  }

  & ul, ol {
    padding-left: 19px;
    margin: 10px 0;
    list-style-position: inside;

    & ul, & ol {
      list-style-position: inside;
      margin-left: 15px;
    }

    & ul {
      list-style-type: circle;
    }

    & ol {
      list-style-type: lower-latin;
    }
  }

  ${media.tablet`
    padding-left: 60px;
    width: calc(100% - 240px);
  `}
`;
