import React from 'react';
import PT from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

/**
 * The PrivateRoute component accepts a boolean value to check whether the user should be able to access the giving component on the given route.
 * If not, the user is redirected to the given fallbackUrl. Ment to be used with React Router V4 or higher.
 * @param {bool} access Boolean that defines if the user is authorised or not
 * @param {string} fallbackUrl The pathname of the URL that the unauthorised user should be redirected to
 * @param {func} component The React component that should only be accessible to authorised users
 * @param {object} rest All the other parameters that might follow
 */
const PrivateRoute = ({
  access, fallbackUrl, component: Component, ...rest
}) => {
  const checkAccess = () => access.status === 'active' &&
    ['community_manager', 'event_manager', 'dealer_admin', 'dealer', 'partner', 'projaxion'].includes(access.role.role.slug);

    // Add redirect URL to search params so it can redirect to that URL after login
    const url = new URL(window.location.href);
    const search = new URLSearchParams({
      ...url.searchParams,
      redirect: url.searchParams.get('redirect') || window.location.pathname,
    });

  return (
    <Route
      {...rest}
      render={props =>
        (checkAccess() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: fallbackUrl,
              state: { from: props.location },
              search: `?${search}`,
            }}
          />
        ))}
    />
  );
};

PrivateRoute.propTypes = {
  access: PT.object,
  fallbackUrl: PT.string,
  component: PT.func,
  location: PT.oneOfType([PT.string, PT.object]),
};

export default PrivateRoute;
