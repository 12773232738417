export const getApiEndpoint = (version = 'v1') => {
  if (['development', 'test'].includes(process.env.APP_ENV)) {
    return `https://api-test.porsche24.app/porsche24/api/${version}/`;
  }

  if (process.env.APP_ENV === 'acceptation') {
    return `https://api-acc.porsche24.app/porsche24/api/${version}/`;
  }

  if (process.env.APP_ENV === 'production') {
    return `https://porsche24-api.porsche.nl/porsche24/api/${version}/`;
  }

  return `https://api-test.porsche24.app/porsche24/api/${version}/`;
};

export const baseUrlBrandPortal = () => {
  if (['development', 'test'].includes(process.env.APP_ENV)) {
    return 'http://192.168.22.14:7775';
  }

  if (process.env.APP_ENV === 'acceptation') {
    return 'https://brandguide-acc.porsche24.app';
  }

  if (process.env.APP_ENV === 'production') {
    return 'https://porsche24guide.porsche.nl';
  }

  return 'http://192.168.22.14:7775';
};

export const getBrandPortalApiEndpoint = () => `${baseUrlBrandPortal()}/api/v2/`;

export const getAuthToken = () => {
  if (['development', 'test'].includes(process.env.APP_ENV)) {
    return 'db9aEWAZ9cUQWXKuk7gkexHUrg9Qa8MDSgbR6tQsbaGBmcEcgiYqde2np5GU8Wlu';
  }

  if (process.env.APP_ENV === 'acceptation') {
    return 'iYdb9qde2np5GU8WluaEWAZ9cUQWXKuk7gkexHUrg9Qa8MDSgbR6tQsbaGBmcEcg';
  }

  if (process.env.APP_ENV === 'production') {
    return 'iYdb9qde2np5GU8WluaEWAZ9cUQWXKuk7gkexHUrg9Qa8MDSgbR6tQsbFJEnbheA';
  }

  return 'db9aEWAZ9cUQWXKuk7gkexHUrg9Qa8MDSgbR6tQsbaGBmcEcgiYqde2np5GU8Wlu';
};
