import styled from 'styled-components';
import PT from 'prop-types';

import { Card, FlexWrapper } from 'common';

export const ExtendedCard = styled(Card)`
  display: flex;
  justify-content: space-between;
`;

export const ExtendedFlexWrapper = styled(FlexWrapper)`
  width: 100%;
  margin: 18px 30px;
  align-items: center;
  padding-top: 30px;
`;
export const ImageWrapper = styled.div`
  width: 220px;
  background-image: url('${props => props.backgroundImage}');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex-shrink: 0;
`;

ImageWrapper.propTypes = {
  backgroundImage: PT.string,
};
