import React from 'react';
import PT from 'prop-types';

class InfiniteScrollContainer extends React.Component {
  componentDidMount() {
    document.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.onScroll);
  }

  onScroll = () => {
    const {
      current, total, loading, fetch,
    } = this.props;
    const container = this.container.current;
    const height = (container.offsetHeight + container.offsetTop) - 400;
    const scrollPosition = window.pageYOffset + window.innerHeight;

    if (current > 0 && current < total && !loading && scrollPosition > height) fetch();
  }

  container = React.createRef();

  render() {
    const { children } = this.props;

    return (
      <div ref={this.container}>
        {children}
      </div>
    );
  }
}

InfiniteScrollContainer.propTypes = {
  children: PT.any.isRequired,
  current: PT.number.isRequired,
  fetch: PT.func.isRequired,
  loading: PT.bool.isRequired,
  total: PT.number.isRequired,
};

export default InfiniteScrollContainer;
