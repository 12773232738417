import React from 'react';
import PT from 'prop-types';
import moment from 'moment';

import { Card, Container, Subtitle, Info, MileageInput } from 'common';

import { ButtonContainer, Content, ExtendedTitle, Header } from './styled';

const ValidationCard = ({ data }) => (
  <Container>
    <Card>
      <Header>
        <div>
          <ExtendedTitle>{data.model}</ExtendedTitle>
          <Subtitle small>{data.user.full_name}</Subtitle>
        </div>
        <ButtonContainer>
          <MileageInput car={data} driver={data.user} />
        </ButtonContainer>
      </Header>
      <Content>
        <Info label="Jaar">
          {moment(data.model_year).format('YYYY')}
        </Info>
        <Info label="Voorkeursdealer">
          {data.preferred_dealer.name}
        </Info>
        <Info label="Kenteken">
          {data.licence_plate}
        </Info>
        <Info label="VIN">
          {data.vin}
        </Info>
        <Info label="Kilometerstand">
          {data.current_mileage.mileage}
        </Info>
      </Content>
    </Card>
  </Container>
);

ValidationCard.propTypes = {
  data: PT.object,
};

export default ValidationCard;
