import styled from 'styled-components';

import { Subtitle } from 'common';

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HeaderContainer = styled.div`
  padding: 36px;
`;

export const ExtendedSubtitle = styled(Subtitle)`
  margin: 0;
`;

export const Date = styled.p`
  margin: 0;
  color: ${props => props.theme.gray};
  letter-spacing: 0.6px;
`;

export const Amount = styled.span`
  color: ${props => props.theme.red};
  font-size: 24px;
`;

export const Table = styled.table`
  border-top: 1px solid ${props => props.theme.border.withBoxShadow};
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
`;

export const TableRow = styled.tr`
  height: 45px;
  border-bottom: 1px solid ${props => props.theme.border.withBoxShadow};

  &:last-of-type {
    border-bottom: 0;
  }
`;

export const TableCell = styled.td`
  font-size: 12px;
  letter-spacing: 2px;
  padding: 0 36px;
  width: 60%;

  &:first-of-type {
    text-transform: capitalize;
    width: 40%;
  }
`;
