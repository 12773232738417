export default {
  category_id: '',
  city: '',
  contact_person: '',
  country: '',
  email: '',
  end_date: '',
  housenumber: '',
  latitude: 0,
  long_description: '',
  longitude: 0,
  name: '',
  infix: '',
  phone: '',
  short_description: '',
  start_date: '',
  street: '',
  surprise: false,
  private: false,
  terms_and_conditions: '',
  whatsapp: false,
  whatsapp_number: '',
  youtube_link: '',
  zipcode: '',
};
