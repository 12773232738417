import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { media } from 'styles/utils';

export const Title = styled.h3`
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 40px;
  display: block;
`;

export const QuickLinkContainer = styled.div`
  ${media.tablet`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  `}
`;

export const QuickLink = styled(Link)`
  display: flex;
  flex-basis: 100%;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 18px;
  border-bottom: 1px solid ${props => props.theme.border};
  margin-bottom: 50px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${props => props.theme.text};

  ${media.tablet`
    flex-basis: calc(50% - 10px);
    max-width: 345px;

    &:nth-of-type(odd) {
      margin-right: 10px;
    }

    &:nth-of-type(even) {
      margin-left: 10px;
    }
  `}
`;

export const QuickLinkLabel = styled.span`
  font-size: 20px;
  line-height: 27px;
`;

export const QuickLinkIcon = styled.span`
  width: 8px;

  & svg {
    fill: ${props => props.theme.red};
  }
`;
