import styled from 'styled-components';
import { media } from 'styles/utils';

export const IntroductionBlock = styled.div`
  display: none;

  ${media.desktop`
    display: flex;
    align-items: center;
    padding: 20px;
    margin-bottom: 18px;
    background-color: ${props => props.theme.black};
    color: ${props => props.theme.white};
  `}
`;

export const Introduction = styled.p`
  line-height: 28px;
`;

export const Image = styled.img`
  max-width: 100px;
  height: auto;
  margin-right: 20px;
`;
