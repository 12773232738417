import styled from 'styled-components';
import { media } from 'styles/utils';

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const Image = styled.img`
  width: 60%;

  ${media.tablet`
    width: auto;
  `}
`;

export const SmallLoader = styled.div`
  @keyframes fade-in {
    0%{
      opacity: 0;
    }
    100%{
      opacity: 1;
    }
  }

  @keyframes spin {
    0%{
      transform: rotate(0deg);
    }
    100%{
      transform: rotate(360deg);
    }
  }

  border-color: ${props => props.theme.border};
  color: ${props => props.theme.border};
  width: 30px;
  height: 30px;
  margin: auto;
  position: relative;
  opacity: 0;
  animation: fade-in 0.5s 0.1s ease-in-out;
  animation-fill-mode: forwards;

  &:after{
    border-radius: 50%;
    content: '';
    width: 30px;
    height: 30px;
    border-width: 5px;
    border-style: solid;
    border-color: inherit;
    border-top: 5px solid ${props => props.theme.gray};
    position: absolute;
    top: 0;
    left: 0;
    animation: spin 1s ease-in-out infinite;
  }
`;
