import createAction from 'services/createAction';
import { showNotification } from 'ducks/notification';

const GET_POINTS_HISTORY = 'pointsHistory/GET';
const GET_POINTS_HISTORY_SUCCESS = 'pointsHistory/GET_SUCCESS';
const GET_POINTS_HISTORY_FAILED = 'pointsHistory/GET_FAILED';

const ADD_POINTS = 'pointsHistory/ADD';
const ADD_POINTS_SUCCESS = 'pointsHistory/ADD_SUCCESS';
const ADD_POINTS_FAILED = 'pointsHistory/ADD_FAILED';

const initialState = {
  data: [],
  driverId: '',
  error: {
    message: '',
  },
  loading: {
    pointsHistory: false,
    addPoints: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case GET_POINTS_HISTORY_SUCCESS:
    return {
      ...state,
      ...payload,
      loading: { ...state.loading, pointsHistory: false },
    };
  case GET_POINTS_HISTORY_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, pointsHistory: false },
    };
  case GET_POINTS_HISTORY:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, pointsHistory: true },
    };
  case ADD_POINTS:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, addPoints: true },
    };
  case ADD_POINTS_SUCCESS:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, addPoints: false },
    };
  case ADD_POINTS_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, addPoints: false },
    };
  default:
    return state;
  }
};

const getPointsHistorySuccess = createAction(GET_POINTS_HISTORY_SUCCESS);
const getPointsHistoryFailed = createAction(GET_POINTS_HISTORY_FAILED);

export const getPointsHistoryFromDriver = driverId => (dispatch, getState, api) => {
  dispatch({ type: GET_POINTS_HISTORY });

  api.get({ path: `management/user/${driverId}/points` })
    .then((res) => {
      dispatch(getPointsHistorySuccess({ data: res, driverId }));
    })
    .catch((err) => {
      dispatch(getPointsHistoryFailed({ message: err.data }));
    });
};

const addPointsSuccess = createAction(ADD_POINTS_SUCCESS);
const addPointsFailed = createAction(ADD_POINTS_FAILED);

export const addPoints = (points, reason, driverId) => (dispatch, getState, api) => {
  dispatch({ type: ADD_POINTS });

  const payload = {
    user_id: driverId,
    amount: Number(points),
    reason,
  };

  return api.post({ path: 'points/gift', body: payload, v2: true })
    .then((res) => {
      dispatch(addPointsSuccess({ data: res }));
      return res;
    })
    .catch((err) => {
      dispatch(addPointsFailed({ message: err.data }));
      dispatch(showNotification('Er is iets fout gegaan. Probeer opnieuw.'));
    });
};
