import React from 'react';
import PT from 'prop-types';
import moment from 'moment';

import { Card } from 'common';

import { HeaderContainer, ExtendedSubtitle, Date, TitleContainer, Amount, Table, TableRow, TableCell } from './styled';

const DriverTabsPointsHistoryCard = ({
  pointHistory: {
    amount, context, date_created: dateCreated, reason,
  },
}) => (
  <Card noPadding>
    <HeaderContainer>
      <TitleContainer>
        <ExtendedSubtitle>{reason.replace('_', ' ')}</ExtendedSubtitle>
        <Amount>{amount} points</Amount>
      </TitleContainer>
      <Date>{moment(dateCreated).format('LL')}</Date>
    </HeaderContainer>
    {context && (
      <Table>
        <tbody>
          {Object.keys(context).map(item => (
            <TableRow key={item}>
              <TableCell>{item.split('_').join(' ')}</TableCell>
              <TableCell>{context[item]}</TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
    )}
  </Card>
);

DriverTabsPointsHistoryCard.propTypes = {
  pointHistory: PT.shape({
    amount: PT.number,
    context: PT.object,
    date_created: PT.string,
    id: PT.string,
    reason: PT.string,
  }),
};

export default DriverTabsPointsHistoryCard;
