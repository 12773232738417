import React, { Component } from 'react';
import PT from 'prop-types';
import { reduxForm, Field, Form } from 'redux-form';

import { Button } from 'common';
import { Label, InputText } from 'common/form';
import { InputContainer, InputSection } from './styled';

class ValidateMileageForm extends Component {
  componentDidMount() {
    this.props.initialize({ mileage: this.props.mileage });
  }

  componentDidUpdate(prevProps) {
    if (this.props.mileage !== prevProps.mileage) {
      this.props.initialize({ mileage: this.props.mileage });
    }
  }

  render() {
    const { handleSubmit, rejectClaim, nextCar } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <InputContainer>
          <InputSection>
            <Label noWhitespace>Kilometers</Label>
            <Field component={InputText} name="mileage" type="number" />
          </InputSection>
          <Button type="submit" small stretch>
            Accepteer
            {nextCar && ' en ga naar de volgende'}
          </Button>
          <Button
            onClick={rejectClaim}
            small
            stretch
            variant="black"
          >
            Claim weigeren
          </Button>
        </InputContainer>
      </Form>
    );
  }
}

ValidateMileageForm.propTypes = {
  handleSubmit: PT.func,
  rejectClaim: PT.func,
  initialize: PT.func,
  mileage: PT.number,
  nextCar: PT.oneOfType([
    PT.object,
    PT.bool,
  ]),
};

export default reduxForm({ form: 'validateMileage' })(ValidateMileageForm);
