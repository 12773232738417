import styled from 'styled-components';

export const ModalContainer = styled.div`
  width: 100%;
  min-width: 500px;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ValidateCarModalContainer = styled.div`
  display: flex;
`;

export const ModalContent = styled.div`
  padding: 36px 54px;
  width: 50%;
  min-width: 350px;
  max-height: 90vh;
  overflow-y: scroll;
`;

export const ImagePreview = styled.div`
  background-image: url('${props => props.image}');
  background-size: cover;
  background-position: center;
  height: 100%;
`;

export const ImageContainer = styled.div`
  width: 50%;
  min-width: 450px;
  position: relative;

  svg {
    position: absolute;
    top: 8px;
    right: 8px;
    fill: ${props => props.theme.black};
    stroke: ${props => props.theme.black};
    width: 25px;
    height: 25px;
    background-color: ${props => props.theme.white};
    padding: 3px;
    border-radius: 2px;
  }
`;

export const ButtonContainer = styled.div`
  button:not(:last-child) {
    margin: 0 0 20px 0;
  }
`;

export const PreviousMessage = styled.pre`
  padding: 8px;
  max-width: 500px;
  margin: 0;
  white-space: pre-wrap;
`;
