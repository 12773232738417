import React, { Component } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import qs from 'qs';

import authRedirect from 'config/authRedirect';
import { validateOktaLogin } from 'ducks/authentication';

class Oauth extends Component {
  componentDidMount() {
    this.props.validateOktaLogin(qs.parse(this.props.history.location.search, { ignoreQueryPrefix: true }).code)
      .then(() => { window.location.href = '/events'; })
      .catch(() => { window.location.href = '/login?error=external' });
  }

  render() {
    return null;
  }
}

Oauth.propTypes = {
  location: PT.object,
  validateOktaLogin: PT.func,
  history: PT.object,
};

export default compose(
  withRouter,
  connect(null, { validateOktaLogin }),
)(Oauth);
