import styled from 'styled-components';

export const TicketsBlock = styled.div`
  margin-top: 90px;
`;

export const Ticket = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid ${props => props.theme.border};

  &:first-of-type {
    border-top: 1px solid ${props => props.theme.border};
  }
`;

export const TicketName = styled.h4`
  flex: 1;
  margin: 0;
  font-size: 22px;
  line-height: 26px;
  font-weight: 700;
`;

export const TicketPrice = styled.p`
  margin: 0;
  font-size: 22px;
  line-height: 26px;
  font-weight: 700;
  color: ${props => props.theme.red};
`;

export const TicketDescription = styled.p`
  flex-basis: 100%;
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.gray};
`;
