import styled, { css } from 'styled-components';
import PT from 'prop-types';
import Flatpickr from 'react-flatpickr';

export const FlatpickrStyled = styled(Flatpickr)`
  display: block;
  width: 100%;
  font-size: 16px;
  color: ${props => props.theme.black};
  border: solid 1px ${props => props.theme.border};
  padding: 7px 20px;
  outline: none;

  ${props => (props.meta.error && props.meta.submitFailed) && css`
    & + input {
      border-color: ${props.theme.red};
    }
  `}
`;

FlatpickrStyled.propTypes = {
  meta: PT.object,
};
