import styled, { css } from 'styled-components';

export const Badge = styled.div`
  flex-basis: 200px;
  height: 170px;
  margin-right: 10px;
  margin-top: 10px;
  background-color: ${props => props.theme.background};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 25px;

  ${props => props.isLoading && css`
    opacity: 0.4;
  `}
`;

export const Image = styled.img`
  display: block;
  height: 60px;
  width: auto;
  margin: 0px auto;
`;

export const BadgeName = styled.p`
  color: ${props => props.theme.gray};
  text-align: center;
  font-size: 14px;
  text-transform: lowercase;
`;

export const ProgressBar = styled.div`
  border: 1px solid ${props => props.theme.border};
  width: 100%;
  height: 15px;
  border-radius: 15px;
  overflow: hidden;
`;

export const ProgressBarFill = styled.div`
  width: ${props => props.progression};
  height: 100%;
  background-color: ${props => props.theme.red};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
`;
