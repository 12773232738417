import React, { Component, Fragment } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { reduxForm, Field, change, getFormValues, reset } from 'redux-form';

import * as validation from 'services/validations';
import { showNotification, hideNotification } from 'ducks/notification';
import { getRoles } from 'ducks/roles';
import { getDealers } from 'ducks/dealers';
import { createUser } from 'ducks/users';
import { Title, Container, Button, Subtitle } from 'common';
import { FormField, Label, InputText, Form, SubLabel, InputSelect } from 'common/form';

class UserCreate extends Component {
  state = {
    showDealerSelect: false,
    createdEmail: '',
  };

  componentDidMount() {
    this.props.onGetRoles();
    this.props.onGetDealers();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.formData && nextProps.formData.role_slug === 'dealer') {
      this.setState({
        showDealerSelect: true,
      });
    } else {
      this.setState({
        showDealerSelect: false,
      });
      this.props.onChange('create-user', 'dealer_id', '');
    }
  }

  onSubmit = (values) => {
    this.props.hideNotification();
    this.props.onCreateUser(values)
      .then((res) => {
        this.setState({
          createdEmail: res.email,
        });
        this.props.onReset('create-user');
      })
      .catch((err) => {
        if (err && err.status === 409) {
          return this.props.showNotification('Er bestaat al een Dashboard gebruiker met deze email. Probeer het opnieuw.', 'red', true);
        }

        return this.props.showNotification('Er is iets mis gegaan bij het aanmaken van de gebruiker. Probeer het opnieuw.');
      });
  }

  render() {
    const { handleSubmit, onChange, dealers, roles } = this.props;

    return (
      <Container>
        <Title underline>Dashboard account aanmaken</Title>
        <SubLabel>Zorg dat de gegevens overeenkomen met de gegevens in OKTA</SubLabel>
        {!this.state.createdEmail ? (
          <Form
            onSubmit={handleSubmit(this.onSubmit)}
            noValidate
          >
            <FormField>
              <Label>Voornaam</Label>
              <Field
                name="firstname"
                component={InputText}
                type="text"
              />
            </FormField>
            <FormField>
              <Label>Achternaam</Label>
              <Field
                name="lastname"
                component={InputText}
                type="text"
              />
            </FormField>
            <FormField>
              <Label>Email*</Label>
              <SubLabel groupWithLabel>Verplicht</SubLabel>
              <Field
                name="email"
                component={InputText}
                type="email"
                validation={[validation.email, validation.required]}
              />
            </FormField>
            <FormField>
              <Label>Rol*</Label>
              <SubLabel groupWithLabel>Verplicht</SubLabel>
              <InputSelect
                classNamePrefix="react-select"
                options={roles.map(role => ({
                  value: role.slug,
                  label: role.name,
                }))}
                onChange={e => onChange('create-user', 'role_slug', e.value)}
              />
            </FormField>
            {/* if selected role is dealer, show field for dealer_id */}
            {this.state.showDealerSelect && (
              <FormField>
                <Label>Dealer*</Label>
                <SubLabel groupWithLabel>Verplicht</SubLabel>
                <InputSelect
                  classNamePrefix="react-select"
                  options={dealers.map(dealer => ({ value: dealer.id, label: dealer.name }))}
                  onChange={e => onChange('create-user', 'dealer_id', e.value)}
                />
              </FormField>
            )}
            <FormField>
              <Button type="submit" stretch>Account aanmaken</Button>
            </FormField>
          </Form>
        ) : (
          <Fragment>
            <Subtitle>
              Het account voor {this.state.createdEmail} is succesvol aangemaakt.
            </Subtitle>
            <Button
              small
              onClick={() => this.setState({ createdEmail: '' })}
            >
              Maak nog een account aan
            </Button>
          </Fragment>
        )}
      </Container>
    );
  }
}

UserCreate.propTypes = {
  onGetRoles: PT.func.isRequired,
  handleSubmit: PT.func.isRequired,
  onChange: PT.func.isRequired,
  onReset: PT.func.isRequired,
  dealers: PT.array.isRequired,
  formData: PT.object,
  onGetDealers: PT.func.isRequired,
  onCreateUser: PT.func.isRequired,
  showNotification: PT.func.isRequired,
  hideNotification: PT.func.isRequired,
  roles: PT.array,
};

export default compose(
  withRouter,
  connect(state => ({
    dealers: state.dealers.data,
    roles: state.roles.data,
    formData: getFormValues('create-user')(state),
  }), {
    onGetRoles: getRoles,
    onChange: change,
    onReset: reset,
    onGetDealers: getDealers,
    onCreateUser: createUser,
    showNotification,
    hideNotification,
  }),
  reduxForm({ form: 'create-user' }),
)(UserCreate);
