import createAction from 'services/createAction';
import { removeCookie } from 'services/cookies';
import { showNotification, hideNotification } from 'ducks/notification';

const LOGIN = 'login';
const LOGIN_SUCCESS = 'login/SUCCESS';
const LOGIN_FAILED = 'login/FAILED';

const LOGOUT = 'logout';

const GET_USER = 'user/GET';
const GET_USER_SUCCESS = 'user/GET_SUCCESS';
const GET_USER_FAILED = 'user/GET_FAILED';

const PASSWORD_FORGOT = 'password/FORGOT';
const PASSWORD_FORGOT_SUCCESS = 'password/FORGOT_SUCCESS';
const PASSWORD_FORGOT_FAILED = 'password/FORGOT_FAILED';

const ACTIVATE_ACCOUNT = 'account/ACTIVATE';
const ACTIVATE_ACCOUNT_SUCCESS = 'account/ACTIVATE_SUCCESS';
const ACTIVATE_ACCOUNT_FAILED = 'account/ACTIVATE_FAILED';

const VALIDATE_OKTA_LOGIN = 'authentication/VALIDATE_OKTA_LOGIN';
const VALIDATE_OKTA_LOGIN_SUCCESS = 'authentication/VALIDATE_OKTA_LOGIN_SUCCESS';
const VALIDATE_OKTA_LOGIN_FAILED = 'authentication/VALIDATE_OKTA_LOGIN_FAILED';

const PASSWORD_RESET = 'password/RESET';
const PASSWORD_RESET_SUCCESS = 'password/RESET_SUCCESS';
const PASSWORD_RESET_FAILED = 'password/RESET_FAILED';

const initialState = {
  data: {},
  roles: [],
  permissions: [],
  accountActivated: false,
  error: {
    message: '',
  },
  loading: {
    login: false,
    getUser: false,
    passwordForgot: false,
    activateAccount: false,
    validateOktaLogin: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case LOGIN_SUCCESS:
    return {
      ...state,
      loading: { ...state.loading, login: false },
    };
  case LOGIN_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, login: false },
    };
  case LOGIN:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, login: true },
    };
  case LOGOUT:
    return initialState;
  case GET_USER_SUCCESS:
    return {
      ...state,
      data: payload,
      roles: [payload.role.role.slug],
      permissions: payload.permissions,
      loading: { ...state.loading, getUser: false },
    };
  case GET_USER_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, getUser: false },
    };
  case GET_USER:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, getUser: true },
    };
  case PASSWORD_FORGOT_SUCCESS:
    return {
      ...state,
      loading: { ...state.loading, passwordForgot: false },
    };
  case PASSWORD_FORGOT_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, passwordForgot: false },
    };
  case PASSWORD_FORGOT:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, passwordForgot: true },
    };
  case ACTIVATE_ACCOUNT_SUCCESS:
    return {
      ...state,
      accountActivated: true,
      loading: { ...state.loading, activateAccount: false },
    };
  case ACTIVATE_ACCOUNT_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, activateAccount: false },
    };
  case ACTIVATE_ACCOUNT:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, activateAccount: true },
    };
  case VALIDATE_OKTA_LOGIN_SUCCESS:
    return {
      ...state,
      loading: { ...state.loading, validateOktaLogin: false },
    };
  case VALIDATE_OKTA_LOGIN_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, validateOktaLogin: false },
    };
  case VALIDATE_OKTA_LOGIN:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, validateOktaLogin: true },
    };
  case PASSWORD_RESET_SUCCESS:
    return {
      ...state,
      loading: { ...state.loading, passwordReset: false },
    };
  case PASSWORD_RESET_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, passwordReset: false },
    };
  case PASSWORD_RESET:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, passwordReset: true },
    };
  default:
    return state;
  }
};

const getUserSuccess = createAction(GET_USER_SUCCESS);
const getUserFailed = createAction(GET_USER_FAILED);

export const getUser = () => (dispatch, getState, api) => new Promise((resolve, reject) => {
  dispatch({ type: GET_USER });

  api.get({ path: 'user' })
    .then((res) => {
      dispatch(getUserSuccess(res));
      resolve(res);
    })
    .catch((err) => {
      dispatch(getUserFailed({ message: err.data }));
      reject();
    });
});

const loginSuccess = createAction(LOGIN_SUCCESS);
const loginFailed = createAction(LOGIN_FAILED);

// only being used for TEST and ACC because okta only works for prod
export const login = (payload, history) => (dispatch, getState, api) => {
  dispatch({ type: LOGIN });

  api.post({ path: 'login', body: payload, withAuth: false })
    .then(() => {
      dispatch(loginSuccess());
      dispatch(getUser());
      dispatch(hideNotification());

      // redirect to redirect URL or just to home
      const search = new URL(window.location.href).searchParams;
      history.push(search.get('redirect') || '/');
    })
    .catch((err) => {
      if (err.data === 'FORCE_PASSWORD_CHANGE') {
        dispatch(showNotification(
          "Uw wachtwoord is verouderd. Pas deze aan via 'Wachtwoord vergeten'.",
          'orange',
          true,
        ));
      } else {
        dispatch(showNotification('Uw e-mailadres en wachtwoord combinatie is niet juist.'));
      }

      dispatch(loginFailed({ message: err.data }));
    });
};

const passwordForgotSuccess = createAction(PASSWORD_FORGOT_SUCCESS);
const passwordForgotFailed = createAction(PASSWORD_FORGOT_FAILED);

export const passwordForgot = payload => (dispatch, getState, api) => {
  dispatch({ type: PASSWORD_FORGOT });

  api.post({ path: 'user/password_forget', body: payload, withAuth: false })
    .then(() => {
      dispatch(passwordForgotSuccess());
      dispatch(showNotification('Het versturen is succesvol. Check je mail om het wachtwoord te resetten.', 'green'));
    })
    .catch((err) => {
      dispatch(showNotification('E-mailadres is incorrect.'));
      dispatch(passwordForgotFailed({ message: err.data }));
    });
};

export const requestOktaLogin = () => (dispatch, getState, api) => {
  api.get({ path: 'okta_login' })
    .then((res) => {
      window.location = res;
    })
    .catch((err) => {
      dispatch(getUserFailed({ message: err.data }));
    });
};

export const logout = history => (dispatch) => {
  removeCookie('X-Access-Token');
  dispatch({ type: LOGOUT });
  history.push('/login');
};

const activateAccountSuccess = createAction(ACTIVATE_ACCOUNT_SUCCESS);
const activateAccountFailed = createAction(ACTIVATE_ACCOUNT_FAILED);

export const activateAccount = payload => (dispatch, getState, api) => {
  dispatch({ type: ACTIVATE_ACCOUNT });

  api.put({ path: 'user/validate', body: { activation_code: payload }, withAuth: false })
    .then(() => {
      dispatch(activateAccountSuccess());
    })
    .catch((err) => {
      dispatch(activateAccountFailed({ message: err.data }));
    });
};

const validateOktaLoginSuccess = createAction(VALIDATE_OKTA_LOGIN_SUCCESS);
const validateOktaLoginFailed = createAction(VALIDATE_OKTA_LOGIN_FAILED);

export const validateOktaLogin = code => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: VALIDATE_OKTA_LOGIN });

    api.get({ path: `oauth_login?code=${code}`, withAuth: true })
      .then(() => {
        dispatch(validateOktaLoginSuccess());
        resolve();
      })
      .catch((err) => {
        dispatch(validateOktaLoginFailed({ message: err.data }));
        reject();
      });
  })
);

const passwordResetSuccess = createAction(PASSWORD_RESET_SUCCESS);
const passwordResetFailed = createAction(PASSWORD_RESET_FAILED);

export const passwordReset = payload => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: PASSWORD_RESET });

    api.post({ path: 'user/password_reset', body: payload, withAuth: false })
      .then(() => {
        dispatch(passwordResetSuccess());
        dispatch(hideNotification());
        resolve();
      })
      .catch((err) => {
        dispatch(showNotification(
          err === 'Reset code has expired.'
            ? 'Reset link is verlopen. Vraag een nieuwe aan in de app.'
            : 'Wachtwoord is niet sterk genoeg',
          'red',
          true,
        ));

        dispatch(passwordResetFailed({ message: err.data }));
        reject();
      });
  })
);
