import React from 'react';
import PT from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';

import * as validation from 'services/validations';

import { passwordForgot } from 'ducks/authentication';

import { Title, Button, Container } from 'common';
import { Label, InputText, FormField } from 'common/form';

const ForgotPassword = ({
  handleSubmit, onForgotPassword, history,
}) => (
  <Container small alignCenter>
    <Title>Wachtwoord vergeten</Title>
    <form
      onSubmit={handleSubmit(values => onForgotPassword(values, history))}
      noValidate
    >
      <FormField>
        <Label uppercase>E-mailadres</Label>
        <Field
          name="email"
          component={InputText}
          type="email"
          validate={[validation.required, validation.email]}
        />
      </FormField>
      <FormField>
        <Button type="submit" stretch>Verstuur reset link</Button>
      </FormField>
      <FormField>
        <Button type="button" plain stretch onClick={() => history.push('/login')}>Terug naar login</Button>
      </FormField>
    </form>
  </Container>
);

ForgotPassword.propTypes = {
  handleSubmit: PT.func,
  onForgotPassword: PT.func,
  history: PT.object,
};

export default compose(
  connect(null, { onForgotPassword: passwordForgot }),
  reduxForm({ form: 'forgot-password' }),
)(ForgotPassword);
