import Raven from 'raven-js';
import SENTRY_ENDPOINT from 'config/sentry';

export default () => {
  if (!__DEV__) {
    Raven.config(SENTRY_ENDPOINT).install({
      environment: process.env.APP_ENV,
    });
    Raven.setTagsContext({
      environment: process.env.APP_ENV,
    });
  }
};
