import React from 'react';
import PT from 'prop-types';

import { Subtitle } from 'common';

import { TicketsBlock, Ticket, TicketName, TicketPrice, TicketDescription } from './styled';

const EventPreviewTickets = ({ tickets }) => (
  <TicketsBlock>
    <Subtitle bold>Tickets</Subtitle>
    {tickets.map(ticket => (
      <Ticket key={ticket.id}>
        <TicketName>{ticket.name}</TicketName>
        {ticket.price_incl >= 0 && (
          <TicketPrice>{ticket.price_incl > 0 ? `€ ${ticket.price_incl}` : 'Free'}</TicketPrice>
        )}
        {ticket.price >= 0 && (
          <TicketPrice>{ticket.price > 0 ? `€ ${ticket.price}` : 'Free'}</TicketPrice>
        )}
        <TicketDescription>{ticket.description}</TicketDescription>
      </Ticket>
    ))}
  </TicketsBlock>
);

EventPreviewTickets.propTypes = {
  tickets: PT.array,
};

export default EventPreviewTickets;
