import React, { Component } from 'react';
import PT from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm, Field, change, getFormValues, initialize } from 'redux-form';

import { uploadUpdateImage, deleteUpdateImage } from 'ducks/update';

import { Anchor, Button } from 'common';
import { Wysiwyg, Label, SubLabel, InputText, FormField, InputDropZoneImage } from 'common/form';

import Cross from 'vectors/Cross.svg';

import { ActionsContainer, Colored, ExtendedButton } from './styled';

class EventDetailUpdatesEditor extends Component {
  state = {
    showVideoInput: false,
    showImagesInput: false,
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.edit && nextProps.edit.youtube_link) {
      this.setState({
        showVideoInput: true,
      });
    }

    if (nextProps.edit && nextProps.edit.image.length > 0) {
      this.setState({
        showImagesInput: true,
      });
    }
  }

  cancelVideo = () => {
    this.props.change('youtube_link', null);

    this.setState({
      showVideoInput: false,
    });
  }

  deleteImage = (imageId) => {
    this.props.deleteUpdateImage(this.props.editingUpdateId, imageId);
  }

  render() {
    const {
      uploadedImages, onUploadUpdateImage, editingUpdateId, publish, save, formData, cancel,
    } = this.props;

    return (
      <div>
        <FormField>
          <Field
            component={Wysiwyg}
            name="text"
            defaultValue={formData.text}
            disabledLink
          />
        </FormField>

        {this.state.showVideoInput && (
          <FormField>
            <Label>Voeg video toe</Label>
            <SubLabel groupWithLabel>Voeg youtube of vimeo link toe</SubLabel>
            <Field
              name="youtube_link"
              component={InputText}
            />
          </FormField>
        )}

        {this.state.showImagesInput && (
          <FormField>
            <InputDropZoneImage
              assets={uploadedImages}
              onUpload={(acceptedFiles) => {
                onUploadUpdateImage(editingUpdateId, acceptedFiles[0]);
              }}
              multiple
              onDelete={this.deleteImage}
            />
          </FormField>
        )}

        <ActionsContainer>
          <div>
            <Button
              small
              sub
              onClick={cancel}
            >
              Annuleren
            </Button>
          </div>

          <div>
            {!this.state.showVideoInput ? (
              <Anchor
                small
                bold
                onClick={() => this.setState({
                  showVideoInput: true,
                })}
              >
                Voeg video toe <Colored>+</Colored>
              </Anchor>
            ) : (
              <Anchor
                small
                bold
                onClick={this.cancelVideo}
              >
                Verwijder video <Colored><Cross /></Colored>
              </Anchor>
            )}

            {(!this.state.showImagesInput) && (
              <Anchor
                small
                bold
                onClick={() => this.setState({
                  showImagesInput: true,
                })}
              >
                Voeg foto&apos;s toe <Colored>+</Colored>
              </Anchor>
            )}

            {!this.props.edit.published && (
              <ExtendedButton small sub onClick={save}>Opslaan</ExtendedButton>
            )}
            <ExtendedButton small onClick={publish}>Publiceren</ExtendedButton>
          </div>
        </ActionsContainer>
      </div>
    );
  }
}

EventDetailUpdatesEditor.propTypes = {
  change: PT.func,
  uploadedImages: PT.array,
  onUploadUpdateImage: PT.func,
  editingUpdateId: PT.string,
  deleteUpdateImage: PT.func,
  publish: PT.func,
  save: PT.func,
  formData: PT.object,
  edit: PT.oneOfType([PT.object, PT.bool]),
  cancel: PT.func,
};

export default compose(
  withRouter,
  connect(state => ({
    formData: getFormValues('edit-update')(state) || {},
    editingUpdateId: state.update.editingUpdateId,
    updates: state.updates.data,
    uploadedImages: state.update.uploadedImages,
  }), {
    change, onUploadUpdateImage: uploadUpdateImage, deleteUpdateImage, initialize,
  }),
  reduxForm({ form: 'edit-update' }),
)(EventDetailUpdatesEditor);
