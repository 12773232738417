import styled from 'styled-components';

export const CEMPackageCSVUploadContainer = styled.div`
  max-width: 250px;
`;

export const ModalContainer = styled.div`
  min-width: 300px;
  margin: 48px 36px;
`;

export const LoadingContainer = styled.div`
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
