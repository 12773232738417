import styled, { css } from 'styled-components';
import PT from 'prop-types';

const FormField = styled.div`
  margin-bottom: 36px;

  ${props => props.indent && css`
    margin-left: 54px;
  `}
`;

FormField.propTypes = {
  indent: PT.bool,
};

export default FormField;
