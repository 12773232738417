import styled, { css } from 'styled-components';
import { media } from 'styles/utils';

import { Anchor } from 'common';

export const ContactBlock = styled.div`
  margin-top: 90px;

  ${props => props.noMargin && css`
    margin-top: 0;
  `}
`;

export const Contact = styled.div`
  margin-bottom: 10px;
  padding-left: 20px;
`;

export const ContactName = styled.p`
  font-size: 16px;
  font-weight: 700;
  margin: 0;
`;

export const ContactRole = styled.p`
  font-size: 16px;
  margin: 0;
`;

export const AnchorLink = styled(Anchor)`
  width: 100%;
  text-align: left;
  font-size: 12px;
  font-weight: 700;
  display: block;

  svg {
    width: 16px;
    margin-right: 10px;
    vertical-align: middle;
  }

  ${media.tablet`
    font-size: 12px;
  `}
`;
