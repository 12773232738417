import createAction from 'services/createAction';
import { showNotification } from 'ducks/notification';

const RESET_EVENT = 'event/RESET';

const GET_EVENT = 'event/GET';
const GET_EVENT_SUCCESS = 'event/GET_SUCCESS';
const GET_EVENT_FAILED = 'event/GET_FAILED';

const GET_ATTENDEES = 'event/GET_ATTENDEES';
const GET_ATTENDEES_SUCCESS = 'event/GET_ATTENDEES_SUCCESS';
const GET_ATTENDEES_FAILED = 'event/GET_ATTENDEES_FAILED';

const UPLOAD_ATTENDEES = 'event/UPLOAD_ATTENDEES';
const UPLOAD_ATTENDEES_SUCCESS = 'event/UPLOAD_ATTENDEES_SUCCESS';
const UPLOAD_ATTENDEES_FAILED = 'event/UPLOAD_ATTENDEES_FAILED';

const MARK_ATTENDEES = 'event/MARK_ATTENDEES';
const MARK_ATTENDEES_SUCCESS = 'event/MARK_ATTENDEES_SUCCESS';
const MARK_ATTENDEES_FAILED = 'event/MARK_ATTENDEES_FAILED';

const EXPORT_ATTENDEES = 'event/EXPORT_ATTENDEES';
const EXPORT_ATTENDEES_SUCCESS = 'event/EXPORT_ATTENDEES_SUCCESS';
const EXPORT_ATTENDEES_FAILED = 'event/EXPORT_ATTENDEES_FAILED';

const EXPORT_INVITEES = 'event/EXPORT_INVITEES';
const EXPORT_INVITEES_SUCCESS = 'event/EXPORT_INVITEES_SUCCESS';
const EXPORT_INVITEES_FAILED = 'event/EXPORT_INVITEES_FAILED';

const CANCEL_TICKETS = 'event/CANCEL_TICKETS';
const CANCEL_TICKETS_SUCCESS = 'event/CANCEL_TICKETS_SUCCESS';
const CANCEL_TICKETS_FAILED = 'event/CANCEL_TICKETS_FAILED';

const MOVE_UP_TICKET = 'event/MOVE_UP_TICKET';
const MOVE_UP_TICKET_SUCCESS = 'event/MOVE_UP_TICKET_SUCCESS';
const MOVE_UP_TICKET_FAILED = 'event/MOVE_UP_TICKET_FAILED';

const MOVE_DOWN_TICKET = 'event/MOVE_DOWN_TICKET';
const MOVE_DOWN_TICKET_SUCCESS = 'event/MOVE_DOWN_TICKET_SUCCESS';
const MOVE_DOWN_TICKET_FAILED = 'event/MOVE_DOWN_TICKET_FAILED';

const initialState = {
  data: {},
  eventId: '',
  attendees: {
    tickets: [],
  },
  error: {
    message: '',
  },
  loading: {
    get: false,
    uploadAttendees: false,
    getAttendees: false,
    markAsAttending: false,
    exportAttendees: false,
    exportInvitees: false,
    cancelTickets: false,
    moveUp: false,
    moveDown: false,
  },
  loaded: {
    uploadAttendees: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case RESET_EVENT:
    return {
      ...initialState,
    };
  case GET_EVENT_SUCCESS:
    return {
      ...state,
      ...payload,
      loading: { ...state.loading, get: false },
    };
  case GET_EVENT_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, get: false },
    };
  case GET_EVENT:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, get: true },
    };
  case GET_ATTENDEES_SUCCESS:
    return {
      ...state,
      attendees: payload,
      loading: { ...state.loading, getAttendees: false },
    };
  case GET_ATTENDEES_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, getAttendees: false },
    };
  case GET_ATTENDEES:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, getAttendees: true },
    };
  case UPLOAD_ATTENDEES_SUCCESS:
    return {
      ...state,
      loaded: { ...state.loaded, uploadAttendees: true },
      loading: { ...state.loading, uploadAttendees: false },
    };
  case UPLOAD_ATTENDEES_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, uploadAttendees: false },
    };
  case UPLOAD_ATTENDEES:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, uploadAttendees: true },
    };
  case MARK_ATTENDEES_SUCCESS:
    return {
      ...state,
      loading: { ...state.loading, markAsAttending: false },
    };
  case MARK_ATTENDEES_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, markAsAttending: false },
    };
  case MARK_ATTENDEES:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, markAsAttending: true },
    };
  case EXPORT_ATTENDEES_SUCCESS:
    return {
      ...state,
      loading: { ...state.loading, exportAttendees: false },
    };
  case EXPORT_ATTENDEES_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, exportAttendees: false },
    };
  case EXPORT_ATTENDEES:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, exportAttendees: true },
    };
  case EXPORT_INVITEES_SUCCESS:
    return {
      ...state,
      loading: { ...state.loading, exportInvitees: false },
    };
  case EXPORT_INVITEES_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, exportInvitees: false },
    };
  case EXPORT_INVITEES:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, exportInvitees: true },
    };
  case CANCEL_TICKETS_SUCCESS:
    return {
      ...state,
      loading: { ...state.loading, cancelTickets: false },
    };
  case CANCEL_TICKETS_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, cancelTickets: false },
    };
  case CANCEL_TICKETS:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, cancelTickets: true },
    };
  case MOVE_UP_TICKET_SUCCESS:
    return {
      ...state,
      loading: { ...state.loading, moveUp: false },
    };
  case MOVE_UP_TICKET_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, moveUp: false },
    };
  case MOVE_UP_TICKET:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, moveUp: true },
    };
  case MOVE_DOWN_TICKET_SUCCESS:
    return {
      ...state,
      loading: { ...state.loading, moveDown: false },
    };
  case MOVE_DOWN_TICKET_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, moveDown: false },
    };
  case MOVE_DOWN_TICKET:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, moveDown: true },
    };
  default:
    return state;
  }
};

export const resetEvent = createAction(RESET_EVENT);

const getEventSuccess = createAction(GET_EVENT_SUCCESS);
const getEventFailed = createAction(GET_EVENT_FAILED);

export const getEvent = eventId => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: GET_EVENT });

    // same api endpoint as get in editEvent, different duck
    api.get({ path: `management/activity/${eventId}` })
      .then((res) => {
        resolve(res);
        dispatch(getEventSuccess({ data: res, eventId }));
      })
      .catch((err) => {
        reject(err);
        dispatch(getEventFailed({ message: err.data }));
      });
  }));

const getAttendeesSuccess = createAction(GET_ATTENDEES_SUCCESS);
const getAttendeesFailed = createAction(GET_ATTENDEES_FAILED);

export const getAttendees = (eventId, query = null) => (dispatch, getState, api) => {
  dispatch({ type: GET_ATTENDEES });

  api.get({ path: `management/activity/${eventId}/attendees`, query })
    .then((res) => {
      dispatch(getAttendeesSuccess(res));
    })
    .catch((err) => {
      dispatch(getAttendeesFailed({ message: err.data }));
    });
};

const uploadAttendeesSuccess = createAction(UPLOAD_ATTENDEES_SUCCESS);
const uploadAttendeesFailed = createAction(UPLOAD_ATTENDEES_FAILED);

export const uploadAttendees = (eventId, file) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: UPLOAD_ATTENDEES });

    const data = new FormData();
    data.append('csv', file);

    api.post({ path: `management/activity/${eventId}/import`, body: data, upload: true })
      .then((res) => {
        resolve(res);
        dispatch(uploadAttendeesSuccess(res));
      })
      .catch((err) => {
        reject(err);
        dispatch(uploadAttendeesFailed({ message: err.data }));
        dispatch(showNotification('Er is iets fout gegaan. Probeer opnieuw.'));
      });
  })
);

const markAttendeesSuccess = createAction(MARK_ATTENDEES_SUCCESS);
const markAttendeesFailed = createAction(MARK_ATTENDEES_FAILED);

export const markAttendees = (eventId, attendees, mark) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: MARK_ATTENDEES });

    const body = {
      attended: mark,
      ticket_ids: attendees,
    };

    api.put({ path: `management/activity/${eventId}/attendees`, body })
      .then(() => {
        resolve();
        dispatch(markAttendeesSuccess());
        dispatch(showNotification('Het markeren is gelukt.', 'green'));
      })
      .catch((err) => {
        reject(err);
        dispatch(markAttendeesFailed({ message: err.data }));
        dispatch(showNotification('Er is iets fout gegaan. Probeer opnieuw.'));
      });
  })
);

const exportAttendeesSuccess = createAction(EXPORT_ATTENDEES_SUCCESS);
const exportAttendeesFailed = createAction(EXPORT_ATTENDEES_FAILED);

export const exportAttendees = (eventId, attendees) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: EXPORT_ATTENDEES });

    const body = {
      ticket_ids: attendees,
    };

    api.post({ path: `management/activity/${eventId}/attendees/export`, body, file: true })
      .then((res) => {
        resolve(res);
        dispatch(exportAttendeesSuccess());
      })
      .catch((err) => {
        reject(err);
        dispatch(exportAttendeesFailed({ message: err.data }));
      });
  })
);

const exportInviteesSuccess = createAction(EXPORT_INVITEES_SUCCESS);
const exportInviteesFailed = createAction(EXPORT_INVITEES_FAILED);

export const exportInvitees = (eventId, attendees) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: EXPORT_INVITEES });

    const body = {
      ticket_ids: attendees,
    };

    api.post({ path: `management/activity/${eventId}/invitees/export`, body, file: true })
      .then((res) => {
        resolve(res);
        dispatch(exportInviteesSuccess());
      })
      .catch((err) => {
        reject(err);
        dispatch(exportInviteesFailed({ message: err.data }));
      });
  })
);

const cancelTicketsSuccess = createAction(CANCEL_TICKETS_SUCCESS);
const cancelTicketsFailed = createAction(CANCEL_TICKETS_FAILED);

export const cancelTickets = (eventId, attendees, refund) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: CANCEL_TICKETS });

    const body = {
      ticket_ids: attendees,
      refund,
    };

    api.del({ path: `management/activity/${eventId}/attendees/cancel`, body })
      .then((res) => {
        resolve(res);
        dispatch(cancelTicketsSuccess());
      })
      .catch((err) => {
        reject(err);
        dispatch(cancelTicketsFailed({ message: err.data }));
      });
  })
);

const moveUpTicketSuccess = createAction(MOVE_UP_TICKET_SUCCESS);
const moveUpTicketFailed = createAction(MOVE_UP_TICKET_FAILED);

export const moveUpTicket = ticketId => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: MOVE_UP_TICKET });

    api.put({ path: `management/ticket/${ticketId}/move/up` })
      .then((res) => {
        resolve(res);
        dispatch(moveUpTicketSuccess());
      })
      .catch((err) => {
        reject(err);
        dispatch(moveUpTicketFailed({ message: err.data }));
      });
  })
);

const moveDownTicketSuccess = createAction(MOVE_DOWN_TICKET_SUCCESS);
const moveDownTicketFailed = createAction(MOVE_DOWN_TICKET_FAILED);

export const moveDownTicket = ticketId => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: MOVE_DOWN_TICKET });

    api.put({ path: `management/ticket/${ticketId}/move/down` })
      .then((res) => {
        resolve(res);
        dispatch(moveDownTicketSuccess());
      })
      .catch((err) => {
        reject(err);
        dispatch(moveDownTicketFailed({ message: err.data }));
      });
  })
);
