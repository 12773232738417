import React from 'react';
import PT from 'prop-types';

import LoadingImage from 'images/24.gif';

import { Container, Image, SmallLoader } from './styled';

const Loading = ({ small }) => (
  <Container>
    {small ?
      <SmallLoader /> :
      <Image src={LoadingImage} alt="loading" />
    }
  </Container>
);

Loading.propTypes = {
  small: PT.bool,
};

export default Loading;
