import createAction from 'services/createAction';
import { showNotification } from 'ducks/notification';

const EDIT_TAGS = 'editTags/EDIT_TAGS';
const EDIT_TAGS_SUCCESS = 'editTags/EDIT_TAGS_SUCCESS';
const EDIT_TAGS_FAILED = 'editTags/EDIT_TAGS_FAILED';

const initialState = {
  error: {
    message: '',
  },
  loading: {
    edit: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case EDIT_TAGS_SUCCESS:
    return {
      ...state,
      loading: { ...state.loading, edit: false },
    };
  case EDIT_TAGS_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, edit: false },
    };
  case EDIT_TAGS:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, edit: true },
    };
  default:
    return state;
  }
};

const editTagsSuccess = createAction(EDIT_TAGS_SUCCESS);
const editTagsFailed = createAction(EDIT_TAGS_FAILED);

export const editTagsFromDriver = (driverId, tags) => (dispatch, getState, api) => {
  dispatch({ type: EDIT_TAGS });

  api.put({ path: `management/user/${driverId}`, body: { tags } })
    .then(() => {
      dispatch(editTagsSuccess());
      dispatch(showNotification('De tags zijn succesvol aangepast', 'green'));
    })
    .catch((err) => {
      dispatch(editTagsFailed({ message: err.data }));
      dispatch(showNotification('Er is iets fout gegaan. Probeer opnieuw.'));
    });
};
