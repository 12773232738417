import React, { Component } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { reduxForm, Field, change, getFormValues, initialize } from 'redux-form';

import * as validation from 'services/validations';
import { createCustomReward, getCustomReward, editCustomReward, resetCustomReward } from 'ducks/customReward';
import { showNotification } from 'ducks/notification';

import { Container, Title, Button, LabelTag } from 'common';
import { Form, Label, FormField, InputText, InputTextarea, InputSelect, InputCheckbox, MultiColumns, SubLabel } from 'common/form';

import { ButtonContainer } from './styled';

const unitTypes = [{
  label: '%',
  value: 'percentage',
}, {
  label: 'euro',
  value: 'currency',
}];

class RewardsEditor extends Component {
  componentDidMount() {
    if (this.editMode) {
      this.props.getCustomReward(this.props.match.params.rewardId)
        .then((res) => {
          this.props.dispatch(initialize('custom-reward', res));
        });
    }
  }

  componentWillUnmount() {
    this.props.resetCustomReward();
  }

  onSubmit = (values) => {
    const { history, create, onShowNotification, edit, match } = this.props;

    const formData = {
      ...values,
      description_claim: !values.is_claimable ? null : values.description_claim,
    };

    if (this.editMode) {
      return edit({ rewardId: match.params.rewardId, formData })
        .then(() => {
          onShowNotification('Je reward is gewijzigd', 'green');
          history.push('/rewards');
        })
        .catch(() => onShowNotification('Er is iets misgegaan bij het aanmaken. Probeer nog eens.', 'red'));
    }

    return create(formData)
      .then(() => {
        onShowNotification('Je reward is aangemaakt', 'green');
        history.push('/rewards');
      })
      .catch(() => onShowNotification('Er is iets misgegaan bij het aanmaken. Probeer nog eens.', 'red'));
  }

  editMode = !!this.props.match.params.rewardId;

  handleBackButton = () => {
    if (this.props.dirty) {
      // eslint-disable-next-line
      const warning = confirm('De wijzigingen zijn niet opgeslagen. Weet je het zeker?');

      if (!warning) return false;
    }

    return this.props.history.push('/rewards');
  }

  render() {
    const { handleSubmit, dispatch, formData, dirty } = this.props;

    return (
      <Container>
        <Title underline>{this.editMode ? 'Edit' : 'Create'} reward</Title>

        <ButtonContainer>
          <Button
            variant="black"
            small
            onClick={this.handleBackButton}
          >
            Back
          </Button>
          {dirty && this.editMode && <LabelTag variant="warning">Unsaved changes</LabelTag>}
        </ButtonContainer>

        <Form onSubmit={handleSubmit(this.onSubmit)}>
          <FormField>
            <Label>Naam</Label>
            <Field
              component={InputText}
              name="name"
              validate={validation.required}
            />
          </FormField>
          <FormField>
            <Label>Titel</Label>
            <Field
              component={InputTextarea}
              name="description"
              validate={validation.required}
            />
          </FormField>
          <FormField>
            <Label>Omschrijving</Label>
            <Field
              component={InputTextarea}
              name="description_long"
              validate={validation.required}
            />
          </FormField>
          <MultiColumns>
            <FormField>
              <Label>Waarde</Label>
              <SubLabel groupWithLabel>100% wordt FREE</SubLabel>
              <Field
                component={InputText}
                name="unit_value"
                validate={validation.required}
              />
            </FormField>
            <FormField>
              <Label>Waarde type</Label>
              <InputSelect
                classNamePrefix="react-select"
                isSearchable={false}
                name="unit_type"
                options={unitTypes}
                value={unitTypes.find(item => item.value === formData.unit_type)}
                onChange={e => dispatch(change('custom-reward', 'unit_type', e.value))}
                className="react-select"
              />
            </FormField>
          </MultiColumns>
          <FormField>
            <Field
              name="is_claimable"
              component={InputCheckbox}
              label="Claimable"
              subLabel="Zorgt ervoor dat de reward slechts één keer geclaimd kan worden. De Christophorus is bijv. niet claimable."
              checked={formData.is_claimable || false}
            />
          </FormField>
          {formData.is_claimable && (
            <FormField>
              <Label>Hoe te claimen</Label>
              <Field
                component={InputTextarea}
                name="description_claim"
              />
            </FormField>
          )}
          <FormField>
            <Field
              name="is_giftable"
              component={InputCheckbox}
              label="Giftable"
              checked={formData.is_giftable || false}
              subLabel="Zorgt ervoor dat de reward uitgedeeld kan worden aan drivers via driver detail"
            />
          </FormField>
          <Button type="submit">Save</Button>
        </Form>
      </Container>
    );
  }
}

RewardsEditor.propTypes = {
  dirty: PT.bool,
  history: PT.object,
  dispatch: PT.func,
  handleSubmit: PT.func,
  onShowNotification: PT.func,
  create: PT.func,
  formData: PT.object,
  match: PT.object,
  getCustomReward: PT.func,
  edit: PT.func,
  resetCustomReward: PT.func,
};

export default compose(
  connect(state => ({
    formData: getFormValues('custom-reward')(state) || {},
  }), {
    create: createCustomReward,
    onShowNotification: showNotification,
    getCustomReward,
    edit: editCustomReward,
    resetCustomReward,
  }),
  withRouter,
  reduxForm({ form: 'custom-reward' }),
)(RewardsEditor);
