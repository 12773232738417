import styled, { css } from 'styled-components';
import PT from 'prop-types';

export const Wrapper = styled.div`
  display: flex;
`;

export const ToggleContainer = styled.button`
  display: inline-block;
  position: relative;
  transition: all 100ms ease-in-out;
  width: 46px;
  height: 24px;
  border-radius: 12px;
  user-select: none;
  background: ${props => props.theme.border};
  border: 0;
  cursor: pointer;

  ${props => props.active && css`
    background: ${props.theme.red};
  `}

  &:focus {
    outline: 0;
  }
`;

ToggleContainer.propTypes = {
  active: PT.bool,
};

export const Slider = styled.span`
  position: absolute;
  border-radius: 50%;
  transition: all 100ms ease-in-out;
  height: 22px;
  width: 22px;
  border-radius: 11px;
  background-color: ${props => props.theme.white};
  top: 1px;
  left: 1px;

  ${props => props.active && css`
    background-color: ${props.theme.white};
    top: 1px;
    transform: translateX(100%);
  `}
`;

Slider.propTypes = {
  active: PT.bool,
};
