import React from 'react';
import { Field } from 'redux-form';

import { InputSearch } from 'common/form';

import { SearchContainer } from './styled';

const SearchDrivers = () => (
  <SearchContainer>
    <Field
      component={InputSearch}
      type="text"
      placeholder="Zoek porsche rijders"
      name="search"
      onBlur={e => e.preventDefault()}
    />
  </SearchContainer>
);

export default SearchDrivers;
