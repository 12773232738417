import styled, { css } from 'styled-components';

import { Button } from 'common';
import Arrow from 'vectors/arrowRight.svg';

export const DropdownButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 5px;

  &:hover > svg > path {
    fill: ${props => props.theme.white};
  }
`;

export const DropdownActions = styled.div`
  display: flex;
  position: relative;
`;

export const DropdownListContainer = styled.div`
  display: flex;
  position: absolute;
`;

export const ActionsList = styled.ul`
  margin: 0;
  padding: 0;
  border: 1px solid ${props => props.theme.border.withBoxShadow};
  box-shadow: ${props => props.theme.boxShadow};
  width: 200px;
  position: relative;
  top: 5px;
`;

export const ActionText = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  width: 100%;
  height: 100%;
  margin: 0;
`;

export const ActionsListItem = styled.li`
  position: relative;
  list-style: none;
  text-align: center;
  border-bottom: 1px solid ${props => props.theme.border};
  background-color: ${props => props.theme.white};
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-size: 12px;
  cursor: pointer;

  &:last-of-type {
    border: 0;
  }

  &:hover {
    & > ${ActionText} {
      background-color: ${props => props.theme.border};
      color: ${props => props.theme.white};

      & svg {
        fill: ${props => props.theme.white};
      }
    }

    & > ${DropdownActions} {
      display: block;

      & > ${ActionsList} {
        top: 0;
      }
    }
  }

  & > ${DropdownActions} {
    display: none;
    position: absolute;
    left: 100%;
    top: 0;
  }

  ${(props) => props.disabled && css`
    color: ${props.theme.gray};
    cursor: auto;

    &:hover > ${ActionText} {
      background-color: ${props.theme.white};
      color: currentColor;
    }
  `}
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SentDate = styled.p`
  margin: 0;
  color: ${props => props.theme.gray};
  letter-spacing: 0.6px;
`;

export const ArrowIcon = styled(Arrow)`
  position: absolute;
  right: 5px;
  width: 12px;
  height: 8px;
  fill: ${props => props.theme.black};
`;
