import createAction from 'services/createAction';
import { showNotification } from 'ducks/notification';

const GET_VALIDATE_MILEAGE = 'validateMileage/GET_COUNT';
const GET_VALIDATE_MILEAGE_SUCCESS = 'validateMileage/GET_COUNT_SUCCESS';
const GET_VALIDATE_MILEAGE_FAILED = 'validateMileage/GET_COUNT_FAILED';

const PUT_MILEAGE = 'validateMileage/PUT_MILEAGE';
const PUT_MILEAGE_SUCCESS = 'validateMileage/PUT_MILEAGE_SUCCESS';
const PUT_MILEAGE_FAILED = 'validateMileage/PUT_MILEAGE_FAILED';

const REJECT_MILEAGE = 'validateMileage/REJECT_MILEAGE';
const REJECT_MILEAGE_SUCCESS = 'validateMileage/REJECT_MILEAGE_SUCCESS';
const REJECT_MILEAGE_FAILED = 'validateMileage/REJECT_MILEAGE_FAILED';

const initialState = {
  data: [],
  count: 0,
  error: {
    message: '',
  },
  loading: {
    getCount: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case GET_VALIDATE_MILEAGE_SUCCESS:
    return {
      ...state,
      ...payload,
      loading: { ...state.loading, getCount: false },
    };
  case GET_VALIDATE_MILEAGE_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, getCount: false },
    };
  case GET_VALIDATE_MILEAGE:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, getCount: true },
    };
  case PUT_MILEAGE_SUCCESS:
    return {
      ...state,
      data: state.data.filter(car => car.id !== payload.id),
      loading: { ...state.loading },
    };
  case PUT_MILEAGE_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading },
    };
  case PUT_MILEAGE:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading },
    };
  case REJECT_MILEAGE_SUCCESS:
    return {
      ...state,
      ...payload,
      loading: { ...state.loading },
    };
  case REJECT_MILEAGE_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading },
    };
  case REJECT_MILEAGE:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading },
    };
  default:
    return state;
  }
};

const getValidateMileageSuccess = createAction(GET_VALIDATE_MILEAGE_SUCCESS);
const getValidateMileageFailed = createAction(GET_VALIDATE_MILEAGE_FAILED);

export const getValidateMileage = () => (dispatch, getState, api) => {
  dispatch({ type: GET_VALIDATE_MILEAGE });

  api.get({ path: 'management/mileage' })
    .then((res) => {
      dispatch(getValidateMileageSuccess({ data: res, count: res.length }));
    })
    .catch((err) => {
      dispatch(getValidateMileageFailed({ message: err.data }));
    });
};

const putMileageSuccess = createAction(PUT_MILEAGE_SUCCESS);
const putMileageFailed = createAction(PUT_MILEAGE_FAILED);

export const putMileage = payload => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: PUT_MILEAGE });

    payload.body.mileage = parseInt(payload.body.mileage, 10);
    api.put({ path: `management/car/${payload.id}/mileage/${payload.mileage_id}/confirm`, body: payload.body })
      .then((res) => {
        resolve(res);
        dispatch(putMileageSuccess(payload.id));
        dispatch(getValidateMileage());
        dispatch(showNotification('De kilometerstand is succesvol gevalideerd.', 'green'));
      })
      .catch((err) => {
        reject(err);
        dispatch(putMileageFailed({ message: err.data }));
        dispatch(showNotification('Er is iets fout gegaan. Probeer opnieuw.'));
      });
  })
);

const rejectMileageSuccess = createAction(REJECT_MILEAGE_SUCCESS);
const rejectMileageFailed = createAction(REJECT_MILEAGE_FAILED);

export const rejectMileage = (carId, mileageId, formData) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: REJECT_MILEAGE });

    const mileage = (typeof mileageId === 'undefined') ? null : mileageId;

    api.put({
      path: `management/car/${carId}/mileage/${mileage}/reject`,
      body: formData,
    })
      .then((res) => {
        resolve(res);
        dispatch(rejectMileageSuccess({ data: res }));
        dispatch(getValidateMileage());
        dispatch(showNotification('De kilometerstand is succesvol geweigerd.', 'green'));
      })
      .catch((err) => {
        reject(err);
        dispatch(rejectMileageFailed({ message: err.data }));
        dispatch(showNotification('Er is iets fout gegaan. Probeer opnieuw.'));
      });
  })
);
