import React, { Fragment } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';

import { Container } from 'common';
import EventDetailOverviewAttendees from '../EventDetailOverviewAttendees';
import EventDetailOverviewTickets from '../EventDetailOverviewTickets';

import { StatsContainer, ContentBlock, Amount, StatsTitle } from './styled';

const EventDetailOverview = ({ event, hasFullAccess }) => (
  <Fragment>
    <Container>
      <StatsContainer>
        <ContentBlock>
          <Amount>{event.attendee}</Amount>
          <StatsTitle>Aanwezig</StatsTitle>
        </ContentBlock>
        <ContentBlock>
          <Amount>{event.interested}</Amount>
          <StatsTitle>Interesse</StatsTitle>
        </ContentBlock>
        <ContentBlock>
          <Amount>{event.invited}</Amount>
          <StatsTitle>Uitgenodigd</StatsTitle>
        </ContentBlock>
        {hasFullAccess && (
          <Fragment>
            <ContentBlock>
              <Amount>{`${event.attendee_percentage}%`}</Amount>
              <StatsTitle>Vol</StatsTitle>
            </ContentBlock>
            <ContentBlock>
              <Amount>{`€ ${event.revenue ? event.revenue : 0}`}</Amount>
              <StatsTitle>Opbrengst</StatsTitle>
            </ContentBlock>
          </Fragment>
        )}
      </StatsContainer>
    </Container>

    <EventDetailOverviewTickets
      event={event}
      tickets={event.ticket || []}
    />

    <EventDetailOverviewAttendees />
  </Fragment>
);

EventDetailOverview.propTypes = {
  event: PT.object,
  hasFullAccess: PT.bool,
};

export default connect(state => ({
  event: state.event.data,
}))(EventDetailOverview);
