import styled from 'styled-components';

import { LabelTag } from 'common';

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledCheckbox = styled.input.attrs({
  type: 'checkbox',
})`
  margin-right: 10px;
`;

export const ExtendedLabelTag = styled(LabelTag)`
  margin-right: 18px;
`;
