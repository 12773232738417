import React from 'react';
import PT from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Container } from 'common';

import Logo from 'images/logo.png';

import { Image, ExtendedAnchor, ExtendedHeaderBar } from './styled';

const EventManagerHeader = ({ history, logout }) => (
  <Container>
    <ExtendedHeaderBar>
      <Image src={Logo} alt="logo" onClick={() => history.push('/')} />
      <ExtendedAnchor onClick={() => logout(history)}>Logout</ExtendedAnchor>
    </ExtendedHeaderBar>
  </Container>
);

EventManagerHeader.propTypes = {
  history: PT.object,
  logout: PT.func,
};

export default withRouter(EventManagerHeader);
