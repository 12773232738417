import React from 'react';
import PT from 'prop-types';

import { ExtendedWrapper, ExtendedContainer, ExtendedTitle, ExtendedSubtitle } from './styled';

const EventPreviewHeader = ({ image, title, subtitle }) => (
  <ExtendedWrapper image={image}>
    <ExtendedContainer>
      {title && <ExtendedTitle bold noWhitespace variant="white">{title}</ExtendedTitle>}
      {subtitle && <ExtendedSubtitle noWhitespace variant="white">{subtitle}</ExtendedSubtitle>}
    </ExtendedContainer>
  </ExtendedWrapper>
);

EventPreviewHeader.propTypes = {
  image: PT.string,
  title: PT.string,
  subtitle: PT.string,
};

export default EventPreviewHeader;
