import React, { Component } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';

import { getEventManagers, editEventManager } from 'ducks/eventManagers';

import { Container, Title } from 'common';
import EditEventManager from './components/EditEventManager';

class EventManagers extends Component {
  componentWillMount() {
    this.props.getEventManagers();
  }

  render() {
    const { eventManagers, onEditEventManager } = this.props;

    return (
      <Container>
        <Title>Beheer event-managers</Title>
        <Container textCenter>
          {eventManagers.map(eventManager => (
            <EditEventManager
              eventManager={eventManager}
              key={eventManager.id}
              edit={onEditEventManager}
            />
          ))}
        </Container>
      </Container>
    );
  }
}

EventManagers.propTypes = {
  eventManagers: PT.array,
  getEventManagers: PT.func,
  onEditEventManager: PT.func,
};

export default connect(state => ({
  eventManagers: state.eventManagers.data,
}), { getEventManagers, onEditEventManager: editEventManager })(EventManagers);
