import React, { Fragment } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import moment from 'moment';
import { Helmet } from 'react-helmet';

import {
  hasRole,
  EVENT_MANAGER,
  PARTNER,
  PROJAXION,
  COMMUNITY_MANAGER,
} from 'services/authHelper';

import { logout } from 'ducks/authentication';

import {
  Header,
  Events,
  EventEditor,
  Drivers,
  ValidateMileage,
  DriverDetail,
  EventManagers,
  EventDetail,
  InviteDrivers,
  BrandPortalTopic,
  BrandPortalPage,
  BrandPortalSubPage,
  Rewards,
  CEM,
  Orders,
  UserCreate,
} from 'modules';

import GeneralModal from './components/GeneralModal';
import EventManagerHeader from './components/EventManagerHeader';

const hideHeaderRoutes = ['/event/edit/', '/invite'];

const hideHeader = location => hideHeaderRoutes.find(route => location.pathname.includes(route));

moment.locale('nl');

const AppRoot = ({
  location, onLogout, roles,
}) => (
  <Fragment>
    <Helmet>
      <title>Porsche24 | Dealer dashboard</title>
    </Helmet>

    {(!hasRole(roles, [EVENT_MANAGER, PARTNER, PROJAXION]) && !hideHeader(location)) && (
      <Header />
    )}

    {(hasRole(roles, [EVENT_MANAGER, PARTNER, PROJAXION]) && !hideHeader(location)) && (
      <EventManagerHeader logout={onLogout} />
    )}

    <Switch>
      {(hasRole(roles, [PROJAXION]) && location.pathname !== '/orders') && (
        <Redirect to="/orders" />
      )}
      {!hasRole(roles, [PROJAXION]) && <Redirect exact from="/" to="/events" />}
      <Route path="/events" component={Events} />
      <Route path="/validate-mileage" component={ValidateMileage} />
      <Route path="/event/edit/:id?" component={EventEditor} />
      <Route path="/event/:eventId/invite/:ticketId?" component={InviteDrivers} />
      <Route path="/event/:id" component={EventDetail} />
      <Route path="/drivers" component={Drivers} />
      <Route path="/driver/:id" component={DriverDetail} />
      <Route path="/rewards" component={Rewards} />
      <Route path="/event-managers" component={EventManagers} />
      <Route path="/brand-portal/:topic?" exact component={BrandPortalTopic} />
      <Route path="/brand-portal/:topic/:page" exact component={BrandPortalPage} />
      <Route path="/brand-portal/:topic/:page/:subPage" exact component={BrandPortalSubPage} />
      <Route path="/cem" component={CEM} />
      <Route path="/orders" component={Orders} />
      {hasRole(roles, [COMMUNITY_MANAGER]) && <Route path="/user/create" component={UserCreate} />}
    </Switch>
    <GeneralModal />
  </Fragment>
);

AppRoot.propTypes = {
  location: PT.object,
  roles: PT.array,
  onLogout: PT.func,
};

export default compose(
  withRouter,
  connect(state => ({
    roles: state.authentication.roles,
  }), { onLogout: logout }),
)(AppRoot);
