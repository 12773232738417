import styled, { css } from 'styled-components';
import PT from 'prop-types';

import Arrow from 'vectors/arrowDown-red.svg';

export const Accordion = styled.div`
  margin: 35px 0;
  display: block;
`;

export const ToggleButton = styled.button`
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 0;
  border: none;
  border-bottom: 1px solid ${props => props.theme.border}
`;

export const Title = styled.h3`
  font-size: 28px;
  line-height: 100%;
  font-weight: 400;
  margin: 0;
`;

export const Checklist = styled.span`
  font-size: 28px;
  font-weight: 700;
  margin-right: 20px;
  color: ${props => props.theme.red};
`;

export const ToggleArrow = styled(Arrow)`
  height: 8px;
  width: 12px;
  color: ${props => props.theme.red};

  ${props => props.direction === 'down' && css`
    transform: rotate(180deg);
  `}
`;

ToggleArrow.propTypes = {
  direction: PT.string,
};

export const Content = styled.div`
  padding: 40px 0 20px;
`;
