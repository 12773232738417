import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import PT from 'prop-types';

import InputError from '../InputError';

export const StyledInput = styled.input`
    display: block;
    width: 100%;
    font-size: 16px;
    color: ${props => props.theme.black};
    border: solid 1px ${props => props.theme.border};
    padding: 7px 20px;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &::placeholder {
      color: ${props => props.theme.grey};
    }

    ::-ms-clear {
      display: none;
    }

    ${props => (props.meta.error && props.meta.submitFailed) && css`
      border: 1px solid ${props.theme.red};
    `}
`;

StyledInput.propTypes = {
  meta: PT.object,
  submitFailed: PT.bool,
};

const Input = ({
  input, meta, ...otherProps
}) => (
  <Fragment>
    <StyledInput {...input} {...otherProps} meta={meta} />
    {!otherProps.noErrorText && <InputError meta={meta} />}
  </Fragment>
);

Input.defaultProps = {
  meta: {
    error: undefined,
    submitFailed: false,
  },
};

Input.propTypes = {
  input: PT.object,
  meta: PT.object,
};

export default Input;
