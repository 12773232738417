import createAction from 'services/createAction';

const GET_ORDERS = 'orders/GET';
const GET_ORDERS_SUCCESS = 'orders/GET_SUCCESS';
const GET_ORDERS_FAILED = 'orders/GET_FAILED';

const RESEND_ORDER = 'orders/RESEND';
const RESEND_ORDER_SUCCESS = 'orders/RESEND_SUCCESS';
const RESEND_ORDERS_FAILED = 'orders/RESEND_FAILED';

const initialState = {
  data: {
    bookings: [],
    count: 0,
  },
  error: {
    message: '',
  },
  loading: {
    get: false,
    resend: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case GET_ORDERS:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, get: true },
    };
  case GET_ORDERS_SUCCESS:
    return {
      ...state,
      data: payload,
      loading: { ...state.loading, get: false },
    };
  case GET_ORDERS_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, get: false },
    };
  case RESEND_ORDER:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, resend: true },
    };
  case RESEND_ORDER_SUCCESS:
    return {
      ...state,
      erro: { message: '' },
      loading: { ...state.loading, resend: false },
    };
  case RESEND_ORDERS_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, resend: false },
    };
  default:
    return state;
  }
};

const resendOrderSuccess = createAction(RESEND_ORDER_SUCCESS);
const resendOrderFailed = createAction(RESEND_ORDERS_FAILED);

export const resendOrder = payload => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: RESEND_ORDER });

    api.post({
      path: 'management/projaxion/send',
      withAuth: true,
      body: payload,
    })
      .then((res) => {
        dispatch(resendOrderSuccess(res));
        resolve(res);
      })
      .catch((err) => {
        reject(err);
        dispatch(resendOrderFailed(err));
      });
  })
);

const getOrdersSuccess = createAction(GET_ORDERS_SUCCESS);
const getOrdersFailed = createAction(GET_ORDERS_FAILED);

export const getOrders = () => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: GET_ORDERS });

    api.get({ path: 'management/projaxion/list', withAuth: true })
      .then((res) => {
        dispatch(getOrdersSuccess(res));
        resolve(res);
      })
      .catch((err) => {
        reject(err);
        dispatch(getOrdersFailed({ message: err.data }));
      });
  })
);
