import styled, { css } from 'styled-components';
import PT from 'prop-types';

const Subtitle = styled.h2`
  color: ${props => props.theme[props.variant]};
  font-size: 24px;
  font-weight: 400;

  ${props => props.small && css`
    font-size: 16px;
  `}

  ${props => props.bold && css`
    font-weight: 700;
  `}

  ${props => props.noWhitespace && css`
    margin: 0;
  `}
`;

Subtitle.defaultProps = {
  variant: 'black',
};

Subtitle.propTypes = {
  small: PT.bool,
  bold: PT.bool,
  noWhitespace: PT.bool,
};

export default Subtitle;
