import React from 'react';
import PT from 'prop-types';
import { baseUrlBrandPortal } from 'config/environment';

import Block from '../Block';
import Text from '../Text';

import { Content, Images, Image } from './styled';

const ContentBlock = ({ data }) => (
  <Block>
    <Content>
      {data.value.images.length > 0 && (
        <Images>
          {data.value.images.map(image => (
            <Image
              key={image.id}
              src={`${baseUrlBrandPortal()}${image.value.large.src}`}
              alt={image.value.large.alt}
            />
          ))}
        </Images>
      )}
      <Text html={data.value.body} />
    </Content>
  </Block>
);

ContentBlock.propTypes = {
  data: PT.object,
};

export default ContentBlock;
