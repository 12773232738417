import React, { Component } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';

import { getTags } from 'ducks/tags';
import { editTagsFromDriver } from 'ducks/driver/editTags';

import { Container } from 'common';
import { Label, SubLabel, InputSelectMultipleTags } from 'common/form';

class DriverTabsTags extends Component {
  state = {
    formattedTags: this.props.tags.map(tag => ({ value: tag, label: tag })),
    tagsSet: this.props.tags.length !== 0,
  }

  componentWillMount() {
    if (this.props.tags.length === 0) {
      this.props.getTags().then((res) => {
        const formattedTags = res.map(tag => ({ value: tag, label: tag }));

        this.setState({ formattedTags, tagsSet: true });
      });
    }
  }

  handleOnChange = (selectedTags) => {
    const reformattedTags = selectedTags.map(tag => tag.value);
    this.props.editTagsFromDriver(this.props.driver.id, reformattedTags);
  }

  render() {
    const { driver } = this.props;

    return (
      <Container>
        <Label>Voeg persoonlijke tags toe</Label>
        <SubLabel groupWithLabel>Tags worden gebruikt om Porsche rijders te zoeken &amp; filteren, bijv. &quot;racing&quot; of &quot;kids&quot;.</SubLabel>

        {Object.keys(driver).length !== 0 && this.state.tagsSet &&
          <InputSelectMultipleTags
            options={this.state.formattedTags}
            defaultValue={this.state.formattedTags.filter(tag => driver.tag.includes(tag.value))}
            onChange={selected => this.handleOnChange(selected)}
          />
        }
      </Container>
    );
  }
}

DriverTabsTags.propTypes = {
  tags: PT.array,
  getTags: PT.func,
  editTagsFromDriver: PT.func,
  driver: PT.object,
};

export default connect(state => ({
  tags: state.tags.data,
  driver: state.driver.data,
}), { getTags, editTagsFromDriver })(DriverTabsTags);
