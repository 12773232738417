import createAction from 'services/createAction';

const GET_ACCOUNT_STATUS = 'accountStatus/GET';
const GET_ACCOUNT_STATUS_SUCCESS = 'accountStatus/GET_SUCCESS';
const GET_ACCOUNT_STATUS_FAILED = 'accountStatus/GET_FAILED';

const initialState = {
  data: [],
  error: {
    message: '',
  },
  loading: {
    accountStatus: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case GET_ACCOUNT_STATUS_SUCCESS:
    return {
      ...state,
      data: payload,
      loading: { ...state.loading, accountStatus: false },
    };
  case GET_ACCOUNT_STATUS_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, accountStatus: false },
    };
  case GET_ACCOUNT_STATUS:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, accountStatus: true },
    };
  default:
    return state;
  }
};

const getAccountStatusSuccess = createAction(GET_ACCOUNT_STATUS_SUCCESS);
const getAccountStatusFailed = createAction(GET_ACCOUNT_STATUS_FAILED);

export const getAccountStatus = () => (dispatch, getState, api) => {
  dispatch({ type: GET_ACCOUNT_STATUS });

  api.get({ path: 'management/user/status' })
    .then((res) => {
      dispatch(getAccountStatusSuccess(res));
    })
    .catch((err) => {
      dispatch(getAccountStatusFailed({ message: err.data }));
    });
};
