import React, { Fragment } from 'react';
import PT from 'prop-types';

import { InputRadio, InputCheckbox } from 'common/form';
import { Label } from '../SidebarFilters/styled';
import { ListContainer } from '../../styled';

import { CheckboxDropdown, SubListContainer, ArrowIcon } from './styled';

const ModelGroupFilter = ({
  filterValues, handleRadioOnClick, carModels, toggleModelGroupDropdown,
  handleCheckboxOnClick, modelGroupsFilter,
}) => (
  <Fragment>
    <ListContainer active>
      <InputRadio
        id="model_all"
        name="classics"
        value="all"
        checked={filterValues.classic === '' || filterValues.classic === 'all'}
        onChange={e => handleRadioOnClick(e, 'classic')}
      >
        <Label htmlFor="model_all">Toon alles</Label>
      </InputRadio>

      <InputRadio
        id="model_classics"
        name="classics"
        value="true"
        checked={filterValues.classic === 'true'}
        onChange={e => handleRadioOnClick(e, 'classic')}
      >
        <Label htmlFor="model_classics">Toon classics</Label>
      </InputRadio>

      <InputRadio
        id="model_modern"
        name="classics"
        value="false"
        checked={filterValues.classic === 'false'}
        onChange={e => handleRadioOnClick(e, 'classic')}
      >
        <Label htmlFor="model_modern">Toon modern</Label>
      </InputRadio>
    </ListContainer>

    {Object.keys(carModels).map(modelGroup => (
      <Fragment key={modelGroup}>
        <CheckboxDropdown onClick={() => toggleModelGroupDropdown(modelGroup)}>
          <InputCheckbox
            key={modelGroup}
            id={modelGroup}
            onClick={e => e.stopPropagation()}
            onChange={e => handleCheckboxOnClick(e, 'model_groups', modelGroup)}
            checked={!!filterValues.model_groups && filterValues.model_groups.includes(modelGroup)}
          >
            <Label active={modelGroupsFilter[modelGroup]}>{modelGroup} <ArrowIcon /></Label>
          </InputCheckbox>
        </CheckboxDropdown>

        <SubListContainer active={modelGroupsFilter[modelGroup]}>
          {carModels[modelGroup].map(carModel => (
            <InputCheckbox
              key={carModel}
              id={carModel}
              onChange={e => handleCheckboxOnClick(e, 'model', carModel)}
              checked={!!filterValues.model && filterValues.model.includes(carModel)}
            >
              <Label htmlFor={carModel}>{carModel}</Label>
            </InputCheckbox>
          ))}
        </SubListContainer>
      </Fragment>
    ))}
  </Fragment>
);

ModelGroupFilter.propTypes = {
  filterValues: PT.object,
  handleRadioOnClick: PT.func,
  carModels: PT.object,
  toggleModelGroupDropdown: PT.func,
  handleCheckboxOnClick: PT.func,
  modelGroupsFilter: PT.object,
};

export default ModelGroupFilter;
