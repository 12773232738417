import styled from 'styled-components';

import InputText from '../form/InputText';

export const ExtendedInputText = styled(InputText)`
  width: auto;
  display: inline-block;
  height: 37px;
`;


export const Icon = styled.span`
  display: inline-block;

  svg {
    width: 13px;
    height: 13px;
    fill: white;
  }
`;

export const CameraIconContainer = styled.span`
  margin: 0 9px 0 0;

  svg {
    vertical-align: text-top;
    width: 13px;
    height: 13px;
  }
`;
