import React, { Component, Fragment } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import qs from 'qs';

import { passwordReset } from 'ducks/authentication';
import { showNotification } from 'ducks/notification';

import * as validation from 'services/validations';

import { Title, Button, Container } from 'common';
import { Label, InputText, FormField, SubLabel } from 'common/form';

class PasswordReset extends Component {
  state = {
    passwordChangeSuccess: false,
  };

  checkValues = (values) => {
    if (values.newPassword === values.passwordConfirm) {
      const data = {
        activation_code: qs.parse(this.props.history.location.search, { ignoreQueryPrefix: true }).code,
        password: values.newPassword,
      };

      this.props.onPasswordReset(data)
        .then(() => {
          this.setState({
            passwordChangeSuccess: true,
          });
        });
    } else {
      this.props.onShowNotification('De wachtwoorden komen niet overheen. Probeer opnieuw.');
    }
  };

  render() {
    const { handleSubmit } = this.props;

    return (
      <Container small alignCenter>
        {!this.state.passwordChangeSuccess ? (
          <Fragment>
            <Title>Stel uw wachtwoord in</Title>
            <form
              onSubmit={handleSubmit(values => this.checkValues(values))}
              noValidate
            >
              <FormField>
                <Label uppercase>Nieuw wachtwoord</Label>
                <Field
                  name="newPassword"
                  component={InputText}
                  type="password"
                  validate={validation.required}
                />
              </FormField>
              <FormField>
                <Label uppercase>Herhaal wachtwoord</Label>
                <Field
                  name="passwordConfirm"
                  component={InputText}
                  type="password"
                  validate={validation.required}
                />
              </FormField>
              <FormField>
                <SubLabel>
                  Uw wachtwoord moet minstens 8 tekens, 1 hoofdletter, 1 kleine letter, 1 nummer en 1 speciaal teken bevatten.
                </SubLabel>
              </FormField>

              <FormField>
                <Button type="submit" stretch>Wachtwoord instellen</Button>
              </FormField>
            </form>
          </Fragment>
        ) : (
          <Fragment>
            <Title>Wachtwoord gewijzigd</Title>
            <p>Uw wachtwoord is succesvol aangepast. Log nu in met uw nieuwe wachtwoord op de Porsche24 app.</p>
            <Button onClick={() => { window.location.href = 'https://porsche24.porsche.nl/redirect/events'; }}>Open de Porsche24 app</Button>
          </Fragment>
        )}
      </Container>
    );
  }
}

PasswordReset.propTypes = {
  handleSubmit: PT.func,
  onPasswordReset: PT.func,
  onShowNotification: PT.func,
  history: PT.object,
};

export default compose(
  withRouter,
  connect(null, { onShowNotification: showNotification, onPasswordReset: passwordReset }),
  reduxForm({ form: 'password-reset' }),
)(PasswordReset);
