import React from 'react';
import PT from 'prop-types';

import Label from 'common/form/Label';
import InputSelect from 'common/form/InputSelect';

import { LimitContainer } from './styled';

const options = [
  { label: '10', value: '10' },
  { label: '25', value: '25' },
  { label: '50', value: '50' },
  { label: '100', value: '100' },
  { label: '250', value: '250' },
];

const FiltersHeaderLimit = ({ onChange, selectedValue }) => (
  <LimitContainer>
    <Label noWhitespace>Limiet</Label>
    <InputSelect
      classNamePrefix="react-select"
      value={options.find(amount => amount.value === selectedValue.toString())}
      isSearchable
      name="limit"
      options={options}
      onChange={onChange}
    />
  </LimitContainer>
);

FiltersHeaderLimit.propTypes = {
  onChange: PT.func,
  selectedValue: PT.string,
};

export default FiltersHeaderLimit;
