import React, { Fragment } from 'react';
import { HeaderPlain, Title, ContentContainer } from 'common';

const PrivacyPolicy = () => (
  <Fragment>
    <HeaderPlain />
    <ContentContainer noBullets>
      <Title>Gebruiksvoorwaarden</Title>

      <section>
        <h2>1. Algemeen</h2>
        <p>1.1 Deze Algemene Voorwaarden, alsmede het beleid en de richtlijnen (inclusief de Porsche24 Privacyverklaring) (hierna gezamenlijk aangeduid als de ‘Algemene Voorwaarden’) zijn van toepassing op de toegang tot en het gebruik van de App (zoals hierna gedefinieerd) van Pon’s Automobielhandel B.V. (hierna: ‘Porsche’). De tekst van deze Privacyverklaring is gepubliceerd op <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">https://porsche24.porsche.nl/privacy-policy</a>, vanwaar hij kan worden gedownload, gelezen, opgeslagen en afgedrukt.<br />
          1.2. Uitsluitend deze algemene voorwaarden zijn van toepassing op al onze aanbiedingen en Overeenkomsten, ongeacht een eventuele (eerdere) verwijzing van Klant (zoals hierna gedefinieerd) naar zijn eigen of andere algemene voorwaarden. Wij wijzen uitdrukkelijk de eventueel door Klant van toepassing verklaarde algemene voorwaarden van de hand.
        </p>
      </section>

      <section>
        <h2>2. Definities</h2>
        <ul>
          <li>- App: de Porsche24 softwareapplicatie, die het eigendom is van Porsche en waartoe Gebruikers toegang kunnen krijgen via het internet of op andere door Porsche aan te geven manieren;</li>
          <li>- Diensten: de door Porsche te leveren diensten in verband met de Producten, waaronder (maar niet beperkt tot) diensten waarmee de Klant via de App tickets kan kopen of punten kan sparen;</li>
          <li>-
            Vertrouwelijke Informatie: de App, documentatie, software, de daarin verwerkte bedrijfsgeheimen en alle overige schriftelijke of digitale informatie die (i) is aangeduid als zijnde vertrouwelijk en/of eigendom van Porsche, of die vergezeld gaat van een schriftelijke kennisgeving dat de informatie vertrouwelijk en/of eigendom van Porsche is, of (ii) niet is aangeduid als of vergezeld gaat van een schriftelijke kennisgeving dat de informatie vertrouwelijk en/of eigendom van Porsche is, maar waarvan in redelijkheid kan worden voorzien dat bekendmaking daarvan aan derden de concurrentiepositie van de eigenaar van die informatie kan schaden. Tot Vertrouwelijke Informatie wordt niet gerekend informatie waarvan de ontvangende partij kan aantonen dat (i) deze reeds algemeen beschikbaar was, (ii) hij deze rechtmatig heeft verkregen van derden zonder beperkingen ten aanzien van openbaarmaking, of (iii) hij deze op onafhankelijke wijze heeft ontwikkeld zonder een beroep te doen op of gebruik te maken van Vertrouwelijke Informatie;
          </li>
          <li>- Klant: de uiteindelijke klant die gebruik maakt van Porsche24, zoals bijvoorbeeld, maar niet beperkt tot, dealerorganisaties en de door klant geautoriseerde medewerkers die gebruik maken van de mobiele applicatie;</li>
          <li>- Overmacht: Gevallen of omstandigheden, of een combinatie daarvan, die niet zijn toe te rekenen aan Porsche, waaronder maar niet beperkt tot het niet functioneren van het internet of andere telecommunicatievoorzieningen, niet-nakoming door derden van wie Porsche afhankelijk is met betrekking tot de App, de gebrekkige staat van zaken, apparatuur, software, en/of overheidsmaatregelen;</li>
          <li>- Gebruiker: De Klant en/of andere Gebruikers die van de Klant toestemming hebben gekregen voor het gebruik van de App; </li>
          <li>- Gebruikersaccount: Een account die een Gebruiker in staat stelt zijn persoonlijke account te openen en te gebruiken via de App, en die wordt aangemaakt met behulp van een gebruikersnaam en activeringscode of een complete registratie (NAW-gegevens, extra contactgegevens, kenteken van de Porsche);</li>
          <li>- Contributie: Het bedrag dat moet worden betaald voor het lidmaatschap van de levels Red en Yellow van het Porsche24 programma;</li>
          <li>- Gevalideerde Kilometers: Het aantal door het Voertuig afgelegde kilometers zoals ingevoerd, geregistreerd en geaccordeerd door de dealer in de App;</li>
          <li>- Portal: De tool die toegang verschaft tot Gegevens die door u zijn ingevoerd in de App, zoals bijvoorbeeld, maar niet beperkt tot, contactgegevens, gevalideerde kilometers, gekochte tickets of bezochte events.</li>
        </ul>
      </section>

      <section>
        <h2>3. Aanbiedingen en totstandkoming Overeenkomsten</h2>
        <p>
          3.1. Al onze aanbiedingen onder andere wat betreft prijzen, kortingen en tijdelijke beloningen zijn geheel vrijblijvend, indicatief en niet bindend, tenzij uitdrukkelijk schriftelijk het tegendeel is bepaald.<br />
          3.2. Een Overeenkomst komt tot stand op het moment dat een order door ons schriftelijk of langs elektronische weg is geaccepteerd. Wij zijn tevens gerechtigd genoegen te nemen met een mondelinge Overeenkomst, indien schriftelijke of elektronische vastlegging is uitgebleven.<br />
          3.3. Aanvullingen op en/of wijzigingen van tot stand gekomen Overeenkomsten, waaronder annuleringen van gesloten Overeenkomsten, gelden slechts indien en voor zover zij door beide partijen schriftelijk zijn vastgelegd.<br />
          3.4. Alle Overeenkomsten, wijzigingen daaronder begrepen, worden aangegaan onder de opschortende voorwaarde van goedkeuring door onze directie. De Overeenkomst wordt geacht te zijn aangegaan binnen twee werkdagen na ondertekening van de Overeenkomst c.q. orderbevestiging, indien de directie binnen die periode niet heeft laten blijken de order niet te accepteren.
        </p>
      </section>

      <section>
        <h2>4. Prijzen</h2>
        <p>
          4.1. Tenzij uitdrukkelijk anders vermeld, gelden onze verkoopprijzen voor de Producten levering af bedrijf. Tenzij uitdrukkelijk anders vermeld, zijn montage-, service- en transportkosten en eventuele fiscale heffingen anders dan BTW niet in onze prijs begrepen.<br />
          4.2. Voor de levering van de Diensten is de Klant een separate gebruiksrechtvergoeding aan ons verschuldigd, zoals nader omschreven in de Overeenkomst c.q. orderbevestiging.<br />
          4.3. Ook nadat de Overeenkomst tot stand is gekomen kunnen wijzigingen in rechten, belastingen en accijnzen aan de Klant worden doorberekend.<br />
          4.4. Naast de in lid 3 van dit artikel zijn onze prijzen onderhevig aan prijsindexering en mogen genoemde prijswijzigingen tevens prijsverhogingen aan de Klant worden doorberekend. Prijs- en/of tariefwijzigingen worden door ons tijdig schriftelijk aan Klant gecommuniceerd. Indien een prijsverhoging, zoals bedoeld in dit artikellid, binnen drie maanden na het sluiten van de Overeenkomst plaatsvindt, heeft de Klant het recht om binnen één week na de kennisgeving van de prijswijziging de Overeenkomst te ontbinden.<br />
          4.5. Indien de Klant gebruik maakt van zijn recht tot ontbinding zoals bedoeld in lid 4 van dit artikel, dient hij aan ons een vergoeding te betalen van de gemaakte kosten, die zijn vastgesteld op 1% van het totale bedrag (inclusief BTW) dat Klant aan ons verschuldigd was geweest uit hoofde van de Overeenkomst indien deze niet zou zijn ontbonden.<br />
          4.6. Een (algemene) prijsverlaging, die wordt doorgevoerd na het aangaan van de Overeenkomst, maar vóór de feitelijke levering, geeft de Klant geen aanspraak op zodanige prijsverlaging.
        </p>
      </section>

      <section>
        <h2>5. Intellectuele Eigendom en Gebruiksrechten</h2>
        <p>
          5.1. Alle huidige en toekomstige intellectuele eigendomsrechten, waaronder (maar niet beperkt tot) de auteursrechten en databankrechten, op de App, alsmede daarbij behorende of onderliggende Producten, Software, documentatie en/of werkdocumenten, berusten uitsluitend bij ons. Voor zover nodig, zal Klant op eerste verzoek van ons alle handelingen verrichten die nodig zijn voor de overdracht van intellectuele eigendomsrechten aan ons op de daartoe bij wet voorgeschreven wijze. De Overeenkomst zal geen enkele overdracht van intellectuele eigendomsrechten door ons aan de Klant tot gevolg hebben. <br />
          5.2. Wij verlenen de Klant en Gebruiker met inachtneming van deze Algemene Voorwaarden een niet-exclusief, niet-overdraagbaar gebruiksrecht, zonder het recht om sub licenties te verstrekken op het gebruik van de app voor de duur van de Overeenkomst.<br />
          5.3. De Klant verkrijgt een beperkt gebruiksrecht met betrekking tot de Diensten voor het in de Overeenkomst gespecificeerde aantal voertuigen. Indien er op enig moment sprake is van een wijziging of toename bij de Klant met betrekking tot het aantal voertuigen, dient zij ons hiervan op de hoogte te stellen.<br />
          5.4. Het is de Klant uitdrukkelijk niet toegestaan om in het kader van de Overeenkomst verkregen informatie, data en overige materialen te verveelvoudigen of openbaar te maken zonder onze voorafgaande schriftelijke toestemming. Klant is niet gerechtigd enige aanduiding omtrent of verwijzing naar intellectuele eigendomsrechten, waaronder maar niet beperkt tot auteursrechten, merken of handelsnamen van, uit of met betrekking tot de Producten en/of Diensten te verwijderen, te wijzigen of toe te voegen, daaronder begrepen aanduidingen omtrent het vertrouwelijke karakter en geheimhouding.<br />
          5.5. Het is Klant niet toegestaan enig merk of ontwerp of enige domeinnaam van ons of een daarmee overeenstemmende naam of teken te gebruiken of te registreren in enig land.<br />
          5.6. Wij zijn bevoegd technische voorzieningen te treffen en ter instandhouding en ter bescherming van de (intellectueel eigendomsrechten op) de Producten en/of Diensten, alsmede met het oog op de overeengekomen beperkingen in het gebruik van de Producten en/of Diensten. Het is Klant niet toegestaan dergelijke technische voorzieningen te omzeilen of te verwijderen.<br />
          5.7. Niets in deze Algemene Voorwaarden legt aan Porsche de verplichting op om kopieën van computerprogramma’s of codes met betrekking tot de App in de vorm van een objectcode of broncode beschikbaar te stellen.<br />
          5.8. De App en daarmee verband houdende documentatie, specificaties en eigenschappen kunnen te allen tijde zonder voorafgaande kennisgeving worden gewijzigd.<br />
          5.9 Porsche behoudt zich het recht voor om naar haar eigen redelijke inzicht de toegang tot en het gebruik van de App door een Gebruiker op te schorten (a) gedurende een door haarzelf in redelijkheid vast te stellen periode om de App bij te werken en te onderhouden, (b) gedurende een periode dat de App niet beschikbaar is als gevolg van omstandigheden die in redelijkheid buiten de macht van Porsche liggen, zoals overmacht, overheidsmaatregelen, terroristische activiteiten of sociale onlusten, of technische storingen waarover Porsche in redelijkheid geen controle heeft (zoals maar niet beperkt tot het geen toegang kunnen krijgen tot het internet), of handelingen van derden, zoals maar niet beperkt tot, ‘distributed denial of internet’- aanvallen, of (c) wanneer Porsche vermoedt of vaststelt dat een Gebruikersaccount kwaadaardige software bevat of dat een Gebruiker de App gebruikt voor kwaadaardige doeleinden, onverminderd de overige bepalingen van deze Algemene Voorwaarden ten aanzien van opschorting van de toegang tot of het gebruik van de App.
        </p>
      </section>

      <section>
        <h2>6. Klant verplichtingen en voorschriften</h2>
        <p>
          6.1. De Klant draagt er zorg voor dat alle gegevens, materialen, informatie, (rand)apparatuur en/of ruimtes, waarvan wij aangeven dat deze noodzakelijk zijn of waarvan de Klant redelijkerwijs behoort te begrijpen dat deze noodzakelijk zijn voor het uitvoeren van de Overeenkomst, tijdig en op bruikbare wijze aan ons ter beschikking worden gesteld. Indien de Klant informatie of materialen aan ons ter beschikking stelt, zullen deze voldoen aan de door ons voorgeschreven specificaties.<br />
          6.2. De Klant zal de door haar aangeleverde gegevens, alsmede de door ons verzamelde gegevens die aan haar beschikbaar kunnen worden gesteld, regelmatig op juistheid controleren.<br />
          6.3. De Klant erkent dat alle rechten op, de eigendom van en het belang in de App, software, afbeeldingen, gebruikersinterface, audioclips, videoclips, redactionele inhoud, documenten en/of materialen verband houdend met App en alle aanpassingen en verbeteringen daarvan, inclusief maar niet beperkt tot de intellectuele-eigendomsrechten, berusten bij Porsche, haar dochtermaatschappijen en/of haar licentiegevers en dat deze rechten worden beschermd door de internationale wetgeving inzake het intellectuele-eigendomsrecht.<br />
          6.4. Het is Gebruiker niet toegestaan:
        </p>
        <ul>
          <li>(i) de App te kopiëren, te reproduceren, te wijzigen of aan te passen of daarvan afgeleide werken te maken; </li>
          <li>(ii) de App in sub licentie te geven, te verkopen, door te verkopen, te verhuren, te leasen, te distribueren, over te dragen, te delen bij wijze van timesharing, te cederen, of de App te gebruiken als basis voor het ontwikkelen van een concurrerende oplossing (of voor dat doeleinde een overeenkomst af te sluiten met een derde); of </li>
          <li>(iii) de op of in de App aanwezige logo’s, handelsmerk-, octrooi- of auteursrechtaanduidingen en vertrouwelijkheids- en eigendomsverklaringen en andere kennisgevingen en markeringen te verwijderen. Gebruiker is verplicht bij het gebruik van de App alle wet- en regelgeving na te leven. </li>
        </ul>
        <p>6.5. Het is Gebruiker niet toegestaan:</p>
        <ul>
          <li>(i) zijn recht op toegang tot en/of gebruik van de App in sub licentie te geven; </li>
          <li>(ii) onbevoegden of derden toe te staan de App te openen en/of te gebruiken; </li>
          <li>(iii) de App te gebruiken voor het verlenen van diensten aan derden; </li>
          <li>(iv) de inhoud of ander materiaal aanwezig in de App opnieuw te publiceren of te verspreiden; </li>
          <li>(v) wijzigingen in de App aan te brengen. </li>
        </ul>
        <p>6.6. Het is Gebruiker niet toegestaan derden toestemming te verlenen of aan te zetten om:</p>
        <ul>
          <li>(i) de App te gebruiken om onwettige, lasterlijke, kwetsende, beledigende, frauduleuze of obscene content, of content die virussen bevat of anderszins laakbaar is, zulks ter redelijke beoordeling van Porsche, te uploaden, te verzenden of anderszins te verspreiden; of </li>
          <li>(ii) een robot, spin of ander geautomatiseerd systeem of handmatig proces te gebruiken om de inhoud van de App te monitoren of kopiëren.</li>
        </ul>
        <p>
          6.7. Klant draagt zorg en staat in voor het correct functioneren van haar eigen apparatuur die wordt gebruikt in samenhang met de Producten en/of Diensten, in overeenstemming met onze minimale systeemeisen, configuratie, randapparatuur en internetverbinding benodigd voor het gebruik van de Producten en/of Diensten en het treffen van de nodige maatregelen om zijn apparatuur, software en telecommunicatie- en internetverbindingen en/of dat van haar gebruikers te beschermen tegen virussen, computercriminaliteit en onrechtmatig gebruik door derden.<br />
          6.8. De Klant draagt zorg voor de noodzakelijke hard- en software, randapparatuur en verbindingen om het gebruik van de Diensten mogelijk te maken, tenzij expliciet en schriftelijk anders is overeengekomen.<br />
          6.9. De Klant stelt ons zo spoedig mogelijk schriftelijk in kennis van wijzigingen in relevante klantgegevens.<br />
          6.10. Een fair use policy is van toepassing op de door ons geleverde Diensten. Dit houdt in dat het de Klant slechts is toegestaan redelijk gebruik te maken van de Diensten. Van ‘unfair use’ is in ieder geval sprake bij: (i) gebruik voor doeleinden anders dan neergelegd in de Overeenkomst; (ii) gebruik boven een bepaalde redelijke hoeveelheid, welke hoeveelheid is bepaald aan de hand van gemiddeld gebruik van andere, vergelijkbare gebruikers; (iii) continue of bijna continue gebruik, door langdurig of veelvuldig openstaande verbindingen.<br />
          6.11. In geval van ‘unfair use’ kunnen wij nadere voorwaarden stellen aan het gebruik, extra kosten in rekening brengen en/of de Overeenkomst opschorten of beëindigen. Wij zullen slechts overgaan tot het in rekening brengen van extra kosten, nadat wij de Klant vooraf hebben geïnformeerd dat het gebruik als ‘unfair’ wordt aangemerkt. Wij zullen de Klant een redelijke termijn geven om het gebruik te reduceren. Indien het ‘unfair use’ zich desondanks voortzet, behouden wij ons het recht voor de Overeenkomst op te schorten of te beëindigen. Wij zijn niet aansprakelijk voor eventuele schade die hieruit voortvloeit.
        </p>
      </section>

      <section>
        <h2>7. Gebruikersaccount en verplichtingen</h2>
        <p>
          7.1. De Gebruiker dient de App te downloaden van een appwinkel (Google Store voor Android, iTunes voor iOS) en te installeren op zijn mobiele telefoon.<br />
          7.2. Tijdens het registreren voor de App is de Gebruiker verplicht een Gebruikersaccount aan te maken. In dat verband moet de Gebruiker persoonsgegevens verstrekken, zoals (maar niet beperkt tot) zijn naam, een gebruikersnaam (alias of schermnaam), volledige NAW gegevens, een geldig e-mailadres en telefoonnummer.<br />
          7.3. Als een Gebruiker de App op verschillende apparaten installeert (bijv. meerdere telefoons), moet de App op elk apparaat afzonderlijk worden geactiveerd. Kennisgevingen worden overigens slechts naar één apparaat gestuurd. De App kan niet vaststellen naar welk apparaat een kennisgeving moet worden verstuurd. De Gebruiker dient er zelf op toe te zien dat hij kennisgevingen ontvangt en daar kennis van neemt. Porsche aanvaardt geen enkele verantwoordelijkheid of aansprakelijkheid in dit opzicht.<br />
          7.4. Klant dient zorgvuldig om te gaan met de inloggegevens die wij verstrekken om toegang te kunnen verkrijgen tot de App. De inloggegevens zijn niet overdraagbaar en mogen niet worden gebruikt buiten de organisatie van Klant. Klant en/of de gebruikers zijn jegens een ieder verplicht volstrekte geheimhouding te betrachten over de inloggegevens en zullen de inloggegevens op een veilige plaats bewaren. Klant is verantwoordelijk en aansprakelijk voor ieder gebruik dat van zijn inloggegevens wordt gemaakt.<br />
          7.5. De Klant dient ons direct, doch uiterlijk binnen 12 uur, nadat zij bekend is geraakt met enige vorm van ongeautoriseerde toegang hierover schriftelijk te informeren. Indien het onbevoegde gebruik van inlogcodes aan de Klant moet worden toegerekend, dient de Klant de aan blokkering en vervanging verbonden daadwerkelijk gemaakte technische en administratieve kosten en de daadwerkelijk gemaakte kosten van het onbevoegde gebruik aan ons te vergoeden.<br />
          7.6. Wij zijn bevoegd de inloggegevens van Klant en/of een Gebruiker te blokkeren indien wij dat nodig vinden, bijvoorbeeld vanwege veiligheid, niet toegestaan gebruik, (een vermoeden van) fraude of indien (het vermoeden bestaat dat) de Klant zijn betalingsverplichtingen of andere verplichtingen niet nakomt. <br />
          7.7. Wij mogen de inlogprocedure van de App naar eigen inzicht aanpassen.
        </p>
      </section>

      <section>
        <h2>8. App</h2>
        <p>
          8.1. De App bevat de volgende eigenschappen: <br />
          8.1.1. Login: De openingspagina. De Gebruiker krijgt toegang tot de App door zijn gegevens in te voeren (of Activeringscode).
        </p>
        <ul>
          <li>− Home: de homepage van de App.</li>
          <li>− Event: het gedeelte waarin de alle Events staan vermeldt die op u van toepassing zijn, georganiseerd door uw geselecteerde of toegewezen Porsche centrum, Porsche nationaal of internationaal, op volgorde van datum.</li>
          <li>− Routes; het gedeelte van de App waarin routes staan voor zowel binnen- als buitenland.</li>
          <li>− Levels: bevat alle informatie over de verschillende levels binnen Porsche24, de bijbehorende beloningen (privileges en rewards) en level contributies.</li>
          <li>− Badges: hierin staan de door u verzamelde badges, op basis van gereden kilometers of bezochte events.</li>
          <li>− My Porsche(s): bevat basisinformatie over de Porsche(s) die u bezit.</li>
          <li>− Support: bevat koppelingen naar het eerste niveau van het supportteam van Porsche.</li>
          <li>− Profile: de persoonlijke pag.</li>
        </ul>
      </section>

      <section>
        <h2>9. Beschikbaarheid en beheer</h2>
        <p>
          9.1. Wij spannen ons in voor optimale beschikbaarheid van en toegang tot de App in de staat waarin deze zich van tijd tot tijd bevindt (beschikbaarstelling ‘as is’). <br />
          9.2. Wij zijn bevoegd de toegang tot de App zonder voorafgaande bekendmaking tijdelijk buiten gebruik te stellen, te beperken of te beëindigen ten behoeve van:
        </p>
        <ul>
          <li>(i) onderhoudswerkzaamheden; </li>
          <li>(ii) te verrichten aanpassingen of verbeteringen van één of meer Diensten; </li>
          <li>(iii) beveiliging van persoonsgegevens in verband met de onrechtmatige verwerking daarvan; (iv) storingen of vertragingen van het netwerk of systemen van derden. Wij spannen ons in dit tot een minimum te beperken en Klant indien mogelijk op voorhand te informeren.</li>
        </ul>
      </section>

      <section>
        <h2>10. Mutaties</h2>
        <p>10.1. Wij zijn gerechtigd zonder voorkennis of medeweten van Klant technisch noodzakelijke wijzigingen aan te brengen in de Producten en/of Diensten, zonder dat Klant daaraan enigerlei recht zou kunnen ontlenen.</p>
      </section>

      <section>
        <h2>11. Termijnen</h2>
        <p>
          11.1. Opgegeven leveringstermijnen zijn indicatief en nimmer te beschouwen als fatale termijnen, tenzij uitdrukkelijk schriftelijk het tegendeel is overeengekomen. Bij niet “tijdige” levering dienen wij derhalve eerst schriftelijk in gebreke te worden gesteld.<br />
          11.2. Wij zijn eerst in gebreke nadat 21 dagen zijn verstreken sedert de schriftelijke ingebrekestelling ons heeft bereikt, e.e.a. onverlet het bepaalde in artikel 14.
        </p>
      </section>

      <section>
        <h2>12. Betalingen</h2>
        <p>
          12.1. Wij zijn gerechtigd van het door de Klant verschuldigde bedrag te allen tijde vooruitbetaling te verlangen (in ieder geval) tot een beloop van 25% daarvan. De factuur betrekking hebbende op de vooruitbetaling dient te zijn voldaan voordat levering van het gekochte heeft plaatsgevonden.<br />
          12.2. Tenzij anders overeengekomen dient betaling, zonder verrekening, ook van de bijkomende kosten, voor of bij afname of aflevering van het verkochte contant plaats te vinden. Onder contante betaling wordt mede verstaan bijschrijving van het verschuldigde bedrag op een door ons aangegeven bankrekening op het tijdstip van levering. Alle betalingstermijnen zijn te beschouwen als fatale termijnen. Indien wij creditcardbetalingen toestaan, kunnen wij daaraan voorwaarden verbinden.<br />
          12.3. Indien Klant het verkochte niet heeft afgehaald binnen vijf (5) werkdagen nadat het verkochte ter beschikking staat wordt hem de factuur betreffende het verkochte toegezonden, welke terstond betaald dient te worden. Als vervaldatum geldt de dag van verzending van deze factuur. Geschiedt de aflevering aan het adres van Klant dan geldt als vervaldatum de dag van ontvangst van de factuur.<br />
          12.4. In geval Klant één of meer betalingsverplichtingen niet, niet tijdig of niet volledig nakomt, is Klant met ingang van de dag volgend op de dag die is overeengekomen als de uiterste dag van betaling tot en met de dag waarop Klant de geldsom heeft voldaan aan ons de wettelijke rente verschuldigd over alle te late betalingen per maand of gedeelte van een maand, waarbij een gedeelte van een maand geldt als een volledige maand.<br />
          12.5. Alleen in geval Klant een niet-consument betreft en hij één of meer betalingsverplichtingen niet, niet tijdig of niet volledig nakomt, is Klant met ingang van de dag volgend op de dag die is overeengekomen als de uiterste dag van betaling tot en met de dag waarop Klant de geldsom heeft voldaan, aan ons de wettelijke handelsrente (zoals is bepaald in de artikelen 6:119a en 6:120 BW) verschuldigd over alle te late betalingen per maand of een gedeelte van een maand, waarbij een gedeelte van een maand geldt als een volledige maand.<br />
          12.6. Tevens is Klant de buitengerechtelijke en gerechtelijke incassokosten verschuldigd. Buitengerechtelijke incassokosten zijn alle kosten welke door ons worden gemaakt om tot incasso van de door Klant uit de Overeenkomst verschuldigde bedragen te geraken, zoals declaraties van advocaten en procureurs, deurwaarders, zaakwaarnemers en incassobureaus. De buitengerechtelijke kosten worden vastgesteld op tenminste 15% van het verschuldigde bedrag met een minimum van € 50.
        </p>
      </section>

      <section>
        <h2>13. Overmacht (niet toerekenbare tekortkoming)</h2>
        <p>
          13.1. Wij zijn niet gehouden tot nakoming van enige verplichting indien wij daartoe gehinderd zijn als gevolg van een omstandigheid, die niet te wijten is aan onze schuld (waaronder maar niet beperkt tot niet of niet tijdige levering aan ons door onze toeleveranciers en/of de aanhoudende uitval van transport, internet, telecommunicatie of elektrische stroom), noch krachtens wet, rechtshandeling of in het verkeer geldende opvattingen voor onze rekening komt.<br />
          13.2. Wij zijn in geval van blijvende overmacht gerechtigd de Overeenkomst met Klant buitengerechtelijk te ontbinden door middel van een schriftelijke verklaring. In geval van tijdelijke overmacht zijn wij gerechtigd de termijnen van de Overeenkomst met Klant te verlengen met de tijd gedurende welke de tijdelijke overmacht geldt.
        </p>
      </section>

      <section>
        <h2>14. Eigendomsvoorbehoud</h2>
        <p>
          14.1. De eigendom van alle door ons aan Klant verkochte Producten blijft bij ons zolang Klant onze vorderingen uit hoofde van deze of andere Overeenkomsten niet heeft voldaan, zolang Klant de vordering uit hoofde van de verrichte of nog te verrichten werkzaamheden uit deze of andere Overeenkomsten nog niet heeft voldaan en zolang Klant onze vorderingen wegens het tekortschieten in de nakoming door Klant van zodanige verbintenissen niet heeft voldaan, waaronder begrepen vorderingen terzake van boete, rente en kosten.<br />
          14.2. Indien de Klant (mede) uit de door ons geleverde Producten een nieuwe zaak vormt, vormt de Klant die zaak slechts voor ons en houdt de Klant de nieuwe gevormde zaak voor ons totdat de Klant alle uit hoofde van de Overeenkomst verschuldigde bedragen heeft voldaan. Wij hebben tot het moment van volledige voldoening door de Klant van uit hoofde van de toepasselijke Overeenkomst alle rechten als eigenaar van de nieuwe gevormde zaak.<br />
          14.3. Klant is vóór volledige betaling van de koopprijs met inbegrip van eventuele rente en kosten niet bevoegd het verkochte aan derden in gebruik af te staan, te verpanden of aan derden over te dragen.<br />
          14.4. In het geval een derde te goeder trouw de eigendom van de nog niet betaalde zaken heeft gekregen en deze derde de verschuldigde koopsom nog niet heeft voldaan, verbindt Klant zich reeds nu voor alsdan een bezitloos pandrecht voor te behouden en voor zover mogelijk te vestigen op de vordering die Klant op deze derde heeft.
        </p>
      </section>

      <section>
        <h2>15. Aansprakelijkheid</h2>
        <p>
          15.1. Gebruiker aanvaardt dat, aangezien de App wordt aangeboden via het internet, er zich momenten kunnen voordoen waarop de App niet beschikbaar is, zoals maar niet beperkt tot periodes van gepland onderhoud. Porsche doet geen uitspraken en verstrekt geen garanties – uitdrukkelijk noch impliciet noch wettelijk – ten aanzien van het gebruik van de App, inclusief de daarmee verband houdende diensten, documentatie, content, gegevens en materialen die via de App beschikbaar worden gesteld. Elke impliciete garantie ten aanzien van verhandelbaarheid, geschiktheid voor een bepaald doel, niet-inbreuk en juistheid wordt expliciet door Porsche afgewezen. Porsche garandeert niet dat de App storingsvrij en ononderbroken zal werken, of dat de met behulp van de App verkregen resultaten voldoen aan de behoefte van de Klant of dat deze juist en/of volledig zijn.<br />
          15.2. Porsche is niet aansprakelijk voor het gebruik van de App door Gebruiker en aanvaardt geen aansprakelijkheid voor incidentele, speciale, indirecte of gevolgschade of punitieve schadevergoedingen in verband met enige vordering voortvloeiend uit deze Algemene Voorwaarden. Gebruiker stemt ermee in Porsche te vrijwaren, te verdedigen en schadeloos te stellen ten aanzien van alle vorderingen van derden voortvloeiend uit of verband houdend met (1) een schending van de overeenkomst door Gebruiker of (2) door Gebruiker binnen of in verband met de App gegenereerde content.
        </p>
      </section>

      <section>
        <h2>16. Geheimhouding </h2>
        <p>16.1. Gebruiker is verplicht Vertrouwelijke Informatie met betrekking tot Porsche geheim te houden. Het is Gebruiker niet toegestaan Vertrouwelijke Informatie met betrekking tot Porsche, inclusief (maar niet beperkt tot) informatie over de App, de met de App verband houdende dienstverlening, de Leaseovereenkomst en overige activiteiten en financiële zaken, bedrijfsplannen, intellectuele-eigendomsrechten, informatiesystemen, werkmethodes, werknemers en leveranciers die betrekking hebben op de App, op enigerlei wijze openbaar te maken. Artikel 9.1 is niet van toepassing indien en voor zover: </p>
        <ul>
          <li>− Gebruiker wettelijk verplicht is deze informatie bekend te maken, in welk geval Gebruiker vooraf overlegt met Porsche over hoe hierbij te werk te gaan; of</li>
          <li>− Gebruiker daarvoor de voorafgaande schriftelijke toestemming van Porsche heeft verkregen.</li>
        </ul>
      </section>

      <section>
        <h2>17. Beëindiging </h2>
        <p>17.1. Porsche is gerechtigd de toegang tot en het gebruik van de App door de Gebruiker te allen tijde zonder aankondiging te beëindigen in de volgende gevallen: </p>
        <ul>
          <li>− de Klant vraagt faillissement aan of er wordt een faillissementsaanvraag tegen hem ingediend of Klant verkrijgt surséance van betaling of wordt anderszins onderwerp van een insolventieprocedure;</li>
          <li>− de Klant wordt ontbonden, liquideert zijn onderneming of staakt zijn bedrijfsactiviteiten of schort deze op; of </li>
          <li>− de Klant of een van de Gebruikers schendt de bepalingen van deze overeenkomst en de schending wordt niet binnen twintig (20) werkdagen na ingebrekestelling opgeheven. </li>
        </ul>
        <p>17.2. Bij beëindiging eindigt het recht van Gebruiker op de toegang tot en het gebruik van de App en is Gebruiker verplicht alle Vertrouwelijke Informatie in zijn bezit te vernietigen.</p>
      </section>

      <section>
        <h2>18. Privacy en gegevensbescherming </h2>
        <p>18.1. Porsche verwerkt persoonsgegevens met inachtneming van de bepalingen van haar Privacybeleid: Porsche algemeen <a href="https://www.porsche.com/netherlands/nl/legal-notice/" target="_blank" rel="noopener noreferrer">https://www.porsche.com/netherlands/nl/legal-notice/</a> en specifiek voor Porsche24; <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">https://porsche24.porsche.nl/privacy-policy</a> </p>
      </section>

      <section>
        <h2>19. Privacy en Data Protection</h2>
        <p>
          19.1. Voor zover de Gegevens die door ons en/of de Klant worden verwerkt in het kader van de uitvoering van een Overeenkomst Persoonsgegevens bevatten, zullen wij en/of de Klant deze verwerken in overeenstemming met de toepasselijke privacywetgeving, waaronder maar niet beperkt tot de Wet bescherming persoonsgegevens.<br />
          19.2. Wij zullen Gegevens verzamelen en verwerken in het kader van de Overeenkomst. Wij verwerken persoonsgegevens conform het bepaalde in ons privacybeleid.<br />
          19.3. Wij zijn gerechtigd om de Gegevens te verstrekken aan andere door ons te bepalen partijen, waaronder maar niet beperkt tot Pon’s Automobielhandel B.V., Porsche AG en de leverancier van de Producten en/of Diensten, waaronder maar niet beperkt tot doeleinden met betrekking tot onderzoek, analyse, verbetering van de Producten en/of Diensten, marketing, service (onderhoud- en reparatie) en communicatie.<br />
          19.4. Klant krijgt via de aan Klant ter beschikking gestelde account toegang tot de Gegevens uitsluitend met betrekking tot de door Klant aangeschafte Producten.<br />
          19.5. Klant is zelf verantwoordelijk voor de naleving van de toepasselijke wet- en regelgeving in verband met het gebruik van de Producten en/of Diensten, waaronder (maar niet beperkt tot) het informeren van de gebruikers over de verwerking van de Persoonsgegevens (onder meer over de partijen die de gegevens zullen ontvangen) en het verkrijgen van benodigde toestemmingen en/of instemmingen uit hoofde van onder de meer Wet bescherming persoonsgegevens en/of de Wet op de ondernemingsraden.<br />
          19.6. De Klant is verantwoordelijk voor het maken van een adequate veiligheidsbackup en archiveringskopieën van haar bestanden.
        </p>
      </section>

      <section>
        <h2>20. Beveiliging van persoonsgegevens</h2>
        <p>
          20.1. Wij nemen alle passende technische en organisatorische maatregelen om Persoonsgegevens te beveiligen tegen verlies of enige vorm van onrechtmatige verwerking. Deze maatregelen garanderen een passend beveiligingsniveau gelet op de risico’s die verwerking en de gevoelige aard van de Persoonsgegevens die wij verwerken met zich meebrengen. Wij zijn gerechtigd (sub)bewerker(s) in te schakelen bij de verwerking van Persoonsgegevens, met dien verstande dat wij erop toezien dat de verplichtingen uit dit artikel 20 van deze voorwaarden eveneens worden nagekomen door deze (sub)bewerker(s).<br />
          20.2. Wij mogen de Persoonsgegevens enkel buiten de Europese Economische Ruimte verwerken met voorafgaande schriftelijke toestemming van Klant.<br />
          20.3. Partijen zullen elkaar onverwijld, maar uiterlijk binnen 12 uur, na daarvan kennis te hebben verkregen, in kennis stellen van iedere inbreuk op de beveiliging (van welke aard dan ook) die (mede) betrekking heeft of kan hebben op de verwerking van Persoonsgegevens, en zullen alle maatregelen treffen die nodig zijn om de (mogelijke) schade te beperken en de andere Partij te ondersteunen bij meldingen aan betrokkenen en/of autoriteiten.
        </p>
      </section>

      <section>
        <h2>21. Overige bepalingen</h2>
        <p>
          21.1. Deze Algemene Voorwaarden zijn van toepassing op elk gebruik van de App.<br />
          21.2. In geval van strijdigheid tussen deze Algemene Voorwaarden en documenten van of waarnaar wordt verwezen door Gebruiker, prevaleren deze Algemene Voorwaarden. De toepassing van door Gebruiker gebruikte strijdige voorwaarden wordt hierbij door Porsche afgewezen. <br />
          21.3. Bepaalde content en diensten die via de App beschikbaar worden gesteld, kunnen materiaal van derden bevatten. Voor het gemak van Gebruiker kunnen in de App links zijn opgenomen naar de websites van derden. Porsche is niet verantwoordelijk voor het onderzoeken of evalueren van de inhoud of juistheid daarvan en Porsche geeft geen garanties en aanvaardt geen aansprakelijkheid of verantwoordelijkheid ten aanzien van de materialen of websites van derden, noch ten aanzien van overige materialen, producten of diensten van derden. Het is Gebruiker niet toegestaan materialen van derden zodanig te gebruiken dat hierdoor de rechten van anderen worden geschonden. Porsche is op generlei wijze verantwoordelijk voor dergelijk gebruik door de Gebruikers in dit opzicht. <br />
          21.4. In geval van strijdigheid tussen deze Algemene Voorwaarden en eventuele aanvullende voorwaarden (bijvoorbeeld met betrekking tot promotieacties), prevaleren de aanvullende voorwaarden. 21.5. Als een bepaling van deze Algemene Voorwaarden onwettig, nietig of onafdwingbaar wordt verklaard, tast dit de wettigheid, geldigheid en afdwingbaarheid van de overige bepalingen niet aan en wordt deze bepaling geïnterpreteerd op een wijze die in overeenstemming is met de toepasselijke wetgeving. Het niet optreden door Porsche tegen een schending van deze Algemene Voorwaarden door een Gebruiker of door anderen houdt geen afstand in van enig recht en tast de rechten van Porsche met betrekking tot die of overige schendingen niet aan. <br />
          21.6. Het is Gebruiker niet toegestaan zijn uit deze Algemene Voorwaarden voortvloeiende rechten en verplichtingen te cederen of over te dragen zonder de voorafgaande schriftelijke toestemming van Porsche. <br />
          21.7. Porsche behoudt zich te allen tijde het recht voor deze Algemene Voorwaarden te wijzigen.
        </p>
      </section>

      <section>
        <h2>22. Toepasselijk recht en geschillen</h2>
        <p>
          22.1. Op al onze aanbiedingen en Overeenkomsten is uitsluitend Nederlands recht van toepassing.<br />
          22.2. Alle geschillen welke mochten ontstaan naar aanleiding van een aanbieding en/of Overeenkomst, waarop de onderhavige verkoop- en leveringsvoorwaarden van toepassing zijn, of van nadere overeenkomsten, welke van zodanige overeenkomsten uitvloeisel mochten zijn, worden berecht door de rechtbank in het arrondissement waarin wij onze statutaire vestigingsplaats hebben, indien het geschil behoort tot de bevoegdheid van een Arrondissementsrechtbank, tenzij wij om ons moverende redenen er de voorkeur aan geven het geschil te doen berechten door de volgens de wet bevoegde rechter. Indien het geschil wordt beslecht door een andere dan de volgens de wet bevoegde rechter, heeft de Klant, indien hij consument is, het recht om binnen een maand nadat wij ons schriftelijk op dit beding hebben beroepen, voor beslechting van het geschil voor de volgens de wet bevoegde rechter te kiezen.
        </p>
      </section>

      <section>
        <p>Versie juli 2017</p>
      </section>
    </ContentContainer>
  </Fragment>
);

export default PrivacyPolicy;
