import React from 'react';
import PT from 'prop-types';
import styled from 'styled-components';
import { media } from 'styles/utils';

import Label from 'common/form/Label';

export const InfoContainer = styled.div`
  color: ${props => props.theme.gray};
  flex-basis: 100%;
  margin: 0 0 31px 0;

  ${media.tablet`
    flex-basis: 48%;
  `}

  ${media.desktop`
    flex-basis: 25%;
  `}
`;

const Info = ({ label, children }) => (
  <InfoContainer>
    <Label noWhitespace>{label}</Label>
    {children}
  </InfoContainer>
);

Info.propTypes = {
  label: PT.string,
  children: PT.node,
};

export default Info;
