import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const activeClass = 'active';
export default styled(NavLink).attrs({ activeClass })`
  color: ${props => props.theme.text};
  font-size: 18px;
  line-height: 100%;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.red};
  }

  &.${activeClass} {
    color: ${props => props.theme.red};
  }
`;
