import styled, { css } from 'styled-components';
import PT from 'prop-types';

const Form = styled.form`
  max-width: 460px;

  ${props => props.center && css`
    margin: auto;
  `}
`;

Form.propTypes = {
  center: PT.bool,
};

export default Form;
