import createAction from 'services/createAction';
import { showNotification } from 'ducks/notification';

const DELETE_CAR = 'deleteCar/DELETE';
const DELETE_CAR_SUCCESS = 'deleteCar/DELETE_SUCCESS';
const DELETE_CAR_FAILED = 'deleteCar/DELETE_FAILED';

const initialState = {
  error: {
    message: '',
  },
  loading: {
    delete: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case DELETE_CAR_SUCCESS:
    return {
      ...state,
      loading: { ...state.loading, delete: false },
    };
  case DELETE_CAR_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, delete: false },
    };
  case DELETE_CAR:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, delete: true },
    };
  default:
    return state;
  }
};

const deleteCarSuccess = createAction(DELETE_CAR_SUCCESS);
const deleteCarFailed = createAction(DELETE_CAR_FAILED);

export const deleteCarFromDriver = id => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: DELETE_CAR });

    api.del({ path: `management/car/${id}` })
      .then(() => {
        resolve();
        dispatch(deleteCarSuccess());
        dispatch(showNotification('De auto is succesvol verwijderd', 'green'));
      })
      .catch((err) => {
        reject();
        dispatch(deleteCarFailed({ message: err.data }));
        dispatch(showNotification('Er is iets fout gegaan. Probeer opnieuw.'));
      });
  })
);
