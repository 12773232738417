import styled, { css } from 'styled-components';
import { media } from 'styles/utils';

export const ModalBackdrop = styled.div`
  background: ${props => props.theme.overlay};
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  z-index: 9;
`;

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 1000000;
`;

export const ModalBody = styled.div`
  background: ${props => props.theme.white};
  position: relative;
  z-index: 10;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  max-height: 100%;
  height: 100%;

  ${media.tablet`
    height: auto;
    width: 600px;
    max-width: 600px;
    max-height: 100%;
    border: 1px solid ${props => props.theme.border.withBoxShadow};
    overflow: auto;

    ${props => props.large && css`
      max-width: 70%;
      width: auto;
    `}
  `}
`;

export const CloseButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  height: 30px;
  position: absolute;
  right: 18px;
  top: 18px;
  width: 30px;

  > svg {
    vertical-align: middle;
    fill: ${props => props.theme.black};
  }
`;
