import React, { Component } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import qs from 'qs';

import { activateAccount } from 'ducks/authentication';

import { Container, HeaderPlain, Loading, Title } from 'common';

class ActivateAccount extends Component {
  componentDidMount() {
    const activationCode = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).code;
    this.props.activateAccount(activationCode);
  }

  render() {
    const { accountActivated, loading } = this.props;

    return (
      <Container>
        <HeaderPlain />
        {loading && <Loading />}
        <Container>
          <Title center>
            {accountActivated ?
              'Uw account is geactiveerd' :
              'Het activeren van uw account is niet gelukt'
            }
          </Title>
        </Container>
      </Container>
    );
  }
}

ActivateAccount.propTypes = {
  accountActivated: PT.bool,
  loading: PT.bool,
  activateAccount: PT.func,
  location: PT.object,
};

export default compose(
  withRouter,
  connect(state => ({
    accountActivated: state.authentication.accountActivated,
    loading: state.authentication.loading.activateAccount,
  }), { activateAccount }),
)(ActivateAccount);
