import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PT from 'prop-types';

import { getBadgesFromDriver } from 'ducks/driver/getBadges';
import { getBadges } from 'ducks/badges';
import { showModal } from 'ducks/modal';

import { hasRole, COMMUNITY_MANAGER } from 'services/authHelper';

import { Container, Info, FlexWrapper, Button } from 'common';
import { Label } from 'common/form';
import AddBadgeDropdown from 'modules/DriverDetail/components/AddBadgeDropdown';

import { Badge, Image, BadgeName, ProgressBar, ProgressBarFill, ButtonsContainer } from './styled';

class DriverTabsProgression extends Component {
  componentWillMount() {
    if (this.props.driverFromProgression !== this.props.driver.id) {
      this.props.getBadgesFromDriver(this.props.driver.id);
      this.props.getBadges();
    }
  }

  render() {
    const { driver, badges, roles, badgeIdLoading } = this.props;

    return (
      <Container>
        {hasRole(roles, [COMMUNITY_MANAGER]) && (
          <ButtonsContainer>
            <AddBadgeDropdown />
            <Button variant="black" sub small square onClick={() => this.props.showModal()}>
              Voeg punten toe
            </Button>
          </ButtonsContainer>
        )}
        <FlexWrapper>
          <Info label="Progress perc. huidig level">
            {driver.level.progress ? `${Math.floor(driver.level.progress)}%` : '-'}
          </Info>
          <Info label="Huidige punten">
            {driver.level.current_points || '-'}
          </Info>
          <Info label="Target v. volg. level">
            {driver.level.target_points || '-'}
          </Info>
          <Info label="Huidige level behaald op">
            {driver.level.achieved_on ? moment(driver.level.achieved_on).format('LLL') : '-'}
          </Info>
        </FlexWrapper>
        <Label noWhitespace>Badges</Label>
        <FlexWrapper>
          {badges.map(badge => (
            <Badge key={badge.id} isLoading={badge.id === badgeIdLoading}>
              <Image src={badge.image} />
              <BadgeName>{badge.name}</BadgeName>
              <ProgressBar>
                <ProgressBarFill progression={`${badge.progress}%`} />
              </ProgressBar>
            </Badge>
          ))}
        </FlexWrapper>
      </Container>
    );
  }
}

DriverTabsProgression.propTypes = {
  driver: PT.object,
  driverFromProgression: PT.string,
  getBadgesFromDriver: PT.func,
  getBadges: PT.func,
  badges: PT.array,
  roles: PT.array,
  badgeIdLoading: PT.oneOfType([PT.bool, PT.string]),
  showModal: PT.func,
};

const mapStateToProps = state => ({
  driver: state.driver.data,
  badges: state.getBadges.data,
  driverFromProgression: state.getBadges.driverId,
  roles: state.authentication.roles,
  badgeIdLoading: state.getBadges.loading.addBadge,
});

const mapDispatchToProps = dispatch => ({
  showModal: () => dispatch(showModal({ type: 'ADD_POINTS' })),
  getBadgesFromDriver: id => dispatch(getBadgesFromDriver(id)),
  getBadges: () => dispatch(getBadges()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DriverTabsProgression);
