import React from 'react';
import PT from 'prop-types';

import { Wrapper, ToggleContainer, Slider } from './styled';

const Toggle = ({ active, onClick, children }) => (
  <Wrapper>
    <ToggleContainer
      onClick={onClick}
      active={active}
    >
      <Slider active={active} />
    </ToggleContainer>
    {children}
  </Wrapper>
);

Toggle.propTypes = {
  active: PT.bool,
  onClick: PT.func,
  children: PT.node,
};

export default Toggle;
