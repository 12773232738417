const getSentryEndpoint = () => {
  switch (process.env.APP_ENV) {
  case 'production':
    return 'https://e473500820af4e9a83bc06baf583a833@o1145659.ingest.sentry.io/6323654';
  case 'acceptation':
    return 'https://59368ed8b7db4ea4a8b59fe134e2d506@o1145659.ingest.sentry.io/6323648';
  default:
  case 'test':
    return 'https://ff3b06c08c7e41f3827550e6f3b8a1f9@o1145659.ingest.sentry.io/6323646';
  }
};

export default getSentryEndpoint();
