import React from 'react';
import PT from 'prop-types';
import getBlock from 'services/blockHelper';

import AccordionBlock from '../AccordionBlock';
import Block from '../Block';

const CollapsableContentBlock = ({ data }) => (
  <Block noMargin>
    <AccordionBlock title={data.value.title}>
      {data.value.blocks.map((contentBlock) => {
        const BlockItem = getBlock(contentBlock.type);
        return <BlockItem key={contentBlock.id} data={contentBlock} />;
      })}
    </AccordionBlock>
  </Block>
);

CollapsableContentBlock.propTypes = {
  data: PT.object,
};

export default CollapsableContentBlock;
