import React, { Component, Fragment } from 'react';
import PT from 'prop-types';
import moment from 'moment';
import onClickOutside from 'react-onclickoutside';

import ArrowDown from 'vectors/ArrowDown.svg';
import { isUpdatePublished } from 'services/eventHelper';
import { LabelTag, Button } from 'common';

import {
  UpdateContainer, UpdateHeader, Title, Time, UpdateContent, LeftContainer,
  ImagesContainer, Image, StyledReactPlayer, VideoContainer, VideoWrapper,
  DropdownActions, ActionsList, ActionsListItem,
} from './styled';

class EventDetailUpdatesCard extends Component {
  state = {
    actionsOpen: false,
  }

  handleClickOutside() {
    this.setState({ actionsOpen: false });
  }

  render() {
    const {
      data, edit, onDelete, hasFullAccess,
    } = this.props;

    return (
      <UpdateContainer>
        <UpdateHeader>
          <LeftContainer>
            <Time>{moment(data.created).format('LLLL')}</Time>
            <div><LabelTag>{isUpdatePublished(data) ? 'Gepubliceerd' : 'Niet gepubliceerd'}</LabelTag></div>
          </LeftContainer>
          {hasFullAccess && (
            <div>
              <Button
                sub
                small
                square
                onClick={(e) => {
                  e.stopPropagation();
                  this.setState({ actionsOpen: !this.state.actionsOpen });
                }}
                className="ignore-react-onclickoutside"
              >
                <ArrowDown />
              </Button>

              {this.state.actionsOpen && (
                <DropdownActions className="ignore-react-onclickoutside">
                  <ActionsList>
                    <ActionsListItem
                      onClick={(e) => {
                        e.stopPropagation();
                        this.setState({ actionsOpen: false });
                        edit();
                      }}
                    >
                    Wijzigen
                    </ActionsListItem>
                    <ActionsListItem
                      onClick={(e) => {
                        e.stopPropagation();
                        this.setState({ actionsOpen: false });
                        onDelete();
                      }}
                    >
                    Verwijderen
                    </ActionsListItem>
                  </ActionsList>
                </DropdownActions>
              )}
            </div>
          )}
        </UpdateHeader>
        <UpdateContent>
          <Title>Tekst</Title>
          <div dangerouslySetInnerHTML={{ __html: data.text }} />

          {data.youtube_link && (
            <Fragment>
              <Title>Video</Title>
              <VideoWrapper>
                <VideoContainer>
                  <StyledReactPlayer url={data.youtube_link} width="100%" height="100%" />
                </VideoContainer>
              </VideoWrapper>
            </Fragment>
          )}

          {data.image && data.image.length > 0 && (
            <Fragment>
              <Title>{data.image.length > 1 ? 'Afbeeldingen' : 'Afbeelding'}</Title>
              <ImagesContainer>
                {data.image.map(image => (
                  <Image key={image.id} src={image.image} alt="update image" />
                ))}
              </ImagesContainer>
            </Fragment>
          )}
        </UpdateContent>
      </UpdateContainer>
    );
  }
}

EventDetailUpdatesCard.propTypes = {
  data: PT.object,
  edit: PT.func,
  onDelete: PT.func,
  hasFullAccess: PT.bool,
};

export default onClickOutside(EventDetailUpdatesCard);
