import React, { Component, Fragment } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';

import { Title, Container, EmptyState, Loading } from 'common';
import { showModal, hideModal } from 'ducks/modal';
import { getOrders } from 'ducks/orders';

import { Summary, OrdersStyled } from './styled';
import OrderCard from './components/OrdersCard';

class Orders extends Component {
  componentDidMount() {
    this.props.getOrders();
  }

  componentWillUnmount() {
    this.props.hideOrdersModal();
  }

  render() {
    const { showOrdersModal, loading, orders } = this.props;

    return (
      <Container>
        {loading && <Loading />}
        {orders.count === 0 && !loading && (
          <EmptyState
            title="All orders have been successfully processed"
            subtitle="There are no failed orders to resend. New failed orders will appear here."
          />
        )}
        {orders.count > 0 && !loading && (
          <Fragment>
            <Title>Failed order overview</Title>
            <Summary>
              <OrdersStyled>{orders.count} failed</OrdersStyled> order{orders.count > 1 && 's'}
            </Summary>

            {orders.bookings.map(booking => (
              <OrderCard
                showOrdersModal={showOrdersModal}
                booking={booking}
                key={booking.id}
              />
            ))}
          </Fragment>
        )}
      </Container>
    );
  }
}

Orders.propTypes = {
  hideOrdersModal: PT.func,
  showOrdersModal: PT.func,
  getOrders: PT.func,
  orders: PT.object,
  loading: PT.bool,
};

const mapStateToProps = state => ({
  loading: state.orders.loading.get,
  orders: state.orders.data,
});

const mapDispatchToProps = dispatch => ({
  showOrdersModal: (order) => {
    dispatch(showModal({
      type: 'ORDERS',
      modalProps: { data: order },
    }));
  },
  hideOrdersModal: () => {
    dispatch(hideModal());
  },
  getOrders: () => {
    dispatch(getOrders());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
