import React, { Component } from 'react';
import PT from 'prop-types';
import { withRouter } from 'react-router-dom';

import ArrowDownIcon from 'vectors/ArrowDown.svg';

import { Link as HeaderLink } from 'common';
import { ExtendedLink, SubNavHeader, Button, SubNavContent } from './styled';

class BrandPortalSubNav extends Component {
  state = {
    open: false,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.page.slug === nextProps.match.params.page && !this.state.open) this.toggleItem();
  }

  toggleItem = () => {
    this.setState({ open: !this.state.open });
  }

  render() {
    const { page, selectedTopic } = this.props;

    return (
      <div>
        <SubNavHeader>
          <HeaderLink to={`/brand-portal/${selectedTopic.slug}/${page.slug}`}>
            {page.title}
          </HeaderLink>

          <Button onClick={this.toggleItem} open={this.state.open}>
            <ArrowDownIcon />
          </Button>
        </SubNavHeader>
        <SubNavContent open={this.state.open}>
          {page.sub_pages.map(subPage => (
            <ExtendedLink
              key={subPage.id}
              to={`/brand-portal/${selectedTopic.slug}/${page.slug}/${subPage.slug}`}
            >
              {subPage.title}
            </ExtendedLink>
          ))}
        </SubNavContent>
      </div>
    );
  }
}

BrandPortalSubNav.propTypes = {
  page: PT.object,
  selectedTopic: PT.object,
  match: PT.object,
};

export default withRouter(BrandPortalSubNav);
