import styled, { css } from 'styled-components';
import PT from 'prop-types';

import Label from '../Label';
import SubLabel from '../SubLabel';

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;

  ${props => props.center && css`
    display: block;
  `}
`;

CheckboxContainer.propTypes = {
  center: PT.bool,
};

export const StyledInput = styled.input`
  flex-basis: 30px;
  cursor: pointer;

  ${props => props.noWhitespace && css`
    flex-basis: auto;
  `}

  ${props => props.center && css`
    margin: auto;
    display: block;
  `}
`;

StyledInput.propTypes = {
  noWhitespace: PT.bool,
  center: PT.bool,
};

export const Labels = styled.div`
  flex: 1;

  ${Label} {
    margin: 0;
  }

  ${SubLabel} {
    margin-top: 5px;
    margin-bottom: 0;
  }
`;
