import createAction from 'services/createAction';

const GET_DEALERS = 'dealers/GET';
const GET_DEALERS_SUCCESS = 'dealers/GET_SUCCESS';
const GET_DEALERS_FAILED = 'dealers/GET_FAILED';

const initialState = {
  data: [],
  error: {
    message: '',
  },
  loading: {
    dealers: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case GET_DEALERS_SUCCESS:
    return {
      ...state,
      data: payload,
      loading: { ...state.loading, dealers: false },
    };
  case GET_DEALERS_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, dealers: false },
    };
  case GET_DEALERS:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, dealers: true },
    };
  default:
    return state;
  }
};

const getDealersSuccess = createAction(GET_DEALERS_SUCCESS);
const getDealersFailed = createAction(GET_DEALERS_FAILED);

export const getDealers = () => (dispatch, getState, api) => {
  dispatch({ type: GET_DEALERS });

  api.get({ path: 'dealer' })
    .then((res) => {
      dispatch(getDealersSuccess(res));
    })
    .catch((err) => {
      dispatch(getDealersFailed({ message: err.data }));
    });
};
