import styled, { css } from 'styled-components';
import PT from 'prop-types';

export const TagStyle = styled.label`
  display: inline-block;
  line-height: 36px;
  border: 1px solid ${props => props.theme.border};
  border-radius: 18px;
  padding: 0 18px;
  margin: 0 12px 12px 0;
  cursor: pointer;

  ${props => props.error && css`
    border-color: ${props.theme.red};
  `}
`;

TagStyle.propTypes = {
  error: PT.oneOfType([PT.string, PT.bool]),
};

export const FieldStyle = styled.input`
  display: none;

  &:checked + label {
    background-color: ${props => props.theme.red};
    border-color: ${props => props.theme.red};
    color: ${props => props.theme.white};
  }
`;
