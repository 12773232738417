import React, { Fragment, Component } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import onClickOutside from 'react-onclickoutside';
import FileSaver from 'file-saver';

import {
  hasRole,
  hasPermissions,
  isOwnDealerEvent,
  isOwnEvent,
  COMMUNITY_MANAGER,
  DEALER,
  BROADCAST_CREATE,
  DRIVER_EXPORT,
  EVENT_UPDATE,
} from 'services/authHelper';
import { isPublished } from 'services/eventHelper';

import { deleteEvent } from 'ducks/events';
import { showNotification } from 'ducks/notification';
import { exportAttendees } from 'ducks/event';
import ArrowDown from 'vectors/ArrowDown.svg';
import { Button } from 'common';

import { ActionsList, ActionsListItem, DropdownActions } from './styled';

class ActionButtons extends Component {
  state = {
    actionsOpen: false,
  }

  handleClickOutside() {
    this.setState({ actionsOpen: false });
  }

  exportAttendees = (eventId) => {
    this.props.onExportAttendees(eventId).then((blob) => {
      FileSaver.saveAs(blob, `${this.props.event.name} - attendees.csv`);
    }).catch(() => this.props.onShowNotification('Er is iets misgegaan'));

    this.setState({ actionsOpen: false });
  }

  hasPermissionToExport = (roles, permissions, event, dealer) => {
    if (!hasRole(roles, [DEALER]) && hasPermissions(permissions, [DRIVER_EXPORT])) return true;
    if (hasRole(roles, [DEALER]) && isOwnDealerEvent(event, dealer)) return true;
    return false;
  }

  hasPermissionToSendEventUpdate = (event, dealer, user, roles, permissions) => {
    if (hasRole(roles, [COMMUNITY_MANAGER])) return true;
    if (isOwnEvent(event, user)) return true;
    if (hasRole(roles, [DEALER]) && isOwnDealerEvent(event, dealer)) return true;
    if (!hasRole(roles, [COMMUNITY_MANAGER, DEALER]) && hasPermissions(permissions, [BROADCAST_CREATE])) return true;
    return false;
  }

  hasPermissionToEditEvents = (event, dealer, roles, permissions) => {
    if (hasRole(roles, [COMMUNITY_MANAGER])) return true;
    if (hasRole(roles, [DEALER]) && isOwnDealerEvent(event, dealer)) return true;
    if (!hasRole(roles, [COMMUNITY_MANAGER, DEALER]) && hasPermissions(permissions, [EVENT_UPDATE])) return true;
    return false;
  }

  hasPermissionToDepublishEvent = (event, dealer, roles) => {
    if (hasRole(roles, [COMMUNITY_MANAGER])) return true;
    if (hasRole(roles, [DEALER]) && isOwnDealerEvent(event, dealer)) return true;
    return false;
  }

  render() {
    const {
      event, history, onDelete, roles, permissions, dealer, user,
    } = this.props;

    return (
      <Fragment>
        {isPublished(event) ? (
          <Button
            variant="black"
            small
            stretch
            disabled={!this.hasPermissionToSendEventUpdate(event, dealer, user, roles, permissions)}
            onClick={(e) => {
              e.stopPropagation();
              history.push(`/event/${event.id}/updates`);
            }}
          >
            Stuur update
          </Button>
        ) : (
          <Button
            variant="black"
            onClick={(e) => {
              e.stopPropagation();
              history.push(`/event/edit/${event.id}`);
            }}
            small
            stretch
            disabled={!this.hasPermissionToEditEvents(event, dealer, roles, permissions)}
          >
            Wijzig event
          </Button>
        )}
        <Button
          sub
          small
          square
          onClick={(e) => {
            e.stopPropagation();
            this.setState({ actionsOpen: !this.state.actionsOpen });
          }}
          className="ignore-react-onclickoutside"
          data-cy="button-event-toggle-actions"
        >
          <ArrowDown />
        </Button>

        {this.state.actionsOpen && (
          <DropdownActions className="ignore-react-onclickoutside">
            <ActionsList>
              {isPublished(event) ? (
                <ActionsListItem
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push(`/event/${event.id}/preview`);
                  }}
                >
                  Preview
                </ActionsListItem>
              ) : (
                <ActionsListItem
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push(`/event/edit/${event.id}`);
                  }}
                >
                  Wijzig
                </ActionsListItem>
              )}
              {isPublished(event) ? (
                this.hasPermissionToDepublishEvent(event, dealer, roles) && (
                  <ActionsListItem
                    onClick={(e) => {
                      e.stopPropagation();
                      onDelete(event.id, true);
                    }}
                    data-cy="button-event-unpublish"
                  >
                  Depubliceer
                  </ActionsListItem>
                )
              ) : (
                <ActionsListItem
                  onClick={(e) => {
                    e.stopPropagation();
                    onDelete(event.id, false);
                  }}
                  data-cy="button-event-delete"
                >
                  Verwijder
                </ActionsListItem>
              )}
              {(isPublished(event) && this.hasPermissionToExport(roles, permissions, event, dealer)) && (
                <ActionsListItem
                  onClick={(e) => {
                    e.stopPropagation();
                    this.exportAttendees(event.id);
                  }}
                >
                  Exporteer aanwezigen
                </ActionsListItem>
              )}
            </ActionsList>
          </DropdownActions>
        )}
      </Fragment>
    );
  }
}

ActionButtons.propTypes = {
  dealer: PT.object,
  user: PT.object,
  event: PT.object,
  history: PT.object,
  onDelete: PT.func,
  roles: PT.array,
  permissions: PT.array,
  onExportAttendees: PT.func,
  onShowNotification: PT.func,
};

export default compose(
  connect(state => ({
    roles: state.authentication.roles,
    permissions: state.authentication.permissions,
    dealer: state.authentication.data.role.dealer,
    user: state.authentication.data,
  }), {
    onDelete: deleteEvent,
    onExportAttendees: exportAttendees,
    onShowNotification: showNotification,
  }),
  withRouter,
  onClickOutside,
)(ActionButtons);
