import React from 'react';
import { createPortal } from 'react-dom';
import PT from 'prop-types';

import IconCross from 'vectors/Cross.svg';

import { CloseButton, ModalBackdrop, ModalBody, ModalContainer } from './styled';

const Modal = ({ children, onHideModal, large }) => createPortal(
  <ModalContainer>
    <ModalBody large={large}>
      <CloseButton onClick={onHideModal}>
        <IconCross />
      </CloseButton>
      {children}
    </ModalBody>
    <ModalBackdrop onClick={onHideModal} />
  </ModalContainer>,
  document.getElementById('modal'),
);

Modal.propTypes = {
  children: PT.any,
  onHideModal: PT.func,
  large: PT.bool,
};

export default Modal;
