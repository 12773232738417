import styled from 'styled-components';

export default styled.p`
  margin: 0;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 700;
  color: ${props => props.theme.gray};
  letter-spacing: 2px;
`;
