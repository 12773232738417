import React, { Fragment } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import { getFormValues, Field } from 'redux-form';

import formatFiltersToQuery from 'services/formatFiltersToQuery';
import isTicketInvite from 'services/isTicketInvite';
import { showNotification } from 'ducks/notification';

import { Container, Title, Button } from 'common';
import { StepsContainer, StepsItem } from 'common/steps';
import { Label, InputText, InputTextarea, SubLabel, Form, FormField } from 'common/form';

const InviteDriversMessage = ({
  history, match, invitedData, onShowNotification,
}) => {
  const confirmMessage = () => {
    if (!invitedData.message || !invitedData.subject) {
      onShowNotification('Vul alstublieft een onderwerp en een bericht in');
    } else {
      history.push(`/event/${match.params.eventId}/invite${isTicketInvite(match.params) ? `/${match.params.ticketId}` : ''}/confirm`);
    }
  };

  return (
    <Fragment>
      <Container>
        <StepsContainer>
          <StepsItem
            onClick={() => (
              history.push(`/event/${match.params.eventId}/invite${isTicketInvite(match.params) ? `/${match.params.ticketId}` : ''}/select${formatFiltersToQuery(invitedData)}`)
            )}
          >
            1. Porsche rijders
          </StepsItem>
          <StepsItem active>2. Bericht</StepsItem>
          <StepsItem disabled>3. Bevestig</StepsItem>
        </StepsContainer>

        <Title underline>Schrijf uw uitnodiging</Title>

        <Form>
          <FormField>
            <Label>Onderwerp</Label>
            <Field
              name="subject"
              component={InputText}
              type="text"
            />
          </FormField>

          <FormField>
            <Label>Bericht</Label>
            <SubLabel groupWithLabel>Uw bericht begint automatisch met: Beste [Rijdersnaam],</SubLabel>
            <Field
              name="message"
              component={InputTextarea}
            />
          </FormField>
        </Form>
      </Container>

      <Container textCenter>
        <Button onClick={confirmMessage}>
          Bevestig bericht &gt;
        </Button>
      </Container>
    </Fragment>
  );
};

InviteDriversMessage.propTypes = {
  history: PT.object,
  match: PT.object,
  invitedData: PT.object,
  onShowNotification: PT.func,
};

export default connect(state => ({
  invitedData: getFormValues('invite-drivers')(state),
}), { onShowNotification: showNotification })(InviteDriversMessage);
