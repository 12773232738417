import React, { Component } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import { change } from 'redux-form';

import Tag from './components/Tag';

class MultipleTags extends Component {
  state = {
    values: [],
    valuesSet: false,
  }

  componentWillMount() {
    // Use default value when value is empty
    if (this.state.values.length === 0) {
      if (this.props.defaultValue.length > 0) {
        this.setState({ values: this.props.defaultValue.map(val => val.id) }, () => {
          this.changeValues();
        });
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.valuesSet) {
      this.setState({ values: nextProps.input.value, valuesSet: true }, () => {
        this.changeValues();
      });
    }
  }

  handleOnClick = (value) => {
    if (this.state.values.includes(value)) {
      // Remove from state
      this.setState({ values: this.state.values.filter(val => val !== value) }, () => {
        this.changeValues();
      });
    } else {
      // Add to state
      this.setState({ values: [...this.state.values, value] }, () => {
        this.changeValues();
      });
    }
  }

  // Send values to redux form after state has changed
  changeValues = (values = this.state.values) => {
    this.props.change(this.props.meta.form, this.props.input.name, values);
  }

  render() {
    const { input, meta, ...otherProps } = this.props;

    return (
      otherProps.data.map(tag => (
        <Tag
          key={tag.id}
          name={input.name}
          id={tag.id}
          label={tag.name}
          type="checkbox"
          handleOnClick={this.handleOnClick}
          defaultChecked={this.state.values}
          error={otherProps.error}
        />
      ))
    );
  }
}

MultipleTags.propTypes = {
  value: PT.array,
  defaultValue: PT.array,
  input: PT.object,
  meta: PT.object,
  otherProps: PT.object,
  field: PT.string,
  change: PT.func,
};

export default connect(null, { change })(MultipleTags);
