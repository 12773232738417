import React from 'react';
import PT from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';

import { Anchor, Container } from 'common';

const DriverTabs = ({ match }) => (
  <Container>
    <Anchor as={NavLink} to={`${match.url}/cars`}>
      Auto&apos;s
    </Anchor>
    <Anchor as={NavLink} to={`${match.url}/tags`}>
      Tags
    </Anchor>
    <Anchor as={NavLink} to={`${match.url}/rewards`}>
      Rewards
    </Anchor>
    <Anchor as={NavLink} to={`${match.url}/progression`}>
      Progression Details
    </Anchor>
    <Anchor as={NavLink} to={`${match.url}/activities`}>
      Activiteiten
    </Anchor>
    <Anchor as={NavLink} to={`${match.url}/mileage-history`}>
      Mileage History
    </Anchor>
    <Anchor as={NavLink} to={`${match.url}/points-history`}>
      Points History
    </Anchor>
    <Anchor as={NavLink} to={`${match.url}/cem`}>
      CEM
    </Anchor>
  </Container>
);

DriverTabs.propTypes = {
  match: PT.object.isRequired,
};

export default withRouter(DriverTabs);
