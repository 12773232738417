import styled from 'styled-components';
import PT from 'prop-types';
import { hexToRgba } from 'styles/utils';

const NotificationContainer = styled.div`
  position: fixed;
  top: 0px;
  background: ${props => props.theme[props.variant]};
  color: ${props => props.theme.white};
  box-shadow: 0 4px 10px 0 ${props => hexToRgba(props.theme[props.variant])};
  font-weight: 400;
  height: auto;
  min-height: 55px;
  padding: 10px 20px;
  max-width: 1140px;
  width: 95%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0 0 4px 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 11;
  animation: ${props => (props.noAnimation ? '' : 'fadeIn .3s ease forwards')};

  @keyframes fadeIn {
    from { margin-top: -100px; }
    to { margin-top: 0; }
  }

  & svg {
    width: 16px;
    cursor: pointer;
    fill: ${props => props.theme.white};
  }
`;

NotificationContainer.propTypes = {
  variant: PT.oneOf(['green', 'red', 'orange']),
  noAnimation: PT.bool,
};

NotificationContainer.defaultProps = {
  variant: 'red',
};

export default NotificationContainer;
