import React, { Component } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import { getFormValues, initialize } from 'redux-form';

import { getUpdates } from 'ducks/updates';
import { postUpdate, publishUpdate, saveUpdate, setUpdateEditor, deleteUpdate } from 'ducks/update';

import { Title, Container, Button, Card, EmptyState } from 'common';

import EventDetailUpdatesCard from '../EventDetailUpdatesCard';
import EventDetailUpdatesEditor from '../EventDetailUpdatesEditor';

import { HeaderContainer, EditorContainer } from './styled';

class EventDetailUpdates extends Component {
  state = {
    showEditor: false,
    edit: false,
  }

  componentDidMount() {
    if (this.props.match.params.eventId !== this.props.currentEventId) {
      this.props.onGetUpdates(this.props.match.params.eventId);
    }
  }

  createNewUpdate = () => {
    this.props.postUpdate(this.props.match.params.eventId)
      .then(() => this.setState({
        showEditor: true,
        edit: false,
      }));
  }

  initEditor = (currentUpdate) => {
    this.props.setUpdateEditor(currentUpdate)
      .then(() => {
        this.setState({
          showEditor: true,
          edit: currentUpdate,
        }, () => window.scrollTo(0, 0));

        this.props.initialize('edit-update', {
          text: currentUpdate.text,
          youtube_link: currentUpdate.youtube_link || '',
        });
      });
  }

  editUpdate = (currentUpdate) => {
    if (this.state.edit) {
      const warning = confirm('De wijzigingen zijn niet opgeslagen. Weet je het zeker?');

      if (!warning) return false;

      // set editor to false and then true so it gets unmounted
      this.setState({
        showEditor: false,
        edit: false,
      });
    }

    this.initEditor(currentUpdate);
  }

  publish = () => {
    this.props.publishUpdate(this.props.editingUpdateId, this.props.formData).then(() => {
      this.props.onGetUpdates(this.props.match.params.eventId);
      this.setState({
        showEditor: false,
        edit: false,
      });
    });
  }

  save = () => {
    this.props.saveUpdate(this.props.editingUpdateId, this.props.formData).then(() => {
      this.props.onGetUpdates(this.props.match.params.eventId);
      this.setState({
        showEditor: false,
        edit: false,
      });
    });
  }

  delete = (updateId) => {
    this.props.deleteUpdate(updateId).then(() => (
      this.props.onGetUpdates(this.props.match.params.eventId)
    ));
  }

  cancel = () => {
    if (!this.state.edit) {
      this.props.deleteUpdate(this.props.editingUpdateId);
    }

    this.setState({
      showEditor: false,
      edit: false,
    });
  }

  render() {
    const { hasFullAccess } = this.props;

    return (
      <Container>
        {hasFullAccess && (
          <HeaderContainer>
            <div>
              <Title small>{this.state.edit ? 'Wijzig bestaande event update' : 'Plaats update'}</Title>
              <p>Het plaatsen van een update zal automatisch een push notification naar alle aanwezigen sturen</p>
            </div>
            <div>
              {!this.state.showEditor && (
                <Button
                  small
                  onClick={this.createNewUpdate}
                >
                  Nieuwe update
                </Button>
              )}
            </div>
          </HeaderContainer>
        )}
        <EditorContainer>
          {this.state.showEditor && (
            <EventDetailUpdatesEditor
              publish={this.publish}
              save={this.save}
              edit={this.state.edit}
              cancel={this.cancel}
            />
          )}
        </EditorContainer>
        <Card noMargin>
          {this.props.updates.length === 0 ? (
            <EmptyState title="Er zijn nog geen event updates" />
          ) : (
            this.props.updates.map(update => (
              <EventDetailUpdatesCard
                data={update}
                key={update.id}
                edit={() => this.editUpdate(update)}
                onDelete={() => this.delete(update.id)}
                hasFullAccess={hasFullAccess}
              />
            ))
          )}
        </Card>
      </Container>
    );
  }
}

EventDetailUpdates.propTypes = {
  match: PT.object,
  currentEventId: PT.string,
  onGetUpdates: PT.func,
  updates: PT.array,
  postUpdate: PT.func,
  editingUpdateId: PT.string,
  publishUpdate: PT.func,
  formData: PT.object,
  saveUpdate: PT.func,
  setUpdateEditor: PT.func,
  initialize: PT.func,
  deleteUpdate: PT.func,
  hasFullAccess: PT.bool,
};

export default connect(state => ({
  currentEventId: state.updates.eventId,
  updates: state.updates.data,
  formData: getFormValues('edit-update')(state),
  editingUpdateId: state.update.editingUpdateId,
}), {
  onGetUpdates: getUpdates, postUpdate, publishUpdate, saveUpdate, setUpdateEditor, initialize, deleteUpdate,
})(EventDetailUpdates);
