import React, { Fragment } from 'react';
import { Field } from 'redux-form';

import { Anchor } from 'common';
import { Label, SubLabel, FormField, InputText, InputTextarea } from 'common/form';

import { Icon, ActionContainer } from './styled';

const TicketFormAdditionalTerms = ({ fields }) => {
  if (!Array.isArray(fields.getAll())) return null;

  return fields.map((field, index) => (
    <Fragment key={`${field.term}_${field.id}`}>
      <FormField indent>
        <Label>Voorwaarde naam</Label>
        <SubLabel groupWithLabel>Bijvoorbeeld: geldig rijbewijs</SubLabel>
        <Field
          name={`${field}.item`}
          component={InputText}
          type="text"
        />
      </FormField>
      <FormField indent>
        <Label>Voorwaarde omschrijving</Label>
        <SubLabel groupWithLabel>Bijvoorbeeld: alleen een valide europese rijbewijs wordt geaccepteerd.</SubLabel>
        <Field
          name={`${field}.description`}
          component={InputTextarea}
        />

        <ActionContainer>
          {fields.length > 1 ? (
            <Anchor small onClick={() => fields.remove(index)}>
              Verwijder
            </Anchor>
          ) : (
            <Anchor
              small
              onClick={() => {
                fields.remove(index);
                fields.push({});
              }}
            >
              Leegmaken
            </Anchor>
          )}
          <Anchor small bold onClick={() => fields.push({})}>
            Voeg voorwaarde toe <Icon>+</Icon>
          </Anchor>
        </ActionContainer>
      </FormField>
    </Fragment>
  ));
};

export default TicketFormAdditionalTerms;
