import createAction from 'services/createAction';
import { showNotification } from 'ducks/notification';

const GET_UNCLAIMED_REWARDS = 'driverRewards/GET_UNCLAIMED';
const GET_UNCLAIMED_REWARDS_SUCCESS = 'driverRewards/GET_UNCLAIMED_SUCCESS';
const GET_UNCLAIMED_REWARDS_FAILED = 'driverRewards/GET_UNCLAIMED_FAILED';

const GET_CLAIMED_REWARDS = 'driverRewards/GET_CLAIMED';
const GET_CLAIMED_REWARDS_SUCCESS = 'driverRewards/GET_CLAIMED_SUCCESS';
const GET_CLAIMED_REWARDS_FAILED = 'driverRewards/GET_CLAIMED_FAILED';

const GET_PREVIOUS_REWARDS = 'driverRewards/GET_PREVIOUS';
const GET_PREVIOUS_REWARDS_SUCCESS = 'driverRewards/GET_PREVIOUS_SUCCESS';
const GET_PREVIOUS_REWARDS_FAILED = 'driverRewards/GET_PREVIOUS_FAILED';

const CLAIM_REWARD = 'driverRewards/CLAIM';
const CLAIM_REWARD_SUCCESS = 'driverRewards/CLAIM_SUCCESS';
const CLAIM_REWARD_FAILED = 'driverRewards/CLAIM_FAILED';

const UNCLAIM_REWARD = 'driverRewards/UNCLAIM';
const UNCLAIM_REWARD_SUCCESS = 'driverRewards/UNCLAIM_SUCCESS';
const UNCLAIM_REWARD_FAILED = 'driverRewards/UNCLAIM_FAILED';

const ASSIGN_REWARD = 'driverRewards/ASSIGN';
const ASSIGN_REWARD_SUCCESS = 'driverRewards/ASSIGN_SUCCESS';
const ASSIGN_REWARD_FAILED = 'driverRewards/ASSIGN_FAILED';

const initialState = {
  data: {
    claimed: [],
    unclaimed: [],
    previous: [],
  },
  driverId: '',
  error: {
    message: '',
  },
  loading: {
    unclaimed: false,
    claim: false,
    assign: false,
  },
  loaded: {
    claimed: false,
    unclaimed: false,
    previous: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case GET_UNCLAIMED_REWARDS_SUCCESS:
    return {
      ...state,
      driverId: payload.driverId,
      data: {
        ...state.data,
        unclaimed: payload.data,
      },
      loading: { ...state.loading, loadUnclaimed: false },
      loaded: { ...state.loaded, unclaimed: true },
    };
  case GET_UNCLAIMED_REWARDS_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, loadUnclaimed: false },
    };
  case GET_UNCLAIMED_REWARDS:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, loadUnclaimed: true },
    };
  case GET_CLAIMED_REWARDS_SUCCESS:
    return {
      ...state,
      driverId: payload.driverId,
      data: {
        ...state.data,
        claimed: payload.data,
      },
      loading: { ...state.loading, loadClaimed: false },
      loaded: { ...state.loaded, claimed: true },
    };
  case GET_CLAIMED_REWARDS_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, loadClaimed: false },
    };
  case GET_CLAIMED_REWARDS:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, loadClaimed: true },
    };
  case CLAIM_REWARD_SUCCESS:
    return {
      ...state,
      loading: { ...state.loading, claim: false },
    };
  case CLAIM_REWARD_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, claim: false },
    };
  case CLAIM_REWARD:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, claim: true },
    };
  case UNCLAIM_REWARD_SUCCESS:
    return {
      ...state,
      loading: { ...state.loading, unclaim: false },
    };
  case UNCLAIM_REWARD_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, unclaim: false },
    };
  case UNCLAIM_REWARD:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, unclaim: true },
    };
  case GET_PREVIOUS_REWARDS_SUCCESS:
    return {
      ...state,
      driverId: payload.driverId,
      data: {
        ...state.data,
        previous: payload.data,
      },
      loading: { ...state.loading, loadPrevious: false },
      loaded: { ...state.loaded, previous: true },
    };
  case GET_PREVIOUS_REWARDS_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, loadPrevious: false },
    };
  case GET_PREVIOUS_REWARDS:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, loadPrevious: true },
    };
  case ASSIGN_REWARD_SUCCESS:
    return {
      ...state,
      loading: { ...state.loading, assign: false },
    };
  case ASSIGN_REWARD_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, assign: false },
    };
  case ASSIGN_REWARD:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, assign: true },
    };
  default:
    return state;
  }
};

const getUnclaimedRewardsSuccess = createAction(GET_UNCLAIMED_REWARDS_SUCCESS);
const getUnclaimedRewardsFailed = createAction(GET_UNCLAIMED_REWARDS_FAILED);

export const getUnclaimedRewards = driverId => (dispatch, getState, api) => {
  dispatch({ type: GET_UNCLAIMED_REWARDS });

  api.get({ path: `management/user/${driverId}/reward/available` })
    .then((res) => {
      dispatch(getUnclaimedRewardsSuccess({ data: res, driverId }));
    })
    .catch((err) => {
      dispatch(getUnclaimedRewardsFailed({ message: err.data }));
    });
};

const getClaimedRewardsSuccess = createAction(GET_CLAIMED_REWARDS_SUCCESS);
const getClaimedRewardsFailed = createAction(GET_CLAIMED_REWARDS_FAILED);

export const getClaimedRewards = driverId => (dispatch, getState, api) => {
  dispatch({ type: GET_CLAIMED_REWARDS });

  api.get({ path: `management/user/${driverId}/reward/claimed` })
    .then((res) => {
      dispatch(getClaimedRewardsSuccess({ data: res, driverId }));
    })
    .catch((err) => {
      dispatch(getClaimedRewardsFailed({ message: err.data }));
    });
};

const claimRewardSuccess = createAction(CLAIM_REWARD_SUCCESS);
const claimRewardFailed = createAction(CLAIM_REWARD_FAILED);

export const claimReward = (driverId, rewardId) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: CLAIM_REWARD });

    api.put({ path: `management/user/${driverId}/reward/${rewardId}/claim` })
      .then(() => {
        resolve();
        dispatch(claimRewardSuccess());
      })
      .catch((err) => {
        reject(err);
        dispatch(claimRewardFailed({ message: err.data }));
        dispatch(showNotification('Het claimen van deze reward is mislukt. Probeer opnieuw.'));
      });
  })
);

const unclaimRewardSuccess = createAction(UNCLAIM_REWARD_SUCCESS);
const unclaimRewardFailed = createAction(UNCLAIM_REWARD_FAILED);

export const unclaimReward = (driverId, rewardId) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: UNCLAIM_REWARD });

    api.put({ path: `management/user/${driverId}/reward/${rewardId}/unclaim` })
      .then(() => {
        resolve();
        dispatch(unclaimRewardSuccess());
      })
      .catch((err) => {
        reject(err);
        dispatch(unclaimRewardFailed({ message: err.data }));
        dispatch(showNotification('Het unclaimen van deze reward is mislukt. Probeer opnieuw.'));
      });
  })
);

const getPreviousRewardsSuccess = createAction(GET_PREVIOUS_REWARDS_SUCCESS);
const getPreviousRewardsFailed = createAction(GET_PREVIOUS_REWARDS_FAILED);

export const getPreviousRewards = driverId => (dispatch, getState, api) => {
  dispatch({ type: GET_PREVIOUS_REWARDS });

  api.get({ path: `management/user/${driverId}/reward/previous` })
    .then((res) => {
      dispatch(getPreviousRewardsSuccess({ data: res, driverId }));
    })
    .catch((err) => {
      dispatch(getPreviousRewardsFailed({ message: err.data }));
    });
};

const assignRewardSuccess = createAction(ASSIGN_REWARD_SUCCESS);
const assignRewardFailed = createAction(ASSIGN_REWARD_FAILED);

export const assignReward = (driverId, rewardId, dealerId) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    const body = {
      user_id: driverId,
      ...(dealerId ? { dealer_id: dealerId } : {}),
    };

    dispatch({ type: ASSIGN_REWARD });

    api.post({ path: `rewards/${rewardId}/gift`, body, v2: true })
      .then(() => {
        dispatch(assignRewardSuccess());
        resolve();
      })
      .catch((err) => {
        dispatch(assignRewardFailed({ message: err.data }));
        reject();
      });
  })
);
