import createAction from 'services/createAction';

const GET_TICKET_TYPE = 'ticketType/GET';
const GET_TICKET_TYPE_SUCCESS = 'ticketType/GET_SUCCESS';
const GET_TICKET_TYPE_FAILED = 'ticketType/GET_FAILED';

const GET_TICKET_CATEGORY = 'ticketCategory/GET';
const GET_TICKET_CATEGORY_SUCCESS = 'ticketCategory/GET_SUCCESS';
const GET_TICKET_CATEGORY_FAILED = 'ticketCategory/GET_FAILED';

const initialState = {
  types: [],
  categories: [],
  error: {
    message: '',
  },
  loading: {
    type: false,
    category: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case GET_TICKET_TYPE_SUCCESS:
    return {
      ...state,
      types: payload,
      loading: { ...state.loading, type: false },
    };
  case GET_TICKET_TYPE_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, type: false },
    };
  case GET_TICKET_TYPE:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, type: true },
    };
  case GET_TICKET_CATEGORY_SUCCESS:
    return {
      ...state,
      categories: payload,
      loading: { ...state.loading, category: false },
    };
  case GET_TICKET_CATEGORY_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, category: false },
    };
  case GET_TICKET_CATEGORY:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, category: true },
    };
  default:
    return state;
  }
};

const getTicketTypeSuccess = createAction(GET_TICKET_TYPE_SUCCESS);
const getTicketTypeFailed = createAction(GET_TICKET_TYPE_FAILED);

export const getTicketType = () => (dispatch, getState, api) => {
  dispatch({ type: GET_TICKET_TYPE });

  api.get({ path: 'management/ticket_type' })
    .then((res) => {
      dispatch(getTicketTypeSuccess(res));
    })
    .catch((err) => {
      dispatch(getTicketTypeFailed({ message: err.data }));
    });
};


const getTicketCategorySuccess = createAction(GET_TICKET_CATEGORY_SUCCESS);
const getTicketCategoryFailed = createAction(GET_TICKET_CATEGORY_FAILED);

export const getTicketCategory = () => (dispatch, getState, api) => {
  dispatch({ type: GET_TICKET_CATEGORY });

  api.get({ path: 'management/ticket/points' })
    .then((res) => {
      dispatch(getTicketCategorySuccess(res));
    })
    .catch((err) => {
      dispatch(getTicketCategoryFailed({ message: err.data }));
    });
};
