import styled from 'styled-components';
import PT from 'prop-types';

const Column = styled.div`
  flex: ${props => props.flex};

  &:not(:last-of-type) {
    margin-right: 18px;
  }
`;

Column.defaultProps = {
  flex: 1,
};

Column.propTypes = {
  flex: PT.number,
};

export default Column;
