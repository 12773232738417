import styled from 'styled-components';

export default styled.div`
  width: 100%;
  position: relative;
  z-index: 2;

  & img {
    width: 100%;
    display: block;
  }
`;
