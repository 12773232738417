import styled, { css } from 'styled-components';
import PT from 'prop-types';
import { media } from 'styles/utils';

const Anchor = styled.button`
  color: ${props => props.theme.text};
  background: transparent;
  border: 0;
  font-size: 21px;
  outline: none;
  cursor: pointer;
  padding: 0;
  margin-bottom: 3px;
  margin-right: 36px;
  text-decoration: none;

  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    color: ${props => props.theme.red};
  }

  ${props => props.active && css`
    color: ${props.theme.red};
    border-bottom: 3px solid ${props.theme.red};
    margin-bottom: 0;
  `}

  &.active {
    color: ${props => props.theme.red};
    border-bottom: 3px solid ${props => props.theme.red};
    margin-bottom: 0;
  }

  ${media.tablet`
    font-size: 24px;
  `}

  ${props => props.small && css`
    font-size: 14px;

    ${media.tablet`
      font-size: 14px;
    `}
  `}

  ${props => props.bold && css`
    font-weight: 700;
    letter-spacing: 0.05em;
  `}

  ${props => props.stretch && css`
    display: block;
    width: 100%;
  `}
`;

Anchor.defaultProps = {
  type: 'button',
};

Anchor.propTypes = {
  small: PT.bool,
  active: PT.bool,
  bold: PT.bool,
  stretch: PT.bool,
};

export default Anchor;
