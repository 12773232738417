/**
 * Given a brand portal page id, return the full page url
 *
 * The brand portal pages object has 3 nested levels, topics -> top pages -> sub pages
 * In order to receive the fully nested url, we need to filter out the whole page stack
 * We do this by fetching the pages from the store
 * We start by checking if we have a topic page, then a top page, and then a sub page
 * If one of these exists, we return this value, so the function doesn't keep on checking
 *
 * @param Number id
 * @param Array pages
 * @return String url (/brand-portal/:topic/:page?/:sub-page?/)
 */

export default (id, pages = []) => {
  const numberId = Number(id);

  if (pages.length > 0 && numberId) {
    // Topic page
    let stack = pages.find(page => page.id === numberId);
    if (stack) return `/brand-portal/${stack.slug}`;

    // Top page
    stack = pages.map(page => ({ ...page, top_pages: page.top_pages.filter(topPage => topPage.id === numberId) }))
      .find(page => page.top_pages.length > 0);

    if (stack) return `/brand-portal/${stack.slug}/${stack.top_pages[0].slug}`;

    // Sub page
    stack = pages.map(page => ({
      ...page,
      top_pages: page.top_pages.filter(topPage => topPage.sub_pages.find(subPage => subPage.id === numberId)),
    })).find(page => page.top_pages.length > 0);

    if (stack) {
      return `/brand-portal/${stack.slug}/${stack.top_pages[0].slug}/${stack.top_pages[0].sub_pages[0].slug}`;
    }
  }

  return '/brand-portal/';
};
