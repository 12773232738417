import React, { Component } from 'react';
import PT from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, change } from 'redux-form';

import { getAttendees, markAttendees } from 'ducks/event';

import { Title, Pagination, Container } from 'common';

import EventDetailOverviewAttendeesActions from '../EventDetailOverviewAttendeesActions';
import EventDetailOverviewAttendeesList from '../EventDetailOverviewAttendeesList';

class EventDetailOverviewAttendees extends Component {
  state = {
    limit: '25',
    page: 0,
  };

  componentWillMount() {
    this.getCurrentAttendees();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      this.getCurrentAttendees();
      this.props.dispatch(change('mark-attendees', 'marked', {}));
    }
  }

  onHandlePageClick = (page) => {
    this.setState({ page });
  }

  onLimitChange = (limit) => {
    this.setState({ limit });
  }

  onSearch = (query) => {
    this.setState({ query, page: 0 });
  }

  onSelectAll = (bool) => {
    if (bool) {
      this.props.attendees.tickets.map(attendee => (
        this.props.dispatch(change('mark-attendees', `marked.${attendee.id}`, bool))
      ));
    } else {
      this.props.dispatch(change('mark-attendees', 'marked', {}));
    }
  }

  getCurrentAttendees = () => {
    this.props.getAttendees(this.props.eventId, {
      ...this.state,
      page: this.state.page + 1,
    });
    this.onSelectAll(false);
  }

  changeAttendeeStatus = (attendees, attending) => {
    const attendeesArray = Object.keys(attendees).filter(attendee => attendees[attendee]);
    this.props.markAttendees(this.props.eventId, attendeesArray, attending)
      .then(() => this.getCurrentAttendees());
  }

  render() {
    const { attendees } = this.props;
    const { page } = this.state;

    return (
      <Container>
        <Title small>Deelnemerslijst</Title>
        <EventDetailOverviewAttendeesActions
          queryValues={this.state}
          onLimitChange={this.onLimitChange}
          onSearch={this.onSearch}
          onSelectAll={this.onSelectAll}
          changeAttendeeStatus={this.changeAttendeeStatus}
          getCurrentAttendees={this.getCurrentAttendees}
        />
        <EventDetailOverviewAttendeesList />
        {attendees.results > attendees.limit && (
          <Pagination
            page={page}
            pageCount={attendees.results / attendees.limit}
            handlePageClick={e => this.onHandlePageClick(e.selected)}
          />
        )}
      </Container>
    );
  }
}

EventDetailOverviewAttendees.propTypes = {
  getAttendees: PT.func,
  eventId: PT.string,
  attendees: PT.object,
  markAttendees: PT.func,
  dispatch: PT.func,
};

export default compose(
  reduxForm({
    form: 'mark-attendees',
    initialValues: {
      marked: {},
    },
  }),
  connect(state => ({
    eventId: state.event.eventId,
    attendees: state.event.attendees,
  }), {
    getAttendees,
    markAttendees,
  }),
)(EventDetailOverviewAttendees);
