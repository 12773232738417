import styled from 'styled-components';
import { media } from 'styles/utils';

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const AllEventsToggleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const LabelHolder = styled.div`
  display: none;

  ${media.tablet`
    margin-right: 5px;
    display: inline-block;
  `}
`;

export const IconContainer = styled.span`
  display: inline-block;
  font-size: 24px;
  font-weight: 300;
  line-height: 14px;
  vertical-align: middle;

  ${media.tablet`
    line-height: 14px;
  `}
`;
