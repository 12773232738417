import createAction from 'services/createAction';

const MODAL_SHOW = 'modal/SHOW';
const MODAL_HIDE = 'modal/HIDE';

const initialState = {
  type: null,
  modalProps: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case MODAL_SHOW:
    return {
      type: payload.type,
      modalProps: payload.modalProps,
    };
  case MODAL_HIDE:
    return initialState;
  default:
    return state;
  }
};

export const hideModal = createAction(MODAL_HIDE);
export const showModal = createAction(MODAL_SHOW);
