import React, { Fragment } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { Dutch } from 'flatpickr/dist/l10n/nl';

import { InputError } from 'common/form';
import { FlatpickrStyled } from './styled';

const InputDate = ({
  defaultDate, minDate, input, meta, onChange, ...otherProps
}) => {
  const processChange = (val, formattedVal) => {
    onChange(meta.form, input.name, formattedVal);
  };

  return (
    <Fragment>
      <FlatpickrStyled
        data-enable-time
        options={{
          time_24hr: true,
          dateFormat: 'Y-m-dTH:i:S',
          altInput: true,
          altFormat: 'd-m-Y H:i',
          mode: 'single',
          minDate,
          locale: Dutch,
        }}
        value={defaultDate}
        onChange={processChange}
        meta={meta}
      />
      {!otherProps.noErrorText && <InputError meta={meta} />}
    </Fragment>
  );
};

InputDate.propTypes = {
  meta: PT.object,
  input: PT.object,
  defaultDate: PT.string,
  minDate: PT.string,
  onChange: PT.func,
};

export default connect(null, { onChange: change })(InputDate);
