import styled from 'styled-components';

import ArrowDown from 'vectors/ArrowDown.svg';
import { ListContainer } from '../../styled';

export const CheckboxDropdown = styled.div`
  border-top: 1px solid ${props => props.theme.border.withBoxShadow};
  border-bottom: 1px solid ${props => props.theme.border.withBoxShadow};
`;

export const SubListContainer = styled(ListContainer)`
  margin-left: 18px;
  margin-bottom: 0;
`;

export const ArrowIcon = styled(ArrowDown)`
  path {
    fill: ${props => props.theme.border.withBoxShadow};
  }
`;
