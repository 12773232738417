import React from 'react';
import PT from 'prop-types';
import moment from 'moment';

import Card from '../Card';
import Button from '../Button';
import LabelTag from '../LabelTag';
import Anchor from '../Anchor';

import { ExtendedSubtitle, ExpirationDate, TitleContainer, Price, ButtonContainer, StyledIcon } from './styled';

const getPrice = (value, valueType) => {
  if (value === '0') return '';
  if (value === '100' && valueType === 'percentage') return 'Free';
  return valueType === 'percentage' ? `- ${value}%` : `- €${value}`;
};

// reward object from custom reward list has different values from API!
const RewardCard = ({
  reward, claimReward, unclaimReward, claim, previous, readOnly, onClick, compact, archiveReward,
}) => (
  <Card onClick={onClick} compact={compact}>
    <TitleContainer>
      <ExtendedSubtitle previous={previous}>
        {(readOnly && archiveReward) && (
          <Anchor onClick={e => archiveReward(e, reward.id)}>
            <StyledIcon />
          </Anchor>
        )}
        {(reward.amount > 0 && !readOnly) && `${reward.amount}x `}{reward.name}
        {(reward.is_giftable && readOnly) && <LabelTag>Giftable</LabelTag>}
      </ExtendedSubtitle>
      <Price previous={previous}>{getPrice(reward.value, reward.value_type)}</Price>
    </TitleContainer>
    {reward.expire_date && <ExpirationDate>Geldig tot en met {moment(reward.expire_date).format('LL')}</ExpirationDate>}
    {!readOnly && !previous &&
      <ButtonContainer>
        {!claim ? (
          <Button onClick={() => claimReward(reward)}>Claim</Button>
        ) : (
          <Button variant="black" onClick={() => unclaimReward(reward)}>Unclaim</Button>
        )}
      </ButtonContainer>
    }
  </Card>
);

RewardCard.propTypes = {
  reward: PT.object,
  claimReward: PT.func,
  unclaimReward: PT.func,
  claim: PT.bool,
  previous: PT.bool,
  readOnly: PT.bool,
  onClick: PT.func,
  compact: PT.bool,
  archiveReward: PT.func,
};

export default RewardCard;
