import styled from 'styled-components';
import PT from 'prop-types';

import { media } from 'styles/utils';

const FootNote = styled.div.attrs(props => ({
  dangerouslySetInnerHTML: !props.children ? { __html: props.text } : null,
}))`
  font-size: 16px;
  font-weight: 100;
  position: absolute;
  left: 20px;
  bottom: -30px;

  & p {
    margin: 0;
  }

  & b {
    font-weight: 700;
  }

  ${media.tablet`
    bottom: 0;
    left: calc(40% + 40px);
  `}
`;

FootNote.propTypes = {
  text: PT.string,
  children: PT.node,
};

export default FootNote;
