import React from 'react';
import PT from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, FieldArray, getFormValues } from 'redux-form';

import { postInviteNewDriver } from 'ducks/inviteNewDriver';
import { showNotification } from 'ducks/notification';

import { Modal, Title, Button } from 'common';
import InviteNewDriversModalField from '../InviteNewDriversModalField';

import { InviteContainer, ActionContainer } from './styled';

const sendInvites = (toggleModal, formValues, inviteNewDriver, dispatch) => {
  Promise
    .all(formValues.inviteNewDrivers.map(invite => inviteNewDriver(invite.email)))
    .then(() => {
      toggleModal();
      dispatch(showNotification('Het versturen van de uitnodigingen is gelukt', 'green'));
    })
    .catch(() => {
      dispatch(showNotification('Het versturen van de uitnodigingen is mislukt. Probeer het nog eens.'));
    });
};

const InviteNewDriversModal = ({
  toggleModal, loading, formValues, inviteNewDriver, dispatch,
}) => (
  <Modal onHideModal={toggleModal}>
    <InviteContainer>
      <Title small>Nieuwe Porsche rijders uitnodigen voor Porsche24</Title>
      <p>Zij ontvangen een uitnodigingsmail</p>
      <FieldArray name="inviteNewDrivers" component={InviteNewDriversModalField} />
      <ActionContainer>
        <Button
          small
          variant="black"
          stretch
          onClick={toggleModal}
        >
          Annuleer
        </Button>
        <Button
          small
          stretch
          disabled={loading}
          onClick={() => sendInvites(toggleModal, formValues, inviteNewDriver, dispatch)}
        >
          Verstuur
        </Button>
      </ActionContainer>
    </InviteContainer>
  </Modal>
);

InviteNewDriversModal.propTypes = {
  toggleModal: PT.func,
  loading: PT.bool,
  formValues: PT.object,
  inviteNewDriver: PT.func,
  dispatch: PT.func,
};

export default compose(
  reduxForm({
    form: 'invite-new-drivers',
    initialValues: {
      inviteNewDrivers: [{}],
    },
  }),
  connect(state => ({
    loading: state.inviteNewDriver.loading.post,
    formValues: getFormValues('invite-new-drivers')(state),
  }), { inviteNewDriver: postInviteNewDriver }),
)(InviteNewDriversModal);
