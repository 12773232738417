export default {
  black: Object.assign('#303338', {
    hover: '#ff0000',
  }),
  white: '#ffffff',
  red: Object.assign('#ff0000', {
    hover: '#303338',
  }),
  green: '#4bb543',
  text: '#1b1d1f',
  gray: Object.assign('#adafb2', {
    light: '#e3e3e4',
  }),
  orange: '#ffb106',
  overlay: 'rgba(255, 255, 255, 0.75)',
  border: Object.assign('#dbdbdb', {
    withBoxShadow: 'rgba(173, 175, 178, 0.35)',
  }),
  boxShadow: '0 0 9px 0 rgba(0, 0, 0, 0.05)',
  background: Object.assign('#f5f5f5', {
    dark: '#aaa',
  }),
  font: 'Porsche Next, Helvetica, Arial, "Lucida Grande", sans-serif',
};
