import React, { Component } from 'react';
import PT from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, getFormValues } from 'redux-form';

import { hideModal } from 'ducks/modal';
import { showNotification } from 'ducks/notification';
import { addPoints } from 'ducks/driver/pointsHistory';
import { getDriverDetails } from 'ducks/driver/driver';
import * as validation from 'services/validations';

import { FlexWrapper, Title, Button } from 'common';
import { Form, FormField, Label, InputText } from 'common/form';

import { ModalContent, ModalContainer } from './styled';

class AddPointsModal extends Component {
  state = {
    isLoading: false,
  }

  addPoints = (values) => {
    this.setState({ isLoading: true });
    this.props.addPoints(values.points, values.reason, this.props.driverId)
      // Allow backend to process the request
      .then(() => new Promise(resolve => setTimeout(resolve, 500)))
      .then(() => this.props.getDriverDetails(this.props.driverId))
      .then(() => this.props.showNotification('De punten zijn succesvol toegevoegd.', 'green'))
      .then(() => this.props.hideModal())
      .finally(() => this.setState({ isLoading: false }));
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <ModalContainer>
        <FlexWrapper>
          <ModalContent>
            <Title>Punten toevoegen</Title>
            <Form onSubmit={handleSubmit(this.addPoints)}>
              <FormField>
                <Label>Punten</Label>
                <Field
                  component={InputText}
                  name="points"
                  type="number"
                  placeholder="1"
                  validate={validation.required}
                />
              </FormField>
              <FormField>
                <Label>Reden</Label>
                <Field
                  component={InputText}
                  name="reason"
                  placeholder="Een korte reden"
                  validate={validation.required}
                />
              </FormField>
              <Button type="submit" disabled={this.state.isLoading}>
                Toevoegen
              </Button>
            </Form>
          </ModalContent>
        </FlexWrapper>
      </ModalContainer>
    );
  }
}

AddPointsModal.propTypes = {
  addPoints: PT.func,
  hideModal: PT.func,
  handleSubmit: PT.func,
  getDriverDetails: PT.func,
  showNotification: PT.func,
  driverId: PT.string,
};

const mapStateToProps = state => ({
  driverId: state.driver.data.id,
  formData: getFormValues('add-points')(state) || {},
});

const mapDispatchToProps = {
  hideModal,
  addPoints,
  getDriverDetails,
  showNotification,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: 'add-points' }),
)(AddPointsModal);
