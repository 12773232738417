export default (data) => {
  if (typeof (data) === 'number') {
    return false;
  }

  if (typeof (data) === 'boolean') {
    return false;
  }

  if (data === null) {
    return true;
  }

  if (data === 'undefined') {
    return true;
  }

  if (typeof (data) === 'undefined') {
    return true;
  }

  if (typeof (data.length) !== 'undefined') {
    return data.length === 0;
  }
};
