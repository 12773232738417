import styled, { css } from 'styled-components';

import { Subtitle } from 'common';

export const StageContainer = styled.div`
  display: flex;
`;

export const StageTitle = styled(Subtitle)`
  line-height: 24px;
  font-size: 18px;
`;

export const StageDescription = styled.p`
  margin-bottom: 32px;
`;

export const TimelineContainer = styled.div`
  position: relative;
  width: 24px;
  margin-right: 18px;
`;

export const Circle = styled.div`
  border-radius: 50%;
  width: 24px;
  height: 24px;
  border: 3px solid ${props => props.theme.border};
  background-color: ${props => props.theme.white};
  position: relative;
  z-index: 2;

  ${props => props.active && css`
    /* outer circle */
    border: 3px solid ${props.theme.red};

    /* inner circle */
    &:after {
      content: '';
      border-radius: 50%;
      position: absolute;
      left: 3px;
      top: 3px;
      background-color: red;
      width: 12px;
      height: 12px;
      z-index: 1;
    }
  `}
`;

export const Line = styled.div`
  width: 4px;
  min-height: 100px;
  height: 100%;
  margin-left: calc(50% - 2px);
  position: relative;
  z-index: -1;
  background-color: ${props => props.theme.border};

  ${props => props.active && css`
    background-color: ${props.theme.red};
  `}
`;
