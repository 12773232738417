import { initialize } from 'redux-form';
import moment from 'moment-timezone';

import initialEventValues from 'modules/EventEditor/components/Details/initialValues';

import createAction from 'services/createAction';
import checkEmptyValue from 'services/checkEmptyValue';
import { showNotification } from 'ducks/notification';

const RESET_EVENT = 'editEvent/RESET';
const CREATE_EVENT = 'editEvent/CREATE';
const CREATE_EVENT_SUCCESS = 'editEvent/CREATE_SUCCESS';
const CREATE_EVENT_FAILED = 'editEvent/CREATE_FAILED';

const LOAD_EVENT = 'editEvent/LOAD';
const LOAD_EVENT_SUCCESS = 'editEvent/LOAD_SUCCESS';
const LOAD_EVENT_FAILED = 'editEvent/LOAD_FAILED';

const SAVE_EVENT = 'editEvent/SAVE';
const SAVE_EVENT_SUCCESS = 'editEvent/SAVE_SUCCESS';
const SAVE_EVENT_FAILED = 'editEvent/SAVE_FAILED';

const SUBMIT_TO_REVIEW = 'editEvent/SUBMIT_TO_REVIEW';
const SUBMIT_TO_REVIEW_SUCCESS = 'editEvent/SUBMIT_TO_REVIEW_SUCCESS';
const SUBMIT_TO_REVIEW_FAILED = 'editEvent/SUBMIT_TO_REVIEW_FAILED';

const PUBLISH_EVENT = 'editEvent/PUBLISH';
const PUBLISH_EVENT_SUCCESS = 'editEvent/PUBLISH_SUCCESS';
const PUBLISH_EVENT_FAILED = 'editEvent/PUBLISH_FAILED';

const LOAD_CATEGORIES = 'editEvent/LOAD_CATEGORIES';
const LOAD_CATEGORIES_SUCCESS = 'editEvent/LOAD_CATEGORIES_SUCCESS';
const LOAD_CATEGORIES_FAILED = 'editEvent/LOAD_CATEGORIES_FAILED';

const UPLOAD_IMAGE = 'editEvent/UPLOAD_IMAGE';
const UPLOAD_IMAGE_SUCCESS = 'editEvent/UPLOAD_IMAGE_SUCCESS';
const UPLOAD_IMAGE_FAILED = 'editEvent/UPLOAD_IMAGE_FAILED';

const DELETE_IMAGE = 'editEvent/DELETE_IMAGE';
const DELETE_IMAGE_SUCCESS = 'editEvent/DELETE_IMAGE_SUCCESS';
const DELETE_IMAGE_FAILED = 'editEvent/DELETE_IMAGE_FAILED';

const initialState = {
  data: {},
  categories: [],
  error: {
    message: '',
  },
  loading: {
    create: false,
    saveEvent: false,
    publishEvent: false,
    loadEvent: false,
    loadCategory: false,
    processImage: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case RESET_EVENT:
    return {
      ...initialState,
    };
  case LOAD_EVENT_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        ...payload,
      },
      loading: {
        ...state.loading,
        loadEvent: false,
      },
    };
  case LOAD_EVENT_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: {
        ...state.loading,
        loadEvent: false,
      },
    };
  case LOAD_EVENT:
    return {
      ...state,
      loading: {
        ...state.loading,
        loadEvent: true,
      },
    };
  case SAVE_EVENT_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        ...payload,
      },
      loading: {
        ...state.loading,
        saveEvent: false,
      },
    };
  case SAVE_EVENT_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: {
        ...state.loading,
        saveEvent: false,
      },
    };
  case SAVE_EVENT:
    return {
      ...state,
      loading: {
        ...state.loading,
        saveEvent: true,
      },
    };
  case CREATE_EVENT_SUCCESS:
    return {
      ...state,
      loading: {
        ...state.loading,
        create: false,
      },
    };
  case CREATE_EVENT_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: {
        ...state.loading,
        create: false,
      },
    };
  case CREATE_EVENT:
    return {
      ...state,
      loading: {
        ...state.loading,
        create: true,
      },
    };
  case PUBLISH_EVENT_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        ...payload,
      },
      loading: {
        ...state.loading,
        publishEvent: false,
      },
    };
  case PUBLISH_EVENT_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: {
        ...state.loading,
        publishEvent: false,
      },
    };
  case PUBLISH_EVENT:
    return {
      ...state,
      loading: {
        ...state.loading,
        publishEvent: true,
      },
    };
  case LOAD_CATEGORIES_SUCCESS:
    return {
      ...state,
      categories: payload,
      loading: {
        ...state.loading,
        loadCategories: false,
      },
    };
  case LOAD_CATEGORIES_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: {
        ...state.loading,
        loadCategories: false,
      },
    };
  case LOAD_CATEGORIES:
    return {
      ...state,
      loading: {
        ...state.loading,
        loadCategories: true,
      },
    };
  case UPLOAD_IMAGE_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        image: payload.image,
      },
      loading: {
        ...state.loading,
        processImage: false,
      },
    };
  case DELETE_IMAGE_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        image: '',
      },
      loading: {
        ...state.loading,
        processImage: false,
      },
    };
  case UPLOAD_IMAGE_FAILED:
  case DELETE_IMAGE_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: {
        ...state.loading,
        processImage: false,
      },
    };
  case UPLOAD_IMAGE:
  case DELETE_IMAGE:
    return {
      ...state,
      loading: {
        ...state.loading,
        processImage: true,
      },
    };
  default:
    return state;
  }
};

export const resetEvent = createAction(RESET_EVENT);

const createEventSuccess = createAction(CREATE_EVENT_SUCCESS);
const createEventFailed = createAction(CREATE_EVENT_FAILED);

export const createEvent = history => (dispatch, getState, api) => {
  dispatch({ type: CREATE_EVENT });

  api.post({ path: 'management/activity', body: initialEventValues })
    .then((res) => {
      dispatch(createEventSuccess());
      history.push(`/event/edit/${res.activity.id}/details`);
      dispatch(initialize('create-event-details', res));
    })
    .catch((err) => {
      dispatch(createEventFailed({ message: err.data }));
    });
};

const loadEventSuccess = createAction(LOAD_EVENT_SUCCESS);
const loadEventFailed = createAction(LOAD_EVENT_FAILED);

const parseEventValues = (data, defaultData) => Object.entries(data).reduce((acc, curr) => {
  if (checkEmptyValue(curr[1]) && defaultData && Object.keys(defaultData).includes(curr[0])) {
    return { ...acc, [curr[0]]: defaultData[curr[0]] };
  }
  return { ...acc, [curr[0]]: curr[1] };
}, {});

export const loadEvent = payload => (dispatch, getState, api) => {
  dispatch({ type: LOAD_EVENT });

  // same api endpoint as get in event, different functionality
  api.get({ path: `management/activity/${payload}` })
    .then((res) => {
      const resWithTimeFormat = {
        ...res,
        start_date: res.start_date ? moment.utc(res.start_date).tz('Europe/Amsterdam').format() : null,
        end_date: res.end_date ? moment.utc(res.end_date).tz('Europe/Amsterdam').format() : null,
      };

      dispatch(loadEventSuccess(resWithTimeFormat));

      dispatch(initialize('create-event-details', parseEventValues(resWithTimeFormat, getState().form['create-event-details'].values)));
    })
    .catch((err) => {
      dispatch(loadEventFailed({ message: err.data }));
    });
};

const saveEventSuccess = createAction(SAVE_EVENT_SUCCESS);
const saveEventFailed = createAction(SAVE_EVENT_FAILED);

export const saveEvent = event => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: SAVE_EVENT });

    // make copy from event object so we can later delete data without deleting them from redux
    const data = { ...event };
    const eventId = data.id;

    // Exclude these values from save, otherwise BE will throw a 400 bad request
    [
      'attendee_percentage', 'image', 'dealer', 'ticket', 'invited', 'share_link', 'created',
      'activity_update', 'category', 'id', 'updated', 'dealer_id', 'bookings', 'attendee',
      'published_at', 'revenue', 'interested', 'infix', 'favorite', 'share_description',
      'user_created_id', 'canceled', 'published', 'draft',
    ].forEach((val) => {
      delete data[val];
    });

    // Input from user is always Amsterdam time despite where they are (if working remote)
    data.start_date = moment.tz(data.start_date, 'Europe/Amsterdam').format();
    data.end_date = moment.tz(data.end_date, 'Europe/Amsterdam').format();

    api.put({ path: `management/activity/${eventId}`, body: data })
      .then((response) => {
        resolve(response);
        dispatch(saveEventSuccess(response));
      })
      .catch((error) => {
        reject(error);
        dispatch(showNotification('Er is iets mis gegaan, probeer het nogmaals.'));
        dispatch(saveEventFailed({ message: error.data }));
      });
  })
);

const submitEventToReviewSuccess = createAction(SUBMIT_TO_REVIEW_SUCCESS);
const submitEventToReviewFailed = createAction(SUBMIT_TO_REVIEW_FAILED);

export const submitEventToReview = id => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: SUBMIT_TO_REVIEW });

    api.put({ path: `management/activity/${id}/review` })
      .then((res) => {
        resolve(res);
        dispatch(showNotification('Het event is succesvol ingediend.', 'green'));
        dispatch(submitEventToReviewSuccess(res));
      })
      .catch((err) => {
        reject(err);
        dispatch(showNotification('Er is iets mis gegaan, probeer het nogmaals.'));
        dispatch(submitEventToReviewFailed({ message: err.data }));
      });
  })
);

const publishEventSuccess = createAction(PUBLISH_EVENT_SUCCESS);
const publishEventFailed = createAction(PUBLISH_EVENT_FAILED);

export const publishEvent = id => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: PUBLISH_EVENT });

    api.put({ path: `management/activity/${id}/publish` })
      .then((res) => {
        resolve(res);
        dispatch(showNotification('Het event is succesvol gepubliceerd.', 'green'));
        dispatch(publishEventSuccess(res));
      })
      .catch((err) => {
        reject(err);
        dispatch(showNotification('Er is iets mis gegaan, probeer het nogmaals.'));
        dispatch(publishEventFailed({ message: err.data }));
      });
  })
);

const loadCategoriesSuccess = createAction(LOAD_CATEGORIES_SUCCESS);
const loadCategoriesFailed = createAction(LOAD_CATEGORIES_FAILED);

export const loadCategories = () => (dispatch, getState, api) => {
  dispatch({ type: LOAD_CATEGORIES });

  api.get({ path: 'activity/category' })
    .then((res) => {
      dispatch(loadCategoriesSuccess(res));
    })
    .catch((err) => {
      dispatch(loadCategoriesFailed({ message: err.data }));
    });
};

const uploadImageSuccess = createAction(UPLOAD_IMAGE_SUCCESS);
const uploadImageFailed = createAction(UPLOAD_IMAGE_FAILED);

export const uploadImage = (eventId, file) => (dispatch, getState, api) => {
  dispatch({ type: UPLOAD_IMAGE });

  const data = new FormData();
  data.append('image', file);

  api.post({ path: `management/activity/${eventId}/image`, body: data, upload: true })
    .then((res) => {
      dispatch(showNotification('De afbeelding is succesvol opgeslagen.', 'green'));
      dispatch(uploadImageSuccess(res));
    })
    .catch((err) => {
      dispatch(showNotification('Er is iets mis gegaan, probeer het nogmaals.'));
      dispatch(uploadImageFailed({ message: err.data }));
    });
};


const deleteImageSuccess = createAction(DELETE_IMAGE_SUCCESS);
const deleteImageFailed = createAction(DELETE_IMAGE_FAILED);

export const deleteImage = eventId => (dispatch, getState, api) => {
  dispatch({ type: DELETE_IMAGE });

  api.del({ path: `management/activity/${eventId}/image` })
    .then((res) => {
      dispatch(showNotification('De afbeelding is succesvol verwijderd.', 'green'));
      dispatch(deleteImageSuccess(res));
    })
    .catch((err) => {
      dispatch(showNotification('Er is iets mis gegaan, probeer het nogmaals.'));
      dispatch(deleteImageFailed({ message: err.data }));
    });
};
