import React from 'react';
import { Switch, Route } from 'react-router-dom';

import RewardsOverview from './components/RewardsOverview';
import RewardsEditor from './components/RewardsEditor';

const Rewards = () => (
  <Switch>
    <Route path="/rewards/edit/:rewardId?" component={RewardsEditor} />
    <Route path="/rewards" component={RewardsOverview} />
  </Switch>
);

export default Rewards;
