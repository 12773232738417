import styled from 'styled-components';

import { media } from 'styles/utils';

import { Link } from 'common';

export const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 35px 0px;

  ${media.tablet`
    width: 240px;
    height: calc(100vh - 400px);
    border-right: 2px solid ${props => props.theme.border};
    padding: 35px 20px 35px 0px;
  `}
`;

export const ExtendedLink = styled(Link)`
  font-size: 18px;
  margin-bottom: 28px;
`;
