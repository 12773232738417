import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import PT from 'prop-types';

import { getDriverCEMPackages } from 'ducks/driver/driverCEMPackages';
import { getUserChannels } from 'ducks/driver/channel';
import { showNotification } from 'ducks/notification';

import { CEMPackageCard } from 'modules/CEM/components';
import CommunicationPreferencesDropdown from 'modules/DriverDetail/components/CommunicationPreferencesDropdown';
import { Loading, EmptyState, Container, Button } from 'common';

import { CommunicationPreferencesHeader } from './styled';

class DriverTabsCEMPackageOverview extends Component {
  componentWillMount() {
    this.props.getDriverCEMPackages(this.props.driver.id);
    this.props.getUserChannels(this.props.driver.id);
  }

  getCommuncationLink = () => {
    navigator.clipboard.writeText(`${window.location.origin}/redirect/communication_settings`)
      .then(() => {
        this.props.showNotification('De link is succesvol gekopieerd', 'green');
      });
  }

  render() {
    const { CEMPackages, match, history, loading } = this.props;

    return (
      <Container>
        <CommunicationPreferencesHeader>
          <CommunicationPreferencesDropdown />
          <Button small variant="black" onClick={() => this.getCommuncationLink()}>
            Kopieer deeplink app-instellingen
          </Button>
        </CommunicationPreferencesHeader>
        {loading ? (
          <Loading />
        ) : CEMPackages.length > 0 ?
          CEMPackages.map(CEMPackage => (
            <CEMPackageCard
              data={CEMPackage.package}
              completed={!!CEMPackage.completed_on}
              key={CEMPackage.id}
              onClick={() => history.push(`${match.url}/${CEMPackage.id}`)}
            />
          )) : (
            <EmptyState
              title="Nog geen CEM pakketten"
              subtitle="Gebruiker heeft tot op heden nog geen CEM pakketten ontvangen"
            />
          )}
      </Container>
    );
  }
}

DriverTabsCEMPackageOverview.propTypes = {
  CEMPackages: PT.arrayOf(PT.shape({
    completed_on: PT.string,
    id: PT.string.isRequired,
    package: PT.shape({
      id: PT.string,
      name: PT.string.isRequired,
      description: PT.string.isRequired,
      description_short: PT.string.isRequired,
      description_start: PT.string.isRequired,
      image: PT.string,
      contact_company: PT.string.isRequired,
      contact_email: PT.string.isRequired,
      contact_name: PT.string.isRequired,
      contact_phone: PT.string.isRequired,
      stages: PT.arrayOf(PT.shape({
        id: PT.string.isRequired,
        name: PT.string.isRequired,
        ordinal: PT.number.isRequired,
        description: PT.string,
      })),
    }),
    stage_id: PT.string.isRequired,
    user_id: PT.string,
  })),
  loading: PT.bool,
  history: PT.object.isRequired,
  match: PT.object.isRequired,
  driver: PT.shape({
    id: PT.string.isRequired,
  }),
  getDriverCEMPackages: PT.func.isRequired,
  showNotification: PT.func.isRequired,
  getUserChannels: PT.func.isRequired,
};

const mapStateToProps = state => ({
  CEMPackages: state.driverCEMPackages.data,
  driver: state.driver.data,
  loading: state.driverCEMPackages.loading.get,
});

const mapDispatchToProps = {
  getDriverCEMPackages,
  showNotification,
  getUserChannels,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(DriverTabsCEMPackageOverview);
