import styled from 'styled-components';

export const ActionsList = styled.ul`
  margin: 0;
  padding: 0;
  border: 1px solid ${props => props.theme.border.withBoxShadow};
  box-shadow: ${props => props.theme.boxShadow};
  width: 200px;
  position: absolute;
  top: 40px;
`;

export const ActionsListItem = styled.li`
  list-style: none;
  text-align: center;
  border-bottom: 1px solid ${props => props.theme.border};
  background-color: ${props => props.theme.white};
  font-weight: 700;
  text-transform: uppercase;
  font-size: 12px;
  padding: 5px;
  cursor: pointer;

  &:last-of-type {
    border: 0;
  }

  &:hover {
    background-color: ${props => props.theme.border};
    color: ${props => props.theme.white};
  }
`;

export const DropdownActions = styled.div`
  display: flex;
  flex-direction: row-reverse;
  position: relative;
`;
