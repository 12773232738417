import React, { Component } from 'react';
import PT from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, getFormValues, initialize, isDirty } from 'redux-form';

import * as validation from 'services/validations';
import { hideModal } from 'ducks/modal';
import { Title, Subtitle, Button, LabelTag, Info } from 'common';
import { Form, FormField, Label, InputText } from 'common/form';
import { showNotification } from 'ducks/notification';
import { resendOrder, getOrders } from 'ducks/orders';

import { OrdersInfoRow } from '../OrdersCard/styled';
import { ModalContainer, ModalContent, ModalInfoContainer } from './styled';

class OrdersModal extends Component {
  componentDidMount() {
    this.props.dispatch(initialize('edit-order', {
      mobile_number: this.props.data.user.mobile_number,
      zipcode: this.props.data.user.address.zipcode,
      infix: this.props.data.user.address.infix,
      country: this.props.data.user.address.country,
      city: this.props.data.user.address.city,
      street: this.props.data.user.address.street,
      house_number: this.props.data.user.address.house_number,
      firstname: this.props.data.user.firstname,
      surname: this.props.data.user.surname,
      nameInfix: this.props.data.user.infix,
    }));
  }

  handleSave = (values) => {
    this.props.resend({
      ...this.props.data,
      user: {
        ...this.props.data.user,
        mobile_number: values.mobile_number,
        firstname: values.firstname,
        surname: values.surname,
        infix: values.nameInfix,
        address: {
          ...this.props.data.user.address,
          zipcode: values.zipcode,
          house_number: values.house_number,
          infix: values.infix,
          country: values.country,
          city: values.city,
          street: values.street,
        },
      },
    })
      .then(() => {
        this.props.onShowNotification('Order successfully sent.', 'green');
        this.props.hideOrdersModal();
        this.props.onGetOrders();
      })
      .catch(() => {
        this.props.onShowNotification('Failed to send the order. Try again later.');
      });
  }

  render() {
    const { data, handleSubmit, dirty } = this.props;

    return (
      <ModalContainer>
        <ModalContent>
          <Title>{data.products[0].product_name}</Title>
          <Subtitle>{data.reason}</Subtitle>
          <ModalInfoContainer>
            <Info label="Email">
              {data.user.email}
            </Info>
            <OrdersInfoRow>
              <Info label="Psp reference">
                {data.psp_reference}
              </Info>
            </OrdersInfoRow>
            <OrdersInfoRow>
              <Info label="Alienordernumber">
                {data.external_id}
              </Info>
              <Info label="Ticket ids">
                {data.products.map((product, i) => (
                  <span key={product.id}>
                    {i > 0 && ', '}
                    {product.unique_product_id}
                  </span>
                ))}
              </Info>
            </OrdersInfoRow>
          </ModalInfoContainer>
          <Form onSubmit={handleSubmit(this.handleSave)}>
            <FormField>
              <Label>First name</Label>
              <Field
                component={InputText}
                name="firstname"
                validate={validation.required}
              />
            </FormField>
            <FormField>
              <Label>Middle name</Label>
              <Field
                component={InputText}
                name="nameInfix"
              />
            </FormField>
            <FormField>
              <Label>Last name</Label>
              <Field
                component={InputText}
                name="surname"
                validate={validation.required}
              />
            </FormField>
            <FormField>
              <Label>Mobile number</Label>
              <Field
                component={InputText}
                name="mobile_number"
                validate={validation.telephone}
              />
            </FormField>
            <FormField>
              <Label>Country</Label>
              <Field
                component={InputText}
                name="country"
                validate={validation.required}
              />
            </FormField>
            <FormField>
              <Label>City</Label>
              <Field
                component={InputText}
                name="city"
                validate={validation.required}
              />
            </FormField>
            <FormField>
              <Label>Zipcode</Label>
              <Field
                component={InputText}
                name="zipcode"
                validate={validation.required}
              />
            </FormField>
            <FormField>
              <Label>Street</Label>
              <Field
                component={InputText}
                name="street"
                validate={validation.required}
              />
            </FormField>
            <FormField>
              <Label>House number</Label>
              <Field
                component={InputText}
                name="house_number"
                validate={validation.required}
              />
            </FormField>
            <FormField>
              <Label>House number addition</Label>
              <Field
                component={InputText}
                name="infix"
              />
            </FormField>
            <Button type="submit">Resend</Button> {dirty && <LabelTag>unsaved changes</LabelTag>}
          </Form>
        </ModalContent>
      </ModalContainer>
    );
  }
}

OrdersModal.propTypes = {
  handleSubmit: PT.func,
  data: PT.object,
  hideOrdersModal: PT.func,
  resend: PT.func,
  onShowNotification: PT.func,
  dirty: PT.bool,
  dispatch: PT.func,
  onGetOrders: PT.func,
};

export default compose(
  connect(
    state => ({
      dirty: isDirty('edit-order'),
      formData: getFormValues('edit-order')(state) || {},
    }),
    {
      hideOrdersModal: hideModal,
      resend: resendOrder,
      onShowNotification: showNotification,
      onGetOrders: getOrders,
    },
  ),
  reduxForm({ form: 'edit-order' }),
)(OrdersModal);
