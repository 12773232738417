import React, { Fragment, Component } from 'react';
import PT from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, getFormValues } from 'redux-form';

import { inviteCSVDrivers } from 'ducks/inviteDrivers';
import { showNotification } from 'ducks/notification';

import { Title, Loading, Modal, Button } from 'common';
import { InputDropZoneFile, Form, FormField, Label, SubLabel, InputText, InputTextarea } from 'common/form';

import { ScrollContainer, ModalContainer, Fade, OverlayContainer } from './styled';

class EventDetailInviteDriversModal extends Component {
  state = {
    notInvitedDrivers: [],
    successfulAmount: 0,
    showFailed: false,
    inviteCSV: false,
    copied: false,
  }

  onUpload = (file) => {
    const values = this.props.formData;
    const id = this.props.ticketId || this.props.eventId;
    const isTicket = !!this.props.ticketId;

    this.props.inviteCSVDrivers(id, values, file, isTicket)
      .then((res) => {
        if (res.not_found.length === 0) {
          this.props.hideModal();
          this.props.showNotification(`Alle uitnodigingen (${res.found}) zijn succesvol verstuurd`, 'green');
        } else {
          this.setState({
            notInvitedDrivers: res.not_found,
            successfulAmount: res.found,
            showFailed: true,
          });
        }
      }).catch(() => {
        this.props.showNotification('Er is iets misgegaan bij het uploaden. Probeer nog eens.');
      });
  }

  copyFailedInvites = () => {
    navigator.clipboard.writeText(this.state.notInvitedDrivers.join('\n'));

    this.setState({ copied: true });

    setTimeout(() => this.setState({ copied: false }), 1500);
  }

  render() {
    const { loading, history, eventId, hideModal, ticketId, title } = this.props;

    return (
      <Modal
        onHideModal={hideModal}
        large
      >
        <ModalContainer>
          {this.state.inviteCSV ? (
            <Fragment>
              <Title small underline>CSV invite: {title}</Title>
              {!this.state.showFailed && !loading && (
                <Form>
                  <p>Let op! Drivers worden meteen uitgenodigd bij upload. E-mailadressen die niet bekend zijn bij P24 zullen geen uitnodiging ontvangen.</p>
                  <FormField>
                    <Label>Onderwerp</Label>
                    <Field
                      name="subject"
                      component={InputText}
                      type="text"
                    />
                  </FormField>

                  <FormField>
                    <Label>Bericht</Label>
                    <SubLabel groupWithLabel>Uw bericht begint automatisch met: Beste [Rijdersnaam],</SubLabel>
                    <Field
                      name="message"
                      component={InputTextarea}
                    />
                  </FormField>

                  <FormField>
                    <Label>Upload CSV</Label>
                    <SubLabel groupWithLabel>Na het uploaden worden de uitnodigingen meteen verstuurd</SubLabel>
                    <InputDropZoneFile
                      onUpload={this.onUpload}
                      label="Upload csv"
                      fullHeight
                    />
                  </FormField>
                </Form>
              )}

              {!loading && this.state.showFailed && this.state.notInvitedDrivers.length > 0 && (
                <Fragment>
                  <p>
                    Er {this.state.successfulAmount === 1 ? 'is 1 driver' : `zijn ${this.state.successfulAmount} drivers`} succesvol uitgenodigd.
                    {this.state.notInvitedDrivers.length === 1 ? ' 1 driver heeft' : ` ${this.state.notInvitedDrivers.length} drivers hebben`} geen P24 account en dus geen uitnodiging ontvangen:
                  </p>
                  <Button small sub onClick={this.copyFailedInvites}>{this.state.copied ? 'Gekopieerd' : 'Kopieer deze lijst'}</Button>
                  <OverlayContainer>
                    <Fade />
                    <ScrollContainer>
                      <ul>
                        {this.state.notInvitedDrivers.map(email => (
                          <li>{email}</li>
                        ))}
                      </ul>
                    </ScrollContainer>
                  </OverlayContainer>
                </Fragment>
              )}

              {loading && <Loading small />}
            </Fragment>
          ) : (
            <Fragment>
              <Title small>Hoe wil je drivers uitnodigen?</Title>
              <Button onClick={() => history.push(`/event/${eventId}/invite${ticketId ? `/${ticketId}/select` : ''}`)}>Uitnodigen met P24 drivers list</Button>
              <Button onClick={() => this.setState({ inviteCSV: true })}>Uitnodigen met CSV</Button>
            </Fragment>
          )}
        </ModalContainer>
      </Modal>
    );
  }
}

EventDetailInviteDriversModal.propTypes = {
  inviteCSVDrivers: PT.func,
  eventId: PT.string,
  showNotification: PT.func,
  loading: PT.bool,
  formData: PT.object,
  history: PT.object,
  ticketId: PT.string,
  hideModal: PT.func,
  title: PT.string,
};

export default compose(
  withRouter,
  connect(state => ({
    loading: state.inviteDrivers.loading.inviteCSV,
    formData: getFormValues('invite-drivers-csv')(state) || {},
  }), { inviteCSVDrivers, showNotification }),
  reduxForm({ form: 'invite-drivers-csv' }),
)(EventDetailInviteDriversModal);
