import React, { Fragment } from 'react';
import PT from 'prop-types';
import { FieldStyle, TagStyle } from './styled';

const Tag = ({
  id, name, label, handleOnClick, defaultChecked, error,
}) => (
  <Fragment>
    <FieldStyle
      name={name}
      type="checkbox"
      checked={defaultChecked.includes(id)}
      value={id}
      id={`${id}`}
      onChange={e => handleOnClick(e.currentTarget.value)}
    />
    <TagStyle htmlFor={`${id}`} error={error}>
      {label}
    </TagStyle>
  </Fragment>
);

Tag.propTypes = {
  id: PT.string,
  defaultChecked: PT.array,
  name: PT.string,
  label: PT.string,
  error: PT.oneOfType([PT.string, PT.bool]),
  handleOnClick: PT.func,
};

export default Tag;
