import React, { Component, Fragment } from 'react';
import PT from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';

import { hasRole, COMMUNITY_MANAGER } from 'services/authHelper';
import { uploadCEMPackage, getCEMPackage } from 'ducks/CEMPackage';
import { Container, Title, Subtitle, Button, Loading } from 'common';
import EventPreviewHeader from 'modules/EventPreview/components/EventPreviewHeader';
import EventPreviewContactBlock from 'modules/EventPreview/components/EventPreviewContactBlock';
import { CEMPackageStages, CEMPackageCSVUpload } from '../';

import { Description, Aside, Main, Note, NoteLabel, DetailSection } from './styled';

class CEMPackageDetail extends Component {
  componentWillMount() {
    this.props.getCEMPackage(this.props.match.params.id);
  }

  render() {
    const { history, CEMPackage, loading, roles, uploading } = this.props;

    if (!CEMPackage || loading) return <Container><Loading /></Container>;

    return (
      <Fragment>
        <Container>
          <Button
            variant="black"
            small
            onClick={() => history.push('/cem/packages')}
          >
            Terug naar overzicht
          </Button>
        </Container>
        <EventPreviewHeader image={CEMPackage.image} />
        <Main>
          <Container>
            <DetailSection>
              <Title noWhitespace>{CEMPackage.name}</Title>
              <Description>{CEMPackage.description}</Description>
            </DetailSection>
            <DetailSection>
              <Note>
                <NoteLabel>Hoe activeer je dit pakket?</NoteLabel>
                <Description bold>{CEMPackage.description_start}</Description>
              </Note>
            </DetailSection>
            <DetailSection>
              <Subtitle bold>Stages</Subtitle>
              <CEMPackageStages stages={_.orderBy(CEMPackage.stages, 'ordinal')} />
            </DetailSection>
          </Container>
          <Aside>
            {hasRole(roles, [COMMUNITY_MANAGER]) && (
              <DetailSection>
                <Subtitle bold>Upload</Subtitle>
                <CEMPackageCSVUpload
                  packageId={CEMPackage.id}
                  onUpload={this.props.uploadCEMPackage}
                  loading={uploading}
                />
              </DetailSection>
            )}
            <DetailSection>
              <EventPreviewContactBlock
                noMargin
                contactRole={CEMPackage.contact_company}
                data={{
                  contact_person: CEMPackage.contact_name,
                  email: CEMPackage.contact_email,
                  phone: CEMPackage.contact_phone,
                }}
              />
            </DetailSection>
          </Aside>
        </Main>
      </Fragment>
    );
  }
}

CEMPackageDetail.propTypes = {
  roles: PT.array,
  history: PT.object.isRequired,
  CEMPackage: PT.shape({
    id: PT.string,
    name: PT.string.isRequired,
    description: PT.string.isRequired,
    description_short: PT.string.isRequired,
    description_start: PT.string.isRequired,
    image: PT.string,
    contact_company: PT.string.isRequired,
    contact_email: PT.string.isRequired,
    contact_name: PT.string.isRequired,
    contact_phone: PT.string.isRequired,
    stages: PT.arrayOf(PT.shape({
      id: PT.string.isRequired,
      name: PT.string.isRequired,
      ordinal: PT.number.isRequired,
      description: PT.string,
    })),
  }),
  getCEMPackage: PT.func.isRequired,
  loading: PT.bool,
  uploading: PT.bool,
  uploadCEMPackage: PT.func.isRequired,
  match: PT.object.isRequired,
};

export default compose(
  withRouter,
  connect(state => ({
    roles: state.authentication.roles,
    CEMPackage: state.CEMPackage.data,
    loading: state.CEMPackage.loading.get,
    uploading: state.CEMPackage.loading.upload,
  }), { getCEMPackage, uploadCEMPackage }),
)(CEMPackageDetail);
