import styled from 'styled-components';

import { Subtitle } from 'common';

export const ModalContainer = styled.div`
  width: 650px;
  max-width: 100%;
`;

export const ModalContent = styled.div`
  padding: 36px 54px;
  width: 50%;
`;

export const ImagePreview = styled.div`
  background-image: url('${props => props.image}');
  background-size: cover;
  background-position: center;
  height: 100%;
`;

export const ImageContainer = styled.div`
  width: 50%;
`;

export const InputSection = styled.div`
  margin: 0 0 10px 0;
`;

export const UserName = styled(Subtitle)`
  font-size: 16px;
  margin: 0 0 24px 0;
`;
