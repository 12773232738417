import styled, { css } from 'styled-components';
import PT from 'prop-types';

const Wrapper = styled.div`
  background-color: ${props => props.theme[props.variant]};

  ${props => props.image && css`
    background-image: url('${props.image}');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  `}
`;

Wrapper.defaultProps = {
  variant: 'black',
};

Wrapper.propTypes = {
  variant: PT.string,
  image: PT.string,
};

export default Wrapper;
