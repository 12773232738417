import styled, { css } from 'styled-components';
import PT from 'prop-types';

export const Row = styled.tr`
  color: ${props => props.theme.black};
  cursor: pointer;

  ${props => props.invite && css`
    cursor: default;
  `}
`;

Row.propTypes = {
  invite: PT.bool,
};

export const Column = styled.td`
  font-size: 15px;
  letter-spacing: 0.05em;
  padding-right: 18px;
  border-bottom: 1px solid ${props => props.theme.border.withBoxShadow};

  &:first-of-type {
    padding: 8px 18px;
  }

  ${props => props.padding && css`
    padding: 8px 18px 8px 0;
  `}

  ${props => props.ellipsis && css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: ${props.ellipsis};
  `}
`;

Column.propTypes = {
  ellipsis: PT.string,
  padding: PT.bool,
};

export const Name = styled.p`
  display: block;
  margin: 0;
`;

export const Status = styled.p`
  color: ${props => props.theme.gray};
  font-size: 10px;
  letter-spacing: 1px;
  font-weight: 400;
  line-height: normal;
  margin: 0;
`;

export const Dealer = styled.p`
  margin: 5px 0;
`;
