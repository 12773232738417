import React, { Component, Fragment } from 'react';
import PT from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  hasRole,
  EVENT_MANAGER,
} from 'services/authHelper';

import { getValidateCars } from 'ducks/validateCars';
import { showModal, hideModal } from 'ducks/modal';

import { Container, EmptyState, Loading, Title, Anchor, FlexWrapper } from 'common';
import ValidationCard from './components/ValidationCard';

import { Cars, Summary } from './styled';

class ValidateCars extends Component {
  state = {
    filter: 'all',
  }

  componentDidMount() {
    if (hasRole(this.props.roles, [EVENT_MANAGER])) {
      this.props.history.push('/');
    }

    this.props.getValidateCars();
  }

  componentWillUnmount() {
    this.props.hideCarsModal();
  }

  filterCars = (cars) => {
    if (this.state.filter === 'all') return cars;

    return cars.filter(car => car.state === this.state.filter);
  }

  render() {
    const { loading, cars, showCarsModal } = this.props;

    return (
      <Container>
        {loading && <Loading />}
        {cars.length === 0 && !loading && (
          <EmptyState
            title="Alle Porsche rijders zijn up-to-date"
            subtitle="Er zijn geen auto's om te valideren. Nieuwe claims zullen hier verschijnen."
          />
        )}
        {cars.length > 0 && !loading && (
          <Fragment>
            <Title>Valideer auto&apos;s</Title>
            <Summary>
              <Cars>{cars.length} ongevalideerde</Cars> auto{cars.length > 1 && "'s"}
            </Summary>
            <FlexWrapper>
              <Anchor
                active={this.state.filter === 'all'}
                onClick={() => this.setState({ filter: 'all' })}
              >
                Alles
              </Anchor>
              <Anchor
                active={this.state.filter === 'new'}
                onClick={() => this.setState({ filter: 'new' })}
              >
                Nieuw
              </Anchor>
              <Anchor
                active={this.state.filter === 'proof'}
                onClick={() => this.setState({ filter: 'proof' })}
              >
                Heeft foto
              </Anchor>
              <Anchor
                active={this.state.filter === 'pending'}
                onClick={() => this.setState({ filter: 'pending' })}
              >
                In afwachting
              </Anchor>
            </FlexWrapper>
            <Container>
              {this.filterCars(cars).map(car => (
                <ValidationCard
                  showCarsModal={showCarsModal}
                  data={car}
                  key={car.id}
                />
              ))}
            </Container>
          </Fragment>
        )}
      </Container>
    );
  }
}

ValidateCars.propTypes = {
  roles: PT.array,
  history: PT.object,
  getValidateCars: PT.func,
  hideCarsModal: PT.func,
  showCarsModal: PT.func,
  loading: PT.bool,
  cars: PT.oneOfType([PT.array, PT.object]),
};

const mapStateToProps = state => ({
  roles: state.authentication.roles,
  loading: state.validateCars.loading.list,
  cars: state.validateCars.data,
});

const mapDispatchToProps = dispatch => ({
  showCarsModal: (car) => {
    dispatch(showModal({
      type: 'VALIDATE_CARS',
      modalProps: { data: car },
    }));
  },
  hideCarsModal: () => {
    dispatch(hideModal());
  },
  getValidateCars: () => {
    dispatch(getValidateCars());
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ValidateCars);
