import React, { Component } from 'react';
import PT from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, change, getFormValues } from 'redux-form';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import _ from 'lodash/fp';

import { getDrivers, resetDrivers } from 'ducks/drivers';

import { Container, Pagination, Loading } from 'common';
import FiltersHeader from './components/FiltersHeader';
import DriversList from './components/DriversList';
import SidebarFilters from './components/SidebarFilters';

import { InnerContainer, ContentContainer } from './styled';

class DriversOverview extends Component {
  componentDidMount() {
    if (this.props.history.location.search) {
      // Parse query string
      const query = qs.parse(this.props.history.location.search, { ignoreQueryPrefix: true });

      // No need to call getDrivers, because change triggers componentWillReceiveProps
      Object.keys(query).forEach((filter) => {
        this.props.dispatch(change('drivers-filters', filter, query[filter]));
      });
    } else {
      this.props.getDrivers();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.filterValues !== nextProps.filterValues) {
      this.updateFilter(nextProps);
    }
  }

  componentWillUnmount() {
    this.props.resetDrivers();
  }

  onHandlePageClick = page => (
    this.props.dispatch(change('drivers-filters', 'page', page))
  );

  updateFilter = _.debounce(500)((props) => {
    this.props.getDrivers(props.filterValues);

    const query = qs.stringify(props.filterValues);
    this.props.history.push({ search: query });
  })

  render() {
    const {
      drivers, results, limit, loading, stats, invite, filterValues,
    } = this.props;

    if (Object.keys(filterValues).length === 0) return null;

    return (
      <Container>
        <FiltersHeader stats={stats} invite={invite} />
        <InnerContainer>
          <SidebarFilters />
          <ContentContainer>
            {loading
              ? <Loading />
              : <DriversList drivers={drivers} invite={invite} />
            }
            {results > limit && (
              <Pagination
                page={filterValues.page && (parseInt(filterValues.page, 10) - 1)}
                pageCount={results / limit}
                handlePageClick={e => this.onHandlePageClick(e.selected + 1)}
              />
            )}
          </ContentContainer>
        </InnerContainer>
      </Container>
    );
  }
}

DriversOverview.propTypes = {
  drivers: PT.array,
  getDrivers: PT.func,
  results: PT.number,
  limit: PT.number,
  dispatch: PT.func,
  filterValues: PT.object,
  loading: PT.bool,
  stats: PT.object,
  history: PT.object,
  invite: PT.bool,
  resetDrivers: PT.func,
};

export default compose(
  reduxForm({
    form: 'drivers-filters',
    initialValues: {
      classic: '',
      status: ['active'],
      limit: '10',
    },
  }),
  connect(state => ({
    drivers: state.drivers.data,
    results: state.drivers.results,
    limit: state.drivers.limit,
    stats: state.drivers.stats,
    loading: state.drivers.loading.drivers,
    filterValues: getFormValues('drivers-filters')(state),
  }), { getDrivers, resetDrivers }),
  withRouter,
)(DriversOverview);
