import createAction from 'services/createAction';

const GET_ACTIVITIES = 'getActivities/GET';
const GET_ACTIVITIES_SUCCESS = 'getActivities/GET_SUCCESS';
const GET_ACTIVITIES_FAILED = 'getActivities/GET_FAILED';

const initialState = {
  data: [],
  driverId: '',
  error: {
    message: '',
  },
  loading: {
    activities: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case GET_ACTIVITIES_SUCCESS:
    return {
      ...state,
      ...payload,
      loading: { ...state.loading, activities: false },
    };
  case GET_ACTIVITIES_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, activities: false },
    };
  case GET_ACTIVITIES:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, activities: true },
    };
  default:
    return state;
  }
};

const getActivitiesSuccess = createAction(GET_ACTIVITIES_SUCCESS);
const getActivitiesFailed = createAction(GET_ACTIVITIES_FAILED);

export const getActivitiesFromDriver = driverId => (dispatch, getState, api) => {
  dispatch({ type: GET_ACTIVITIES });

  api.get({ path: `management/user/${driverId}/activities` })
    .then((res) => {
      dispatch(getActivitiesSuccess({ data: res, driverId }));
    })
    .catch((err) => {
      dispatch(getActivitiesFailed({ message: err.data }));
    });
};
