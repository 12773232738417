import React from 'react';
import PT from 'prop-types';

import Search from 'vectors/search.svg';

import { SearchContainer, InputStyled, IconContainer } from './styled';

const InputSearch = ({ input, ...otherProps }) => (
  <SearchContainer>
    <IconContainer>
      <Search />
    </IconContainer>
    <InputStyled {...input} {...otherProps} />
  </SearchContainer>
);

InputSearch.propTypes = {
  input: PT.object,
};

export default InputSearch;
