import React, { Component, Fragment } from 'react';
import PT from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import blueconic from 'services/blueconic';
import tealium from 'services/tealium';

import { EventPreview } from 'modules';

import { getEvent, getTickets } from 'ducks/publicEvent';

class EventShare extends Component {
  static fetchData(store, match) {
    return Promise.all([
      store.dispatch(getTickets(match.params.id)),
      store.dispatch(getEvent(match.params.id)),
    ]);
  }

  componentDidMount() {
    if (Object.keys(this.props.event).length > 0) return;

    this.props.getTickets(this.props.match.params.id);
    this.props.getEvent(this.props.match.params.id);
  }

  render() {
    const { event, tickets, history } = this.props;

    return (
      <Fragment>
        <Helmet
          meta={[
            { property: 'og:title', content: `${event.name}` },
            { property: 'og:image', content: `${event.image}` },
            { property: 'og:site-name', content: 'Porsche24' },
            { property: 'og:description', content: `${event.share_description}` },
          ]}
        >
          <title>
            {`Porsche24 ${Object.keys(event).length > 0 ? `| ${event.name}` : ''}`}
          </title>
        </Helmet>
        {process.env.APP_ENV === 'production' && (
          <Fragment>
            <Helmet script={[{ type: 'text/javascript', innerHTML: blueconic }]} />
            <Helmet script={[{ type: 'text/javascript', innerHTML: tealium }]} />
          </Fragment>
        )}

        <EventPreview
          publicEvent={{ ...event, ticket: tickets }}
          history={history}
          showHeader
        />
      </Fragment>
    );
  }
}

EventShare.propTypes = {
  event: PT.object,
  tickets: PT.array,
  history: PT.object,
  match: PT.object,
  getEvent: PT.func,
  getTickets: PT.func,
};

export default compose(
  withRouter,
  connect(state => ({
    event: state.publicEvent.data,
    tickets: state.publicEvent.tickets,
  }), {
    getEvent,
    getTickets,
  }),
)(EventShare);
