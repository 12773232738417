import styled, { css } from 'styled-components';
import PT from 'prop-types';

const Card = styled.div`
  padding: 36px;
  box-shadow: ${props => props.theme.boxShadow};
  border: 1px solid ${props => props.theme.border.withBoxShadow};
  margin: 50px 22px 0;
  background-color: ${props => props.theme.white};

  ${props => props.compact && css`
    margin: 18px 0;
    padding: 18px;
  `}

  ${props => props.noPadding && css`
    padding: 0;
  `}

  ${props => props.noMargin && css`
    margin: 0;
  `}

  ${props => props.onClick && css`
    cursor: pointer;
  `}
`;

Card.propTypes = {
  compact: PT.bool,
  noPadding: PT.bool,
  noMargin: PT.bool,
  onClick: PT.func,
};

export default Card;
