import styled from 'styled-components';

export const InputStyled = styled.input`
  margin: 0 10px;
  height: 100%;
  width: calc(100% - 50px);
  outline: 0;
  border: 0;
  font-size: 16px;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SearchContainer = styled.div`
  width: 100%;
  padding: 0 10px;
  height: 36px;
  background-color: ${props => props.theme.white};
  border: 1px solid ${props => props.theme.border};
  border-radius: 15px;
  display: flex;

  & svg {
    width: 16px;
  }
`;
