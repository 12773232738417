import React, { Component, Fragment } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getFormValues } from 'redux-form';
import onClickOutside from 'react-onclickoutside';
import FileSaver from 'file-saver';
import _ from 'lodash';

import {
  hasRole,
  hasPermissions,
  isOwnDealerEvent,
  isOwnEvent,
  DRIVER_EXPORT,
  COMMUNITY_MANAGER,
  DEALER,
  PARTNER,
  EVENT_MANAGER,
  TICKET_CANCEL,
} from 'services/authHelper';

import { uploadAttendees, exportAttendees, exportInvitees, cancelTickets } from 'ducks/event';

import ArrowDown from 'vectors/ArrowDown.svg';

import { Button, LimitFilter, Modal, Title } from 'common';
import { InputDropZoneFile, InputSearch, InputCheckbox } from 'common/form';

import {
  ActionsContainer, ModalContainer, SearchContainer, AttendingContainer,
  DropdownList, DropdownItem, ButtonContainer, ModalFooter,
} from './styled';

class EventDetailOverviewAttendeesActions extends Component {
  state = {
    showUploadAttendeesModal: false,
    uploadResponse: {},
    showDropdown: false,
    showRefundModal: false,
    giveRefund: false,
  };

  onSearchInvitees = _.debounce((value) => { this.props.onSearch(value); }, 800);

  onUpload = (file) => {
    this.props.onUploadAttendees(this.props.eventId, file).then((res) => {
      this.setState({
        showUploadAttendeesModal: true,
        uploadResponse: res,
      });
    });
  }

  getRefundTicketOwners = (attendees) => {
    // get array with names from array with only ticket_ids
    const attendeesArray = Object.keys(attendees).filter(attendee => attendees[attendee]);
    return attendeesArray.map(attendeeId => this.props.attendees.tickets.find(ticket => ticket.id === attendeeId));
  }

  toggleUploadModal = () => {
    this.setState({
      showUploadAttendeesModal: !this.state.showUploadAttendeesModal,
    });
  }

  toggleRefundModal = () => {
    this.setState({
      showRefundModal: !this.state.showRefundModal,
    }, () => {
      if (!this.state.showRefundModal) {
        // reset checkbox when modal is closed
        this.setState({
          giveRefund: false,
        });

        this.props.onSelectAll(false);
      }
    });
  }

  handleClickOutside = () => {
    this.setState({ showDropdown: false });
  }

  parseUploadResponse = (amount, total) => `${amount}/${total}`;

  exportAttendees = (attendees) => {
    const attendeesArray = Object.keys(attendees).filter(attendee => attendees[attendee]);
    this.props.onExportAttendees(this.props.eventId, attendeesArray).then((blob) => {
      FileSaver.saveAs(blob, `${this.props.event.name} - attendees.csv`);

      this.props.onSelectAll(false);
      this.setState({ showDropdown: false });
    });
  }

  exportInvitees = (attendees) => {
    const attendeesArray = Object.keys(attendees).filter(attendee => attendees[attendee]);
    this.props.onExportInvitees(this.props.eventId, attendeesArray).then((blob) => {
      FileSaver.saveAs(blob, `${this.props.event.name} - invitees.csv`);

      this.props.onSelectAll(false);
      this.setState({ showDropdown: false });
    });
  }

  cancelTickets = (attendees) => {
    const attendeesArray = Object.keys(attendees).filter(attendee => attendees[attendee]);
    this.props.onCancelTickets(this.props.eventId, attendeesArray, this.state.giveRefund).then(() => {
      this.toggleRefundModal();
      this.props.getCurrentAttendees();
    });
  }

  hasPermissionToExportAttendees = (roles, permissions, event, dealer) => {
    if (!hasRole(roles, [DEALER]) && hasPermissions(permissions, [DRIVER_EXPORT])) return true;
    if (hasRole(roles, [DEALER]) && isOwnDealerEvent(event, dealer)) return true;
    return false;
  }

  hasPermissionToExportInvitees = (roles, permissions, event, dealer) => {
    if (!hasRole(roles, [DEALER, PARTNER]) && hasPermissions(permissions, [DRIVER_EXPORT])) return true;
    if (hasRole(roles, [DEALER]) && isOwnDealerEvent(event, dealer)) return true;
    return false;
  }

  hasPermissionToSetToAttending = (roles, permissions, event, dealer, user) => {
    if (hasRole(roles, [COMMUNITY_MANAGER])) return true;
    if (hasRole(roles, [DEALER]) && isOwnDealerEvent(event, dealer)) return true;
    if (hasRole(roles, [PARTNER]) && isOwnEvent(event, user)) return true;
    if (hasRole(roles, [EVENT_MANAGER]) && isOwnDealerEvent(event, dealer)) return true;
    return false;
  }

  hasPermissionToCancel = (roles, permissions, event, dealer, user) => {
    if (hasRole(roles, [COMMUNITY_MANAGER])) return true;
    if (!hasRole(roles, [DEALER]) && hasPermissions(permissions, [TICKET_CANCEL])) return true;
    if (hasRole(roles, [DEALER]) && isOwnDealerEvent(event, dealer)) return true;
    if (hasRole(roles, [PARTNER]) && isOwnEvent(event, user)) return true;
    return false;
  }

  render() {
    const {
      queryValues, onLimitChange, onSelectAll, markedValues,
      changeAttendeeStatus, event, roles, permissions, dealer, user,
    } = this.props;

    return (
      <Fragment>
        <ActionsContainer>
          <AttendingContainer>
            <InputCheckbox
              id="selectAll"
              onChange={e => onSelectAll(e.target.checked)}
            />
            <Button
              variant="black"
              small
              onClick={() => changeAttendeeStatus(markedValues.marked, true)}
              disabled={!this.hasPermissionToSetToAttending(roles, permissions, event, dealer, user)}
            >
              Markeer als aanwezig
            </Button>
            <div>
              <Button
                sub
                small
                square
                onClick={(e) => {
                  e.stopPropagation();
                  this.setState({ showDropdown: !this.state.showDropdown });
                }}
                className="ignore-react-onclickoutside"
              >
                <ArrowDown />
              </Button>

              {this.state.showDropdown &&
                <DropdownList>
                  {this.hasPermissionToSetToAttending(roles, permissions, event, dealer, user) && (
                    <DropdownItem
                      onClick={() => {
                        this.setState({ showDropdown: false });
                        changeAttendeeStatus(markedValues.marked, false);
                      }}
                    >
                      Markeer als &quot;niet aanwezig&quot;
                    </DropdownItem>
                  )}

                  <Fragment>
                    {this.hasPermissionToExportAttendees(roles, permissions, event, dealer) && (
                      <DropdownItem
                        onClick={() => this.exportAttendees(markedValues.marked)}
                      >
                        Exporteer aanwezigen als csv
                      </DropdownItem>
                    )}
                    {this.hasPermissionToExportInvitees(roles, permissions, event, dealer) && (
                      <DropdownItem
                        onClick={() => this.exportInvitees(markedValues.marked)}
                      >
                        Exporteer genodigden als csv
                      </DropdownItem>
                    )}
                  </Fragment>
                  {this.hasPermissionToCancel(roles, permissions, event, dealer, user) && (
                    <DropdownItem
                      onClick={() => this.setState({ showRefundModal: true })}
                    >
                      Annuleer & betaal terug
                    </DropdownItem>
                  )}
                </DropdownList>
              }
            </div>
          </AttendingContainer>

          <LimitFilter
            selectedValue={queryValues.limit}
            onChange={e => onLimitChange(e.value)}
          />

          {!hasRole(roles, [EVENT_MANAGER]) && (
            <InputDropZoneFile
              onUpload={this.onUpload}
              label="Upload csv"
            />
          )}

          <SearchContainer>
            <InputSearch
              placeholder="Zoek Porsche rijders"
              onChange={e => this.onSearchInvitees(e.target.value)}
            />
          </SearchContainer>
        </ActionsContainer>

        {this.state.showUploadAttendeesModal &&
          <Modal onHideModal={this.toggleUploadModal}>
            <ModalContainer>
              <Title small>Deelnemers uploaden</Title>
              <p>
                {this.state.uploadResponse.message}
                {!!this.state.uploadResponse.report.failed_rows.length &&
                  ` (${this.parseUploadResponse(
                    this.state.uploadResponse.report.failed_rows.length,
                    this.state.uploadResponse.report.succes_rows.length + this.state.uploadResponse.report.failed_rows.length,
                  )})`
                }
              </p>
              <ul>
                {this.state.uploadResponse.report.failed_rows.map((attendee, i) => (
                  <li key={i}>{attendee.email ? attendee.email : 'Geen e-mail gespecificeerd'}</li>
                ))}
              </ul>
              <p>
                {`${this.parseUploadResponse(
                  this.state.uploadResponse.report.succes_rows.length,
                  this.state.uploadResponse.report.succes_rows.length + this.state.uploadResponse.report.failed_rows.length,
                )} deelnemers succesvol verwerkt`}
              </p>
              <Button variant="black" onClick={this.toggleUploadModal}>Sluiten</Button>
            </ModalContainer>
          </Modal>
        }

        {this.state.showRefundModal &&
          <Modal onHideModal={this.toggleRefundModal}>
            <ModalContainer>
              <Title small>Weet u het zeker?</Title>
              <p>U staat op het punt om de volgende tickets te annuleren:</p>
              <ul>
                {this.getRefundTicketOwners(markedValues.marked).map(ticket => (
                  <li key={ticket.id}>{ticket.user.name} - {ticket.unique_ticket_id}</li>
                ))}
              </ul>
              <ModalFooter>
                <InputCheckbox
                  id="refund"
                  onChange={e => this.setState({ giveRefund: e.target.checked })}
                  checked={this.state.giveRefund}
                >
                  <span>Tickets terug betalen</span>
                </InputCheckbox>
                <ButtonContainer>
                  <Button variant="black" onClick={this.toggleRefundModal}>Annuleren</Button>
                  <Button onClick={() => this.cancelTickets(markedValues.marked)}>Tickets annuleren</Button>
                </ButtonContainer>
              </ModalFooter>
            </ModalContainer>
          </Modal>
        }
      </Fragment>
    );
  }
}

EventDetailOverviewAttendeesActions.propTypes = {
  queryValues: PT.object,
  onLimitChange: PT.func,
  onUploadAttendees: PT.func,
  eventId: PT.string,
  onSearch: PT.func,
  onSelectAll: PT.func,
  markedValues: PT.object,
  changeAttendeeStatus: PT.func,
  onExportAttendees: PT.func,
  event: PT.object,
  onExportInvitees: PT.func,
  attendees: PT.object,
  onCancelTickets: PT.func,
  getCurrentAttendees: PT.func,
  dealer: PT.object,
  user: PT.object,
  roles: PT.array,
  permissions: PT.array,
};

export default compose(
  connect(state => ({
    eventId: state.event.eventId,
    event: state.event.data,
    attendees: state.event.attendees,
    markedValues: getFormValues('mark-attendees')(state),
    roles: state.authentication.roles,
    permissions: state.authentication.permissions,
    dealer: state.authentication.data.role.dealer,
    user: state.authentication.data,
  }), {
    onUploadAttendees: uploadAttendees,
    onExportAttendees: exportAttendees,
    onExportInvitees: exportInvitees,
    onCancelTickets: cancelTickets,
  }),
  onClickOutside,
)(EventDetailOverviewAttendeesActions);
