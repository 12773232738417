import styled from 'styled-components';

import { media } from 'styles/utils';

import { Container, Anchor, Title } from 'common';

export const ExtendedContainer = styled(Container)`
  padding: 10px 20px;

  ${media.tablet`
    display: flex;
    align-items: center;
  `}
`;

export const Image = styled.img`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  height: 8px;
  width: auto;
  margin-bottom: 20px;
  cursor: pointer;

  ${media.tablet`
    left: 0;
    transform: none;
    margin: 0;
  `}
`;

export const ExtendedTitle = styled(Title)`
  flex: 1;
  padding: 0 36px;
  margin: 0;
  align-self: center;
`;

export const ExtendedAnchor = styled(Anchor)`
  display: flex;
  text-transform: uppercase;
  letter-spacing: 2px;
  align-items: center;
  line-height: 1;

  svg {
    margin-left: 5px;
    height: 12px;
    fill: ${props => props.theme.black};
  }
`;
