import React from 'react';
import PT from 'prop-types';

import AccordionBlock from '../AccordionBlock';
import Block from '../Block';
import Text from '../Text';
import ContentBlock from '../ContentBlock';

const ChecklistBlock = ({ data: { value } }) => (
  <Block>
    <h3>{value.title}</h3>
    <Text html={value.sub_text} />
    {value.checklist_items.map((item, index) => (
      <AccordionBlock
        title={item.value.title}
        checklist
        index={index += 1}
        key={item.id}
      >
        <ContentBlock data={item} />
      </AccordionBlock>
    ))}
  </Block>
);

ChecklistBlock.propTypes = {
  data: PT.object,
};

export default ChecklistBlock;
