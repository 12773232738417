import React, { Fragment, Component } from 'react';
import PT from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import ArrowDown from 'vectors/ArrowDown.svg';

import {
  hasRole,
  isOwnEvent,
  DEALER,
  PARTNER,
  COMMUNITY_MANAGER,
} from 'services/authHelper';
import { isPublished } from 'services/eventHelper';

import { moveUpTicket, getEvent, moveDownTicket } from 'ducks/event';

import { Wrapper, Container, Title, Button } from 'common';
import Ticket from 'modules/EventEditor/components/Ticket';
import EventDetailInviteDriversModal from '../EventDetailInviteDriversModal';

import { HeaderContainer, TicketContainer, TicketWrapper, ButtonWrapper, ButtonUp } from './styled';

class EventDetailOverviewTickets extends Component {
  state = {
    inviteModalOpen: null,
  }

  moveUp = (ticketId) => {
    this.props.onMoveUpTicket(ticketId).then(() => this.props.onGetEvent(this.props.eventId));
  };

  moveDown = (ticketId) => {
    this.props.onMoveDownTicket(ticketId).then(() => this.props.onGetEvent(this.props.eventId));
  };

  hasPermissionToAddTicket = () => {
    if (hasRole(this.props.roles, [COMMUNITY_MANAGER])) return true;
    if (hasRole(this.props.roles, [DEALER]) && isOwnEvent(this.props.event, this.props.user)) return true;
    if (hasRole(this.props.roles, [PARTNER]) && !isPublished(this.props.event)) return true;
    return false;
  };

  render() {
    const { tickets, eventId, history, event } = this.props;

    return (
      <Wrapper variant="background">
        <Container>
          <HeaderContainer>
            <Title small>Tickets</Title>
            <div>
              {this.hasPermissionToAddTicket() && (
                <Button
                  onClick={() => history.push(`/event/edit/${eventId}/tickets`)}
                  small
                  variant="black"
                >
                  Nieuw ticket +
                </Button>
              )}
            </div>
          </HeaderContainer>

          {tickets.map((ticket, index) => (
            <Fragment key={ticket.id}>
              <TicketContainer>
                <TicketWrapper>
                  <Ticket
                    data={ticket}
                    key={ticket.id}
                    summary
                    eventId={eventId}
                    eventDetail
                    isPublished={isPublished(event)}
                    onInviteDrivers={() => this.setState({ inviteModalOpen: ticket.id })}
                  />
                </TicketWrapper>

                {tickets.length > 1 && (
                  <ButtonWrapper>
                    {(index > 0 && index < tickets.length) && (
                      <ButtonUp
                        onClick={() => this.moveUp(ticket.id)}
                        light
                        small
                        square
                        boxShadow
                      >
                        <ArrowDown />
                      </ButtonUp>
                    )}

                    {(index !== tickets.length - 1) && (
                      <Button
                        onClick={() => this.moveDown(ticket.id)}
                        light
                        small
                        square
                        boxShadow
                      >
                        <ArrowDown />
                      </Button>
                    )}
                  </ButtonWrapper>
                )}
              </TicketContainer>
              {this.state.inviteModalOpen === ticket.id && (
                <EventDetailInviteDriversModal
                  eventId={event.id}
                  ticket
                  ticketId={ticket.id}
                  title={ticket.name}
                  hideModal={() => this.setState({ inviteModalOpen: null })}
                />
              )}
            </Fragment>
          ))}
        </Container>
      </Wrapper>
    );
  }
}

EventDetailOverviewTickets.propTypes = {
  tickets: PT.array,
  eventId: PT.string,
  history: PT.object,
  onMoveUpTicket: PT.func,
  onGetEvent: PT.func,
  onMoveDownTicket: PT.func,
  roles: PT.array,
  event: PT.object,
  user: PT.object,
};

export default compose(
  withRouter,
  connect(state => ({
    eventId: state.event.eventId,
    roles: state.authentication.roles,
    user: state.authentication.data,
  }), {
    onMoveUpTicket: moveUpTicket,
    onMoveDownTicket: moveDownTicket,
    onGetEvent: getEvent,
  }),
)(EventDetailOverviewTickets);
