import { change } from 'redux-form';

import createAction from 'services/createAction';
import { showNotification } from 'ducks/notification';
import { loadEvent } from 'ducks/editEvent';

const SAVE_TICKET = 'editTicket/SAVE_TICKET';
const SAVE_TICKET_SUCCESS = 'editTicket/SAVE_TICKET_SUCCESS';
const SAVE_TICKET_FAILED = 'editTicket/SAVE_TICKET_FAILED';

const DELETE_TICKET = 'editTicket/DELETE_TICKET';
const DELETE_TICKET_SUCCESS = 'editTicket/DELETE_TICKET_SUCCESS';
const DELETE_TICKET_FAILED = 'editTicket/DELETE_TICKET_FAILED';

const POST_SPECIAL_REQUIREMENTS = 'editTicket/POST_SPECIAL_REQUIREMENTS';
const POST_SPECIAL_REQUIREMENTS_SUCCESS = 'editTicket/POST_SPECIAL_REQUIREMENTS_SUCCESS';
const POST_SPECIAL_REQUIREMENTS_FAILED = 'editTicket/POST_SPECIAL_REQUIREMENTS_FAILED';

const PUT_SPECIAL_REQUIREMENTS = 'editTicket/PUT_SPECIAL_REQUIREMENTS';
const PUT_SPECIAL_REQUIREMENTS_SUCCESS = 'editTicket/PUT_SPECIAL_REQUIREMENTS_SUCCESS';
const PUT_SPECIAL_REQUIREMENTS_FAILED = 'editTicket/PUT_SPECIAL_REQUIREMENTS_FAILED';

const DELETE_SPECIAL_REQUIREMENTS = 'editTicket/DELETE_SPECIAL_REQUIREMENTS';
const DELETE_SPECIAL_REQUIREMENTS_SUCCESS = 'editTicket/DELETE_SPECIAL_REQUIREMENTS_SUCCESS';
const DELETE_SPECIAL_REQUIREMENTS_FAILED = 'editTicket/DELETE_SPECIAL_REQUIREMENTS_FAILED';

const POST_CHECKLIST = 'editTicket/POST_CHECKLIST';
const POST_CHECKLIST_SUCCESS = 'editTicket/POST_CHECKLIST_SUCCESS';
const POST_CHECKLIST_FAILED = 'editTicket/POST_CHECKLIST_FAILED';

const PUT_CHECKLIST = 'editTicket/PUT_CHECKLIST';
const PUT_CHECKLIST_SUCCESS = 'editTicket/PUT_CHECKLIST_SUCCESS';
const PUT_CHECKLIST_FAILED = 'editTicket/PUT_CHECKLIST_FAILED';

const DELETE_CHECKLIST = 'editTicket/DELETE_CHECKLIST';
const DELETE_CHECKLIST_SUCCESS = 'editTicket/DELETE_CHECKLIST_SUCCESS';
const DELETE_CHECKLIST_FAILED = 'editTicket/DELETE_CHECKLIST_FAILED';

const CHANGE_TICKET_AMOUNT = 'editTicket/CHANGE_TICKET_AMOUNT';
const CHANGE_TICKET_AMOUNT_SUCCESS = 'editTicket/CHANGE_TICKET_AMOUNT_SUCCESS';
const CHANGE_TICKET_AMOUNT_FAILED = 'editTicket/CHANGE_TICKET_AMOUNT_FAILED';

const initialState = {
  error: {
    message: '',
  },
  loading: {
    saveTicket: false,
    deleteTicket: false,
    postSpecialRequirements: false,
    putSpecialRequirements: false,
    deleteSpecialRequirements: false,
    postChecklist: false,
    putChecklist: false,
    deleteChecklist: false,
    changeTicketAmount: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case SAVE_TICKET_SUCCESS:
    return {
      ...state,
      loading: {
        ...state.loading,
        saveTicket: false,
      },
    };
  case SAVE_TICKET_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: {
        ...state.loading,
        saveTicket: false,
      },
    };
  case SAVE_TICKET:
    return {
      ...state,
      loading: {
        ...state.loading,
        saveTicket: true,
      },
    };
  case DELETE_TICKET_SUCCESS:
    return {
      ...state,
      loading: {
        ...state.loading,
        deleteTicket: false,
      },
    };
  case DELETE_TICKET_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: {
        ...state.loading,
        deleteTicket: false,
      },
    };
  case DELETE_TICKET:
    return {
      ...state,
      loading: {
        ...state.loading,
        deleteTicket: true,
      },
    };
  case POST_SPECIAL_REQUIREMENTS_SUCCESS:
    return {
      ...state,
      loading: {
        ...state.loading,
        postSpecialRequirements: false,
      },
    };
  case POST_SPECIAL_REQUIREMENTS_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: {
        ...state.loading,
        postSpecialRequirements: false,
      },
    };
  case POST_SPECIAL_REQUIREMENTS:
    return {
      ...state,
      loading: {
        ...state.loading,
        putSpecialRequirements: true,
      },
    };
  case PUT_SPECIAL_REQUIREMENTS_SUCCESS:
    return {
      ...state,
      loading: {
        ...state.loading,
        putSpecialRequirements: false,
      },
    };
  case PUT_SPECIAL_REQUIREMENTS_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: {
        ...state.loading,
        putSpecialRequirements: false,
      },
    };
  case PUT_SPECIAL_REQUIREMENTS:
    return {
      ...state,
      loading: {
        ...state.loading,
        putSpecialRequirements: true,
      },
    };
  case DELETE_SPECIAL_REQUIREMENTS_SUCCESS:
    return {
      ...state,
      loading: {
        ...state.loading,
        deleteSpecialRequirements: false,
      },
    };
  case DELETE_SPECIAL_REQUIREMENTS_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: {
        ...state.loading,
        deleteSpecialRequirements: false,
      },
    };
  case DELETE_SPECIAL_REQUIREMENTS:
    return {
      ...state,
      loading: {
        ...state.loading,
        deleteSpecialRequirements: true,
      },
    };
  case POST_CHECKLIST_SUCCESS:
    return {
      ...state,
      loading: {
        ...state.loading,
        postChecklist: false,
      },
    };
  case POST_CHECKLIST_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: {
        ...state.loading,
        postChecklist: false,
      },
    };
  case POST_CHECKLIST:
    return {
      ...state,
      loading: {
        ...state.loading,
        postChecklist: true,
      },
    };
  case PUT_CHECKLIST_SUCCESS:
    return {
      ...state,
      loading: {
        ...state.loading,
        putChecklist: false,
      },
    };
  case PUT_CHECKLIST_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: {
        ...state.loading,
        putChecklist: false,
      },
    };
  case PUT_CHECKLIST:
    return {
      ...state,
      loading: {
        ...state.loading,
        putChecklist: true,
      },
    };
  case DELETE_CHECKLIST_SUCCESS:
    return {
      ...state,
      loading: {
        ...state.loading,
        deleteChecklist: false,
      },
    };
  case DELETE_CHECKLIST_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: {
        ...state.loading,
        deleteChecklist: false,
      },
    };
  case DELETE_CHECKLIST:
    return {
      ...state,
      loading: {
        ...state.loading,
        deleteChecklist: true,
      },
    };
  case CHANGE_TICKET_AMOUNT_SUCCESS:
    return {
      ...state,
      loading: {
        ...state.loading,
        changeTicketAmount: false,
      },
    };
  case CHANGE_TICKET_AMOUNT_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: {
        ...state.loading,
        changeTicketAmount: false,
      },
    };
  case CHANGE_TICKET_AMOUNT:
    return {
      ...state,
      loading: {
        ...state.loading,
        changeTicketAmount: true,
      },
    };
  default:
    return state;
  }
};

const saveTicketSuccess = createAction(SAVE_TICKET_SUCCESS);
const saveTicketFailed = createAction(SAVE_TICKET_FAILED);

export const saveTicket = (payload, id, newTicket) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: SAVE_TICKET });

    // Inline validation on these 2 fields don't work due to plugin rendered input fields.
    // Custom error text will provide proper error handling.
    if (!payload.ticket_type_id) {
      dispatch(showNotification('Er is geen ticket type geselecteerd.'));
      reject();
      return;
    }
    if (!payload.points_id) {
      dispatch(showNotification('Er is geen ticket categorie geselecteerd.'));
      reject();
      return;
    }

    // Parse string values to integer pre-submit
    payload.price = parseInt(payload.price, 10);
    payload.vat = parseInt(payload.vat, 10);
    payload.quantity = parseInt(payload.quantity, 10);
    payload.limit_per_person = parseInt(payload.limit_per_person, 10);
    payload.custom_fields = payload.custom_fields.filter(field => field.item);

    const excludedValues = [];
    const excludeValues = ['special_requirements', 'checklist'];
    excludeValues.forEach((val) => {
      excludedValues.push({ [val]: payload[val] });
      delete payload[val];
    });

    if (!newTicket) {
      api.put({ path: `management/ticket/${id}`, body: payload })
        .then((res) => {
          resolve({ res, excludedValues });
          dispatch(showNotification('Uw ticket is succesvol opgeslagen.', 'green'));
          dispatch(saveTicketSuccess(res));
        })
        .catch((err) => {
          reject(err);
          dispatch(showNotification('Er is iets mis gegaan, probeer het nogmaals.'));
          dispatch(saveTicketFailed({ message: err.data }));
        });
    } else {
      api.post({ path: `management/activity/${getState().editEvent.data.id}/ticket`, body: payload })
        .then((res) => {
          resolve({ res, excludedValues });
          dispatch(showNotification('Uw ticket is succesvol aangemaakt.', 'green'));
          dispatch(saveTicketSuccess(res));
        })
        .catch((err) => {
          reject(err);
          dispatch(showNotification('Er is iets mis gegaan, probeer het nogmaals.'));
          dispatch(saveTicketFailed({ message: err.data }));
        });
    }
  })
);

const deleteTicketSuccess = createAction(DELETE_TICKET_SUCCESS);
const deleteTicketFailed = createAction(DELETE_TICKET_FAILED);

export const deleteTicket = id => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: DELETE_TICKET });

    api.del({ path: `management/ticket/${id}` })
      .then((res) => {
        resolve(res);
        dispatch(showNotification('Uw ticket is succesvol verwijderd.', 'green'));
        dispatch(deleteTicketSuccess(res));
        dispatch(loadEvent(getState().editEvent.data.id));
      })
      .catch((err) => {
        reject(err);
        dispatch(showNotification('Er is iets mis gegaan, probeer het nogmaals.'));
        dispatch(deleteTicketFailed({ message: err.data }));
      });
  })
);

const postSpecialRequirementsSuccess = createAction(POST_SPECIAL_REQUIREMENTS_SUCCESS);
const postSpecialRequirementsFailed = createAction(POST_SPECIAL_REQUIREMENTS_FAILED);

export const postSpecialRequirements = (payload, id) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: POST_SPECIAL_REQUIREMENTS });

    api.post({ path: `management/ticket/${id}/checklist`, body: payload })
      .then((res) => {
        resolve(res);
        dispatch(change('edit-ticket-form', 'special_requirements', res.checklist));
        dispatch(postSpecialRequirementsSuccess(res));
      })
      .catch((err) => {
        reject(err);
        dispatch(postSpecialRequirementsFailed({ message: err.data }));
      });
  })
);


const putSpecialRequirementsSuccess = createAction(PUT_SPECIAL_REQUIREMENTS_SUCCESS);
const putSpecialRequirementsFailed = createAction(PUT_SPECIAL_REQUIREMENTS_FAILED);

export const putSpecialRequirements = (payload, id) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: PUT_SPECIAL_REQUIREMENTS });

    const excludeValues = ['id', 'description', 'send_date', 'name'];
    excludeValues.forEach((val) => {
      delete payload[val];
    });

    api.put({ path: `management/checklist/${id}`, body: payload })
      .then((res) => {
        resolve(res);
        dispatch(change('edit-ticket-form', 'special_requirements', res.checklist));
        dispatch(putSpecialRequirementsSuccess(res));
      })
      .catch((err) => {
        reject(err);
        dispatch(putSpecialRequirementsFailed({ message: err.data }));
      });
  })
);

const deleteSpecialRequirementsSuccess = createAction(DELETE_SPECIAL_REQUIREMENTS_SUCCESS);
const deleteSpecialRequirementsFailed = createAction(DELETE_SPECIAL_REQUIREMENTS_FAILED);

export const deleteSpecialRequirements = id => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: DELETE_SPECIAL_REQUIREMENTS });

    api.del({ path: `management/checklist/${id}` })
      .then((res) => {
        resolve(res);
        dispatch(change('edit-ticket-form', 'checklist', res.checklist));
        dispatch(deleteSpecialRequirementsSuccess(res));
      })
      .catch((err) => {
        reject(err);
        dispatch(deleteSpecialRequirementsFailed({ message: err.data }));
      });
  })
);

const postChecklistSuccess = createAction(POST_CHECKLIST_SUCCESS);
const postChecklistFailed = createAction(POST_CHECKLIST_FAILED);

export const postChecklist = (payload, id) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: POST_CHECKLIST });

    api.post({ path: `management/ticket/${id}/checklist`, body: payload })
      .then((res) => {
        resolve(res);
        dispatch(change('edit-ticket-form', 'checklist', res.checklist));
        dispatch(postChecklistSuccess(res));
      })
      .catch((err) => {
        reject(err);
        dispatch(postChecklistFailed({ message: err.data }));
      });
  })
);

const putChecklistSuccess = createAction(PUT_CHECKLIST_SUCCESS);
const putChecklistFailed = createAction(PUT_CHECKLIST_FAILED);

export const putChecklist = (payload, id) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: PUT_CHECKLIST });

    const excludeValues = ['id', 'description', 'send_date', 'name'];
    excludeValues.forEach((val) => {
      delete payload[val];
    });

    api.put({ path: `management/checklist/${id}`, body: payload })
      .then((res) => {
        resolve(res);
        dispatch(change('edit-ticket-form', 'checklist', res.checklist));
        dispatch(putChecklistSuccess(res));
      })
      .catch((err) => {
        reject(err);
        dispatch(putChecklistFailed({ message: err.data }));
      });
  })
);


const deleteChecklistSuccess = createAction(DELETE_CHECKLIST_SUCCESS);
const deleteChecklistFailed = createAction(DELETE_CHECKLIST_FAILED);

export const deleteChecklist = id => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: DELETE_CHECKLIST });

    api.del({ path: `management/checklist/${id}` })
      .then((res) => {
        resolve(res);
        dispatch(change('edit-ticket-form', 'checklist', res.checklist));
        dispatch(deleteChecklistSuccess(res));
      })
      .catch((err) => {
        reject(err);
        dispatch(deleteChecklistFailed({ message: err.data }));
      });
  })
);


const changeTicketAmountSuccess = createAction(CHANGE_TICKET_AMOUNT_SUCCESS);
const changeTicketAmountFailed = createAction(CHANGE_TICKET_AMOUNT_FAILED);

export const changeTicketAmount = (payload, id) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: CHANGE_TICKET_AMOUNT });

    api.put({ path: `management/ticket/${id}`, body: payload })
      .then((res) => {
        resolve(res);
        dispatch(showNotification('Het aantal tickets is aangepast.', 'green'));
        dispatch(changeTicketAmountSuccess(res));
      })
      .catch((err) => {
        reject(err);
        dispatch(showNotification('Er is iets mis gegaan, probeer het nogmaals.'));
        dispatch(changeTicketAmountFailed({ message: err.data }));
      });
  })
);
