import styled from 'styled-components';

export const ModalContainer = styled.div`
  width: 100%;
  min-width: 600px;
  max-width: 800px;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const ModalContent = styled.div`
  padding: 36px 54px;
  width: 100%;
  max-height: 90vh;
  overflow-y: scroll;
`;
