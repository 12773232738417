import React, { Fragment } from 'react';
import { HeaderPlain, Title, ContentContainer } from 'common';

const PrivacyPolicy = () => (
  <Fragment>
    <HeaderPlain />
    <ContentContainer>
      <Title>Privacy Statement Porsche24</Title>
      <section>
        <h2>1. Wie is verantwoordelijk voor de verwerking van jouw gegevens?</h2>
        <p>
          In dit privacy statement beschrijft Pon's Automobielhandel B.V. ("<b>PAH</b>" of "<b>wij</b>"), tevens handelend onder de naam Pon Porsche Import (“PPI”) als de importeur van Porsche voertuigen in Nederland, hoe zij als verwerkingsverantwoordelijke met uw persoonsgegevens omgaat die via de Porsche24 App ("<b>App</b>") worden verwerkt. PAH is de aanbieder van de App en gevestigd aan de Zuiderinslag 2 te Leusden. PAH is verbonden aan Pon Holdings B.V. en behoort tot de groep van PON ("<b>PON Groep</b>").
        </p>
      </section>

      <section>
        <h2>2. Wat is een persoonsgegeven?</h2>
        <p>
          Informatie over een geïdentificeerde of identificeerbare natuurlijke persoon is een persoonsgegeven. Of bepaalde informatie als een persoonsgegeven moet worden gekwalificeerd hangt er mede vanaf of PAH beschikt over wettige middelen waarvan redelijkerwijs mag worden aangenomen dat wij deze inzetten om een betrokkene te identificeren.
        </p>
      </section>

      <section>
        <h2>3. Persoonsgegevens die wij verwerken</h2>
        <p>Wij verwerken persoonsgegevens over u omdat u gegevens zelf aan ons verstrekt of beschikbaar stelt bij het aangaan van een overeenkomst voor de App, maar ook wanneer u gebruik maakt van de App en ons daarmee gegevens verstrekt, dan wel ons in staat stelt gegevens vast te leggen over uw interesses en voorkeuren.</p>
        <h3>Aan ons verstrekte of beschikbaar gestelde gegevens</h3>
        <p>Als u met PAH een overeenkomst aangaat voor gebruik van de App, hebben wij de volgende persoonsgegevens van u nodig, die u aan ons verstrekt of beschikbaar stelt:</p>
        <ul>
          <li>naam, adres en contactgegevens (zoals telefoonnummer en e-mailadres);</li>
          <li>geboortedatum en geslacht;</li>
          <li>het kenteken van uw voertuig;</li>
          <li>andere gegevens relevant voor de specifieke overeenkomst;</li>
        </ul>

        <h3>Gegevens over uw gebruik van het voertuig, de App en andere diensten</h3>
        <p>Als u gebruik maakt van de App of anderszins contact hebt met PAH, verwerken wij in het kader van onze dienstverlening – afhankelijk van het precieze gebruik dat u ervan maakt – de volgende gegevens die als persoonsgegevens (kunnen) worden gezien:</p>
        <ul>
          <li>klantnummer(s)</li>
          <li>profielfoto</li>
          <li>social logingegevens (Facebook/Linked-in)</li>
          <li>uw voorkeur voor een dealer of servicepartner</li>
          <li>uw communicatievoorkeuren en instellingen</li>
          <li>technische gegevens over uw apparaat, zoals IP-adres, MAC-adres</li>
          <li>technische gegevens over uw voertuig, zoals chassisnummer, model en type-nummers</li>
          <li>onderhoudsgegevens van uw voertuig, zoals werkorders en kilometerstanden</li>
          <li>financiële gegevens, zoals facturen en offertes</li>
          <li>Andere relevante (ongestructureerde) gegevens door u verstrekt</li>
        </ul>
      </section>

      <section>
        <h2>4. Verwerking: grondslagen en gerechtvaardigde belangen</h2>
        <p>De verwerking van persoonsgegevens vindt plaats op basis van de volgende grondslagen uit artikel 6 van de Algemene Verordening Gegevensbescherming:</p>
        <ol>
          <li>toestemming</li>
          <li>uitvoering van een overeenkomst met u</li>
          <li>wettelijke verplichting</li>
          <li>gerechtvaardigd belang van PAH of een derde</li>
        </ol>
        <p>Gerechtvaardigde belangen zijn onder andere: marketing, reclame, beveiliging, (misdaad)preventie, IT-beheer, (markt)onderzoek naar en analyse van eigen producten of diensten, bedrijfshuishouding, juridische zaken en intern beheer.</p>
      </section>

      <section>
        <h2>5. Doeleinden voor het verwerken van persoonsgegevens</h2>
        <p>PAH verwerkt uw persoonsgegevens voor de hieronder genoemde doeleinden. Het cijfer achter ieder doel correspondeert met de grondslag genoemd in artikel 4 van dit privacy statement:</p>
        <ul>
          <li>om de App aan te kunnen aanbieden en de diensten uit de App te kunnen leveren [grondslag: 1, 2];</li>
          <li>om de communicatie met de door u geselecteerde voorkeursdealer te faciliteren zodat deze (proactief) met u contact kan opnemen als er een storing in het voertuig optreedt of om een onderhoudsbeurt in te plannen [grondslag: 1, 2];</li>
          <li>om met u te communiceren en promotionele berichten te versturen, zoals aanbiedingen/aankondigingen, en u op de hoogte te houden van acties, nieuwsbrieven, evenementen, onderzoeken en overige producten of diensten, die kunnen worden verstuurd door PPI of PAH. Wij gebruiken uw naam, adres en contactgegevens om magazines, aanbiedingen, nieuws of andere commerciële berichten toe te sturen. Deze communicatie stemmen we zoveel mogelijk af op uw voorkeur: wat voor u inhoudelijk relevant is en op de manier die uw voorkeur heeft. Deze voorkeuren kunt u zelf aanpassen in de App of door instructies (afmelden) te volgen in het desbetreffende bericht. Ook van openbare bronnen of van onze groepsmaatschappijen kunnen wij hierover gegevens ontvangen en deze combineren met bovenstaand genoemde gegevens. Uw Social logingegevens (Facebook/Linked-in) worden alleen gebruikt om uw Porsche24 account te kunnen activeren tijdens het aanmeldproces. [grondslag: 1, 4];
          </li>
          <li>Om uw deelname aan evenementen te kunnen afhandelen, hebben wij uw persoonsgegevens nodig. We verwerken bijvoorbeeld uw naam en andere persoonsgegevens om uw event-ticket te kunnen verstrekken en u te registreren op de gastenlijst. Om u te informeren rondom evenementen waarvoor u zich heeft aangemeld, hebben we eveneens uw contactgegevens (adres, telefoon, e-mail) nodig.
            Wanneer u eenmaal ter plaatse deelneemt aan het evenement, kunnen uw gegevens worden gebruikt om op dat moment locatie specifieke informatie met u te delen, bijvoorbeeld door de dichtstbijzijnde interessante routes te laten zien of berichten te laten verschijnen in de App. [grondslag: 1, 2 of 4]
          </li>
          <li>Om deel te nemen aan ons loyaliteitsprogramma. We gebruiken uw persoonsgegevens, zoals bijvoorbeeld klantnummer en gereden kilometers die u bij ons heeft geregistreerd en informatie met betrekking tot evenementen waaraan u heeft deelgenomen, om u te kunnen laten profiteren van de beloningen en privileges binnen het Porsche24 programma. Bijvoorbeeld het toesturen van Christophorus Magazine als beloning voor deelname aan het programma. [grondslag: 1 of 4]</li>
          <li>om het gebruik van de App (soorten gebruikers, klikgedrag, etc.) en de functionaliteiten te analyseren met als doel trends te ontdekken en op basis daarvan nieuwe producten te ontwikkelen (product development) [grondslag: 4];</li>
          <li>om u notificaties te sturen bij service en onderhoud aan het voertuig [grondslag: 1, 2].</li>
        </ul>
      </section>

      <section>
        <h2>6. Analytische, statistische en wetenschappelijke verwerkingen</h2>
        <p>Wij kunnen uw gegevens verwerken voor marktonderzoek, historische analysedoeleinden om trends en correlaties in gegevens te ontdekken, of om bijdragen te leveren aan onderzoeken voor onder meer maatschappelijke en/of wetenschappelijke doeleinden of de verbetering van de verkeersveiligheid.</p>
        <p>Hiervoor kunnen wij uw gegevens verstrekken aan partijen binnen de Pon Groep en eventueel ook aan derde partijen. Deze gegevens kunnen bestaan uit uw voertuiggegevens en geregistreerde kilometerstanden, waarbij wij passende maatregelen (zoals hashing, aggregatie en versleuteling) nemen ter bescherming van uw privacy zodat persoonsgegevens voor ons, de Pon Groep en eventueel betrokken derde partijen niet meer direct herleidbaar zullen zijn naar u als natuurlijk persoon (pseudo anoniem).</p>
        <p>Wij verwerken uw gegevens op basis van de grondslag gerechtvaardigd belang. U kunt de verwerking van persoonsgegevens voor de in dit artikel omschreven doeleinden beëindigen door dit bij ons kenbaar maken via de klantenservice (zie onderstaande contactgegevens).</p>
      </section>

      <section>
        <h2>7. Delen met derden</h2>
        <p>Wij delen uw persoonsgegevens met derden in onder andere de volgende gevallen en met bijbehorende redenen. Het cijfer achter ieder doel correspondeert met de grondslag genoemd in artikel 4 van dit privacy statement:</p>
        <ul>
          <li>Voertuig- en onderhoudsgegevens, gereden kilometers, deelname aan activiteiten, naam, contactgegevens en communicatievoorkeuren worden gedeeld met de voorkeursdealer welke in de App door u geselecteerd of aan u toegewezen wordt. De voorkeursdealer kan uw gegevens onder andere gebruiken om contact met u op te nemen ten aanzien van de dienstverlening inzake de App, tenzij u daarvoor uw toestemming heeft ingetrokken. [grondslag: 1, 2 of 4];</li>
          <li>Indien een evenement door PAH georganiseerd wordt in samenwerking met een derde partij, dan worden uw persoonsgegevens gedeeld  met deze derde partij ten behoeve van het organiseren van dit evenement, bijvoorbeeld voor registratie-/deelname doeleinden. [grondslag: 2 of 4];</li>
          <li>Indien u via onze App een aankoop doet van bijvoorbeeld een evenementticket, dan verstrekken wij uw gegevens aan de partij(en) die voor ons het betalingsverkeer verzorgen. [grondslag: 2];</li>
          <li>Indien u via onze diensten gebruik maakt van het afsluiten van een verzekering, bijvoorbeeld voor het rijden op een circuit, dan verstrekken wij uw gegevens  aan de van toepassing zijnde verzekeringsmaatschappij, alleen voor deze doeleinden. [grondslag: 2];</li>
          <li>als u een storing of een vraag hebt over de App, kunnen gekwalificeerde medewerkers van PAH, uw voorkeursdealer en/of door PAH ingeschakelde leveranciers toegang hebben tot uw persoonsgegevens om u te helpen [grondslag: 4];</li>
        </ul>

        <p>PAH zal derden geen toegang geven tot bovengenoemde persoonsgegevens of deze persoonsgegevens aan derden verstrekken, tenzij dit noodzakelijk is:</p>
        <ul>
          <li>voor het verlenen van een dienst, ten behoeve van bovengenoemde doeleinden, bijvoorbeeld dienstverleners op het gebied van hosting en onderzoek (dit zijn 'verwerkers' van PAH);</li>
          <li>in het kader van onderhouds- en supportwerkzaamheden;</li>
          <li>in het kader van een transactie, bijvoorbeeld wanneer PAH besluit om haar bedrijf of een deel daarvan te verkopen of over te dragen. PAH kan informatie die is verzameld en opgeslagen, inclusief persoonlijke informatie, overdragen aan de partij of partijen die betrokken zijn bij de transactie, als onderdeel van die transactie; en/of</li>
          <li>in verband met een wettelijk voorschrift of een bevel van een bevoegde autoriteit.</li>
        </ul>

        <p>Indien deze derden conform toepasselijke gegevensbeschermingswetgeving worden aangemerkt als verwerker, zal PAH schriftelijke afspraken maken met deze derden. Door PAH ingeschakelde verwerkers handelen op instructie en onder verantwoordelijkheid van PAH.</p>
        <p>De persoonsgegevens die worden verzameld via de App worden opgeslagen op een server van een leverancier van PAH in de Europese Economische Ruimte (EER). Als persoonsgegevens naar een ontvanger in het buitenland worden verzonden, gebeurt dit doorgaans alleen naar een ontvanger in een land dat volgens de Europese Commissie een voor persoonsgegevens passend beschermingsniveau biedt. Als persoonsgegevens naar een ontvanger in een land worden verstuurd dat geen passend beschermingsniveau biedt, zal PAH ervoor zorgen dat de wettelijk vereiste waarborgen worden getroffen, zoals het sluiten van een EU-modelcontract of het eisen van Privacy Shield-certificering van de ontvanger. Indien u nadere informatie wenst te ontvangen over de doorgifte van uw persoonsgegevens naar landen buiten de EER, kunt u contact opnemen met de Functionaris voor Gegevensbescherming van Pon (zie artikel 13 van dit privacy statement).</p>
      </section>

      <section>
        <h2>8. Hoe lang wij gegevens bewaren</h2>
        <p>Wij bewaren persoonsgegevens overeenkomstig ons interne bewaartermijnenbeleid en/of zo lang als (i) u klant bent bij PAH of interesse blijft tonen in de diensten van PAH (ii) wij wettelijk verplicht zijn gegevens te bewaren, of (iii) wij op goede gronden menen gegevens te mogen bewaren, voor zover noodzakelijk. PAH bewaart de gegevens niet langer dan noodzakelijk om u de functionaliteiten in de App aan te bieden en passend bij de genoemde doeleinden voor verwerking van deze gegevens.
        </p>
        <p>Na afloop van ieder kalenderjaar zijn de in het daaraan voorafgaande kalenderjaar verzamelde gegevens nog maximaal 12 maanden beschikbaar na het begin van dit nieuwe kalenderjaar. Deze bewaartermijn geldt voor gebruikers die zich hebben afgemeld voor alle aan Porsche24 gerelateerde diensten via porsche24@porsche.nl.</p>

      </section>

      <section>
        <h2>9. Uw rechten, waaronder het recht om bezwaar te maken</h2>
        <p>U hebt het recht om te weten welke persoonsgegevens wij van u hebben vastgelegd en aan wie wij uw persoonsgegevens hebben verstrekt. Hiervoor kunt u contact met ons opnemen via de klantenservice. PAH zal zich inspannen om te reageren binnen de wettelijke termijn. Buitensporige verzoeken kunnen wij buiten behandeling laten.</p>
        <p>Naast het recht op inzage hebt u met betrekking tot onze verwerking van uw persoonsgegevens de volgende wettelijke rechten:</p>
        <ul>
          <li>het recht om uw toestemming in te trekken, voor zover onze verwerking van uw persoonsgegevens daarop is gebaseerd;</li>
          <li>het recht om een klacht in te dienen bij de Autoriteit Persoonsgegevens;</li>
          <li>het recht om uw persoonsgegevens te (laten) rectificeren/corrigeren;</li>
          <li>het recht op verwijdering van uw persoonsgegevens;</li>
          <li>het recht op beperking van verwerking;</li>
          <li>het recht op dataportabiliteit;</li>
          <li>het recht om bezwaar te maken tegen direct marketing;</li>
          <li>het recht om bezwaar te maken tegen verwerkingen die plaatsvinden vanwege de gerechtvaardigde belangen van PAH of een derde (afhankelijk van uw persoonlijke omstandigheden).</li>
        </ul>
        <p>U kunt uw rechten slechts uitoefenen voor zover de wet u deze rechten toekent. Om er zeker van te zijn dat een verzoek door uzelf is gedaan, kunnen wij vragen om een kopie van uw identiteitsbewijs bij dit verzoek mee te sturen. Dit vragen wij alleen als we dit noodzakelijk achten om u te kunnen identificeren en waarbij wij verzoeken om op deze kopie de pasfoto, MRZ (machine readable zone; de strook met nummers onderaan het paspoort), paspoortnummer en Burgerservicenummer (BSN) onherkenbaar te maken. De kopie van uw identiteitsbewijs vernietigen wij direct nadat wij u hebben geïdentificeerd.</p>
      </section>

      <section>
        <h2>10. Stopzetten verwerken van persoonsgegevens en intrekken toestemming</h2>
        <p>U hebt op ieder moment de keuze om het verzamelen en verwerken van uw persoonsgegevens door PAH stop te zetten. Neem hiervoor contact op met onze klantenservice via <a href="mailto:porsche24@porsche.nl">porsche24@porsche.nl</a>. Tevens dient u de App te de-installeren. Uw gegevens blijven nog wel bewaard conform de genoemde bewaartermijnen in artikel 8.</p>
        <p>In het kader van het gebruik van de App kunt u berichten, waaronder commerciële communicatie en serviceberichten, ontvangen van PAH, alsmede de door u geselecteerde voorkeursdealer. Indien u deze berichten niet langer wenst te ontvangen, kunt u uw toestemming/voorkeur hiervoor aanpassen in de App bij communicatie instellingen menu alsmede afmelden onderaan iedere e-mail die u van ons ontvangt.</p>
      </section>

      <section>
        <h2>11. Analytics</h2>
        <p>De App maakt gebruik van Google Analytics, een webanalyse-service die wordt aangeboden door Google Inc. Google Analytics maakt gebruik van cookies om de wijze waarop de gebruiker de App gebruikt te analyseren en vervolgens met behulp van deze informatierapporten over het gebruik van de App op te stellen voor PAH. De door de cookies gegenereerde informatie over uw gebruik van de App wordt overgebracht naar en door Google opgeslagen op servers in de Verenigde Staten. Google mag de door de cookies gegenereerde informatie alleen aan derden verschaffen indien Google hiertoe wettelijk wordt verplicht of voor zover deze derden de informatie namens Google verwerken. Google zal uw IP-adres niet combineren met andere gegevens waarover Google beschikt. Het gebruik van Google Analytics is nodig voor het analyseren van de werking van de App. </p>
      </section>

      <section>
        <h2>12. Beveiliging</h2>
        <p>PAH heeft passende technische (via onder andere veilige servers, firewalls en het elektronisch beveiligen en versleutelen van de gegevensverbinding en bijbehorende verwerkingen) en organisatorische beveiligingsmaatregelen getroffen die ervoor zorgen dat uw persoonsgegevens worden beschermd tegen onrechtmatige of onbevoegde verwerking en verlies, vernietiging, beschadiging, wijziging of openbaarmaking en dat de overbrenging van uw persoonsgegevens van het voertuig en de App naar de servers op een veilige manier geschiedt. </p>
      </section>

      <section>
        <h2>13. Contact</h2>
        <p>Voor vragen en/of opmerkingen met betrekking tot dit privacy statement kun je contact opnemen met:</p>
        <p>Pon’s Automobielhandel B.V. <br />
          T.a.v: Pon Porsche Import klantenservice <br />
          Zuiderinslag 8 <br />
          3833 BP Leusden <br />
          Telefoon: +31 (0) 033 4949 757<br />
          E-mail: <a href="mailto:porsche24@porsche.nl">porsche24@porsche.nl</a>
        </p>

        <p>Wij beschikken over een Functionaris Gegevensbescherming die is aangemeld bij de toezichthouder Autoriteit Persoonsgegevens. Dit is iemand die binnen onze organisatie toezicht houdt op de toepassing en naleving van privacywetgeving zoals de Algemene Verordening Gegevensbescherming (AVG). Onze Functionaris Gegevensbescherming is bereikbaar per e-mail via <a href="mailto:privacy@pon.com">privacy@pon.com</a> en per post via bovenstaand adres (t.a.v. 'Privacy Office').</p>
      </section>

      <section>
        <h2>14. Wijzigingen van dit privacy statement</h2>
        <p>De manier waarop wij persoonsgegevens verwerken en de samenstelling of hoeveelheid van gegevens die wij verwerken, kan wijzigen. Daarom behouden wij ons het recht voor dit privacy statement aan te passen. U zult indien noodzakelijk op de hoogte worden gebracht van een wijziging. </p>
        <p>Voor zover wijzigingen in het privacy statement leiden tot wezenlijke wijzigingen met betrekking tot de door PAH te verschaffen prestaties onder de gebruikersovereenkomst voor gebruik van de App, wordt u daarbij eerst de mogelijkheid gegeven de gebruikersovereenkomst te beëindigen. Voor meer informatie over klik hier voor de gebruiksvoorwaarden. <a href="/gebruiksvoorwaarden" target="_blank" rel="noopener noreferrer">https://porsche24.porsche.nl/gebruiksvoorwaarden</a></p>
      </section>

      <section>
        <p>Dit statement is voor het laatst herzien op 8 maart 2019. Eerdere versies zijn opvraagbaar via de klantenservice.</p>
      </section>
    </ContentContainer>
  </Fragment>
);

export default PrivacyPolicy;
