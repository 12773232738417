import React from 'react';
import PT from 'prop-types';

import { Title, LabelTag, PlaceholderImage } from 'common';

import {
  CEMPackageCardContainer, CEMPackageCardImage, CEMPackageCardContent, CEMPackageCardShortDescription,
  CEMPackageCardImageContainer,
} from './styled';

const CEMPackageCard = ({ data, onClick, completed }) => (
  <CEMPackageCardContainer noPadding onClick={onClick}>
    <CEMPackageCardImageContainer>
      {data.image ? <CEMPackageCardImage backgroundImage={data.image} /> : <PlaceholderImage />}
    </CEMPackageCardImageContainer>
    <CEMPackageCardContent>
      <Title small noWhitespace>{data.name} {completed && <LabelTag>Voltooid</LabelTag>}</Title>
      <CEMPackageCardShortDescription>{data.description_short}</CEMPackageCardShortDescription>
    </CEMPackageCardContent>
  </CEMPackageCardContainer>
);

CEMPackageCard.propTypes = {
  onClick: PT.func.isRequired,
  data: PT.shape({
    id: PT.string.isRequired,
    image: PT.string,
    name: PT.string.isRequired,
    description_short: PT.string.isRequired,
  }),
  completed: PT.bool,
};

export default CEMPackageCard;
