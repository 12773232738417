import React from 'react';
import PT from 'prop-types';

import Block from '../Block';
import Text from '../Text';

const IntroTextBlock = ({ data }) => (
  <Block>
    <Text html={data.value.body} medium />
  </Block>
);

IntroTextBlock.propTypes = {
  data: PT.object,
};

export default IntroTextBlock;
