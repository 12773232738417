import React, { Component, Fragment } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';

import { getValidateMileage } from 'ducks/validateMileage';
import { hideModal } from 'ducks/modal';

import { Container, EmptyState, Loading, Title } from 'common';
import ValidationCard from './components/ValidationCard';

import { Mileage, Summary } from './styled';

class ValidateMileage extends Component {
  componentDidMount() {
    this.props.getValidateMileage();
  }

  componentWillUnmount() {
    this.props.hideMileageModal();
  }

  render() {
    const { loading, mileage } = this.props;

    if (loading) return <Loading />;

    return (
      <Container>
        {mileage.length ? (
          <Fragment>
            <Title flat>Valideer kilometers</Title>
            <Summary>
              <Mileage>{mileage.length} lopende</Mileage> kilometerclaim{mileage.length > 1 && 's'}
            </Summary>
            <Container>
              {mileage.map(car => (
                <ValidationCard
                  data={car}
                  key={car.id}
                />
              ))}
            </Container>
          </Fragment>
        ) : (
          <EmptyState
            title="Alle Porsche rijders zijn up-to-date"
            subtitle="Er zijn geen lopende kilometer claims voor u om te valideren. Nieuwe claims zullen hier verschijnen."
          />
        )}
      </Container>
    );
  }
}

ValidateMileage.propTypes = {
  getValidateMileage: PT.func,
  hideMileageModal: PT.func,
  loading: PT.bool,
  mileage: PT.oneOfType([PT.array, PT.object]),
};

const mapStateToProps = state => ({
  loading: state.validateMileage.loading.getCount,
  mileage: state.validateMileage.data,
});

const mapDispatchToProps = dispatch => ({
  hideMileageModal: () => {
    dispatch(hideModal());
  },
  getValidateMileage: () => {
    dispatch(getValidateMileage());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ValidateMileage);
