import createAction from 'services/createAction';

const NOTIFICATION_SHOW = 'notification/SHOW';
const NOTIFICATION_HIDE = 'notification/HIDE';

const initialState = {
  isActive: false,
  message: '',
  variant: 'red',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case NOTIFICATION_SHOW:
    return {
      message: payload.message,
      isActive: true,
      variant: payload.variant || initialState.variant,
    };
  case NOTIFICATION_HIDE:
    return initialState;
  default:
    return state;
  }
};

export const hideNotification = createAction(NOTIFICATION_HIDE);
const setNotification = createAction(NOTIFICATION_SHOW);

let timeOut = null;
export const showNotification = (message, variant, noAutoClose) => (dispatch) => {
  clearTimeout(timeOut);

  dispatch(setNotification({ message, variant }));

  if (!noAutoClose) {
    timeOut = setTimeout(() => {
      dispatch(hideNotification());
    }, 5000);
  }
};
