import styled, { css } from 'styled-components';
import PT from 'prop-types';
import Slider from 'react-slick';

import Arrow from 'vectors/arrowRight.svg';

export const SliderContainer = styled(Slider)`
  position: relative;
  display: block;
  box-sizing: border-box;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  cursor: move;
  cursor: grab;

  .slick-list {
    position: relative;
    display: block;
    overflow: visible;
    margin: 0;
    padding: 0;
    width: 100%;

    &:focus {
      outline: none;
    }

    &.dragging {
      cursor: pointer;
      cursor: hand;
    }
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
  }

  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    margin-bottom: -7px;
  }

  .slick-initialized .slick-slide {
    display: block;
  }

  .slick-loading .slick-slide {
    visibility: hidden;
  }
`;

export const SlideArrow = styled(Arrow)`
  width: 15px;
  height: 24px;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  fill: ${props => props.theme.white};
  opacity: .5;
  cursor: pointer;
  z-index: 2;
  transition: opacity 300ms;

  &:hover {
    opacity: .8;
  }

  ${props => props.direction === 'left' && css`
    left: 20px;
    transform: translateY(-50%) rotate(180deg);
  `}
`;

SlideArrow.propTypes = {
  direction: PT.string,
};
