import styled, { css } from 'styled-components';
import PT from 'prop-types';
import AsyncSelect from 'react-select/lib/Async';

const AsyncSelectStyled = styled(AsyncSelect)`
  .react-select__control {
    background-color: #fff;
    border-radius: 0;
    border: 1px solid ${props => props.theme.border};
  }

  .react-select__value-container {
    outline: none;
  }

  .react-select__single-value {
    font-size: 16px;
    color: ${props => props.theme.black};
  }

  .react-select__indicators {
    display: none;
  }

  ${props => (props.error && props.submitFailed) && css`
    .react-select__control {
      border-color: ${props.theme.red};
    }
  `}
`;

AsyncSelectStyled.propTypes = {
  error: PT.oneOfType([PT.bool, PT.string]),
  submitFailed: PT.bool,
};

export default AsyncSelectStyled;
