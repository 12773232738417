import styled, { css } from 'styled-components';
import PT from 'prop-types';

const StepsItem = styled.p`
  display: inline-block;
  font-size: 24px;
  outline: none;
  cursor: pointer;
  padding: 0;
  margin-bottom: 3px;
  margin-right: 36px;
  color: ${props => props.theme.black};
  text-decoration: none;

  &:hover {
    color: ${props => props.theme.red};
  }

  ${props => props.active && css`
    color: ${props.theme.red};
    border-bottom: 3px solid ${props.theme.red};
    margin-bottom: 0;
  `}

  ${props => props.disabled && css`
    color: ${props.theme.gray};
    margin-bottom: 0;
    cursor: not-allowed;

    &:hover {
      color: ${props.theme.gray};
    }
  `}
`;

StepsItem.propTypes = {
  active: PT.bool,
  gray: PT.bool,
};

export default StepsItem;
