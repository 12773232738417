import React from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import { getFormValues, change } from 'redux-form';

import {
  hasRole,
  DEALER,
} from 'services/authHelper';

import { selectAllDrivers, deselectAllDrivers } from 'ducks/inviteDrivers';
import { InputCheckbox } from 'common/form';

import DriverRow from '../DriverRow';

import { DriversContainer, HeaderCell, RowEmpty } from './styled';

const DriversList = ({
  drivers, roles, invite, onChange, onSelectAllDrivers, onDeselectAllDrivers,
}) => {
  const onSelectAll = (bool) => {
    if (bool) {
      drivers.forEach((driver) => {
        onChange('invite-drivers', `invite.${driver.id}`, bool);
      });
      onSelectAllDrivers(drivers);
    } else {
      onChange('invite-drivers', 'invite', {});
      onDeselectAllDrivers();
    }
  };

  return (
    <DriversContainer>
      <thead>
        <tr>
          {invite && (
            <HeaderCell>
              <InputCheckbox
                id="invite"
                onChange={e => onSelectAll(e.target.checked)}
              />
            </HeaderCell>
          )}
          <HeaderCell>Naam</HeaderCell>
          <HeaderCell>Stad</HeaderCell>
          {!hasRole(roles, [DEALER]) && <HeaderCell>Dealer(s)</HeaderCell>}
          <HeaderCell>E-mailadres</HeaderCell>
        </tr>
      </thead>

      <tbody>
        {drivers.length === 0 && (
          <RowEmpty>
            <td colSpan="4">Geen resultaten gevonden</td>
          </RowEmpty>
        )}
        {drivers.map(driver => (
          <DriverRow
            key={driver.id}
            driver={driver}
            roles={roles}
            invite={invite}
          />
        ))}
      </tbody>
    </DriversContainer>
  );
};

DriversList.propTypes = {
  drivers: PT.array,
  roles: PT.array,
  invite: PT.bool,
  onChange: PT.func,
  onSelectAllDrivers: PT.func,
  onDeselectAllDrivers: PT.func,
};

export default connect(state => ({
  roles: state.authentication.roles,
  invitedData: getFormValues('invite-drivers')(state),
}), { onChange: change, onSelectAllDrivers: selectAllDrivers, onDeselectAllDrivers: deselectAllDrivers })(DriversList);
