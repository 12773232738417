import styled, { css } from 'styled-components';
import { media } from 'styles/utils';

import PT from 'prop-types';

export const Description = styled.div`
  line-height: 28px;

  ${props => props.large && css`
    p {
      font-size: 20px;
      line-height: 34px;
    }
  `}
`;

Description.propTypes = {
  large: PT.bool,
};

export const Highlights = styled.div`
  margin: 50px 0;

  ${media.tablet`
    margin: 90px 0;
  `}
`;

export const Highlight = styled.div`
  padding: 36px 0;
  border-top: 1px solid ${props => props.theme.border};

  ${media.tablet`
    display: flex;
  `}
`;

export const Block = styled.div`
  flex: 1;
  padding: 10px 0;

  ${media.tablet`
    padding: 0 18px;
  `}
`;

export const Small = styled.p`
  font-size: 14px;
  margin: 0;
`;

export const BlockTitle = styled.p`
  font-size: 20px;
  font-weight: 700;
  margin: 0;
`;

BlockTitle.propTypes = {
  capitalize: PT.bool,
};
