import styled from 'styled-components';

export const Subject = styled.p`
  font-weight: bold;
`;

export const DriversListContainer = styled.div`
  margin: 48px 0;
`;

export const ActionContainer = styled.div`
  display: flex;

  button {
    margin-right: 30px;
  }
`;
