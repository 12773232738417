import styled from 'styled-components';

import { media } from 'styles/utils';

import Link, { activeClass } from 'common/Link';

export const TopicContainer = styled.div`
  padding-bottom: 10px;
  border-bottom: 2px solid ${props => props.theme.border};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${media.tablet`
    padding: 10px 0 50px;
    justify-content: start;
  `}
`;

export const ExtendedLink = styled(Link)`
  font-size: 20px;

  &.${activeClass} {
    border-bottom: 3px solid ${props => props.theme.red};
  }

  ${media.tablet`
    font-size: 26px;
    margin-right: 40px;
  `}
`;
