import React from 'react';
import PT from 'prop-types';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';

const PaginationContainer = styled.div`
  .container {
    display: flex;
    justify-content: center;
    margin: 40px 0 10px 0;
  }

  .page,
  .next,
  .previous,
  .break {
    list-style: none;
  }

  .break {
    padding: 0px 12px;
  }

  .nextLink, .previousLink, .pageLink {
    padding: 5px;
    outline: none;
    cursor: pointer;
  }

  .active {
    color: red;
  }
`;

const Pagination = ({ pageCount, handlePageClick, page }) => (
  <PaginationContainer>
    <ReactPaginate
      previousLabel="&laquo;"
      nextLabel="&raquo;"
      pageCount={pageCount}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={handlePageClick}
      breakClassName="break"
      containerClassName="container"
      pageClassName="page"
      pageLinkClassName="pageLink"
      activeClassName="active"
      previousClassName="previous"
      nextClassName="next"
      previousLinkClassName="previousLink"
      nextLinkClassName="nextLink"
      disabledClassName="disabled"
      forcePage={page}
    />
  </PaginationContainer>
);

Pagination.propTypes = {
  pageCount: PT.number,
  handlePageClick: PT.func,
  page: PT.number,
};

export default Pagination;
