import createAction from 'services/createAction';

const GET_CEM_PACKAGE = 'CEMPackage/GET';
const GET_CEM_PACKAGE_SUCCESS = 'CEMPackage/GET_SUCCESS';
const GET_CEM_PACKAGE_FAILED = 'CEMPackage/GET_FAILED';

const UPLOAD_CEM_PACKAGE = 'CEMPackage/UPLOAD';
const UPLOAD_CEM_PACKAGE_SUCCESS = 'CEMPackage/UPLOAD_SUCCESS';
const UPLOAD_CEM_PACKAGE_FAILED = 'CEMPackage/UPLOAD_FAILED';

const initialState = {
  data: [],
  error: {
    message: '',
  },
  loading: {
    get: false,
    upload: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case GET_CEM_PACKAGE_SUCCESS:
    return {
      ...state,
      data: payload,
      loading: { get: false },
    };
  case GET_CEM_PACKAGE_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { get: false },
    };
  case GET_CEM_PACKAGE:
    return {
      ...state,
      error: { message: '' },
      loading: { get: true },
    };
  case UPLOAD_CEM_PACKAGE_SUCCESS:
    return {
      ...state,
      loading: { upload: false },
    };
  case UPLOAD_CEM_PACKAGE_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { upload: false },
    };
  case UPLOAD_CEM_PACKAGE:
    return {
      ...state,
      error: { message: '' },
      loading: { upload: true },
    };
  default:
    return state;
  }
};

const getCEMPackageSuccess = createAction(GET_CEM_PACKAGE_SUCCESS);
const getCEMPackageFailed = createAction(GET_CEM_PACKAGE_FAILED);

export const getCEMPackage = packageId => (dispatch, getState, api) => {
  dispatch({ type: GET_CEM_PACKAGE });

  api.get({ path: `packages/${packageId}`, v2: true })
    .then((res) => {
      dispatch(getCEMPackageSuccess(res));
    })
    .catch((err) => {
      dispatch(getCEMPackageFailed({ message: err.data }));
    });
};

const uploadCEMPackageSuccess = createAction(UPLOAD_CEM_PACKAGE_SUCCESS);
const uploadCEMPackageFailed = createAction(UPLOAD_CEM_PACKAGE_FAILED);

export const uploadCEMPackage = (packageId, file) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: UPLOAD_CEM_PACKAGE });

    const data = new FormData();
    data.append('file', file);

    api.post({
      path: `packages/${packageId}/upload`,
      body: data,
      v2: true,
      upload: true,
    })
      .then((res) => {
        dispatch(uploadCEMPackageSuccess(res));
        resolve(res);
      })
      .catch((err) => {
        dispatch(uploadCEMPackageFailed({ message: err.data }));
        reject();
      });
  })
);
