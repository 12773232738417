import React, { Component, Fragment } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';

import { getDriverDetails } from 'ducks/driver/driver';

import { Loading } from 'common';

import DriverHeader from './components/DriverHeader';
import DriverTabs from './components/DriverTabs';
import DriverTabsActivities from './components/DriverTabsActivities';
import DriverTabsCars from './components/DriverTabsCars';
import DriverTabsMileageHistory from './components/DriverTabsMileageHistory';
import DriverTabsPointsHistory from './components/DriverTabsPointsHistory';
import DriverTabsProgression from './components/DriverTabsProgression';
import DriverTabsRewards from './components/DriverTabsRewards';
import DriverTabsTags from './components/DriverTabsTags';
import DriverTabsCEMPackageOverview from './components/DriverTabsCEMPackageOverview';
import DriverTabsCEMPackageDetail from './components/DriverTabsCEMPackageDetail';

class DriverDetail extends Component {
  componentWillMount() {
    this.props.getDriverDetails(this.props.match.params.id);
  }

  render() {
    const { driver, loading, roles, match } = this.props;
    const noDriverDetails = Object.keys(driver).length === 0;

    // Only show full-page loader when driver details are not loaded yet
    // This allows refetching driver details without showing a full-page loader
    if (noDriverDetails || (noDriverDetails && loading)) return <Loading />;

    return (
      <Fragment>
        <DriverHeader driver={driver} roles={roles} />
        <DriverTabs />
        <Switch>
          <Redirect exact from={`${match.url}`} to={`${match.url}/cars`} />
          <Route path="/driver/:id/cars" component={DriverTabsCars} />
          <Route path="/driver/:id/tags" component={DriverTabsTags} />
          <Route path="/driver/:id/rewards" component={DriverTabsRewards} />
          <Route path="/driver/:id/progression" component={DriverTabsProgression} />
          <Route path="/driver/:id/activities" component={DriverTabsActivities} />
          <Route path="/driver/:id/mileage-history" component={DriverTabsMileageHistory} />
          <Route path="/driver/:id/points-history" component={DriverTabsPointsHistory} />
          <Route path="/driver/:id/cem/:packageId" component={DriverTabsCEMPackageDetail} />
          <Route path="/driver/:id/cem" component={DriverTabsCEMPackageOverview} />
        </Switch>
      </Fragment>
    );
  }
}

DriverDetail.propTypes = {
  match: PT.object,
  driver: PT.object,
  getDriverDetails: PT.func,
  loading: PT.bool,
  roles: PT.array,
};

export default compose(
  withRouter,
  connect(state => ({
    driver: state.driver.data,
    loading: state.driver.loading.get,
    roles: state.authentication.roles,
  }), { getDriverDetails }),
)(DriverDetail);
