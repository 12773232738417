import styled from 'styled-components';

export const InputContainer = styled.div`
  button:not(:last-child) {
    margin: 0 0 20px 0;
  }
`;

export const InputSection = styled.div`
  margin: 0 0 20px 0;
`;
