import styled, { css } from 'styled-components';
import PT from 'prop-types';
import FormField from '../FormField';

const FormSection = styled.div`
  border: 1px solid ${props => props.theme.border};
  padding: 18px;
  margin-bottom: 36px;

  ${FormField} {
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  ${props => props.flex && css`
    display: flex;
  `}
`;

FormSection.propTypes = {
  flex: PT.bool,
};

export default FormSection;
