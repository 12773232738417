import React, { Component, Fragment } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';

import { showNotification } from 'ducks/notification';

import { InputError } from 'common/form';

import DraftEditor from './styled';

class Wysiwyg extends Component {
  state = {
    editorState: EditorState.createEmpty(),
  };

  componentDidMount() {
    this.initializeValues(this.props.defaultValue);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.defaultValue !== this.props.defaultValue) {
      this.initializeValues(nextProps.defaultValue);
    }
  }

  // This function will be called upon every update in component state.
  // It converts draft RAW format to html and updates field through redux forms' change duck.
  onChange = (data) => {
    const html = draftToHtml(convertToRaw(data));
    this.props.change(this.props.meta.form, this.props.input.name, html);
  }

  onEditorStateChange = (editorState) => {
    this.setState({ editorState });
  };

  onEditorBlur = () => {
    this.onChange(this.state.editorState.getCurrentContent());
  }

  getEditorValues = (defaultValue) => {
    const contentBlock = htmlToDraft(defaultValue || this.props.value);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);

    this.setState({ editorState });
  }

  initializeValues = (defaultValue) => {
    if (!this.state.editorState.getCurrentContent().hasText() && defaultValue) {
      this.getEditorValues(defaultValue);
    }
  }

  handlePastedText = (string, html, editorState) => {
    this.setState({ editorState });

    const textLength = this.state.editorState.getCurrentContent().getPlainText().length;
    if ((string.length + textLength) >= 250 && this.props.maxChar) {
      this.props.showNotification('De tekst die je probeert te plakken is langer dan 250 karakters');
      return ((string.length + textLength) >= 250 && this.props.maxChar);
    }
  }

  handleBeforeInput = (val) => {
    const textLength = this.state.editorState.getCurrentContent().getPlainText().length;
    if (val && textLength >= 250 && this.props.maxChar) {
      return 'handled';
    }

    return 'not-handled';
  }

  render() {
    const {
      input, meta, height, disabledLink,
    } = this.props;

    const toolbar = {
      options: ['inline', 'list'],
      inline: {
        options: ['bold', 'italic', 'underline'],
      },
    };

    if (!disabledLink) toolbar.options.push('link');

    return (
      <Fragment>
        <DraftEditor error={meta.error} submitFailed={meta.submitFailed}>
          <Editor
            id={input.name}
            height={height}
            editorState={this.state.editorState}
            stripPastedStyles
            handlePastedText={this.handlePastedText}
            onEditorStateChange={this.onEditorStateChange}
            toolbar={toolbar}
            onBlur={this.onEditorBlur}
            handleBeforeInput={this.handleBeforeInput}
          />
        </DraftEditor>
        <InputError meta={meta} />
      </Fragment>
    );
  }
}

Wysiwyg.propTypes = {
  meta: PT.object,
  input: PT.object,
  value: PT.string,
  change: PT.func,
  height: PT.string,
  defaultValue: PT.string,
  disabledLink: PT.bool,
  maxChar: PT.bool,
  showNotification: PT.func,
};

export default connect(null, { change, showNotification })(Wysiwyg);
