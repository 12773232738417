import createAction from 'services/createAction';

const SET_P24APP_STATUS = 'p24app/SET_STATUS';
const SET_P24APP_STATUS_SUCCESS = 'p24app/SET_STATUS_SUCCESS';
const SET_P24APP_STATUS_FAILED = 'p24app/SET_STATUS_FAILED';

const GET_P24APP_STATUS = 'p24app/GET_STATUS';
const GET_P24APP_STATUS_SUCCESS = 'p24app/GET_STATUS_SUCCESS';
const GET_P24APP_STATUS_FAILED = 'p24app/GET_STATUS_FAILED';

const initialState = {
  status: 'up',
  loading: {
    setStatus: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case SET_P24APP_STATUS_SUCCESS:
    return {
      ...state,
      status: payload,
      loading: { ...state.loading, setStatus: false },
    };
  case SET_P24APP_STATUS_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, setStatus: false },
    };
  case SET_P24APP_STATUS:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, setStatus: true },
    };
  case GET_P24APP_STATUS_SUCCESS:
    return {
      ...state,
      status: payload,
      loading: { ...state.loading, getStatus: false },
    };
  case GET_P24APP_STATUS_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, getStatus: false },
    };
  case GET_P24APP_STATUS:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, getStatus: true },
    };
  default:
    return state;
  }
};

const getAppStatusSuccess = createAction(GET_P24APP_STATUS_SUCCESS);
const getAppStatusFailed = createAction(GET_P24APP_STATUS_FAILED);

export const getAppStatus = () => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: GET_P24APP_STATUS });

    api.get({ path: 'status' })
      .then((res) => {
        resolve(res);
        dispatch(getAppStatusSuccess(res.status));
      })
      .catch((err) => {
        reject(err);
        dispatch(getAppStatusFailed({ message: err.data }));
      });
  }));

const setAppStatusSuccess = createAction(SET_P24APP_STATUS_SUCCESS);
const setAppStatusFailed = createAction(SET_P24APP_STATUS_FAILED);

export const setAppStatus = status => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: SET_P24APP_STATUS });

    api.post({ path: 'status', body: { status } })
      .then((res) => {
        resolve(res);
        dispatch(setAppStatusSuccess(status));
      })
      .catch((err) => {
        reject(err);
        dispatch(setAppStatusFailed({ message: err.data }));
      });
  }));
