import createAction from 'services/createAction';

const GET_LEVELS = 'levels/GET';
const GET_LEVELS_SUCCESS = 'levels/GET_SUCCESS';
const GET_LEVELS_FAILED = 'levels/GET_FAILED';

const initialState = {
  data: [],
  error: {
    message: '',
  },
  loading: {
    levels: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case GET_LEVELS_SUCCESS:
    return {
      ...state,
      data: payload,
      loading: { ...state.loading, levels: false },
    };
  case GET_LEVELS_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, levels: false },
    };
  case GET_LEVELS:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, levels: true },
    };
  default:
    return state;
  }
};

const getLevelsSuccess = createAction(GET_LEVELS_SUCCESS);
const getLevelsFailed = createAction(GET_LEVELS_FAILED);

export const getLevels = () => (dispatch, getState, api) => {
  dispatch({ type: GET_LEVELS });

  api.get({ path: 'management/level' })
    .then((res) => {
      dispatch(getLevelsSuccess(res));
    })
    .catch((err) => {
      dispatch(getLevelsFailed({ message: err.data }));
    });
};
