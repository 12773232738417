import React, { Component, Fragment } from 'react';
import PT from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';

import {
  hasRole,
  isOwnDealerEvent,
  isOwnEvent,
  COMMUNITY_MANAGER,
} from 'services/authHelper';
import { isPublished } from 'services/eventHelper';

import { getEvent, resetEvent } from 'ducks/event';
import { resetUpdates } from 'ducks/updates';

import { EventPreview } from 'modules';
import { Loading } from 'common';

import EventDetailHeader from './components/EventDetailHeader';
import EventDetailOverview from './components/EventDetailOverview';
import EventDetailUpdates from './components/EventDetailUpdates';
import EventDetailEmailHistory from './components/EventDetailEmailHistory';
import EventDetailEmailEditor from './components/EventDetailEmailEditor';
import EventDetailEmailPreview from './components/EventDetailEmailPreview';

class EventDetail extends Component {
  componentWillMount() {
    if (this.props.match.params.id !== this.props.eventId) {
      this.props.getEvent(this.props.match.params.id)
        .then((event) => {
          if (!isPublished(event)) this.props.history.push(`/event/edit/${event.id}/details`);
        });
    }
  }

  componentWillUnmount() {
    this.props.resetEvent();
    this.props.resetUpdates();
  }

  render() {
    const {
      loading, event, match, eventId, roles, dealer, user,
    } = this.props;

    if (loading) return <Loading />;

    // Don't load the route when there is no event yet
    if (!eventId) return null;

    const hasFullAccess = () => {
      if (hasRole(roles, [COMMUNITY_MANAGER])) return true;
      if (isOwnDealerEvent(event, dealer)) return true;
      if (isOwnEvent(event, user)) return true;
      return false;
    };

    return (
      <Fragment>
        <EventDetailHeader event={event} roles={roles} dealer={dealer} hasFullAccess={hasFullAccess()} />
        <Switch>
          <Redirect exact from={`${match.url}`} to={`${match.url}/overview`} />
          <Route
            path="/event/:eventId/overview"
            render={props => <EventDetailOverview {...props} hasFullAccess={hasFullAccess()} />}
          />
          <Route
            path="/event/:eventId/updates"
            render={props => <EventDetailUpdates {...props} hasFullAccess={hasFullAccess()} />}
          />
          <Route path="/event/:eventId/emails" exact component={EventDetailEmailHistory} />
          <Route path="/event/:eventId/emails/edit/:emailId?" component={EventDetailEmailEditor} />
          <Route path="/event/:eventId/emails/preview/:emailId" component={EventDetailEmailPreview} />
          <Route path="/event/:eventId/preview" component={EventPreview} />
        </Switch>
      </Fragment>
    );
  }
}

EventDetail.propTypes = {
  loading: PT.bool,
  match: PT.object,
  history: PT.object,
  eventId: PT.string,
  dealer: PT.object,
  event: PT.object,
  getEvent: PT.func,
  roles: PT.array,
  resetUpdates: PT.func,
  resetEvent: PT.func,
  user: PT.object,
};

export default compose(
  withRouter,
  connect(state => ({
    eventId: state.event.eventId,
    roles: state.authentication.roles,
    dealer: state.authentication.data.role.dealer,
    event: state.event.data,
    user: state.authentication.data,
    loading: state.event.loading.get,
  }), { getEvent, resetEvent, resetUpdates }),
)(EventDetail);
