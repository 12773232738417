import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Login from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import ActivateAccount from './components/ActivateAccount';
import Oauth from './components/Oauth';
import PasswordReset from './components/PasswordReset';

const Authentication = () => (
  <Switch>
    <Route path="/login" component={Login} />
    <Route path="/forgot-password" component={ForgotPassword} />
    <Route path="/activate_account" component={ActivateAccount} />
    <Route path="/password_reset" component={PasswordReset} />
    <Route path="/oauth" component={Oauth} />
  </Switch>
);

export default Authentication;
