import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PT from 'prop-types';

import { deleteCarFromDriver } from 'ducks/driver/deleteCar';
import { getDriverDetails } from 'ducks/driver/driver';

import { Card, Container, Button, Info, MileageInput, LabelTag } from 'common';

import { ExtendedTitle, CardHeader, Name, ButtonContainer, CardContent, CardActions } from './styled';

class DriverTabsCars extends Component {
  confirmDeleteCar = (carId) => {
    if (window.confirm('Weet u het zeker dat u deze auto wilt verwijderen bij deze gebruiker?')) {
      this.props.deleteCarFromDriver(carId).then(() => this.props.getDriverDetails(this.props.driver.id));
    }
  };

  render() {
    const { driver } = this.props;

    return (
      <Container>
        {driver.car_link.map(car => (
          <Card key={car.id}>
            <CardHeader>
              <div>
                <ExtendedTitle bold>{car.car.model} {(!car.car.vin && !car.car.licence_plate) && <LabelTag variant="warning">In productie</LabelTag>}</ExtendedTitle>
                <Name>{driver.full_name}</Name>
              </div>
              {(car.car.vin || car.car.licence_plate) && (
                <CardActions>
                  <ButtonContainer>
                    <Button
                      small
                      onClick={() => this.confirmDeleteCar(car.car.id)}
                    >
                      Auto verwijderen
                    </Button>
                  </ButtonContainer>
                  <MileageInput car={car.car} driver={driver} hideNextCar />
                </CardActions>
              )}
            </CardHeader>

            <CardContent>
              <Info label="Jaar">
                {car.car.model_year ? moment(car.car.model_year).format('YYYY') : '-'}
              </Info>
              <Info label="Voorkeursdealer">
                {car.car.preferred_dealer.name || '-'}
              </Info>
              <Info label="Kenteken">
                {car.car.licence_plate || '-'}
              </Info>
              <Info label="VIN">
                {car.car.vin || '-'}
              </Info>
              <Info label="Kilometerstand">
                {car.car.current_mileage && car.car.current_mileage.mileage ?
                  car.car.current_mileage.mileage :
                  '0'
                }
              </Info>
            </CardContent>
          </Card>
        ))}
      </Container>
    );
  }
}

DriverTabsCars.propTypes = {
  driver: PT.object,
  deleteCarFromDriver: PT.func,
  getDriverDetails: PT.func,
};

export default connect(state => ({
  driver: state.driver.data,
}), { deleteCarFromDriver, getDriverDetails })(DriverTabsCars);
