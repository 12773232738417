import styled from 'styled-components';
import ReactPlayer from 'react-player';

export const UpdateContainer = styled.div`
  border-bottom: 1px solid ${props => props.theme.border};
  margin-bottom: 18px;
  padding-bottom: 18px;
`;

export const UpdateHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.p`
  margin-top: 20px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: 1px solid ${props => props.theme.border};
`;

export const Time = styled.div`
  font-weight: bold;
`;

export const LeftContainer = styled.div`
  display: flex;
`;

export const UpdateContent = styled.div`
  margin: 18px 0;
  letter-spacing: 0.05em;
  padding: 0 18px;
`;

export const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Image = styled.img`
  max-width: 100%;
  max-height: 200px;
  margin: 10px;
`;

export const VideoWrapper = styled.div`
  max-width: 640px;
  margin: 0 auto;
`;

// https://fettblog.eu/blog/2013/06/16/preserving-aspect-ratio-for-embedded-iframes/
export const VideoContainer = styled.div`
  position: relative;
  margin-top: 36px;
  text-align: center;
  padding-bottom: 56.25%;
  height: 0;
`;

export const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;

export const ActionsList = styled.ul`
  margin: 0;
  padding: 0;
  border: 1px solid ${props => props.theme.border.withBoxShadow};
  box-shadow: ${props => props.theme.boxShadow};
  width: 200px;
  position: absolute;
  top: 5px;
`;

export const ActionsListItem = styled.li`
  list-style: none;
  text-align: center;
  border-bottom: 1px solid ${props => props.theme.border};
  background-color: ${props => props.theme.white};
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-size: 12px;
  padding: 5px;
  cursor: pointer;

  &:last-of-type {
    border: 0;
  }

  &:hover {
    background-color: ${props => props.theme.border};
    color: ${props => props.theme.white};
  }
`;

export const DropdownActions = styled.div`
  display: flex;
  flex-direction: row-reverse;
  position: relative;
`;
