import styled from 'styled-components';
import { NavLink, Link } from 'react-router-dom';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div`
  flex-shrink: 0;

  button {
    &:first-of-type {
      margin-right: 18px;
    }
  }
`;

export const ShareLink = styled(Link)`
  color: ${props => props.theme.gray};
  font-size: 14px;
`;

export const TabsContainer = styled.div`
  margin: 18px 0 0;
`;

export const Tab = styled(NavLink)`
  border: 0;
  font-size: 24px;
  outline: none;
  cursor: pointer;
  padding: 0;
  margin-bottom: 3px;
  margin-right: 36px;
  color: ${props => props.theme.black};
  text-decoration: none;

  &:hover {
    color: ${props => props.theme.red};
  }

  &.active {
    color: ${props => props.theme.red};
    border-bottom: 3px solid ${props => props.theme.red};
    margin-bottom: 0;
  }
`;
