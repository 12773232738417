import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
`;

export const Images = styled.div`
  min-width: 50%;
  max-width: 50%;
  padding: 0 20px;
`;

export const Image = styled.img`
  width: 100%;
  margin: 10px 0;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;
