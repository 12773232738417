import React from 'react';
import PT from 'prop-types';

import { Container, Subtitle, Title } from 'common';

const EmptyState = ({ title, subtitle, small }) => (
  <Container textCenter>
    {title && <Title variant="gray" small={small}>{title}</Title>}
    {subtitle && <Subtitle variant="gray" small>{subtitle}</Subtitle>}
  </Container>
);

EmptyState.propTypes = {
  title: PT.string,
  subtitle: PT.string,
  small: PT.bool,
};

export default EmptyState;
