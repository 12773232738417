import createAction from 'services/createAction';
import { showNotification } from 'ducks/notification';

const GET_VALIDATE_CARS_COUNT = 'validateCars/GET_COUNT';
const GET_VALIDATE_CARS_COUNT_SUCCESS = 'validateCars/GET_COUNT_SUCCESS';
const GET_VALIDATE_CARS_COUNT_FAILED = 'validateCars/GET_COUNT_FAILED';

const GET_VALIDATE_CARS = 'validateCars/GET';
const GET_VALIDATE_CARS_SUCCESS = 'validateCars/GET_SUCCESS';
const GET_VALIDATE_CARS_FAILED = 'validateCars/GET_FAILED';

const APPROVE_VALIDATE_CARS = 'validateCars/APPROVE';
const APPROVE_VALIDATE_CARS_SUCCESS = 'validateCars/APPROVE_SUCCESS';
const APPROVE_VALIDATE_CARS_FAILED = 'validateCars/APPROVE_FAILED';

const POST_VALIDATE_CAR_MESSAGE = 'validateCars/POST_MESSAGE';
const POST_VALIDATE_CAR_MESSAGE_SUCCESS = 'validateCars/POST_MESSAGE_SUCCESS';
const POST_VALIDATE_CAR_MESSAGE_FAILED = 'validateCars/POST_MESSAGE_FAILED';

const REJECT_VALIDATE_CARS = 'validateCars/REJECT';
const REJECT_VALIDATE_CARS_SUCCESS = 'validateCars/REJECT_SUCCESS';
const REJECT_VALIDATE_CARS_FAILED = 'validateCars/REJECT_FAILED';

const initialState = {
  data: [],
  count: 0,
  error: {
    message: '',
  },
  loading: {
    count: false,
    list: false,
    modal: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case GET_VALIDATE_CARS_COUNT_SUCCESS:
    return {
      ...state,
      count: payload,
      loading: { ...state.loading, count: false },
    };
  case GET_VALIDATE_CARS_COUNT_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, count: false },
    };
  case GET_VALIDATE_CARS_COUNT:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, count: true },
    };
  case GET_VALIDATE_CARS_SUCCESS:
    return {
      ...state,
      data: payload,
      loading: { ...state.loading, list: false },
    };
  case GET_VALIDATE_CARS_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, list: false },
    };
  case GET_VALIDATE_CARS:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, list: true },
    };
  case APPROVE_VALIDATE_CARS_SUCCESS:
    return {
      ...state,
      data: state.data.filter(data => data.car.id !== payload.carId),
      count: state.count - 1,
      loading: { ...state.loading, modal: false },
    };
  case APPROVE_VALIDATE_CARS_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, modal: false },
    };
  case APPROVE_VALIDATE_CARS:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, modal: true },
    };
  case REJECT_VALIDATE_CARS_SUCCESS:
    return {
      ...state,
      data: state.data.filter(data => data.car.id !== payload.carId),
      count: state.count - 1,
      loading: { ...state.loading, modal: false },
    };
  case REJECT_VALIDATE_CARS_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, modal: false },
    };
  case REJECT_VALIDATE_CARS:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, modal: true },
    };
  case POST_VALIDATE_CAR_MESSAGE_SUCCESS:
    return {
      ...state,
      loading: { ...state.loading, modal: false },
    };
  case POST_VALIDATE_CAR_MESSAGE_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, modal: false },
    };
  case POST_VALIDATE_CAR_MESSAGE:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, modal: true },
    };
  default:
    return state;
  }
};


const getValidateCarsCountSuccess = createAction(GET_VALIDATE_CARS_COUNT_SUCCESS);
const getValidateCarsCountFailed = createAction(GET_VALIDATE_CARS_COUNT_FAILED);

export const getValidateCarsCount = () => (dispatch, getState, api) => {
  dispatch({ type: GET_VALIDATE_CARS_COUNT });

  api.get({ path: 'management/car/validation/count' })
    .then((res) => {
      dispatch(getValidateCarsCountSuccess(res.amount));
    })
    .catch((err) => {
      dispatch(getValidateCarsCountFailed({ message: err.data }));
    });
};


const getValidateCarsSuccess = createAction(GET_VALIDATE_CARS_SUCCESS);
const getValidateCarsFailed = createAction(GET_VALIDATE_CARS_FAILED);

export const getValidateCars = () => (dispatch, getState, api) => {
  dispatch({ type: GET_VALIDATE_CARS });

  api.get({ path: 'management/car/validation' })
    .then((res) => {
      dispatch(getValidateCarsSuccess(res));
    })
    .catch((err) => {
      dispatch(getValidateCarsFailed({ message: err.data }));
    });
};

const approveCarsSuccess = createAction(APPROVE_VALIDATE_CARS_SUCCESS);
const approveCarsFailed = createAction(APPROVE_VALIDATE_CARS_FAILED);

export const approveCars = carId => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: APPROVE_VALIDATE_CARS });

    api.put({ path: `management/car/${carId}/validate` })
      .then((res) => {
        resolve(res);
        dispatch(approveCarsSuccess({ data: res, carId }));
        dispatch(showNotification('Het voertuig is succesvol geverifieerd.', 'green'));
      })
      .catch((err) => {
        reject(err);
        dispatch(approveCarsFailed({ message: err.data }));
        dispatch(showNotification('Er is iets fout gegaan. Probeer opnieuw.'));
      });
  })
);

const rejectCarsSuccess = createAction(REJECT_VALIDATE_CARS_SUCCESS);
const rejectCarsFailed = createAction(REJECT_VALIDATE_CARS_FAILED);

export const rejectCars = carId => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: REJECT_VALIDATE_CARS });

    api.del({ path: `management/car/${carId}` })
      .then((res) => {
        resolve(res);
        dispatch(rejectCarsSuccess({ data: res, carId }));
        dispatch(showNotification('Het voertuig is succesvol geweigerd.', 'green'));
      })
      .catch((err) => {
        reject(err);
        dispatch(rejectCarsFailed({ message: err.data }));
        dispatch(showNotification('Er is iets fout gegaan. Probeer opnieuw.'));
      });
  })
);

const postValidateCarMessageSuccess = createAction(POST_VALIDATE_CAR_MESSAGE_SUCCESS);
const postValidateCarMessageFailed = createAction(POST_VALIDATE_CAR_MESSAGE_FAILED);

export const postValidateCarMessage = (carId, message) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: POST_VALIDATE_CAR_MESSAGE });

    api.post({
      path: 'information_requests',
      body: {
        car_id: carId,
        message,
      },
      v2: true,
    })
      .then((res) => {
        dispatch(postValidateCarMessageSuccess({ data: res, carId }));
        dispatch(showNotification('De opmerking is succesvol verstuurd.', 'green'));
        resolve(res);
      })
      .catch((err) => {
        reject(err);
        dispatch(postValidateCarMessageFailed({ message: err.data }));
        dispatch(showNotification('Er is iets fout gegaan. Probeer opnieuw.'));
      });
  })
);
