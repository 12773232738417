import React, { Component } from 'react';
import PT from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getCEMPackages } from 'ducks/CEMPackages';
import { Container, FlexWrapper, EmptyState, Loading } from 'common';
import CEMPackageCard from '../CEMPackageCard';

class CEMPackageOverview extends Component {
  componentWillMount() {
    this.props.getCEMPackages();
  }

  render() {
    const { CEMPackages, loading, history } = this.props;

    return (
      <Container>
        <FlexWrapper>
          {loading ? (
            <Container alignCenter>
              <Loading />
            </Container>
          ) : CEMPackages.length > 0 ?
            CEMPackages.map(CEMPackage => (
              <CEMPackageCard
                data={CEMPackage}
                key={CEMPackage.id}
                onClick={() => history.push(`/cem/packages/${CEMPackage.id}`)}
              />
            )) : (
              <EmptyState
                title="Nog geen CEM pakketten"
                subtitle="Er zijn op dit moment nog geen CEM pakketten"
              />
            )}
        </FlexWrapper>
      </Container>
    );
  }
}

CEMPackageOverview.propTypes = {
  getCEMPackages: PT.func.isRequired,
  CEMPackages: PT.arrayOf(PT.shape({
    id: PT.string.isRequired,
    image: PT.string,
    name: PT.string.isRequired,
    description_short: PT.string.isRequired,
  })),
  loading: PT.bool,
  history: PT.object.isRequired,
};

export default compose(
  withRouter,
  connect(state => ({
    CEMPackages: state.CEMPackages.data,
    loading: state.CEMPackages.loading.get,
  }), { getCEMPackages }),
)(CEMPackageOverview);
