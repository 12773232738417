import createAction from 'services/createAction';

const GET_EVENT = 'publicEvent/GET_EVENT';
const GET_EVENT_SUCCESS = 'publicEvent/GET_EVENT_SUCCESS';
const GET_EVENT_FAILED = 'publicEvent/GET_EVENT_FAILED';

const GET_TICKETS = 'publicEvent/GET_TICKETS';
const GET_TICKETS_SUCCESS = 'publicEvent/GET_TICKETS_SUCCESS';
const GET_TICKETS_FAILED = 'publicEvent/GET_TICKETS_FAILED';

const initialState = {
  data: {},
  tickets: [],
  error: {
    message: '',
  },
  loading: {
    event: false,
    tickets: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case GET_EVENT_SUCCESS:
    return {
      ...state,
      data: payload,
      loading: { ...state.loading, event: false },
    };
  case GET_EVENT_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, event: false },
    };
  case GET_EVENT:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, event: true },
    };
  case GET_TICKETS_SUCCESS:
    return {
      ...state,
      tickets: payload,
      loading: { ...state.loading, tickets: false },
    };
  case GET_TICKETS_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, tickets: false },
    };
  case GET_TICKETS:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, tickets: true },
    };
  default:
    return state;
  }
};

const getEventSuccess = createAction(GET_EVENT_SUCCESS);
const getEventFailed = createAction(GET_EVENT_FAILED);

export const getEvent = eventId => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: GET_EVENT });

    api.get({ path: `activity/${eventId}/share`, withAuth: false })
      .then((res) => {
        dispatch(getEventSuccess(res));
        resolve();
      })
      .catch((err) => {
        dispatch(getEventFailed({ message: err.data }));
        reject();
      });
  })
);

const getTicketsSuccess = createAction(GET_TICKETS_SUCCESS);
const getTicketsFailed = createAction(GET_TICKETS_FAILED);

export const getTickets = eventId => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: GET_TICKETS });

    api.get({ path: `activity/${eventId}/ticket/public`, withAuth: false })
      .then((res) => {
        dispatch(getTicketsSuccess(res));
        resolve();
      })
      .catch((err) => {
        dispatch(getTicketsFailed({ message: err.data }));
        reject();
      });
  })
);
