import styled from 'styled-components';
import { Container } from 'common';

export default styled(Container)`
  padding: 90px 18px;
  margin-top: 36px;

  p, a {
    font-size: 16px;
    line-height: 28px;
    color: ${props => props.theme.white};
  }

  a:hover {
    color: ${props => props.theme.red};
  }
`;
