import React, { Fragment, Component } from 'react';
import PT from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import {
  hasRole, COMMUNITY_MANAGER, DEALER,
} from 'services/authHelper';
import { Container, Title, Info, FlexWrapper, Anchor, Button } from 'common';
import { driverPasswordReset, deleteDriver } from 'ducks/driver/driver';

import DriverEdit from '../DriverEdit';
import { StyledEditIcon, TitleContainer } from './styled';

const formatAddress = (addresses) => {
  if (addresses.length === 0) return '';

  const address = addresses[0];
  const street = address.street || '-';
  const number = street !== '-' ? (address.housenumber || '') : '';
  const infix = address.infix && !!number ? `-${address.infix}` : '';
  const zipcode = address.zipcode || '';
  const city = address.city || '';
  const country = address.country || '';

  return [`${street} ${number}${infix}`, zipcode, city, country];
};

class DriverHeader extends Component {
  state = {
    editOpen: false,
  };

  confirmResetPassword = () => {
    if (window.confirm('Weet u zeker dat u een password reset email wilt versturen naar deze rijder?')) {
      this.props.driverPasswordReset({ email: this.props.driver.email });
    }
  }

  confirmDeleteDriver = () => {
    if (window.confirm('Weet u zeker dat u dit account wilt verwijderen?')) {
      this.props.deleteDriver(this.props.driver.id).then(() => {
        this.props.history.push('/drivers');
      });
    }
  }

  render() {
    const { driver, roles } = this.props;

    return (
      <Fragment>
        <Container>
          <TitleContainer>
            <Title>
              {driver.full_name}
              {hasRole(roles, [COMMUNITY_MANAGER, DEALER]) && (
                <Anchor onClick={() => this.setState({ editOpen: true })}><StyledEditIcon /></Anchor>
              )}
            </Title>
            <div>
              <Button
                variant="black"
                onClick={() => this.confirmResetPassword()}
                disabled={this.props.resetPasswordLoading}
              >
                Verstuur password reset
              </Button>
              {hasRole(roles, [COMMUNITY_MANAGER]) && (
                <Button
                  variant="red"
                  onClick={() => this.confirmDeleteDriver()}
                  disabled={this.props.deleteDriverLoading}
                >
                  Verwijder account
                </Button>
              )}
            </div>
          </TitleContainer>
          <FlexWrapper>
            <Info label="E-mailadres">
              {driver.email}
            </Info>
            <Info label="Adres">
              {formatAddress(driver.address).length ?
                formatAddress(driver.address).map((addressLine) => {
                  if (addressLine === '') return '';
                  return <span key={addressLine}>{addressLine}<br /></span>;
                }) :
                '-'
              }
            </Info>
            <Info label="Telefoonnummer">
              {driver.phone || '-'}
            </Info>
            <Info label="Verjaardag">
              {driver.birthdate ? moment(driver.birthdate).format('D MMMM, Y') : '-'}
            </Info>
            <Info label="Geslacht">
              {driver.gender === 0 ? 'Vrouw' : (
                driver.gender === 1 ? 'Man' : '-'
              )}
            </Info>
            <Info label="PON-ID">
              {driver.pon_id || '-'}
            </Info>
            <Info label="Laatste login">
              {driver.last_login ? moment(driver.last_login).format('dddd D MMMM, Y') : 'Nooit'}
            </Info>
            <Info label="Level">
              {driver.level.name || '-'}
            </Info>
          </FlexWrapper>
        </Container>
        {this.state.editOpen && (
          <DriverEdit
            onClose={() => this.setState({ editOpen: false })}
          />
        )}
      </Fragment>
    );
  }
}

DriverHeader.propTypes = {
  driver: PT.object,
  roles: PT.array,
  resetPasswordLoading: PT.bool,
  deleteDriverLoading: PT.bool,
  driverPasswordReset: PT.func,
  deleteDriver: PT.func,
  history: PT.object,
};

const mapStateToProps = state => ({
  resetPasswordLoading: state.driver.loading.resetPassword,
  deleteDriverLoading: state.driver.loading.delete,
});

const mapDispatchToProps = {
  driverPasswordReset,
  deleteDriver,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(DriverHeader);
