import styled, { css } from 'styled-components';
import PT from 'prop-types';

import Container from '../Container';

export const ContentContainer = styled(Container)`
  p {
    color: ${props => props.theme.text};
    margin-bottom: 16px;
  }

  b {
    font-weight: bold;
  }

  h1 {
    font-size: 48px;
    max-width: 800px;
    width: 100%;
    margin: 64px auto 32px;
    text-align: center;
  }

  h2 {
    margin-bottom: 8px;
    margin-top: 32px;
    font-weight: 100;
    font-size: 200%;
    line-height: 40px;
  }

  h3 {
    font-size: 100%;
    margin-top: 32px;
    margin-bottom: 8px;
    max-width: 800px;
  }

  a {
    color: ${props => props.theme.red};
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;

    ${props => props.noBullets && css`
      list-style-type: none;
    `}
  }

  ol {
   list-style-type: decimal;
   list-style-position: inside;
  }

  ol li {
    margin: 10px 0;
  }

  li {
    display: list-item;
  }

  ul ul,
  ol ul {
   list-style-type: circle;
   list-style-position: inside;
   margin-left: 15px;
  }

  ol ol,
  ul ol {
   list-style-type: lower-latin;
   list-style-position: inside;
   margin-left: 15px;
  }

  section {
    margin-bottom: 50px;
  }
`;

ContentContainer.propTypes = {
  noBullets: PT.bool,
};
