import React from 'react';

import Logo from 'images/logo.png';

import { HeaderBlock, Image } from './styled';

const HeaderPlain = () => (
  <HeaderBlock>
    <Image src={Logo} alt="Logo" />
  </HeaderBlock>
);

export default HeaderPlain;
