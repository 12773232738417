import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { Loading } from 'common';

// This page contains an immediate redirect.
// It has a loading state as fallback that should only show in a split second.
// Mobile needs this page to redirect to the app with fallback to the official p24 landing page.
// Opening of the app is handled by a hosted JSON by sysops.
class Redirect extends Component {
  componentDidMount() {
    window.location.href = 'https://www.porsche.com/netherlands/nl/motorsportandevents/porsche24';
  }

  render() {
    return <Loading />;
  }
}

export default withRouter(Redirect);
