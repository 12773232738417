import styled from 'styled-components';

import { media } from 'styles/utils';

export const HeaderBlock = styled.header`
  position: relative;
  width: 100%;
  height: 66px;
  max-width: 1200px;
  display: flex;
  align-items: center;

  ${media.tablet`
    height: 73px;
    margin: 0 auto;
  `}

  ${media.large`
    height: 82px;
  `}
`;

export const Image = styled.img`
  display: block;
  width: auto;
  height: clamp(0.63rem, 0.42vw + 0.5rem, 1rem); // copied from porsche.com
  margin: 0 auto;
  padding: 0 12px;
  background-color: ${props => props.theme.white};
`;
