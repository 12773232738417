import styled, { css } from 'styled-components';
import PT from 'prop-types';

import { media } from 'styles/utils';

const Block = styled.div`
  margin: 80px 0;
  position: relative;

  & .block {
    margin: 20px 0;
  }

  ${props => props.noMargin && css`
    margin: 0;
  `}

  ${props => props.withDivider && css`
    padding-top: 27px;

    &:before {
      content: '';
      position: absolute;
      top: -4px;
      left: 20px;
      width: 104px;
      height: 4px;
      background-color: ${props.theme.red};
    }
  `}

  ${props => props.columns && media.tablet && css`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  `}
`;

Block.propTypes = {
  columns: PT.bool,
  noMargin: PT.bool,
  withDivider: PT.bool,
};

export default Block;
