import React, { Component } from 'react';
import PT from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  hasRole,
  COMMUNITY_MANAGER,
  DEALER,
} from 'services/authHelper';

import { logout } from 'ducks/authentication';

import { Button, Title, Toggle } from 'common';
import { Label } from 'common/form';

import BlockMobileAppModal from '../BlockMobileAppModal';
import { UserDetailsBlock, Email, ExtendedButton, ExtendedFlexWrapper } from './styled';

class UserDetails extends Component {
  state = {
    modalOpen: false,
  }

  toggleModal = () => this.setState({ modalOpen: !this.state.modalOpen });

  render() {
    const {
      data, onLogout, history, roles, status,
    } = this.props;

    return (
      <UserDetailsBlock>
        <Title small>{data.full_name}</Title>
        <Email>{data.email}</Email>

        {hasRole(roles, [DEALER]) && (
          <ExtendedButton
            variant="black"
            stretch
            small
            onClick={() => history.push('/event-managers')}
          >
            Beheer event-managers
          </ExtendedButton>
        )}

        {hasRole(roles, [COMMUNITY_MANAGER]) && (
          <ExtendedButton
            variant="black"
            stretch
            small
            onClick={() => history.push('/user/create')}
          >
            Accounts aanmaken
          </ExtendedButton>
        )}

        {hasRole(roles, [COMMUNITY_MANAGER]) && (
          <ExtendedFlexWrapper>
            <Toggle
              onClick={() => this.toggleModal()}
              active={status === 'down'}
            />
            <Label uppercase noWhitespace>Technische problemen inschakelen</Label>
          </ExtendedFlexWrapper>
        )}

        <Button
          sub
          stretch
          small
          onClick={() => onLogout(history)}
        >
          Log uit
        </Button>

        {this.state.modalOpen && (
          <BlockMobileAppModal
            toggleModal={this.toggleModal}
          />
        )}
      </UserDetailsBlock>
    );
  }
}

UserDetails.propTypes = {
  data: PT.object,
  status: PT.string,
  onLogout: PT.func,
  history: PT.object,
  roles: PT.array,
};

export default compose(
  withRouter,
  connect(state => ({
    status: state.p24app.status,
    roles: state.authentication.roles,
  }), { onLogout: logout }),
)(UserDetails);
