import React from 'react';
import PT from 'prop-types';

import { TopicContainer, ExtendedLink } from './styled';

const BrandPortalTopics = ({ topics }) => (
  <TopicContainer>
    {topics.map(topic => (
      <ExtendedLink key={topic.id} to={`/brand-portal/${topic.slug}`}>
        {topic.title}
      </ExtendedLink>
    ))}
  </TopicContainer>
);

BrandPortalTopics.propTypes = {
  topics: PT.array,
};

export default BrandPortalTopics;
