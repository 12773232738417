import React, { Fragment } from 'react';
import PT from 'prop-types';

import { SubLabel } from 'common/form';

import { DropzoneStyled, Thumbnail, ExtendedButton, ThumbnailsContainer, ThumbnailContainer } from './styled';

const InputDropZoneImage = ({
  assets, onUpload, onDelete, multiple,
}) => (
  <Fragment>
    <DropzoneStyled
      onDrop={onUpload}
    >
      {(!assets || multiple) && <SubLabel>Upload afbeelding</SubLabel>}

      {(typeof assets === 'string' && assets && !multiple) && (
        <Fragment>
          <Thumbnail src={assets} alt="Thumbnail" />
          <ExtendedButton onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
          >
            x
          </ExtendedButton>
        </Fragment>
      )}
    </DropzoneStyled>

    {/* if multiple, then assets is an array */}
    {(Array.isArray(assets) && assets.length > 0 && multiple) && (
      <ThumbnailsContainer>
        {assets.map(asset => (
          <ThumbnailContainer key={asset.id} backgroundImage={asset.image}>
            <ExtendedButton onClick={(e) => {
              e.stopPropagation();
              onDelete(asset.id);
            }}
            >
              x
            </ExtendedButton>
          </ThumbnailContainer>
        ))}
      </ThumbnailsContainer>
    )}
  </Fragment>
);

InputDropZoneImage.propTypes = {
  onUpload: PT.func,
  onDelete: PT.func,
  assets: PT.oneOfType([
    PT.string,
    PT.array,
  ]),
  multiple: PT.bool,
};

export default InputDropZoneImage;
