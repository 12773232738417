import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PT from 'prop-types';

import ArrowDown from 'vectors/ArrowDown.svg';
import { updateChannel, getUserChannels } from 'ducks/driver/channel';
import { showNotification } from 'ducks/notification';
import { hasRole, COMMUNITY_MANAGER } from 'services/authHelper';

import { DropdownActions, ActionsList, ActionsListItem, DropdownButton, ActionListItemLabel } from './styled';

class CommunicationPreferencesDropdown extends Component {
  state = {
    isOpen: false,
  }

  options = [
    {
      label: 'Email',
      value: 'email',
    },
    {
      label: 'Post',
      value: 'post',
    },
    {
      label: 'Push notificaties',
      value: 'push',
    },
  ];

  disableChannel = (type) => {
    if (!hasRole(this.props.roles, [COMMUNITY_MANAGER])) {
      this.props.showNotification('U heeft geen toestemming om dit te doen.', 'red');
      this.setState({ isOpen: false });
      return;
    }

    if (window.confirm(`Weet u zeker dat u "${type}" wilt uitschakelen voor deze gebruiker?`)) {
      this.setState({ isOpen: false });

      this.props.updateChannel(this.props.driver.id, type, false)
        // Allow backend to process the request
        .then(() => new Promise(resolve => setTimeout(resolve, 500)))
        .then(() => this.props.getUserChannels(this.props.driver.id));
    }
  }

  render() {
    const { channels } = this.props;

    return (
      <div>
        <DropdownButton
          sub
          small
          square
          onClick={() => {
            this.setState(prevState => ({ isOpen: !prevState.isOpen }));
          }}
          className="ignore-react-onclickoutside"
        >
          Communicatie uitschakelen
          <ArrowDown />
        </DropdownButton>

        {this.state.isOpen && (
          <DropdownActions className="ignore-react-onclickoutside">
            <ActionsList>
              {this.options.map(option => (
                <ActionsListItem
                  key={option.value}
                  onClick={() => this.disableChannel(option.value)}
                >
                  {option.label}
                  <ActionListItemLabel isEnabled={channels[option.value]}>
                    {channels[option.value] ? 'AAN' : 'UIT'}
                  </ActionListItemLabel>
                </ActionsListItem>
              ))}
            </ActionsList>
          </DropdownActions>
        )}
      </div>
    );
  }
}

CommunicationPreferencesDropdown.propTypes = {
  driver: PT.object,
  updateChannel: PT.func,
  showNotification: PT.func,
  getUserChannels: PT.func,
  roles: PT.array,
  channels: PT.object,
};

const mapStateToProps = state => ({
  driver: state.driver.data,
  roles: state.authentication.roles,
  channels: state.channel.data,
});

const mapDispatchToProps = {
  updateChannel,
  showNotification,
  getUserChannels,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(CommunicationPreferencesDropdown);
