import React, { Component } from 'react';
import PT from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import qs from 'qs';

import * as validation from 'services/validations';

import { login, requestOktaLogin } from 'ducks/authentication';
import { showNotification } from 'ducks/notification';

import { Title, Button, Anchor, Container, Small, Card } from 'common';
import { Label, InputText, FormField } from 'common/form';

class Login extends Component {
  componentDidMount() {
    const { error } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

    if (error === 'external') {
      this.props.showNotification('Er is iets mis gegaan bij het inloggen met Okta. Probeer het opnieuw.');
    }
  }

  render() {
    const {
      handleSubmit, onLogin, onRequestOktaLogin, history,
    } = this.props;

    return (
      <Container small alignCenter>
        <Title>Welkom terug</Title>
        <FormField>
          <Button onClick={onRequestOktaLogin} stretch>Log in met okta</Button>
        </FormField>
        {process.env.APP_ENV !== 'production' && (
          <Card>
            <form
              onSubmit={handleSubmit(values => onLogin(values, history))}
              noValidate
            >
              <FormField>
                <Small>This manual login form is only showing on TEST/ACC. PROD only shows OKTA login.</Small>
              </FormField>
              <FormField data-cy="form-field-email">
                <Label uppercase>E-mailadres</Label>
                <Field
                  name="email"
                  component={InputText}
                  type="email"
                  validate={[validation.required, validation.email]}
                  data-cy="input-email"
                />
              </FormField>
              <FormField data-cy="form-field-password">
                <Label uppercase>Wachtwoord</Label>
                <Field
                  name="password"
                  component={InputText}
                  type="password"
                  validate={validation.required}
                  data-cy="input-password"
                />
              </FormField>
              <FormField>
                <Button data-cy="button-login" type="submit" stretch>Log in</Button>
              </FormField>
              <FormField>
                <Anchor
                  small
                  stretch
                  bold
                  onClick={() => history.push('/forgot-password')}
                >
                  Wachtwoord vergeten
                </Anchor>
              </FormField>
            </form>
          </Card>
        )}
      </Container>
    );
  }
}

Login.propTypes = {
  handleSubmit: PT.func.isRequired,
  onRequestOktaLogin: PT.func.isRequired,
  onLogin: PT.func.isRequired,
  history: PT.object,
  location: PT.object.isRequired,
  showNotification: PT.func.isRequired,
};

export default compose(
  connect(
    state => ({ authentication: state.authentication.data }),
    {
      onLogin: login,
      onRequestOktaLogin: requestOktaLogin,
      showNotification,
    },
  ),
  reduxForm({ form: 'login' }),
)(Login);
