import React from 'react';
import Raven from 'raven-js';
import styled from 'styled-components';
import PT from 'prop-types';

import { Button, Title } from 'common';

const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CenterContainer = styled.div`
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
`;

const TryAgain = styled.button`
  padding: 20px;
  font-size: 13px;
  border: none;
  outline: none;
  cursor: pointer;
  color: ${props => props.theme.black};
  margin-right: 20px;
  background-color: ${props => props.theme.white};
`;

/**
 * The SentryBoundary can be used to catch errors using React16's ErrorBoundaries. It displays a generic error message while also
 * giving the user the option to fill in an error report.
 * @type {React.Component}
 */
class SentryBoundary extends React.Component {
  state = {
    error: null,
  };

  /**
   * Make sure the error is send to Sentry and that we pass all the information we can use to solve the possible bug
   * @param  {Error} error The error object that this ErrorBoundary catched
   * @param  {Object} errorInfo Object containing the componentStack; the path through your component tree from your application root all the way to the offending component
   * @return {Void}
   */
  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    if (!__DEV__) Raven.captureException(error, { extra: errorInfo });
  }

  /**
   * Alert the user that something went wrong or show him the actual working content
   * @return {*}
   */
  render() {
    if (this.state.error) {
      return (
        <Container>
          <CenterContainer>
            <Title bold>Sorry! Er is iets fout gegaan!</Title>
            <Title>Ons team is op de hoogte gebracht, maar je kunt een melding maken van het probleem.</Title>
            <TryAgain onClick={() => window.location.reload()}>Probeer het opnieuw</TryAgain>
            <Button onClick={() => Raven.lastEventId() && Raven.showReportDialog()}>Meld probleem</Button>
          </CenterContainer>
        </Container>
      );
    }

    return this.props.children;
  }
}

SentryBoundary.propTypes = {
  children: PT.object,
};

export default SentryBoundary;
