import React, { Component } from 'react';
import PT from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, initialize } from 'redux-form';
import { withRouter } from 'react-router-dom';

import { loadEvent } from 'ducks/editEvent';
import { saveTicket } from 'ducks/editTicket';

import { Button } from 'common';
import { Form, Label, FormField, InputText } from 'common/form';

import * as validation from 'services/validations';

import FormActions from './styled';

class TicketAmountForm extends Component {
  componentWillMount() {
    const { data } = this.props;

    this.props.dispatch(initialize('edit-ticket-amount-form', {
      ticket_type_id: data.ticket_type_id ? data.ticket_type_id : '',
      name: data.name ? data.name : '',
      price: data.price_incl ? data.price_incl.toString() : '',
      vat: data.vat ? data.vat : 21,
      quantity: data.quantity ? data.quantity : '',
      limit_per_person: data.limit_per_person ? data.limit_per_person : 2,
      description: data.description ? data.description : '',
      level_id: data.level ? data.level.map(level => level.id) : [],
      points_id: data.points ? data.points.id : '',
      signature: data.signature ? data.signature : false,
      custom_fields: data.custom_fields && data.custom_fields.length > 0
        ? data.custom_fields
        : [{}],
      checklist: data.checklist && data.checklist.filter(item => !item.special).length > 0
        ? data.checklist.filter(item => !item.special)[0]
        : {
          special: false,
          fields: [{}],
        },
      special_requirements: data.checklist && data.checklist.filter(item => item.special).length > 0
        ? data.checklist.filter(item => item.special)[0]
        : {
          special: true,
          fields: [{}],
        },
      public: data.public ? data.public : false,
      external_url: data.external_url ? data.external_url : '',
    }));
  }

  render() {
    const {
      data, handleSubmit, onToggleEdit, onSaveTicket, onLoadEvent, match,
    } = this.props;

    return (
      <Form onSubmit={
        handleSubmit(values => onSaveTicket(values, data.id).then(() => {
          onLoadEvent(match.params.id);
          onToggleEdit();
        }))}
      >
        <FormField>
          <Label>Aantal</Label>
          <Field
            name="quantity"
            component={InputText}
            type="number"
            min="0"
            validate={validation.required}
          />
        </FormField>

        <FormActions>
          <Button small variant="black" type="submit">Opslaan</Button>
          <Button small sub onClick={onToggleEdit}>Annuleren</Button>
        </FormActions>
      </Form>
    );
  }
}

TicketAmountForm.propTypes = {
  data: PT.object,
  handleSubmit: PT.func,
  onSaveTicket: PT.func,
  onLoadEvent: PT.func,
  onToggleEdit: PT.func,
  dispatch: PT.func,
  match: PT.object,
};

export default compose(
  withRouter,
  connect(null, { onSaveTicket: saveTicket, onLoadEvent: loadEvent }),
  reduxForm({ form: 'edit-ticket-amount-form' }),
)(TicketAmountForm);
