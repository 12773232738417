import React from 'react';
import PT from 'prop-types';

import BrandPortalSubNav from '../BrandPortalSubNav';
import { Sidebar, ExtendedLink } from './styled';

const BrandPortalSidebar = ({ selectedTopic }) => (
  <Sidebar>
    {selectedTopic && selectedTopic.top_pages.map(page => (
      page.sub_pages.length > 0 ? (
        <BrandPortalSubNav
          key={page.id}
          page={page}
          selectedTopic={selectedTopic}
        />
      ) : (
        <ExtendedLink
          key={page.id}
          to={`/brand-portal/${selectedTopic.slug}/${page.slug}`}
        >
          {page.title}
        </ExtendedLink>
      )
    ))}
  </Sidebar>
);

BrandPortalSidebar.propTypes = {
  selectedTopic: PT.object,
};

export default BrandPortalSidebar;
