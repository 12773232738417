import React from 'react';
import PT from 'prop-types';

import { SliderContainer, SlideArrow } from './styled';

const SliderBlock = ({ children, onChange }) => (
  <SliderContainer
    nextArrow={(<SlideArrow />)}
    prevArrow={(<SlideArrow direction="left" />)}
    afterChange={onChange}
  >
    {children}
  </SliderContainer>
);

SliderBlock.propTypes = {
  children: PT.oneOfType([PT.object, PT.array]),
  onChange: PT.func,
};

export default SliderBlock;
