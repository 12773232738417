import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PT from 'prop-types';

import { postValidateCarMessage, getValidateCars } from 'ducks/validateCars';
import { hideModal } from 'ducks/modal';
import { Button } from 'common';
import { InputTextarea, Label, SubLabel } from 'common/form';

import { ButtonContainer } from './styled';

class ValidateCarsMessageEditor extends Component {
  state = {
    message: '',
    count: 0,
  };

  onSend = () => {
    this.props.postValidateCarMessage(this.props.carId, this.state.message)
      .then(() => {
        this.props.hideModal();
        this.props.getValidateCars();
      });
  };

  countCharacters = (e) => {
    const message = e.target.value;
    const count = message.length;

    this.setState({
      // stop typing when user reaches character limit
      message: count < 350 ? message : this.state.message,
      count: message.length,
    });
  };

  render() {
    const { onCancel } = this.props;

    return (
      <Fragment>
        <Label noWhitespace>Opmerking</Label>
        <InputTextarea
          height="125px"
          onChange={this.countCharacters}
          value={this.state.message}
        />
        <SubLabel noWhitespace>{this.state.count}/350</SubLabel>
        <ButtonContainer>
          <Button sub small square stretch onClick={onCancel}>Annuleren</Button>
          <Button small square stretch onClick={this.onSend} disabled={this.state.count === 0}>Versturen</Button>
        </ButtonContainer>
      </Fragment>
    );
  }
}

ValidateCarsMessageEditor.propTypes = {
  onCancel: PT.func.isRequired,
  postValidateCarMessage: PT.func.isRequired,
  carId: PT.string.isRequired,
  hideModal: PT.func.isRequired,
  getValidateCars: PT.func.isRequired,
};

export default connect(null, { postValidateCarMessage, hideModal, getValidateCars })(ValidateCarsMessageEditor);
