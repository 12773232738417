import styled, { css } from 'styled-components';
import PT from 'prop-types';

const LabelTag = styled.span`
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 2px;
  padding: 3px 6px;
  font-weight: bold;
  vertical-align: middle;
  display: inline-block;
  margin-left: 10px;
  color: ${props => props.theme.white};
  background-color: ${props => props.theme.border};

  ${props => props.variant === 'warning' && css`
    background-color: ${props.theme.orange};
  `}

  ${props => props.variant === 'danger' && css`
    background-color: ${props.theme.red};
  `}

  ${props => props.variant === 'black' && css`
    background-color: ${props.theme.black};
  `}
`;

LabelTag.propTypes = {
  variant: PT.oneOf(['warning', 'danger', 'black']),
};

export default LabelTag;
