import React from 'react';
import PT from 'prop-types';
import { reduxForm, Form, Field } from 'redux-form';

import { Button, Title, Subtitle } from 'common';
import { Label, InputText } from 'common/form';

import { InputContainer, InputSection } from './styled';

const RejectMileageForm = ({ handleSubmit, onSubmit, onCancel }) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Title small>Weet u zeker dat u deze claim wilt weigeren?</Title>
    <Subtitle small>Deze actie kan niet ongedaan gemaakt worden en de bestuurder moet zijn kilometers opnieuw invoeren om punten te verdienen.</Subtitle>
    <InputSection>
      <Label>Reason</Label>
      <Field
        name="reason"
        type="text"
        component={InputText}
      />
    </InputSection>
    <InputContainer>
      <Button
        type="submit"
        variant="black"
        small
        stretch
      >
        Ja, weiger claim
      </Button>
      <Button
        onClick={onCancel}
        small
        stretch
        sub
      >
        Niet weigeren
      </Button>
    </InputContainer>
  </Form>
);

RejectMileageForm.propTypes = {
  handleSubmit: PT.func.isRequired,
  onSubmit: PT.func.isRequired,
  onCancel: PT.func.isRequired,
};

export default reduxForm({ form: 'reject-mileage' })(RejectMileageForm);
