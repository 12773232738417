import React, { Component, Fragment } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import IframeResizer from 'iframe-resizer-react';

import { showNotification } from 'ducks/notification';
import { sendEventEmail, getTemplateEventEmail, resetEventEmail } from 'ducks/eventEmail';

import { Container, Title, Button, Subtitle, Card, LabelTag, Loading } from 'common';

import { ButtonContainer } from './styled';

class EventDetailEmailPreview extends Component {
  componentDidMount() {
    this.props.getTemplateEventEmail(this.props.match.params.emailId);
  }

  componentWillUnmount() {
    this.props.resetEventEmail();
  }

  handleSend = () => {
    // eslint-disable-next-line
    const warning = confirm('Weet je zeker dat je de email wilt versturen?');

    if (!warning) return false;

    this.props.sendEventEmail(this.props.match.params.emailId)
      .then(() => {
        this.props.showNotification('Het versturen van de email is gelukt', 'green');
        this.props.history.push(`/event/${this.props.match.params.eventId}/emails`);
      })
      .catch(() => this.props.showNotification('Het versturen van de email is niet gelukt. Probeer nog eens.', 'red'));
  }

  render() {
    const { history, match, preview, loading } = this.props;

    if (loading) return <Loading small />;

    return (
      <Fragment>
        <Container>
          <ButtonContainer>
            <Button
              variant="black"
              small
              onClick={() => history.push(`/event/${match.params.eventId}/emails`)}
            >
              Terug naar overzicht
            </Button>
            {preview.status !== 'published' && (
              <Button
                small
                onClick={this.handleSend}
              >
                Verstuur email
              </Button>
            )}
          </ButtonContainer>

          <Title underline>
            Subject: {preview.subject}
            {preview.status === 'draft' && <LabelTag>Draft</LabelTag>}
            {preview.status === 'published' && <LabelTag variant="danger">Verzonden</LabelTag>}
            {preview.status === 'error' && <LabelTag variant="warning">Error</LabelTag>}
          </Title>
          <Subtitle>To: {preview.to}</Subtitle>

          <Card noPadding noMargin>
            <IframeResizer
              srcDoc={`<html><script type='text/javascript' src='https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/3.5.3/iframeResizer.contentWindow.js'></script></html>${preview.html}`}
              heightCalculationMethod="bodyScroll"
              width="100%"
              checkOrigin={false}
              frameBorder="0"
            />
          </Card>

        </Container>
        {preview.status !== 'published' && (
          <Container textCenter>
            <Subtitle>To: {preview.to}</Subtitle>
            <Button onClick={this.handleSend}>Verstuur email</Button>
          </Container>
        )}
      </Fragment>
    );
  }
}

EventDetailEmailPreview.propTypes = {
  history: PT.object,
  match: PT.object,
  sendEventEmail: PT.func,
  resetEventEmail: PT.func,
  getTemplateEventEmail: PT.func,
  preview: PT.object,
  loading: PT.bool,
  showNotification: PT.func,
};

export default compose(
  connect(state => ({
    preview: state.eventEmail.preview,
    loading: state.eventEmail.loading.getPreview,
  }), {
    sendEventEmail, getTemplateEventEmail, resetEventEmail, showNotification,
  }),
  withRouter,
)(EventDetailEmailPreview);
