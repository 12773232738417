import styled from 'styled-components';
import Select from 'react-select';

export default styled(Select)`
  .react-select__control {
    background-color: ${props => props.theme.white};
    border-radius: 0;
    border: 1px solid ${props => props.theme.border};
  }

  .react-select__value-container {
    outline: none;
    min-width: 80px;
  }

  .react-select__single-value {
    font-size: 16px;
    color: ${props => props.theme.black};
  }

  .react-select__single-value--is-disabled {
    color: ${props => props.theme.gray}
  }
`;
