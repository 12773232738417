import createAction from 'services/createAction';

const GET_CHANGELOG = 'changelog/GET_CHANGELOG';
const GET_CHANGELOG_SUCCESS = 'changelog/GET_CHANGELOG_SUCCESS';
const GET_CHANGELOG_FAILED = 'changelog/GET_CHANGELOG_FAILED';

const initialState = {
  loading: false,
  error: false,
  data: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case GET_CHANGELOG:
    return {
      ...state,
      loading: true,
    };

  case GET_CHANGELOG_SUCCESS:
    return {
      ...state,
      loading: false,
      data: payload,
    };

  case GET_CHANGELOG_FAILED:
    return {
      ...state,
      loading: false,
      data: [],
    };

  default:
    return state;
  }
};

const getChangelogSuccess = createAction(GET_CHANGELOG_SUCCESS);
const getChangelogFailed = createAction(GET_CHANGELOG_SUCCESS);

export const getChangelog = () => (dispatch, getState, api) => {
  dispatch({ type: GET_CHANGELOG });

  api.get({ path: 'changelog/', brandPortal: true })
    .then((res) => {
      dispatch(getChangelogSuccess(res.changes));
    })
    .catch((err) => {
      dispatch(getChangelogFailed({ message: err.data }));
    });
};
