import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import PT from 'prop-types';

import { getDriverCEMPackages } from 'ducks/driver/driverCEMPackages';
import { Button, Container, Title, Loading, LabelTag } from 'common';
import { CEMPackageStages } from 'modules/CEM/components';

import { CEMPackageDetailContainer } from './styled';

class DriverTabsCEMPackageDetail extends Component {
  componentWillMount() {
    if (!this.props.CEMPackage) {
      // use data in list call to display detail page
      this.props.getDriverCEMPackages(this.props.driver.id);
    }
  }

  formatStages = (stages) => {
    const currentStageIndex = stages.findIndex(stage => stage.id === this.props.CEMPackage.stage_id);

    return stages.map((stage, index) => ({
      ...stage,
      active: index <= currentStageIndex,
    }));
  }

  render() {
    const { CEMPackage, loading, history, driver } = this.props;

    if (!CEMPackage || loading) return <Container><Loading /></Container>;

    return (
      <Container>
        <Button
          variant="black"
          small
          onClick={() => history.push(`/driver/${driver.id}/cem`)}
        >
          Terug naar overzicht
        </Button>
        <Title underline>Waypoints {CEMPackage.completed_on && <LabelTag>Voltooid</LabelTag>}</Title>
        <CEMPackageDetailContainer>
          <CEMPackageStages stages={this.formatStages(CEMPackage.package.stages)} />
        </CEMPackageDetailContainer>
      </Container>
    );
  }
}

DriverTabsCEMPackageDetail.propTypes = {
  CEMPackage: PT.shape({
    completed_on: PT.string,
    id: PT.string.isRequired,
    package: PT.shape({
      id: PT.string,
      name: PT.string.isRequired,
      description: PT.string.isRequired,
      description_short: PT.string.isRequired,
      description_start: PT.string.isRequired,
      image: PT.string,
      contact_company: PT.string.isRequired,
      contact_email: PT.string.isRequired,
      contact_name: PT.string.isRequired,
      contact_phone: PT.string.isRequired,
      stages: PT.arrayOf(PT.shape({
        id: PT.string.isRequired,
        name: PT.string.isRequired,
        ordinal: PT.number.isRequired,
        description: PT.string,
      })),
    }),
    stage_id: PT.string.isRequired,
    user_id: PT.string,
  }),
  loading: PT.bool,
  history: PT.object.isRequired,
  driver: PT.shape({
    id: PT.string.isRequired,
  }),
  getDriverCEMPackages: PT.func.isRequired,
};

export default compose(
  withRouter,
  connect((state, props) => ({
    driver: state.driver.data,
    // TODO: add reselect or detail call
    CEMPackage: state.driverCEMPackages.data.find(CEMPackage => CEMPackage.id === props.match.params.packageId),
    loading: state.driverCEMPackages.loading.get,
  }), { getDriverCEMPackages }),
)(DriverTabsCEMPackageDetail);
