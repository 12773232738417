import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import { reducer as formReducer } from 'redux-form';
import thunk from 'redux-thunk';

import * as appReducers from 'app/ducks';
import * as api from 'services/api';

let middleware = applyMiddleware(thunk.withExtraArgument(api));
const reducers = combineReducers({ ...appReducers, form: formReducer });
const initialState = __CLIENT__ ? window.__PRELOADED_STATE__ : {};

if (__CLIENT__ && typeof window.__REDUX_DEVTOOLS_EXTENSION__ === 'function') {
  middleware = compose(middleware, window.__REDUX_DEVTOOLS_EXTENSION__());
}

export default () => createStore(reducers, initialState, middleware);
