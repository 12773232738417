import React from 'react';
import PT from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  hasRole,
  EVENT_MANAGER,
  PARTNER,
} from 'services/authHelper';

import { createEvent } from 'ducks/editEvent';
import { Anchor, Button } from 'common';

import { HeaderContainer, AllEventsToggleContainer, LabelHolder, IconContainer } from './styled';

const EventsHeader = ({
  toggleShowAllEvents, showAllEvents, history, roles, onCreateEvent,
}) => (
  <HeaderContainer>
    <AllEventsToggleContainer>
      <Anchor
        active={!showAllEvents}
        onClick={() => toggleShowAllEvents(false)}
        data-cy="button-events-my"
      >
        Mijn events
      </Anchor>
      {!hasRole(roles, [PARTNER, EVENT_MANAGER]) && (
        <Anchor
          active={showAllEvents}
          onClick={() => toggleShowAllEvents(true)}
          data-cy="button-events-all"
        >
          Alle events
        </Anchor>
      )}
    </AllEventsToggleContainer>
    {!hasRole(roles, [EVENT_MANAGER]) && (
      <Button
        large
        onClick={() => onCreateEvent(history)}
        data-cy="button-add-event"
      >
        <LabelHolder>Event aanmaken</LabelHolder> <IconContainer>+</IconContainer>
      </Button>
    )}
  </HeaderContainer>
);

EventsHeader.propTypes = {
  toggleShowAllEvents: PT.func,
  showAllEvents: PT.bool,
  history: PT.object,
  onCreateEvent: PT.func,
  roles: PT.array,
};

export default compose(
  withRouter,
  connect(null, { onCreateEvent: createEvent }),
)(EventsHeader);
