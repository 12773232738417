import createAction from 'services/createAction';

const GET_DRIVERS = 'drivers/GET';
const GET_DRIVERS_SUCCESS = 'drivers/GET_SUCCESS';
const GET_DRIVERS_FAILED = 'drivers/GET_FAILED';

const RESET_DRIVERS = 'drivers/RESET';

const initialState = {
  data: [],
  current_page: 0,
  limit: 10,
  next_page: 0,
  pages: 0,
  previous_page: 0,
  results: 0,
  stats: {},
  error: {
    message: '',
  },
  loading: {
    drivers: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case GET_DRIVERS_SUCCESS:
    return {
      ...state,
      ...payload,
      loading: { ...state.loading, drivers: false },
    };
  case GET_DRIVERS_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, drivers: false },
    };
  case GET_DRIVERS:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, drivers: true },
    };
  case RESET_DRIVERS:
    return {
      ...initialState,
    };
  default:
    return state;
  }
};

const getDriversSuccess = createAction(GET_DRIVERS_SUCCESS);
const getDriversFailed = createAction(GET_DRIVERS_FAILED);

export const getDrivers = (query = null) => (dispatch, getState, api) => {
  dispatch({ type: GET_DRIVERS });

  api.get({ path: 'management/user', query })
    .then((res) => {
      dispatch(getDriversSuccess(res));
    })
    .catch((err) => {
      dispatch(getDriversFailed({ message: err.data }));
    });
};

export const resetDrivers = createAction(RESET_DRIVERS);
