import styled from 'styled-components';

export const ModalContainer = styled.div`
  margin: 48px 36px;
`;

export const ScrollContainer = styled.div`
  max-height: 50vh;
  overflow-y: scroll;

  ul {
    padding-bottom: 40px;
  }
`;

export const OverlayContainer = styled.div`
  position: relative;
`;

export const Fade = styled.div`
  height: 80px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(-180deg,rgba(255,255,255,0) 0%,rgba(256,255,255,1) 100%);
`;
