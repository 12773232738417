import React from 'react';
import PT from 'prop-types';
import moment from 'moment';

import Camera from 'vectors/Camera.svg';
import { Card, Container, Button, Subtitle, Info, LabelTag } from 'common';

import { ButtonContainer, Content, ExtendedTitle, Header, CameraIconContainer } from './styled';

const ValidationCard = ({ data, showCarsModal }) => (
  <Container>
    <Card>
      <Header>
        <div>
          <ExtendedTitle>
            {data.car.model}
            {data.state === 'pending' && (
              <LabelTag>
                <CameraIconContainer><Camera /></CameraIconContainer>
                In afwachting
              </LabelTag>
            )}
            {data.state === 'proof' && (
              <LabelTag variant="black">
                <CameraIconContainer><Camera /></CameraIconContainer>
                Heeft foto
              </LabelTag>
            )}
          </ExtendedTitle>
          <Subtitle small>{data.user.full_name}</Subtitle>
        </div>
        <ButtonContainer>
          <Button small onClick={() => showCarsModal(data)}>Valideer auto</Button>
        </ButtonContainer>
      </Header>
      <Content>
        <Info label="Jaar">
          {moment(data.car.model_year).format('YYYY')}
        </Info>
        {data.dealer && (
          <Info label="Voorkeursdealer">
            {data.dealer.name}
          </Info>
        )}
        {data.car.licence_plate && (
          <Info label="Kenteken">
            {data.car.licence_plate}
          </Info>
        )}
        {data.car.vin && (
          <Info label="VIN">
            {data.car.vin}
          </Info>
        )}
        {data.car.current_mileage && (
          <Info label="Kilometerstand">
            {data.car.current_mileage.mileage}
          </Info>
        )}
      </Content>
    </Card>
  </Container>
);

ValidationCard.propTypes = {
  data: PT.object,
  showCarsModal: PT.func,
};

export default ValidationCard;
