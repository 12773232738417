import React, { Component, Fragment } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';

import {
  getUnclaimedRewards,
  claimReward,
  getClaimedRewards,
  unclaimReward,
  getPreviousRewards,
  assignReward,
} from 'ducks/driver/rewards';
import { showNotification } from 'ducks/notification';

import { Container, Toggle, Button, Modal, EmptyState, Wrapper, RewardCard } from 'common';
import DriverTabsRewardsAssign from '../DriverTabsRewardsAssign';

import { ToggleLabel, ModalContainer, ExtendedTitle, ExtendedContainer, HeaderContainer } from './styled';

class DriverTabsRewards extends Component {
  state = {
    showClaimedRewards: false,
    showPreviousRewards: false,
    confirmationModal: {
      open: false,
      content: '',
    },
    showAssignRewardModal: false,
  }

  componentWillMount() {
    if (this.props.driverFromRewards !== this.props.driver.id) {
      this.props.getUnclaimedRewards(this.props.driver.id);
    }
  }

  onToggle = () => {
    this.setState({
      showClaimedRewards: !this.state.showClaimedRewards,
    }, () => {
      if (this.state.showClaimedRewards && !this.props.loaded.claimed) {
        this.props.getClaimedRewards(this.props.driver.id);
      }
    });
  }

  togglePreviousRewards = () => {
    this.setState({
      showPreviousRewards: !this.state.showPreviousRewards,
    }, () => {
      if (this.state.showPreviousRewards && !this.props.loaded.previous) {
        this.props.getPreviousRewards(this.props.driver.id);
      }
    });
  }

  closeConfirmationModal = () => {
    this.setState({
      confirmationModal: {
        open: false,
        content: '',
      },
    });
  }

  claimReward = (reward) => {
    this.props.claimReward(this.props.driver.id, reward.id).then(() => {
      this.props.getUnclaimedRewards(this.props.driver.id);
      this.props.getClaimedRewards(this.props.driver.id);

      this.setState({
        confirmationModal: {
          open: true,
          content: `${reward.name} is succesvol geclaimed`,
        },
      });
    });
  }

  unclaimReward = (reward) => {
    this.props.unclaimReward(this.props.driver.id, reward.id).then(() => {
      this.props.getUnclaimedRewards(this.props.driver.id);
      this.props.getClaimedRewards(this.props.driver.id);

      this.setState({
        confirmationModal: {
          open: true,
          content: `${reward.name} is niet meer geclaimed`,
        },
      });
    });
  }

  toggleAssignModal = () => {
    this.setState({ showAssignRewardModal: !this.state.showAssignRewardModal });
  }

  assignReward = (rewardId, selectedDealer) => {
    this.props.assignReward(this.props.driver.id, rewardId, selectedDealer)
      .then(() => {
        this.props.getUnclaimedRewards(this.props.driver.id);
        this.props.getClaimedRewards(this.props.driver.id);

        this.setState({
          showAssignRewardModal: false,
          confirmationModal: {
            open: true,
            content: 'De reward is succesvol verstuurd',
          },
        });
      })
      .catch(() => {
        this.props.showNotification('Het versturen van de reward is niet gelukt. Probeer het nog eens.');
      });
  }

  render() {
    const { unclaimedRewards, claimedRewards, previousRewards } = this.props;

    const rewards = !this.state.showClaimedRewards ? unclaimedRewards : claimedRewards;

    return (
      <Fragment>
        <Container>
          <HeaderContainer>
            <Toggle
              active={this.state.showClaimedRewards}
              onClick={this.onToggle}
            >
              <ToggleLabel>Toon claimed rewards</ToggleLabel>
            </Toggle>

            <Button
              small
              sub
              onClick={this.toggleAssignModal}
              data-cy="rewards-assign-btn"
            >
              Geef reward
            </Button>
          </HeaderContainer>

          {rewards.length !== 0 ? rewards.map((reward, index) => (
            <RewardCard
              key={`${reward.id}_${reward.expire_date}_${index}`}
              reward={reward}
              claimReward={this.claimReward}
              unclaimReward={this.unclaimReward}
              claim={this.state.showClaimedRewards}
            />
          )) : (
            <EmptyState
              subtitle={`Er zijn geen ${!this.state.showClaimedRewards ? 'unclaimed' : 'claimed'} rewards om te tonen.`}
            />
          )}
        </Container>

        <Wrapper>
          {this.state.showPreviousRewards && (
            <Container>
              {this.props.previousRewards.length !== 0 ? this.props.previousRewards.map((reward, index) => (
                <RewardCard
                  key={`${reward.id}_${reward.expire_date}_${index}`}
                  reward={reward}
                  claimReward={this.claimReward}
                  unclaimReward={this.unclaimReward}
                  claim={this.state.showClaimedRewards}
                  previous
                />
              )) : (
                <EmptyState
                  subtitle="Er zijn geen vorige rewards om te tonen."
                />
              )}
            </Container>
          )}

          <ExtendedContainer>
            <Button
              onClick={this.togglePreviousRewards}
              sub
              light
            >
              {!this.state.showPreviousRewards ? 'Toon' : 'Verberg'} vorige rewards
            </Button>
          </ExtendedContainer>
        </Wrapper>

        {this.state.confirmationModal.open && (
          <Modal onHideModal={this.closeConfirmationModal}>
            <ModalContainer>
              <ExtendedTitle small>{this.state.confirmationModal.content}</ExtendedTitle>
              <Button small onClick={this.closeConfirmationModal}>Ok</Button>
            </ModalContainer>
          </Modal>
        )}

        {this.state.showAssignRewardModal && (
          <Modal onHideModal={this.toggleAssignModal}>
            <DriverTabsRewardsAssign
              assignReward={this.assignReward}
            />
          </Modal>
        )}
      </Fragment>
    );
  }
}

DriverTabsRewards.propTypes = {
  driverFromRewards: PT.string,
  driver: PT.object,
  getUnclaimedRewards: PT.func,
  unclaimedRewards: PT.array,
  claimReward: PT.func,
  loaded: PT.object,
  getClaimedRewards: PT.func,
  unclaimReward: PT.func,
  claimedRewards: PT.array,
  getPreviousRewards: PT.func,
  assignReward: PT.func,
  showNotification: PT.func,
  previousRewards: PT.array,
};

export default connect(state => ({
  unclaimedRewards: state.driverRewards.data.unclaimed,
  claimedRewards: state.driverRewards.data.claimed,
  previousRewards: state.driverRewards.data.previous,
  driver: state.driver.data,
  driverFromRewards: state.driverRewards.driverId,
  loaded: state.driverRewards.loaded,
}), {
  getUnclaimedRewards,
  claimReward,
  getClaimedRewards,
  unclaimReward,
  getPreviousRewards,
  assignReward,
  showNotification,
})(DriverTabsRewards);
