import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PT from 'prop-types';

import { getMileageHistoryFromDriver } from 'ducks/driver/getMileageHistory';

import { Container, Info, EmptyState, Loading } from 'common';

import { ExtendedCard, ExtendedFlexWrapper, ImageWrapper } from './styled';

class DriverTabsMileageHistory extends Component {
  componentWillMount() {
    if (this.props.driverFromMileage !== this.props.driver.id) {
      this.props.getMileageHistoryFromDriver(this.props.driver.id);
    }
  }

  render() {
    const { mileageHistory, loading } = this.props;

    if (loading) return <Container><Loading /></Container>;

    return (
      <Container>
        {mileageHistory.length === 0 ? (
          <EmptyState
            title="Nog geen mileage history"
            subtitle="Gebruiker heeft tot op heden nog geen kilometers gevalideerd."
          />
        ) : mileageHistory.map(mileageCard => (
          <ExtendedCard key={mileageCard.id} noPadding>
            <ExtendedFlexWrapper>
              <Info label="Kilometerstand">
                {mileageCard.mileage}
              </Info>
              <Info label="Punten">
                {mileageCard.points}
              </Info>
              <Info label="Invoerdatum">
                {moment(mileageCard.created).format('LLL')}
              </Info>
              <Info label="Auto">
                {mileageCard.licence_plate ? mileageCard.licence_plate : mileageCard.vin}
              </Info>
              <Info label="Bevestigingsdatum">
                {moment(mileageCard.confirmed_date).format('LLL')}
              </Info>
            </ExtendedFlexWrapper>
            <ImageWrapper backgroundImage={mileageCard.verification_image} />
          </ExtendedCard>
        ))}
      </Container>
    );
  }
}

DriverTabsMileageHistory.propTypes = {
  driver: PT.object,
  driverFromMileage: PT.string,
  getMileageHistoryFromDriver: PT.func,
  mileageHistory: PT.array,
  loading: PT.bool,
};

export default connect(state => ({
  driver: state.driver.data,
  mileageHistory: state.getMileageHistory.data,
  loading: state.getMileageHistory.loading.mileageHistory,
  driverFromMileage: state.getMileageHistory.driverId,
}), { getMileageHistoryFromDriver })(DriverTabsMileageHistory);
