import React from 'react';
import PT from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { downloadFile } from 'ducks/download';

import Block from '../Block';

import { Column, Title, Text, Button, Icon } from './styled';

const DownloadBlock = ({ data: { value }, download }) => (
  <Block columns>
    <Column>
      {!!value.title.length && <Title>{value.title}</Title>}
      {!!value.body.length && <Text html={value.body} />}
    </Column>
    <Column>
      {value.links && value.links.length && value.links.map(link => (
        <Button key={link.id} onClick={() => download(link.value.file)} >
          {link.value.button_label}
          <Icon />
        </Button>
      ))}
    </Column>
  </Block>
);

DownloadBlock.propTypes = {
  data: PT.object,
  download: PT.func,
};

export default compose(connect(state => ({
  file: state.download.data,
}), { download: downloadFile }))(DownloadBlock);
