import styled, { css } from 'styled-components';
import PT from 'prop-types';

const Title = styled.h1`
  font-size: 40px;
  font-weight: 400;
  color: ${props => props.theme[props.variant]};

  ${props => props.small && css`
    font-size: 24px;
  `}

  ${props => props.bold && css`
    font-weight: 700;
  `}

  ${props => props.underline && css`
    position: relative;
    padding-bottom: 20px;

    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      border-bottom: 4px solid ${props.theme.red};
      left: 0;
      width: 54px;
    }
  `}

  ${props => props.noWhitespace && css`
    margin: 0;
  `}

  ${props => props.center && css`
    text-align: center;
  `}
`;

Title.defaultProps = {
  variant: 'black',
};

Title.propTypes = {
  small: PT.bool,
  underline: PT.bool,
  bold: PT.bool,
  noWhitespace: PT.bool,
  center: PT.bool,
};

export default Title;
