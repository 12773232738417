import React, { Fragment } from 'react';
import PT from 'prop-types';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';

import { Title, Anchor, Container } from 'common';
import { CEMPackageOverview, CEMPackageDetail } from './components';

const CEM = ({ match, history }) => (
  <Fragment>
    <Container>
      <Title>Excitement platform</Title>
      <Anchor active onClick={() => history.push('/cem/packages')}>CEM pakketten</Anchor>
    </Container>
    <Switch>
      <Redirect exact from={`${match.url}`} to={`${match.url}/packages`} />
      <Route path="/cem/packages/:id" component={CEMPackageDetail} />
      <Route path="/cem/packages" component={CEMPackageOverview} />
    </Switch>
  </Fragment>
);

CEM.propTypes = {
  match: PT.object.isRequired,
  history: PT.object.isRequired,
};

export default withRouter(CEM);
