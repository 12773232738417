import createAction from 'services/createAction';
import { PUBLISHED } from 'services/eventHelper';

const POST_UPDATE_IMAGE = 'update/POST_IMAGE';
const POST_UPDATE_IMAGE_SUCCESS = 'update/POST_IMAGE_SUCCESS';
const POST_UPDATE_IMAGE_FAILED = 'update/POST_IMAGE_FAILED';

const POST_UPDATE = 'update/POST';
const POST_UPDATE_SUCCESS = 'update/POST_SUCCESS';
const POST_UPDATE_FAILED = 'update/POST_FAILED';

const DELETE_UPDATE_IMAGE = 'update/DELETE_IMAGE';
const DELETE_UPDATE_IMAGE_SUCCESS = 'update/DELETE_IMAGE_SUCCESS';
const DELETE_UPDATE_IMAGE_FAILED = 'update/DELETE_IMAGE_FAILED';

const PUBLISH_UPDATE = 'update/PUBLISH';
const PUBLISH_UPDATE_SUCCESS = 'update/PUBLISH_SUCCESS';
const PUBLISH_UPDATE_FAILED = 'update/PUBLISH_FAILED';

const SAVE_UPDATE = 'update/SAVE';
const SAVE_UPDATE_SUCCESS = 'update/SAVE_SUCCESS';
const SAVE_UPDATE_FAILED = 'update/SAVE_FAILED';

const DELETE_UPDATE = 'update/DELETE';
const DELETE_UPDATE_SUCCESS = 'update/DELETE_SUCCESS';
const DELETE_UPDATE_FAILED = 'update/DELETE_FAILED';

const SET_UPDATE_EDITOR = 'update/SET';

const initialState = {
  editingUpdateId: '',
  uploadedImages: [],
  error: {
    message: '',
  },
  loading: {
    postImage: false,
    postUpdate: false,
    deleteImage: false,
    publish: false,
    save: false,
    delete: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case POST_UPDATE_IMAGE_SUCCESS:
    return {
      ...state,
      uploadedImages: [payload, ...state.uploadedImages],
      loading: { ...state.loading, postImage: false },
    };
  case POST_UPDATE_IMAGE_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, postImage: false },
    };
  case POST_UPDATE_IMAGE:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, postImage: true },
    };
  case POST_UPDATE_SUCCESS:
    return {
      ...state,
      editingUpdateId: payload,
      loading: { ...state.loading, postUpdate: false },
    };
  case POST_UPDATE_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, postUpdate: false },
    };
  case POST_UPDATE:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, postUpdate: true },
    };
  case DELETE_UPDATE_IMAGE_SUCCESS:
    return {
      ...state,
      uploadedImages: state.uploadedImages.filter(image => image.id !== payload),
      loading: { ...state.loading, deleteImage: false },
    };
  case DELETE_UPDATE_IMAGE_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, deleteImage: false },
    };
  case DELETE_UPDATE_IMAGE:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, deleteImage: true },
    };
  case PUBLISH_UPDATE_SUCCESS:
    return {
      ...state,
      editingUpdateId: '',
      uploadedImages: [],
      loading: { ...state.loading, publish: false },
    };
  case PUBLISH_UPDATE_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, publish: false },
    };
  case PUBLISH_UPDATE:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, publish: true },
    };
  case SAVE_UPDATE_SUCCESS:
    return {
      ...state,
      editingUpdateId: '',
      uploadedImages: [],
      loading: { ...state.loading, save: false },
    };
  case SAVE_UPDATE_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, save: false },
    };
  case SAVE_UPDATE:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, save: true },
    };
  case DELETE_UPDATE_SUCCESS:
    return {
      ...state,
      loading: { ...state.loading, delete: false },
    };
  case DELETE_UPDATE_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, delete: false },
    };
  case DELETE_UPDATE:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, delete: true },
    };
  case SET_UPDATE_EDITOR:
    return {
      ...state,
      editingUpdateId: payload.id,
      uploadedImages: payload.image || [],
    };
  default:
    return state;
  }
};

const postUpdateSuccess = createAction(POST_UPDATE_SUCCESS);
const postUpdateFailed = createAction(POST_UPDATE_FAILED);

export const postUpdate = (eventId, formData = { text: '' }) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: POST_UPDATE });

    api.post({ path: `management/activity/${eventId}/activity-update`, body: formData })
      .then((res) => {
        dispatch(postUpdateSuccess(res.activity_update.id));
        resolve();
      })
      .catch((err) => {
        dispatch(postUpdateFailed({ message: err.data }));
        reject();
      });
  })
);

const postUpdateImageSuccess = createAction(POST_UPDATE_IMAGE_SUCCESS);
const postUpdateImageFailed = createAction(POST_UPDATE_IMAGE_FAILED);

export const uploadUpdateImage = (updateId, image) => (dispatch, getState, api) => {
  dispatch({ type: POST_UPDATE_IMAGE });

  const data = new FormData();
  data.append('image', image);

  api.post({ path: `management/activity-update/${updateId}/image`, body: data, upload: true })
    .then((res) => {
      dispatch(postUpdateImageSuccess(res.image));
    })
    .catch((err) => {
      dispatch(postUpdateImageFailed({ message: err.data }));
    });
};

const deleteUpdateImageSuccess = createAction(DELETE_UPDATE_IMAGE_SUCCESS);
const deleteUpdateImageFailed = createAction(DELETE_UPDATE_IMAGE_FAILED);

export const deleteUpdateImage = (updateId, imageId) => (dispatch, getState, api) => {
  dispatch({ type: DELETE_UPDATE_IMAGE });

  api.del({ path: `management/activity-update/${updateId}/image/${imageId}` })
    .then(() => {
      dispatch(deleteUpdateImageSuccess(imageId));
    })
    .catch((err) => {
      dispatch(deleteUpdateImageFailed({ message: err.data }));
    });
};

const publishUpdateSuccess = createAction(PUBLISH_UPDATE_SUCCESS);
const publishUpdateFailed = createAction(PUBLISH_UPDATE_FAILED);

export const publishUpdate = (updateId, formData) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: PUBLISH_UPDATE });

    const body = formData;
    body.status = PUBLISHED;
    body.id = updateId;
    body.youtube_link = formData.youtube_link || '';

    api.put({ path: `management/activity-update/${updateId}/publish`, body })
      .then(() => {
        dispatch(publishUpdateSuccess());
        resolve();
      })
      .catch((err) => {
        dispatch(publishUpdateFailed({ message: err.data }));
        reject();
      });
  })
);

const saveUpdateSuccess = createAction(SAVE_UPDATE_SUCCESS);
const saveUpdateFailed = createAction(SAVE_UPDATE_FAILED);

export const saveUpdate = (updateId, formData) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: SAVE_UPDATE });

    const body = formData;
    body.id = updateId;
    body.youtube_link = formData.youtube_link || '';

    api.put({ path: `management/activity-update/${updateId}`, body })
      .then(() => {
        dispatch(saveUpdateSuccess());
        resolve();
      })
      .catch((err) => {
        dispatch(saveUpdateFailed({ message: err.data }));
        reject();
      });
  })
);

export const setUpdateEditor = update => dispatch => (
  new Promise((resolve) => {
    dispatch({
      type: SET_UPDATE_EDITOR,
      payload: update,
    });
    resolve();
  })
);

const deleteUpdateSuccess = createAction(DELETE_UPDATE_SUCCESS);
const deleteUpdateFailed = createAction(DELETE_UPDATE_FAILED);

export const deleteUpdate = updateId => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: DELETE_UPDATE });

    api.del({ path: `management/activity-update/${updateId}` })
      .then(() => {
        dispatch(deleteUpdateSuccess());
        resolve();
      })
      .catch((err) => {
        dispatch(deleteUpdateFailed({ message: err.data }));
        reject();
      });
  })
);
