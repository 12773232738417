import React, { Fragment } from 'react';
import PT from 'prop-types';

import groupEvents from 'services/groupEvents';

import EventsCategory from '../EventsCategory';

const UpcomingList = ({ events }) => {
  const sortedEvents = groupEvents(events);

  return (
    <Fragment>
      <EventsCategory
        title="Geen startdatum"
        events={sortedEvents.noDate}
      />
      <EventsCategory
        title="Deze week"
        events={sortedEvents.thisWeek}
      />
      <EventsCategory
        title="Volgende week"
        events={sortedEvents.nextWeek}
      />
      <EventsCategory
        title="Deze maand"
        events={sortedEvents.thisMonth}
      />
      <EventsCategory
        title="Volgende maand"
        events={sortedEvents.nextMonth}
      />
      <EventsCategory
        title="Dit jaar"
        events={sortedEvents.thisYear}
      />
      <EventsCategory
        title="Later"
        events={sortedEvents.later}
      />
    </Fragment>
  );
};

UpcomingList.propTypes = {
  events: PT.array,
};

export default UpcomingList;
