import React, { Fragment, Component } from 'react';
import PT from 'prop-types';
import { withRouter } from 'react-router-dom';

import {
  hasRole,
  EVENT_MANAGER,
  PARTNER,
} from 'services/authHelper';

import { Title, Button, Container } from 'common';
import EventDetailInviteDriversModal from '../EventDetailInviteDriversModal';

import { HeaderContainer, ButtonContainer, ShareLink, TabsContainer, Tab } from './styled';

const getBaseURL = () => (`${location.protocol}//${location.host}`);

class EventDetailHeader extends Component {
  state = {
    inviteModalOpen: false,
  }

  hideModal = () => {
    this.setState({
      inviteModalOpen: false,
    });
  }

  render() {
    const { event, match, roles, hasFullAccess, history } = this.props;
    return (
      <Fragment>
        <Container>
          <HeaderContainer>
            <div>
              <Title noWhitespace>{event.name || 'Nog geen titel'}</Title>
              <ShareLink to={`/share/${event.id}`} target="_blank">{`${getBaseURL()}/share/${event.id}`}</ShareLink>
            </div>

            <ButtonContainer>
              {!hasRole(roles, [EVENT_MANAGER, PARTNER]) && (
                <Fragment>
                  <Button
                    variant="black"
                    large
                    onClick={() => this.setState({ inviteModalOpen: true })}
                  >
                    Uitnodigen +
                  </Button>

                  <Button
                    onClick={() => history.push(`/event/edit/${event.id}`)}
                    sub
                    large
                    disabled={!hasFullAccess}
                  >
                    Wijzig event
                  </Button>
                </Fragment>
              )}
            </ButtonContainer>
          </HeaderContainer>

          <TabsContainer>
            <Tab to={`${match.url}/overview`}>Overzicht</Tab>
            <Tab to={`${match.url}/updates`}>Updates</Tab>
            {!hasRole(roles, [EVENT_MANAGER, PARTNER]) && (
              <Tab to={`${match.url}/emails`}>Event emails</Tab>
            )}
            <Tab to={`${match.url}/preview`}>Preview</Tab>
          </TabsContainer>
        </Container>

        {this.state.inviteModalOpen && (
          <EventDetailInviteDriversModal
            eventId={event.id}
            hideModal={() => this.setState({ inviteModalOpen: false })}
            title={event.name}
          />
        )}
      </Fragment>
    );
  }
}

EventDetailHeader.propTypes = {
  event: PT.object,
  match: PT.object,
  roles: PT.array,
  hasFullAccess: PT.bool,
  history: PT.object,
};

export default withRouter(EventDetailHeader);
