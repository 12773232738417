import React, { Fragment } from 'react';
import PT from 'prop-types';
import { FieldStyle, TagStyle } from './styled';

const RadioTag = ({
  input, meta, ...otherProps
}) => (
  <Fragment>
    <FieldStyle
      {...input}
      type="radio"
      value={otherProps.id}
      id={`${otherProps.id}_${otherProps.type}`}
      checked={otherProps.checked}
    />
    <TagStyle
      htmlFor={`${otherProps.id}_${otherProps.type}`}
      error={otherProps.error}
      data-cy={otherProps['data-cy']}
    >
      {otherProps.label}
    </TagStyle>
  </Fragment>
);

RadioTag.propTypes = {
  input: PT.object,
  meta: PT.object,
  otherProps: PT.object,
};

export default RadioTag;
