import React, { Component } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { getCustomRewards } from 'ducks/rewards';
import { archiveCustomReward } from 'ducks/customReward';
import { showNotification } from 'ducks/notification';

import { Container, Title, Button, RewardCard, EmptyState, Loading } from 'common';

import { HeaderContainer } from './styled';

class RewardsOverview extends Component {
  componentDidMount() {
    this.props.getCustomRewards();
  }

  onArchiveReward = (e, rewardId) => {
    e.stopPropagation();

    // eslint-disable-next-line
    const warning = confirm('Weet je zeker dat je deze reward wilt archiveren? \n De drivers zullen gearchiveerde rewards behouden in de app. Hij kan enkel niet meer uitgedeeld worden.');
    if (!warning) return false;

    return this.props.archiveCustomReward(rewardId)
      .then(() => {
        this.props.showNotification('De reward is succesvol gearchiveerd', 'green');
        this.props.getCustomRewards();
      })
      .catch(() => this.props.showNotification('Er is iets misgegaan bij het archiveren. Probeer nog eens.'));
  }

  render() {
    const { rewards, loading, history } = this.props;

    return (
      <Container>
        <Title underline>Rewards</Title>

        <HeaderContainer>
          <div>
            <Title small>Custom rewards</Title>
            <p>Enkel de custom rewards worden hier getoond. De level rewards worden niet weergegeven.</p>
          </div>
          <div>
            <Button
              small
              onClick={() => history.push('/rewards/edit')}
            >
              Nieuwe reward
            </Button>
          </div>
        </HeaderContainer>

        {loading && <Loading small />}

        {rewards.length !== 0 && !loading && rewards.map(reward => (
          <RewardCard
            key={reward.id}
            reward={{
              ...reward,
              value: reward.unit_value,
              value_type: reward.unit_type,
            }}
            readOnly
            onClick={() => history.push(`/rewards/edit/${reward.id}`)}
            compact
            archiveReward={this.onArchiveReward}
          />
        ))}

        {rewards.length === 0 && !loading && (
          <EmptyState
            title="Geen custom rewards"
            subtitle="Er zijn nog geen custom rewards aangemaakt"
          />
        )}
      </Container>
    );
  }
}

RewardsOverview.propTypes = {
  getCustomRewards: PT.func,
  loading: PT.bool,
  rewards: PT.array,
  history: PT.object,
  archiveCustomReward: PT.func,
  showNotification: PT.func,
};

export default compose(
  connect(state => ({
    rewards: state.rewards.data.custom,
    loading: state.rewards.loading.getCustomRewards,
  }), { getCustomRewards, archiveCustomReward, showNotification }),
  withRouter,
)(RewardsOverview);
