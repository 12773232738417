import React, { Fragment, Component } from 'react';
import PT from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  hasRole,
  DEALER,
  COMMUNITY_MANAGER,
} from 'services/authHelper';
import { DRAFT } from 'services/eventHelper';

import { loadEvent } from 'ducks/editEvent';
import { getEvent } from 'ducks/event';
import { getTicketType, getTicketCategory } from 'ducks/ticket';
import { deleteTicket } from 'ducks/editTicket';
import { getLevels } from 'ducks/levels';

import { TicketList, Icon } from 'modules/EventEditor/styled';
import { Wrapper, Container, Title, Button, Loading, EmptyState } from 'common';
import { StepsContainer, StepsItem } from 'common/steps';

import Ticket from '../Ticket';

class Tickets extends Component {
  constructor(props) {
    super(props);

    this.state = {
      create: false,
      edit: this.props.match.params.ticketId || '',
    };
  }

  componentWillMount() {
    if (this.props.ticketTypes.length === 0) {
      this.props.getTicketType();
    }

    if (this.props.ticketCategories.length === 0) {
      this.props.getTicketCategory();
    }

    if (this.props.levels.length === 0) {
      this.props.getLevels();
    }

    if (Object.keys(this.props.data).length === 0) {
      this.props.onLoad(this.props.match.params.id);
    }
  }

  componentDidMount() {
    this.props.getEvent(this.props.match.params.id);
  }

  toggleCreate = () => {
    this.setState({ create: !this.state.create });
  }

  toggleEdit = (id) => {
    this.setState({ edit: this.state.edit === id ? '' : id });
  }

  deleteEvent = (id) => {
    this.props.onDeleteTicket(id);
  }

  render() {
    const {
      data, loading, history, match, roles,
    } = this.props;

    return (
      <Fragment>
        <Container>
          <StepsContainer>
            <StepsItem onClick={() => {
              history.push(`/event/edit/${match.params.id}/details`);
            }}
            >
              1. Details
            </StepsItem>
            <StepsItem active>2. Tickets</StepsItem>
            <StepsItem disabled>
              {`3. ${hasRole(roles, [COMMUNITY_MANAGER])
                ? 'Publiceren'
                : hasRole(roles, [DEALER]) && data.status === DRAFT
                  ? 'Indienen voor review'
                  : 'Opslaan'}`}
            </StepsItem>
          </StepsContainer>
          <Title underline>Tickets beheren</Title>
        </Container>

        {loading && <Loading />}

        {data.ticket && (
          <Wrapper variant="background">
            <Container>
              {data.ticket.length === 0 && !this.state.create && (
                <EmptyState title="Er zijn nog geen tickets toegevoegd" subtitle="Voeg hieronder je eerste ticket toe" />
              )}

              <TicketList>
                {this.state.create ? (
                  <Ticket
                    data={{
                      name: '',
                      checklist: [],
                      custom_fields: [],
                    }}
                    edit
                    newTicket
                    onToggleEdit={this.toggleCreate}
                  />
                ) : (
                  data.ticket.map(ticket => (
                    <Ticket
                      data={ticket}
                      key={ticket.id}
                      edit={this.state.edit === ticket.id}
                      onToggleEdit={this.toggleEdit}
                      onDeleteTicket={this.deleteEvent}
                    />
                  ))
                )}
              </TicketList>
            </Container>
          </Wrapper>
        )}

        {!this.state.create && (
          <Container textCenter>
            <Button
              onClick={() => this.toggleCreate()}
              variant="black"
              data-cy="button-event-add-tickets"
            >
              Voeg ticket toe
            </Button>
          </Container>
        )}

        <Container textCenter>
          <Button
            onClick={() => history.push(`/event/edit/${match.params.id}/publish`)}
            data-cy="button-event-next-step"
          >
            Volgende <Icon>&gt;</Icon>
          </Button>
        </Container>
      </Fragment>
    );
  }
}

Tickets.propTypes = {
  data: PT.object,
  loading: PT.bool,
  ticketTypes: PT.array,
  ticketCategories: PT.array,
  levels: PT.array,
  onDeleteTicket: PT.func,
  getTicketType: PT.func,
  getTicketCategory: PT.func,
  getLevels: PT.func,
  onLoad: PT.func,
  getEvent: PT.func,
  history: PT.object,
  match: PT.object,
  roles: PT.array,
};

export default compose(
  withRouter,
  connect(state => ({
    roles: state.authentication.roles,
    data: state.editEvent.data,
    ticketTypes: state.ticket.types,
    ticketCategories: state.ticket.categories,
    levels: state.levels.data,
    loading: state.editEvent.loading.loadEvent,
  }), {
    getTicketType,
    getTicketCategory,
    getLevels,
    onDeleteTicket: deleteTicket,
    onLoad: loadEvent,
    getEvent,
  }),
)(Tickets);
