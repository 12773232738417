import createAction from 'services/createAction';

const GET_TAGS = 'tags/GET';
const GET_TAGS_SUCCESS = 'tags/GET_SUCCESS';
const GET_TAGS_FAILED = 'tags/GET_FAILED';

const initialState = {
  data: [],
  error: {
    message: '',
  },
  loading: {
    tags: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case GET_TAGS_SUCCESS:
    return {
      ...state,
      data: payload,
      loading: { ...state.loading, tags: false },
    };
  case GET_TAGS_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, tags: false },
    };
  case GET_TAGS:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, tags: true },
    };
  default:
    return state;
  }
};

const getTagsSuccess = createAction(GET_TAGS_SUCCESS);
const getTagsFailed = createAction(GET_TAGS_FAILED);

export const getTags = () => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: GET_TAGS });

    api.get({ path: 'management/tag' })
      .then((res) => {
        dispatch(getTagsSuccess(res));
        resolve(res);
      })
      .catch((err) => {
        dispatch(getTagsFailed({ message: err.data }));
        reject(err);
      });
  })
);
