import React, { Fragment } from 'react';
import PT from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { HeaderPlain } from 'common';

import EventPreviewHeader from './components/EventPreviewHeader';
import EventPreviewBasicInfo from './components/EventPreviewBasicInfo';
import EventPreviewIntroductionBlock from './components/EventPreviewIntroductionBlock';
import EventPreviewActions from './components/EventPreviewActions';
import EventPreviewTickets from './components/EventPreviewTickets';
import EventPreviewContactBlock from './components/EventPreviewContactBlock';
import EventPreviewFooter from './components/EventPreviewFooter';

import { Main, Aside } from './styled';

const EventPreview = ({
  event, publicEvent, showHeader,
}) => {
  // event is loaded from a logged in session (used for preview)
  // public event is loaded from public endpoints (used for share)
  const eventData = Object.keys(event).length > 0 ? event : publicEvent;

  return (
    <Fragment>
      {showHeader && <HeaderPlain />}
      <EventPreviewHeader
        image={eventData.image}
        title={eventData.name}
        subtitle={`${eventData.street}${eventData.housenumber ? ` ${eventData.housenumber}, ` : ', '}${eventData.city}`}
      />
      <Main>
        <EventPreviewBasicInfo event={eventData} />
        <Aside>
          <EventPreviewIntroductionBlock event={eventData} />
          <EventPreviewActions
            eventId={eventData.id}
            hasTickets={eventData.ticket && eventData.ticket.length > 0}
          />
          {(eventData.ticket && eventData.ticket.filter(ticket => (publicEvent ? ticket : ticket.public)).length > 0) && (
            <EventPreviewTickets tickets={eventData.ticket.filter(ticket => (publicEvent ? ticket : ticket.public))} />
          )}
          <EventPreviewContactBlock data={eventData} />
        </Aside>
      </Main>
      <EventPreviewFooter />
    </Fragment>
  );
};

EventPreview.defaultProps = {
  showHeader: false,
};

EventPreview.propTypes = {
  showHeader: PT.bool,
  event: PT.object,
  publicEvent: PT.object,
};

export default compose(
  withRouter,
  connect(state => ({
    event: state.event.data,
  })),
)(EventPreview);
