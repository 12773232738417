import styled from 'styled-components';
import { Anchor, HeaderBar } from 'common';

export const Image = styled.img`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  height: 16px;
  width: auto;
  margin-bottom: 20px;
  margin-top: 20px;
`;

export const ExtendedAnchor = styled(Anchor)`
  font-size: 18px;
  position: absolute;
  top: 30%;
  right: 18px;
`;

export const ExtendedHeaderBar = styled(HeaderBar)`
  position: relative;
`;
