import ChecklistBlock from 'modules/BrandPortal/components/Blocks/ChecklistBlock';
import ContentBlock from 'modules/BrandPortal/components/Blocks/ContentBlock';
import CollapsableContentBlock from 'modules/BrandPortal/components/Blocks/CollapsableContentBlock';
import DownloadBlock from 'modules/BrandPortal/components/Blocks/DownloadBlock';
import FallbackBlock from 'modules/BrandPortal/components/Blocks/FallbackBlock';
import IntroTextBlock from 'modules/BrandPortal/components/Blocks/IntroTextBlock';
import ImageBlock from 'modules/BrandPortal/components/Blocks/ImageBlock';
import QuoteBlock from 'modules/BrandPortal/components/Blocks/QuoteBlock';
import QuickLinksBlock from 'modules/BrandPortal/components/Blocks/QuickLinksBlock';
import TextBlock from 'modules/BrandPortal/components/Blocks/TextBlock';
import VideoBlock from 'modules/BrandPortal/components/Blocks/VideoBlock';
import ChangelogBlock from 'modules/BrandPortal/components/Blocks/ChangelogBlock';

const getBlock = (type) => {
  switch (type) {
  case 'checklist_block':
    return ChecklistBlock;
  case 'content_block':
    return ContentBlock;
  case 'collapsable_content_block':
    return CollapsableContentBlock;
  case 'download_block':
    return DownloadBlock;
  case 'intro_text_block':
    return IntroTextBlock;
  case 'image_slider_block':
    return ImageBlock;
  case 'quote_block':
    return QuoteBlock;
  case 'quick_links_block':
    return QuickLinksBlock;
  case 'text_block':
    return TextBlock;
  case 'video_block':
    return VideoBlock;
  case 'changelog_block':
    return ChangelogBlock;
  default:
    return FallbackBlock;
  }
};

export default getBlock;
