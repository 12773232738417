import styled, { css } from 'styled-components';
import PT from 'prop-types';

import Small from '../../Small';

const SubLabel = styled(Small)`
  display: block;
  padding-left: 18px;
  margin-bottom: 12px;
  margin-top: 12px;

  ${props => props.groupWithLabel && css`
    margin-top: -10px;
  `}

  ${props => props.noWhitespace && css`
    padding: 0;
  `}
`;

SubLabel.propTypes = {
  groupWithLabel: PT.bool,
  noWhitespace: PT.bool,
};


export default SubLabel;
