import React from 'react';
import PT from 'prop-types';
import styled from 'styled-components';
import { media } from 'styles/utils';

const StyledError = styled.span`
    color: ${props => props.theme.red};
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 1.5;
    padding: 10px 0;

    ${media.tablet`
        padding: 10px 20px;
    `}
`;

const InputError = ({ meta }) => {
  if (!meta.error || !meta.submitFailed) return null;

  return (
    <StyledError data-cy="message-error">
      {meta.error}
    </StyledError>
  );
};

InputError.propTypes = {
  meta: PT.shape({
    submitFailed: PT.bool,
    error: PT.oneOfType([PT.string, PT.bool]),
  }),
};

export default InputError;
