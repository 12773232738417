import styled, { css } from 'styled-components';
import PT from 'prop-types';
import { media } from 'styles/utils';

const Button = styled.button`
  color: ${props => props.theme.white};
  background: ${props => props.theme[props.variant]};
  border: none;
  padding: 18px;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 2.9px;
  font-weight: 700;
  margin-right: 18px;

  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    background-color: ${props => props.theme[props.variant].hover};
  }

  &:disabled {
    background-color: ${props => props.theme.gray};
    color: rgba(255, 255, 255, 0.8);
    border: 1px solid ${props => props.theme.gray};
    cursor: default;

    &:hover {
      background-color: ${props => props.theme.gray};
      border: 1px solid ${props => props.theme.gray};
    }
  }

  ${props => props.stretch && css`
    display: block;
    width: 100%;
  `}

  ${props => props.sub && css`
    background-color: transparent;
    color: ${props.theme.black};
    border: 1px solid ${props.theme.border};
    padding: 17px;

    &:hover {
      background-color: ${props.theme.red};
      border-color: ${props.theme.red};
      color: ${props.theme.white};

      polygon {
        fill: ${props.theme.white};
      }
    }
  `}

  ${props => props.light && css`
    background-color: ${props.theme.white};
    border: 1px solid ${props.theme.white};
    color: ${props.theme.black};

    &:hover {
      background-color: ${props.theme.red};
      border-color: ${props.theme.red};
      color: ${props.theme.white};

      polygon {
        fill: ${props.theme.white};
      }
    }

    &:disabled {
      &:hover {
        cursor: default;
        background-color: ${props.theme.gray};
        color: ${props.theme.white};
        border: 1px solid ${props.theme.gray};
      }
    }
  `}

  ${props => props.boxShadow && css`
    border: 1px solid ${props.theme.border.withBoxShadow};
    box-shadow: ${props.theme.boxShadow};
  `}

  ${props => props.large && css`
    ${media.tablet`
      padding: 18px 36px;
    `}
  `}

  ${props => props.small && css`
    font-size: 10px;
    padding: 10px;

    ${media.tablet`
      padding: 10px 30px;
    `}
  `}

  ${props => props.small && props.sub && css`
    padding: 9px;

    ${media.tablet`
      padding: 9px 30px;
    `}
  `}

  ${props => props.square && css`
    ${media.tablet`
      padding: 10px;
    `}
  `}
`;

Button.defaultProps = {
  variant: 'red',
  type: 'button',
};

Button.propTypes = {
  stretch: PT.bool,
  plain: PT.bool,
  large: PT.bool,
  sub: PT.bool,
  variant: PT.oneOf(['red', 'black']),
  small: PT.bool,
  square: PT.bool,
  light: PT.bool,
  boxShadow: PT.bool,
};

export default Button;
