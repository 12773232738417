import React from 'react';
import PT from 'prop-types';

import { Button, Modal } from 'common';

import { ModalContainer, ModalTitle, ModalButtonsContainer } from './styled';

const WarningModal = ({ onClose }) => (
  <Modal onHideModal={onClose}>
    <ModalContainer>
      <ModalTitle small>Weet je zeker dat je deze velden wilt aanpassen? Dit heeft grote impact op het event.</ModalTitle>
      <ModalButtonsContainer>
        <Button small onClick={onClose}>Ok</Button>
      </ModalButtonsContainer>
    </ModalContainer>
  </Modal>
);

WarningModal.propTypes = {
  onClose: PT.func,
};

export default WarningModal;
