import React from 'react';
import PT from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { HeaderBar } from 'common';

import Logo from 'images/logo.png';
import Cross from 'vectors/Cross.svg';

import { ExtendedContainer, Image, ExtendedAnchor, ExtendedTitle } from './styled';

const EventHeader = ({ history, eventId, name }) => (
  <HeaderBar fixed>
    <ExtendedContainer>
      <Image src={Logo} alt="logo" onClick={() => history.push('/')} />
      <ExtendedTitle small>Invite: {name}</ExtendedTitle>
      <ExtendedAnchor
        bold
        small
        onClick={() => { history.push(`/event/${eventId}`); }}
      >
          Sluiten <Cross />
      </ExtendedAnchor>
    </ExtendedContainer>
  </HeaderBar>
);

EventHeader.propTypes = {
  history: PT.object,
  eventId: PT.string,
  name: PT.string,
};

export default compose(withRouter)(EventHeader);
