import createAction from 'services/createAction';
import { showNotification } from 'ducks/notification';

const GET_BADGES = 'getBadges/GET';
const GET_BADGES_SUCCESS = 'getBadges/GET_SUCCESS';
const GET_BADGES_FAILED = 'getBadges/GET_FAILED';

const ADD_BADGE = 'getBadges/ADD';
const ADD_BADGE_SUCCESS = 'getBadges/ADD_SUCCESS';
const ADD_BADGE_FAILED = 'getBadges/ADD_FAILED';

const GET_BADGE_SUCCESS = 'getBadges/GET_BADGE_SUCCESS';
const GET_BADGE_FAILED = 'getBadges/GET_BADGE_FAILED';

const initialState = {
  data: [],
  badges: {},
  driverId: '',
  error: {
    message: '',
  },
  loading: {
    badges: false,
    addBadge: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case GET_BADGES_SUCCESS:
    return {
      ...state,
      data: payload.data,
      driverId: payload.driverId,
      loading: { ...state.loading, badges: false },
    };
  case GET_BADGES_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, badges: false },
    };
  case GET_BADGES:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, badges: true },
    };
  case ADD_BADGE:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, addBadge: payload.badgeId },
    };
  case ADD_BADGE_SUCCESS:
    return {
      ...state,
      data: state.data.map((badge) => {
        if (badge.id === payload.data.badge_id) {
          badge.achieved_tier = true;
          badge.progress = payload.data.current_progress;
          badge.image = payload.image;
        }

        return badge;
      }),
      error: { message: '' },
      loading: { ...state.loading, addBadge: false },
    };
  case ADD_BADGE_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, addBadge: false },
    };
  case GET_BADGE_SUCCESS:
    return {
      ...state,
      badges: {
        ...state.badges,
        [payload.data.id]: payload.data,
      },
      loading: { ...state.loading, getBadge: false },
    };
  case GET_BADGE_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, getBadge: false },
    };
  default:
    return state;
  }
};

const getBadgesSuccess = createAction(GET_BADGES_SUCCESS);
const getBadgesFailed = createAction(GET_BADGES_FAILED);

export const getBadgesFromDriver = driverId => (dispatch, getState, api) => {
  dispatch({ type: GET_BADGES });

  api.get({ path: `management/user/${driverId}/badges` })
    .then((res) => {
      dispatch(getBadgesSuccess({ data: res, driverId }));
    })
    .catch((err) => {
      dispatch(getBadgesFailed({ message: err.data }));
    });
};

const addBadgeLoading = createAction(ADD_BADGE);
const addBadgeSuccess = createAction(ADD_BADGE_SUCCESS);
const addBadgeFailed = createAction(ADD_BADGE_FAILED);

export const addBadgeToDriver = (driverId, badgeId, progress) => (dispatch, getState, api) => {
  dispatch(addBadgeLoading({ badgeId }));

  const payload = {
    user_id: driverId,
    badge_id: badgeId,
    badge_progress: progress,
  };

  return api.post({ path: 'badge/gift', body: payload, v2: true })
    .then(async (res) => {
      const badge = await api.get({ path: `badge/${res.badge_id}` });
      dispatch(addBadgeSuccess({ data: res, image: badge.tiers[0].image }));
      dispatch(showNotification('De badge is succesvol toegevoegd.', 'green'));
    })
    .catch((err) => {
      dispatch(addBadgeFailed({ message: err.data }));
      dispatch(showNotification('Er is iets fout gegaan. Probeer opnieuw.'));
    });
};

const getBadgeSuccess = createAction(GET_BADGE_SUCCESS);
const getBadgeFailed = createAction(GET_BADGE_FAILED);

export const getBadge = (badgeId) => (dispatch, getState, api) => {
  if (getState().getBadges.badges[badgeId]) {
    return getState().getBadges.badges[badgeId];
  }

  return api.get({ path: `badge/${badgeId}` })
    .then((res) => {
      dispatch(getBadgeSuccess({ data: res }));
      return res;
    })
    .catch((err) => {
      dispatch(getBadgeFailed({ message: err.data }));
    });
};