import styled from 'styled-components';
import PT from 'prop-types';

export const Quote = styled.div.attrs(props => ({
  dangerouslySetInnerHTML: !props.children ? { __html: props.text } : null,
}))`
  font-size: 34px;
  max-width: 466px;
  margin: 0 0 10px 20px;
  line-height: 50px;
  font-weight: 400;
  letter-spacing: 0.1px;

  &:before, &:after {
    content: '"';
  }
`;

Quote.propTypes = {
  text: PT.string,
};

export const Author = styled.div`
  margin-left: 20px;
  font-size: 18px;
  line-height: 30px;
  font-weight: 100;
  color: ${props => props.theme.gray};
`;
