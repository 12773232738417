import createAction from 'services/createAction';
import { showNotification } from 'ducks/notification';

const EDIT_MILEAGE = 'editMileage/EDIT_MILEAGE';
const EDIT_MILEAGE_SUCCESS = 'editMileage/EDIT_MILEAGE_SUCCESS';
const EDIT_MILEAGE_FAILED = 'editMileage/EDIT_MILEAGE_FAILED';

const initialState = {
  error: {
    message: '',
  },
  loading: {
    edit: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case EDIT_MILEAGE_SUCCESS:
    return {
      ...state,
      loading: { ...state.loading, edit: false },
    };
  case EDIT_MILEAGE_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, edit: false },
    };
  case EDIT_MILEAGE:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, edit: true },
    };
  default:
    return state;
  }
};

const editMileageSuccess = createAction(EDIT_MILEAGE_SUCCESS);
const editMileageFailed = createAction(EDIT_MILEAGE_FAILED);

export const editMileageFromDriver = (carId, mileage) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: EDIT_MILEAGE });

    api.post({ path: `management/car/${carId}/mileage`, body: { mileage } })
      .then(() => {
        resolve();
        dispatch(editMileageSuccess());
        dispatch(showNotification('De kilometerstand is succesvol aangepast.', 'green'));
      })
      .catch((err) => {
        reject(err);
        dispatch(editMileageFailed({ message: err.data }));
        dispatch(showNotification('Er is iets fout gegaan. Probeer opnieuw.'));
      });
  })
);
