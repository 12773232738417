import styled from 'styled-components';
import { media } from 'styles/utils';

import { Title, FlexWrapper } from 'common';

export const Content = styled(FlexWrapper)`
  justify-content: space-between;
`;

export const Header = styled.div`
  margin: 0 0 43px 0;

  ${media.desktop`
    display: flex;
    justify-content: space-between;
  `}
`;

export const ButtonContainer = styled.div`
  margin: 31px 0 0 0;

  ${media.desktop`
    margin: 0;
  `}
`;

export const IconContainer = styled.span`
  margin: 0 9px 0 0;

  svg {
    vertical-align: middle;
    width: 20px;
    height: 20px;
  }
`;

export const ExtendedTitle = styled(Title)`
  font-weight: 700;
  line-height: 1;
  margin: 0;
`;

export const CameraIconContainer = styled.span`
  margin: 0 9px 0 0;

  svg {
    vertical-align: text-top;
    width: 13px;
    height: 13px;
  }
`;
