import React from 'react';
import PT from 'prop-types';

import Block from '../Block';

import StyledContainer from '../StyledContainer';
import FootNote from '../FootNote';

import { Video, Iframe } from './styled';

const VideoBlock = ({ data }) => (
  <Block>
    <StyledContainer>
      <Video>
        <Iframe
          title="video"
          src={`https://www.youtube.com/embed/${data.value.youtube_id}?showinfo=0`}
          frameBorder="0"
          allowFullScreen
        />
      </Video>
      <FootNote text={data.value.foot_note} />
    </StyledContainer>
  </Block>
);

VideoBlock.propTypes = {
  data: PT.object,
};

export default VideoBlock;
