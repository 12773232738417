import React from 'react';
import PT from 'prop-types';

import CreatableSelectStyled from './styled';

const InputSelectMultipleTags = ({
  defaultValue, name, onChange, options,
}) => {
  return (
    <CreatableSelectStyled
      defaultValue={defaultValue}
      isMulti
      name={name}
      options={options}
      classNamePrefix="select-multi"
      onChange={onChange}
    />
  );
}

InputSelectMultipleTags.propTypes = {
  defaultValue: PT.array,
  name: PT.string,
  onChange: PT.func,
  options: PT.array,
};

export default InputSelectMultipleTags;
