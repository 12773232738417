import createAction from 'services/createAction';

const GET_DRIVER_CEM_PACKAGES = 'driverCEMPackages/GET';
const GET_DRIVER_CEM_PACKAGES_SUCCESS = 'driverCEMPackages/GET_SUCCESS';
const GET_DRIVER_CEM_PACKAGES_FAILED = 'driverCEMPackages/GET_FAILED';

const initialState = {
  data: [],
  error: {
    message: '',
  },
  loading: {
    get: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case GET_DRIVER_CEM_PACKAGES_SUCCESS:
    return {
      ...state,
      data: payload,
      loading: { get: false },
    };
  case GET_DRIVER_CEM_PACKAGES_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { get: false },
    };
  case GET_DRIVER_CEM_PACKAGES:
    return {
      ...state,
      error: { message: '' },
      loading: { get: true },
    };
  default:
    return state;
  }
};

const getDriverCEMPackagesSuccess = createAction(GET_DRIVER_CEM_PACKAGES_SUCCESS);
const getDriverCEMPackagesFailed = createAction(GET_DRIVER_CEM_PACKAGES_FAILED);

export const getDriverCEMPackages = userId => (dispatch, getState, api) => {
  dispatch({ type: GET_DRIVER_CEM_PACKAGES });

  api.get({ path: `users/${userId}/packages`, v2: true })
    .then((res) => {
      dispatch(getDriverCEMPackagesSuccess(res));
    })
    .catch((err) => {
      dispatch(getDriverCEMPackagesFailed({ message: err.data }));
    });
};
