import styled, { css } from 'styled-components';
import PT from 'prop-types';

import Link, { activeClass } from 'common/Link';

export const SubNavHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: 18px;
  margin-bottom: 18px;
`;

export const Button = styled.button`
  cursor: pointer;
  padding: 0 10px;
  appearance: none;
  border: none;
  background-color: transparent;

  & svg {
    width: 12px;
    height: 8px;

    ${props => props.open && css`
      transform: rotate(180deg);
    `}
  }
`;

Button.propTypes = {
  open: PT.bool,
};

export const SubNavContent = styled.div`
  display: none;

  ${props => props.open && css`
    display: block;
  `}
`;

SubNavContent.propTypes = {
  open: PT.bool,
};

export const ExtendedLink = styled(Link)`
  font-size: 16px;

  &.${activeClass}, &:hover {
    color: ${props => props.theme.text};
    text-decoration: underline;
  }
`;
