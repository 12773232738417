import qs from 'qs';
import { API_ENDPOINT, AUTH_TOKEN, API_BRANDPORTAL_ENDPOINT, API_V2_ENDPOINT } from 'config';
import { setCookie, getCookie, removeCookie } from 'services/cookies';

/**
 * The request helper is used to make sure every API call acts the same by always checking authorization and errors
 * @param {String} path The URL that we should make an fetch request to
 * @param {Object} options An object containing all options for the fetch call to the given path
 * @param {Bool} handle401 Should the request handle a 401 response or should it let it pass
 * @return {*} Either a rejected promise or a JSON object
 */
const request = ({ path, options, handle401, file }) =>
  new Promise((resolve, reject) => {
    fetch(path, options)
      .then((response) => {
        const unauthorized = response.status === 401 || response.status === 403;

        if (unauthorized && handle401) {
          const cookie = getCookie('X-Access-Token');
          if (cookie) removeCookie('X-Access-Token');

          // Redirect to login with current URL as redirect URL
          const redirect = window.location.pathname;
          window.location = `/login?redirect=${redirect}`;
        }

        if (response.ok) {
          const token = response.headers.get('X-Access-Token');
          if (token) setCookie('X-Access-Token', token);

          if (file) return response.blob();

          return response.json();
        }

        if (response.status === 404) {
          return Promise.reject(response.text());
        }

        if (response.status === 409) {
          return Promise.reject(response);
        }

        return Promise.reject(response.json());
      })
      .then((json) => {
        resolve(json);
      })
      .catch((error) => {
        try {
          error.then((err) => {
            if (__DEV__) console.error('API call failed:', err); // eslint-disable-line no-console

            reject(err);
          }).catch();
        } catch (err) {
          reject(error);
        }
      });
  });

/**
 * The generateOptions helper is used to make sure every API call is build with the same parameters.
 * The same headers, a certain method (GET, POST, etc) and a body
 * @param {String} method either GET, DELETE, POST, PATCH or PUT
 * @param {String} path The URL that we should make an fetch request to
 * @param {Boolean} [withAuth=false] Should we send a request with our JWT token in the authorization header
 * @param {Object} query An object containing all the options that we want to add to the paths query parameter
 * @param {Object} body An object containing all parameters that we want to send along in the body of the request
 * @return {Object}
 */
const generateOptions = ({
  method, path, withAuth = true, query, body, upload = false, file = false, brandPortal = false, v2 = false,
}) => {
  if (!upload) body = JSON.stringify(body);
  const ENDPOINT = brandPortal ? API_BRANDPORTAL_ENDPOINT : v2 ? API_V2_ENDPOINT : API_ENDPOINT;

  return {
    path: `${ENDPOINT}${path}${query ? '?' : ''}${qs.stringify(query || {}, { arrayFormat: 'brackets' })}`,
    options: {
      ...!brandPortal ? {
        headers: {
          ...!upload ? { 'Content-Type': 'application/json' } : {},
          'x-auth-token': AUTH_TOKEN,
          ...withAuth ? { 'X-Access-Token': getCookie('X-Access-Token') } : {},
        },
      } : {},
      method,
      ...(body ? { body } : {}),
    },
    handle401: withAuth,
    file,
  };
};

export const get = ({
  path, query, withAuth, brandPortal, v2,
}) =>
  request(generateOptions({
    method: 'GET',
    path,
    query,
    withAuth,
    brandPortal,
    v2,
  }));

export const del = ({
  path, query, withAuth, body, v2,
}) =>
  request(generateOptions({
    method: 'DELETE',
    path,
    query,
    withAuth,
    body,
    v2,
  }));

export const post = ({
  path, body, withAuth, upload, file, v2,
}) =>
  request(generateOptions({
    method: 'POST',
    path,
    body,
    withAuth,
    upload,
    file,
    v2,
  }));

export const put = ({
  path, body, withAuth, v2,
}) =>
  request(generateOptions({
    method: 'PUT',
    path,
    body,
    withAuth,
    v2,
  }));

export const patch = ({
  path, body, withAuth, v2,
}) =>
  request(generateOptions({
    method: 'PATCH',
    path,
    body,
    withAuth,
    v2,
  }));
