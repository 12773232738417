import React, { Component } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import { approveCars, rejectCars } from 'ducks/validateCars';
import { hideModal } from 'ducks/modal';
import NewTabIcon from 'vectors/newtab.svg';
import { Info, Title, Subtitle, PlaceholderImage, Button, Loading } from 'common';
import ValidateCarsMessageEditor from '../ValidateCarsMessageEditor';

import {
  ModalContent, ImagePreview, ImageContainer, ButtonContainer, ModalContainer,
  PreviousMessage, ValidateCarModalContainer,
} from './styled';

class ValidateCarsModal extends Component {
  state = {
    showMessages: false,
  };

  onConfirm = (carId) => {
    // eslint-disable-next-line
    const warning = confirm('Weet je zeker dat je dit verzoek wilt accepteren?');

    if (!warning) return false;

    this.props.onApproveCars(carId).then(() => this.props.hideCarsModal());
  };

  onReject = (carId) => {
    // eslint-disable-next-line
    const warning = confirm('Weet je zeker dat je dit verzoek wilt weigeren?');

    if (!warning) return false;

    this.props.onRejectCars(carId).then(() => this.props.hideCarsModal());
  };

  render() {
    const { data, loading } = this.props;

    if (loading) {
      return (
        <ModalContainer>
          <Loading small />
        </ModalContainer>
      );
    }

    return (
      <ModalContainer>
        <ValidateCarModalContainer>
          <ImageContainer>
            {data.information_request && data.information_request.proof_image ? (
              <a href={data.information_request.proof_image} target="_blank" rel="noopener noreferrer">
                <ImagePreview image={data.information_request.proof_image} />
                <NewTabIcon />
              </a>
            ) : (
              <PlaceholderImage size="80%" />
            )}
          </ImageContainer>
          <ModalContent>
            <Title noWhitespace small>{data.car.model}</Title>
            <Subtitle small>{data.user.full_name}</Subtitle>
            <Info label="Jaar">
              {moment(data.model_year).format('YYYY')}
            </Info>
            {data.car.licence_plate && (
              <Info label="Kenteken">
                {data.car.licence_plate}
              </Info>
            )}
            {data.car.vin && (
              <Info label="VIN">
                {data.car.vin}
              </Info>
            )}
            {data.information_request && data.information_request.message && (
              <Info label="Laatst verzonden opmerking">
                <PreviousMessage>
                  {data.information_request.message}
                </PreviousMessage>
              </Info>
            )}
            {!this.state.showMessages ? (
              <ButtonContainer>
                <Button onClick={() => this.onConfirm(data.car.id)} small stretch>Accepteer verzoek</Button>
                <Button onClick={() => this.setState({ showMessages: true })} small sub stretch>
                  Vraag om meer informatie
                </Button>
                <Button onClick={() => this.onReject(data.car.id)} small stretch variant="black">
                  Weiger verzoek
                </Button>
              </ButtonContainer>
            ) : (
              <ValidateCarsMessageEditor
                onCancel={() => this.setState({ showMessages: false })}
                carId={data.car.id}
              />
            )}
          </ModalContent>
        </ValidateCarModalContainer>
      </ModalContainer>

    );
  }
}

ValidateCarsModal.propTypes = {
  data: PT.object,
  onApproveCars: PT.func,
  onRejectCars: PT.func,
  hideCarsModal: PT.func,
  loading: PT.bool,
};

const mapStateToProps = state => ({
  loading: state.validateCars.loading.modal,
});

const mapDispatchToProps = dispatch => ({
  hideCarsModal: () => {
    dispatch(hideModal());
  },
  onApproveCars: carId => (
    dispatch(approveCars(carId))
  ),
  onRejectCars: carId => (
    dispatch(rejectCars(carId)).then(dispatch(hideModal()))
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ValidateCarsModal);
