import styled from 'styled-components';
import { Wrapper, Title, Subtitle, Container } from 'common';
import { media } from 'styles/utils';

export const ExtendedWrapper = styled(Wrapper)`
  position: relative;
  width: 100%;
  height: 78vh;
  margin-bottom: 20px;
  border-bottom: 20px solid ${props => props.theme.gray.light};

  ${media.tablet`
    height: 40vw;
    border-bottom: 60px solid ${props => props.theme.gray.light};
  `}

  ${media.large`
    height: 465px;
  `}
`;

export const ExtendedContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  margin: 0 auto;
  padding: 36px;
`;

export const ExtendedTitle = styled(Title)`
  font-size: 22px;

  ${media.tablet`
    font-size: 48px;
  `}
`;

export const ExtendedSubtitle = styled(Subtitle)`
  font-size: 16px;

  ${media.tablet`
    font-size: 28px;
  `}
`;
