import styled, { css } from 'styled-components';
import PT from 'prop-types';

const Text = styled.div.attrs(props => ({
  dangerouslySetInnerHTML: !props.children ? { __html: props.html } : null,
}))`
  padding: 0 20px;
  font-weight: 100;
  letter-spacing: 0.1px;
  font-size: 18px;

  & a {
    color: ${props => props.theme.red};
    text-decoration: underline;
    cursor: pointer;
  }

  & p {
    line-height: 30px;
  }

  ${props => props.medium && css`
    font-size: 22px;

    & p {
      line-height: 38px;
    }
  `}
`;

Text.propTypes = {
  html: PT.string,
  medium: PT.bool,
};

export default Text;
