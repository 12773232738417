import createAction from 'services/createAction';
import { showNotification } from 'ducks/notification';

const INVITE_DRIVERS = 'inviteDrivers/INVITE';
const INVITE_DRIVERS_SUCCESS = 'inviteDrivers/INVITE_SUCCESS';
const INVITE_DRIVERS_FAILED = 'inviteDrivers/INVITE_FAILED';

const SELECT_DRIVER = 'inviteDrivers/SELECT';
const SELECT_ALL_DRIVERS = 'inviteDrivers/SELECT_ALL';
const DESELECT_DRIVER = 'inviteDrivers/DESELECT';
const DESELECT_ALL_DRIVERS = 'inviteDrivers/DESELECT_ALL';

const SET_SELECTED_DRIVERS_COUNT = 'inviteDrivers/SET_SELECTED_COUNT';

const INVITE_CSV_DRIVERS = 'inviteDrivers/INVITE_CSV';
const INVITE_CSV_DRIVERS_SUCCESS = 'inviteDrivers/INVITE_CSV_SUCCESS';
const INVITE_CSV_DRIVERS_FAILED = 'inviteDrivers/INVITE_CSV_FAILED';

const initialState = {
  data: [],
  selectedDriversCount: 0,
  error: {
    message: '',
  },
  loading: {
    inviteDrivers: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case INVITE_DRIVERS_SUCCESS:
    return {
      ...state,
      loading: { ...state.loading, inviteDrivers: false },
    };
  case INVITE_DRIVERS_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, inviteDrivers: false },
    };
  case INVITE_DRIVERS:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, inviteDrivers: true },
    };
  case SELECT_DRIVER:
    return {
      ...state,
      data: [
        ...state.data,
        payload,
      ],
    };
  case SELECT_ALL_DRIVERS:
    return {
      ...state,
      data: payload,
    };
  case DESELECT_DRIVER:
    return {
      ...state,
      data: state.data.filter(driver => driver.id !== payload),
    };
  case DESELECT_ALL_DRIVERS:
    return {
      ...state,
      data: [],
    };
  case SET_SELECTED_DRIVERS_COUNT:
    return {
      ...state,
      selectedDriversCount: payload,
    };
  case INVITE_CSV_DRIVERS_SUCCESS:
    return {
      ...state,
      loading: { ...state.loading, inviteCSV: false },
    };
  case INVITE_CSV_DRIVERS_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, inviteCSV: false },
    };
  case INVITE_CSV_DRIVERS:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, inviteCSV: true },
    };
  default:
    return state;
  }
};

const inviteDriversSuccess = createAction(INVITE_DRIVERS_SUCCESS);
const inviteDriversFailed = createAction(INVITE_DRIVERS_FAILED);

export const inviteDriversToEvent = (id, inviteData, isTicketInvite) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: INVITE_DRIVERS });

    const body = {
      message: inviteData.message,
      subject: inviteData.subject,
      users: Object.keys(inviteData.invite).filter(checked => inviteData.invite[checked]),
    };

    if (inviteData.user_filters) {
      body.user_filters = inviteData.user_filters;

      // backend inconsistency. Remove this if statement when tags is changed to tag like the get drivers call
      if (body.user_filters.tag) {
        body.user_filters.tags = body.user_filters.tag;
        delete body.user_filters.tag;
      }
    }

    const path = isTicketInvite ? `management/ticket/${id}/invite` : `management/activity/${id}/invite`;

    api.put({ path, body })
      .then((res) => {
        dispatch(inviteDriversSuccess(res));
        dispatch(showNotification('De uitnodigingen zijn succesvol verstuurd', 'green'));
        resolve();
      })
      .catch((err) => {
        dispatch(inviteDriversFailed({ message: err.data }));
        dispatch(showNotification('Er is iets misgegaan bij het versturen van de uitnodigingen'));
        reject();
      });
  })
);

export const selectDriver = createAction(SELECT_DRIVER);
export const selectAllDrivers = createAction(SELECT_ALL_DRIVERS);
export const deselectDriver = createAction(DESELECT_DRIVER);
export const deselectAllDrivers = createAction(DESELECT_ALL_DRIVERS);

export const setSelectedDriversCount = createAction(SET_SELECTED_DRIVERS_COUNT);

const inviteCSVDriversSuccess = createAction(INVITE_CSV_DRIVERS_SUCCESS);
const inviteCSVDriversFailed = createAction(INVITE_CSV_DRIVERS_FAILED);

export const inviteCSVDrivers = (id, formData, file, ticket = false) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: INVITE_CSV_DRIVERS });

    const data = new FormData();
    data.append('csv', file);
    data.append('subject', formData.subject);
    data.append('message', formData.message);

    const path = ticket ? `tickets/${id}/invite?csv` : `events/${id}/invite?csv`;

    api.post({ path, body: data, upload: true, v2: true })
      .then((res) => {
        dispatch(inviteCSVDriversSuccess());
        resolve(res);
      })
      .catch((err) => {
        dispatch(inviteCSVDriversFailed({ message: err.data }));
        reject();
      });
  })
);
