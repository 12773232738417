import React from 'react';
import PT from 'prop-types';

import SubLabel from 'common/form/SubLabel';

import { DropzoneStyled } from './styled';

const InputDropZoneFile = ({ onUpload, label, fullHeight }) => (
  <DropzoneStyled
    onDrop={(acceptedFiles) => {
      onUpload(acceptedFiles[0]);
    }}
    fullHeight={fullHeight}
  >
    <SubLabel noWhitespace>{label}</SubLabel>
  </DropzoneStyled>
);

InputDropZoneFile.propTypes = {
  onUpload: PT.func,
  label: PT.string,
  fullHeight: PT.bool,
};

export default InputDropZoneFile;
