import React from 'react';
import PT from 'prop-types';

import getPaginationOffset from 'services/getPaginationOffset';

import { InfiniteScrollContainer } from 'common';
import EventsCategory from '../EventsCategory';

const PastList = ({
  events, getMorePastEvents, loading, pagination,
}) => {
  const paginationOffset = getPaginationOffset(pagination);

  return (
    <InfiniteScrollContainer
      fetch={() => getMorePastEvents(paginationOffset)}
      total={pagination.count}
      current={paginationOffset}
      loading={loading}
    >
      <EventsCategory
        title="Afgelopen events"
        events={events}
        titleInvert
        past
      />
    </InfiniteScrollContainer>
  );
};

PastList.propTypes = {
  events: PT.array,
  getMorePastEvents: PT.func,
  loading: PT.bool,
  pagination: PT.object,
};

export default PastList;
