import styled, { css } from 'styled-components';
import { media } from 'styles/utils';
import { Container } from 'common';

export const Main = styled(Container)`
  ${media.desktop`
    display: flex;

    ${Container} {
      flex: 1;
      margin-right: 36px;
    }
  `}
`;

export const Aside = styled.aside`
  flex-basis: 420px;
`;

export const Description = styled.p`
  font-size: 20px;
  line-height: 34px;

  ${props => props.bold && css`
    font-weight: 700;
    margin: 0;
  `}
`;

export const Note = styled.div`
  padding: 24px 18px;
  border-top: 1px solid ${props => props.theme.border};
  border-bottom: 1px solid ${props => props.theme.border};
  margin-bottom: 36px;
`;

export const NoteLabel = styled.p`
  font-size: 14px;
  margin: 0;
`;

export const DetailSection = styled.div`
  margin-bottom: 36px;
`;
