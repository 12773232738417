import styled from 'styled-components';

import { Title, Container } from 'common';

export const ToggleLabel = styled.span`
  margin-left: 18px;
  line-height: 16px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
  display: flex;
  align-items: center;
`;

export const ModalContainer = styled.div`
  margin: 48px 36px;
`;

export const ExtendedTitle = styled(Title)`
  margin-bottom: 36px;
`;

export const ExtendedContainer = styled(Container)`
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 22px;
  align-items: center;
`;
