import createAction from 'services/createAction';
import { showNotification } from 'ducks/notification';

const GET_DRIVER = 'driver/GET';
const GET_DRIVER_SUCCESS = 'driver/GET_SUCCESS';
const GET_DRIVER_FAILED = 'driver/GET_FAILED';

const EDIT_DRIVER = 'driver/EDIT';
const EDIT_DRIVER_SUCCESS = 'driver/EDIT_SUCCESS';
const EDIT_DRIVER_FAILED = 'driver/EDIT_FAILED';

const RESET_DRIVER_PASSWORD = 'driver-password/FORGOT';
const RESET_DRIVER_PASSWORD_SUCCESS = 'driver-password/FORGOT_SUCCESS';
const RESET_DRIVER_PASSWORD_FAILED = 'driver-password/FORGOT_FAILED';

const DELETE_DRIVER = 'driver/DELETE';
const DELETE_DRIVER_SUCCESS = 'driver/DELETE_SUCCESS';
const DELETE_DRIVER_FAILED = 'driver/DELETE_FAILED';

const initialState = {
  data: {},
  error: {
    message: '',
  },
  loading: {
    get: false,
    edit: false,
    resetPassword: false,
    delete: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case GET_DRIVER_SUCCESS:
    return {
      ...state,
      data: payload,
      loading: { ...state.loading, get: false },
    };
  case GET_DRIVER_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, get: false },
    };
  case GET_DRIVER:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, get: true },
    };
  case EDIT_DRIVER_SUCCESS:
    return {
      ...state,
      loading: { ...state.loading, edit: false },
    };
  case EDIT_DRIVER_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, edit: false },
    };
  case EDIT_DRIVER:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, edit: true },
    };
  case RESET_DRIVER_PASSWORD:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, resetPassword: true },
    };
  case RESET_DRIVER_PASSWORD_SUCCESS:
    return {
      ...state,
      loading: { ...state.loading, resetPassword: false },
    };
  case RESET_DRIVER_PASSWORD_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, resetPassword: false },
    };
  case DELETE_DRIVER:
    return {
      ...state,
      error: { message: '' },
      loading: { ...state.loading, delete: true },
    };
  case DELETE_DRIVER_SUCCESS:
    return {
      ...state,
      loading: { ...state.loading, delete: false },
    };
  case DELETE_DRIVER_FAILED:
    return {
      ...state,
      error: { message: payload },
      loading: { ...state.loading, delete: false },
    };
  default:
    return state;
  }
};

const getDriverSuccess = createAction(GET_DRIVER_SUCCESS);
const getDriverFailed = createAction(GET_DRIVER_FAILED);

export const getDriverDetails = id => (dispatch, getState, api) => {
  dispatch({ type: GET_DRIVER });

  return api.get({ path: `management/user/${id}` })
    .then((res) => {
      dispatch(getDriverSuccess(res));
    })
    .catch((err) => {
      dispatch(getDriverFailed({ message: err.data }));
    });
};

const editDriverSuccess = createAction(EDIT_DRIVER_SUCCESS);
const editDriverFailed = createAction(EDIT_DRIVER_FAILED);

export const editDriverDetails = (userId, formData) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: EDIT_DRIVER });

    api.put({ path: `users/${userId}`, body: formData, v2: true })
      .then(() => {
        dispatch(editDriverSuccess());
        resolve();
      })
      .catch((err) => {
        dispatch(editDriverFailed({ message: err.data }));
        reject();
      });
  })
);

const driverPasswordResetSuccess = createAction(RESET_DRIVER_PASSWORD_SUCCESS);
const driverPasswordResetFailed = createAction(RESET_DRIVER_PASSWORD_FAILED);

export const driverPasswordReset = payload => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch({ type: RESET_DRIVER_PASSWORD });

    api.post({ path: 'management/user/password_forget', body: payload, withAuth: true })
      .then(() => {
        dispatch(driverPasswordResetSuccess());
        dispatch(showNotification('Password reset email is succesvol verzonden.', 'green'));
        resolve();
      })
      .catch(() => {
        dispatch(driverPasswordResetFailed({ message: 'Er ging iets mis. Probeer opnieuw.' }));
        dispatch(showNotification('Er ging iets mis. Probeer opnieuw.'));
        reject();
      });
  })
);

const deleteDriverSuccess = createAction(DELETE_DRIVER_SUCCESS);
const deleteDriverFailed = createAction(DELETE_DRIVER_FAILED);

export const deleteDriver = driverId => (dispatch, getState, api) => {
  dispatch({ type: DELETE_DRIVER });

  return api.del({ path: `management/${driverId}/delete`, v2: true })
    .then(() => {
      dispatch(deleteDriverSuccess());
      dispatch(showNotification('Het account is succesvol verwijderd.', 'green'));
    })
    .catch((err) => {
      dispatch(deleteDriverFailed({ message: err.data }));
      dispatch(showNotification('Er is iets fout gegaan. Probeer opnieuw.'));
    });
};
