export const setCookie = (name, value, days) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = `; expires=${date.toGMTString()}`;
  }
  document.cookie = `${name}=${value}${expires}; Secure; path=/`;
};

export const getCookie = (name) => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  let cookie;
  ca.forEach((c) => {
    if (c.indexOf(nameEQ) === -1) return;
    cookie = c.replace(' ', '').replace(nameEQ, '');
  });
  return cookie;
};

export const removeCookie = (name) => {
  setCookie(name, '', -1);
};
