import styled from 'styled-components';

export const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 36px;

  & button:first-of-type {
    margin-right: 36px;
  }
`;

export const InviteContainer = styled.div`
  padding: 35px 55px;
`;
