import styled from 'styled-components';

export const Icon = styled.span`
  color: ${props => props.theme.red};
  font-weight: 700;
  font-size: 18px;
  vertical-align: middle;
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 36px;
  margin-top: 10px;

  & button:first-of-type {
    margin-right: 36px;
  }
`;
