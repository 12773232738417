import PT from 'prop-types';
import styled, { css } from 'styled-components';

export const Title = styled.p`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 2px;

  ${props => props.titleInvert && css`
    color: ${props.theme.white};
  `}
`;

Title.propTypes = {
  titleInvert: PT.bool,
};
