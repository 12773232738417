import styled from 'styled-components';
import CreatableSelect from 'react-select/lib/Creatable';

export default styled(CreatableSelect)`
  .select-multi__value-container {
    background-color: ${props => props.theme.white};
  }

  .select-multi__multi-value {
    border-radius: 18px;
    cursor: pointer;
    background-color: ${props => props.theme.red};
    margin: 9px;

    &:first-of-type {
      margin-left: 0;
    }
  }

  .select-multi__multi-value__label {
    color: ${props => props.theme.white};
    margin-right: 9px;
    height: 36px;
    margin-left: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 14px;
  }

  .select-multi__multi-value__remove {
    color: ${props => props.theme.white};
    padding-right: 9px;

    &:hover {
      color: ${props => props.theme.text};
      border-top-right-radius: 18px;
      border-bottom-right-radius: 18px;
      background-color: ${props => props.theme.red};
    }
  }

  .select-multi__control {
    padding: 9px 18px;
    background-color: ${props => props.theme.white};
    border-radius: 0;
  }

  .select-multi__control--is-focused {
    border: 1px solid ${props => props.theme.border};
    box-shadow: none;
    &:hover {
      border-color: ${props => props.theme.border};
    }
  }
`;
