import React, { Component } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { getEventEmails } from 'ducks/eventEmails';
import { deleteEventEmail } from 'ducks/eventEmail';

import { Container, Title, Button, EmptyState, Card, Loading } from 'common';
import EventDetailEmailCard from '../EventDetailEmailCard';

import { HeaderContainer } from './styled';

class EventDetailEmailHistory extends Component {
  componentDidMount() {
    this.props.getHistory(this.props.match.params.eventId);
  }

  render() {
    const { history, match, emails, loading, onDelete, getHistory } = this.props;

    return (
      <Container>
        <HeaderContainer>
          <div>
            <Title small>Verstuur email</Title>
            <p>Een email wordt verstuurd naar alle Porsche24 Members die een ticket hebben gekocht voor het event.</p>
          </div>
          <div>
            <Button
              small
              onClick={() => history.push(`/event/${match.params.eventId}/emails/edit`)}
            >
              Nieuwe email
            </Button>
          </div>
        </HeaderContainer>

        {loading && <Loading small />}

        {!loading && emails.length > 0 && emails.map(email => (
          <EventDetailEmailCard
            email={email}
            key={email.id}
            onDelete={onDelete}
            getHistory={getHistory}
          />
        ))}

        {!loading && emails.length === 0 && (
          <Card>
            <EmptyState
              title="Nog geen emails"
              subtitle="Er zijn tot op heden nog geen emails verzonden voor dit event"
            />
          </Card>
        )}
      </Container>
    );
  }
}

EventDetailEmailHistory.propTypes = {
  history: PT.object,
  match: PT.object,
  getHistory: PT.func,
  emails: PT.array,
  loading: PT.bool,
  onDelete: PT.func,
};

export default compose(
  connect(state => ({
    emails: state.eventEmails.data,
    loading: state.eventEmails.loading.eventEmails,
  }), { getHistory: getEventEmails, onDelete: deleteEventEmail }),
  withRouter,
)(EventDetailEmailHistory);
