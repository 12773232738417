import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { media } from 'styles/utils';

import Arrow from 'vectors/arrowRight.svg';

export const Title = styled.h2`
  display: block;
  margin-bottom: 30px;
`;

export const Item = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  cursor: pointer;
  padding: 20px 0px;
  border-top: 1px solid ${props => props.theme.border};
  text-decoration: none;
  color: ${props => props.theme.text};

  &:last-of-type {
    border-bottom: 1px solid ${props => props.theme.border};
  }

  ${media.tablet`
    padding: 40px 0px;
    justify-content: flex-start;
    flex-wrap: nowrap;
  `}
`;

export const ItemInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  ${media.tablet`
    width: 30%;
    margin-bottom: 0;
    display: block;
  `}
`;

export const ItemType = styled.span`
  letter-spacing: 2.2px;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 19px;
  font-weight: bold;
  display: block;
  color: ${props => props.theme.red};
`;

export const ItemDate = styled(ItemType)`
  color: ${props => props.theme.gray};
`;

export const ItemTitle = styled.h3`
  width: 70%;
  font-size: 24px;
  font-weight: normal;
  margin: 0;
  line-height: 30px;
`;

export const ItemArrow = styled(Arrow)`
  display: block;
  width: 8px;
  margin-right: 10px;
  fill: ${props => props.theme.red};
`;
